import axios from 'axios';
import React, { useState } from "react";
import {
    Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Box, FormControl, FormLabel, Select, Image, Flex, Heading, SimpleGrid, useToast, Textarea, FormErrorMessage
} from '@chakra-ui/react';
import avatar4 from 'assets/img/dashboards/grey_world.png';
import { EditIcon, CloseIcon } from '@chakra-ui/icons'
function TicketReason({ isOpen, onClose, incident, setting, onIncidentUpdated}) {
    const toast = useToast();
    const [selectSettings, setSelectSettings] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        id_setting: '',
    });

    const clearForm = () => {
        setFormData({
            id_setting: '',
        });
    };


    const handleSettingChange = (event) => {
        const value = event.target.value;
        setSelectSettings(value);
    };

    const handleSubmit = async (id) => {
        formData.id_setting = selectSettings;
        try {
            const response = await axios.post(`/updateReason/${incident.id}`, formData);
            if (response.status === 200) {
                onIncidentUpdated();
                toast({
                    title: 'Reason added successfully',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                clearForm();
                onClose();
                setFormErrors({});
            }
        } catch (error) {
            toast({
                title: "Error Creating a Ticket",
                status: "error",
                duration: 1000,
                isClosable: true,
            });
            console.error('Error creating employee:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}
            closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent mt={"1%"} background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalHeader

                    textColor='white'
                    textAlign='center'
                    borderRadius='20px'
                    mx="14"
                    mt={6}
                >
                    <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Image
                            src={avatar4}
                            alt="Avatar"
                            style={{ width: '80px', height: '80px', marginRight: '10px' }}
                        />
                    </Flex>
                </ModalHeader>
                <ModalBody mt={2} ml={10} mr={10}>
                    <Box>
                        <Heading fontSize="18px" color={'#808080'} textAlign={'center'} fontFamily="DM Sans" position="relative" letterSpacing="1px" mb='10' mt={10}>
                            ADD REASON
                        </Heading>
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl isInvalid={!!formErrors.id_setting}>
                                <FormLabel
                                    ms='10px'
                                    fontSize='md'
                                    color="#808080"
                                    textAlign={"center"}

                                >
                                    Reason
                                </FormLabel>
                                <Select

                                    w='100%'
                                    fontSize={'md'}
                                    borderRadius={'5px'}
                                    name='id_option'
                                    color={'#cccccc'}
                                    background={"white"}
                                    textAlign={"center"}
                                    value={selectSettings}
                                    onChange={handleSettingChange}
                                    h='44px'
                                    maxh='44px'
                                    errorBorderColor='#fc7466'
                                >
                                    <option value=''>Please select a reason</option>
                                    {setting.map((sc) => (
                                        <option key={sc.id} value={sc.id}>
                                            {sc.name}
                                        </option>
                                    ))}

                                </Select>
                                {formErrors.id_setting && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}

                            </FormControl>
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"6%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Add  <EditIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={onClose}>
                        Close <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
}
export default TicketReason;