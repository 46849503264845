import axios from 'axios';
import React, { useEffect, useState } from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody,
    Box, Flex,
    Text,
    Button,
    VStack
} from '@chakra-ui/react';

import bgMundochat from "assets/img/dashboards/Mundo_chat_2.png";


function ModalNotesUser({ isOpen, onClose, schedule, data, isWeeklyBonusEligible, userAuth }) {


    const [notes, setNotes] = useState([]);

    const fetchData = async (schedules) => {
        try {
            const response = await axios.get(`/notes/${schedule.id}`);
            setNotes(response.data);
        } catch (error) {
            console.error('Error fetching Notes:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []
    );

    const getColorSchemeOption = (option) => {
        const colorMap = {
            1: '#fcd677', //Late
            2: '#8a6dce', //Call out
            3: '#fca066', //Leave early
            4: '#ff9fb8', //No call, no show
            5: '#6bc2ed', //Cell phone use
            6: '#fc7466', //Camera off
            7: '#bf957e', //Other and clock in description
            8: '#a2bd34', //Permission with client 
            9: '#7080fc', //Disciplinary actions
            10: '#424ea8', //Vacations
            11: '#fcd677', //Late from lunch
            12: '#fcd677', //Late from break

        };
        return colorMap[option] || '#b3b3b3'; //default
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'} isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent borderRadius={'20px'} width={"50%"} bg={"#f2f2f2"}>

                <ModalBody display={"flex"} flexDirection={"row"}>
                    <Flex width={"100%"} justify={"space-evenly"} mt={"8%"}>

                        <Box borderRadius={"30px"} bg={"white"}
                            width={"650px"}
                            height={"800px"}
                            overflow='hidden'
                            px={{ base: "10px", md: "40px" }}
                            position='static'
                            style={{
                                overflowY: 'auto',
                                backgroundImage: `url(${bgMundochat})`,
                                backgroundSize: 'auto',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            <Text mt={'40px'} fontSize={"50px"} color={'#1c2147'} fontWeight={"bold"} textAlign={'left'}>
                                Notes
                            </Text>

                            <Box fontSize={"16px"} color={'#b3b3b3'}   >
                                <Flex style={{ display: 'flex' }} flexDirection={"column"} width={"max-content"} color={'#7080fc'} fontSize={'24px'}  >

                                    <span style={{ marginRight: '8px', color: '#7080fc', fontWeight: 'bold' }}>{schedule.user.name} {schedule.user.last_name}</span>
                                    <span style={{ marginRight: '8px', color: '#808080', marginTop: '10px', fontStyle: 'italic' }}>{schedule.date}</span>
                                </Flex>
                            </Box>

                            <Flex direction={'column'} height={"560px"} overflowY={"auto"}>


                                {notes && notes.length > 0 ? (
                                    notes.map((note, index) => (
                                        <Flex p={1}
                                            key={note.id}
                                            boxSizing='border-box'
                                            direction={'column'}
                                        >
                                            <VStack spacing={3}>
                                                <Flex flexDirection={"column"} width={"100%"}  >
                                                    <Box width={"100%"} mt={2} mb={4} height={"4px"} bg={"#e6e6e6"}></Box>
                                                    <Flex>
                                                        <Box width={"22px"} background={getColorSchemeOption(note.option)} borderRadius='5px' height={"auto"} ></Box>

                                                        <Box textAlign={'center'} color={'#b3b3b3'} textColor={"#b3b3b3"} fontWeight='medium' bg={"white"} borderRadius={"10px"} ml={4} shadow={"0px 0px 8px 0px"}>
                                                            <Text width={"380px"} textAlign={"left"} fontSize={'14px'} p={2} textColor={"black"}>
                                                                {(() => {
                                                                    switch (note.option) {
                                                                        case 1: // Arriving late
                                                                            return "Arriving late";
                                                                        case 2: // Call out
                                                                            return "Call out";
                                                                        case 3: // Leave early
                                                                            return "Leave early";
                                                                        case 4: // No call, no show
                                                                            return "No call, no show";
                                                                        case 5: // Cell phone use during shift
                                                                            return "Cell phone use during shift";
                                                                        case 6: // Having the camera off
                                                                            return "Having the camera off";
                                                                        case 8: // Asking for permission directly to the client
                                                                            return "Asking for permission directly to the client";
                                                                        case 9: // Disciplinary actions
                                                                            return "Disciplinary actions";
                                                                        case 11: // Late from lunch
                                                                            return "Late from lunch";
                                                                        case 12: // Late from break
                                                                            return "Late from break";
                                                                        case 13: // No longer with us
                                                                            return "No longer with us";
                                                                        default:
                                                                            return "Other. For any clarification, please contact your supervisor";
                                                                    }
                                                                })()}
                                                            </Text>
                                                        </Box>
                                                        {isWeeklyBonusEligible(userAuth) && (
                                                            schedule.user.type_user_id !== 22 && schedule.user.type_user_id !== 36 && schedule.user.type_user_id !== 43 && schedule.user.type_user_id !== 44 ? '' : (
                                                                <Box borderRadius='5px' ml={'5%'} mt={'1%'}>
                                                                    <Text color={'#424ea8'} fontWeight={'bold'}>
                                                                        {(() => {
                                                                            switch (note.option) {
                                                                                case 1: // Arriving late
                                                                                    return "-15 points";
                                                                                case 2: // Call out
                                                                                    return "-50 points";
                                                                                case 3: // Leave early
                                                                                    return "-25 points";
                                                                                case 4: // No call, no show
                                                                                    return "-100 points";
                                                                                case 5: // Cell phone use during shift
                                                                                    return "-5 points";
                                                                                case 6: // Having the camera off
                                                                                    return "-5 points";
                                                                                case 8: // Asking for permission directly to the client
                                                                                    return "-5 points";
                                                                                case 9: // Disciplinary actions
                                                                                    return "-5 points";
                                                                                default:
                                                                                    return "";
                                                                            }
                                                                        })()}
                                                                    </Text>
                                                                </Box>
                                                            )
                                                        )}
                                                    </Flex>
                                                    <Flex ml={12} mt={2} justifyContent={'left'}>
                                                        <Text color={'#808080'} fontSize={'11px'} textAlign={'center'} mr={"4px"}>
                                                            {note.user_id === 1 ? 'Eri System' : `${note.user.name} ${note.user.last_name}`}
                                                        </Text>
                                                        <Text color={'#808080'} fontSize={'11px'} textAlign={'center'} mr={"4px"}>
                                                            {note.created}
                                                        </Text>
                                                        <span style={{ color: '#808080', fontSize: '12px' }}></span>
                                                    </Flex>
                                                </Flex>


                                            </VStack>
                                            <span style={{ borderTop: '4px', borderColor: '#808080', width: '100%' }}></span>
                                        </Flex>
                                    ))
                                ) : (
                                    <Box>
                                        <Text mt={"50%"} bg={"#f2f2f2"} fontSize={"24px"} fontWeight={"bold"} textAlign={"center"}>
                                            No notes
                                        </Text>
                                    </Box>
                                )}
                            </Flex>
                        </Box>

                    </Flex>
                </ModalBody>
                <ModalFooter justifyContent="center" bg={"#f2f2f2"} borderRadius={"0 0 20px 20px"}>
                    <Button bg="#fc7466" color={'white'} _hover={{ background: "#808080", color: 'white' }} width={"120px"} mr={3} borderRadius='8px' fontWeight='bold' onClick={onClose}>

                        <Text mr={1}>
                            Close
                        </Text>
                    </Button>

                </ModalFooter>
            </ModalContent>
        </Modal >
    );
}



export default ModalNotesUser;
