import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import ReportTS from "./components/itemsTS/tableTS";
import FunctionsReports from "./components/itemsTS/FunctionsTS";

export default function ReportsTSOverview() {
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='100px'>
        <FunctionsReports/>
      </Card>
    </Flex>
  );
} 