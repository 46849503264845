import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import {
    useToast, TableContainer
} from '@chakra-ui/react'
import ChangeSchedulesList from './ChangeSchedulesTable';


function ChangeSchedulesFunctions() {
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');
    const [userSearch, setUserSearch] = useState(null);
    const [changeSchedules, setchangeSchedules] = useState([]);
    const [user, setUser] = useState([]);
    const [resetKey, setResetKey] = useState(0);


    const clearFilters = () => {
        setUserSearch(null);
        setCurrentPage(1);
        setResetKey(resetKey + 1);
    }


    const optionsUsers = user.map(user => ({
        value: user.id,
        label: `${user.name} ${user.last_name}`,
    }));


    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            fontSize: '16px',
            marginRight: '3px',
            marginLeft: '10px',
            height: '40px',
            width: '250px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `/changeSchedule?page=${currentPage}`
                + `${userSearch ? `&users=${encodeURIComponent(userSearch)}` : ''}`
            );
            const responseData = response.data;
            setchangeSchedules(responseData.data.data);
            setTotal(responseData.data.total)
            setTotalPages(responseData.data.last_page);
            setUser(responseData.user);
        } catch (error) {
            console.error('Error fetching Change Schedules:', error);
        } finally {
            setLoading(false);
        }
    };

   

    useEffect(() => {
        fetchData();
    }, [currentPage, userSearch, resetKey]);


    const handleSelectChange = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                setUserSearch(selectedOption ? selectedOption.value : null);
                setCurrentPage(1);
                break;            
            default:
                break;
        }
    };

    const exportChangeSchedules = () => {
        axios.get(
            `/changeScheduleP?
            ${userSearch ? `&users=${userSearch}` : ''}`, {
            responseType: 'blob'
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ChangeSchedules_Report.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error('Error al descargar el archivo Excel:', error);
            });
    };


    return (
        <TableContainer mt={"2%"}  >
           <ChangeSchedulesList
           optionsUsers={optionsUsers}
           total={total}
           currentPage={currentPage}
           changeSchedules={changeSchedules}
           handleSelectChange={handleSelectChange}
           setCurrentPage={setCurrentPage}
           customStyles={customStyles}
           totalPages={totalPages}
           filterOptions={filterOptions}
           resetKey={resetKey}
           clearFilters={clearFilters}
           loading={loading}
           exportChangeSchedules={exportChangeSchedules}
           />
          
           
        </TableContainer >

    );
}
export default ChangeSchedulesFunctions; 