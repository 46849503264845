import axios from 'axios';
import React, { useEffect, useState, useRef } from "react";
import Pusher from 'pusher-js';
import {
    Modal, ModalOverlay, ModalContent, ModalBody,
    Box, Flex, VStack,
    Input, Text,
    Button, Icon
} from '@chakra-ui/react';
import { IoPaperPlane } from "react-icons/io5";
import bgMundochat from "assets/img/dashboards/Mundo_chat_2.png";
import { ArrowBackIcon } from '@chakra-ui/icons'


function ModalChatMobile({ isOpen, onClose, selectedIncidentId, incidentID }) {
    const [joiningRoomId, setJoiningRoomId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [canFetchMessages, setCanFetchMessages] = useState(true);
    const [canProcessPusherNotifications, setCanProcessPusherNotifications] = useState(true);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [user, setUser] = useState("");
    const [incident, setIncident] = useState(null);

    const handleLeaveClick = async () => {
        if (selectedIncidentId !== null) {
            setJoiningRoomId(selectedIncidentId);

            try {
                await axios.post(`/chat/rooms/${selectedIncidentId}/leave`);
                onClose();
            } catch (error) {
                console.error('Error al unirse a la sala de chat:', error);
            } finally {
                setJoiningRoomId(null);
            }
        }
    };

    const fetchDataIncident = async () => {
        try {
            const response = await axios.get(`/showTicket/${incidentID}`);
            setIncident(response.data);
        } catch (error) {
            console.error('Error fetching incident:', error);
        }
    };

    const fetchData = async () => {
        try {
            const response = axios.get("/user").then((response) => {
                const user = response.data.user;
                setUser(user);
            });
        } catch (error) {
            console.error('Error al obtener datos del usuario:', error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchDataIncident();
        if (isOpen) {
            const timer = setInterval(() => {
                setElapsedTime(prevTime => prevTime + 1);
            }, 1000);
            return () => clearInterval(timer);
        }
        const intervalId = setInterval(() => {
            fetchDataIncident();
        }, 5000);
        return () => {
            clearInterval(intervalId);
        };
    }, [isOpen]);

    useEffect(() => {
        fetchDataIncident();
        const intervalId = setInterval(() => {
            fetchDataIncident();
        }, 5000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const pusher = new Pusher("20dade1a513097de43eb", {
                cluster: "mt1",
                encrypted: true,
                authEndpoint: "/pusher/auth",
                auth: {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            });
            const chatChannel = pusher.subscribe("pusher");
            chatChannel.bind("new-message", (data) => {
                if (canProcessPusherNotifications) {
                    setMessages((prevMessages) => [...prevMessages, data.message]);
                    setCanProcessPusherNotifications(false);
                    setTimeout(() => {
                        setCanProcessPusherNotifications(true);
                    }, 5000);
                }
            });
            const fetchMessages = () => {
                axios
                    .get(`/chat/messages/${incidentID}`)
                    .then((response) => {
                        setMessages(response.data.messages);
                        setTimeout(() => {
                            setCanFetchMessages(true);
                        }, 5000);
                    })
                    .catch((error) => {
                        console.error(error);
                        setTimeout(() => {
                            setCanFetchMessages(true);
                        }, 10000);
                    });
            };
            if (canFetchMessages) {
                fetchMessages();
                setCanFetchMessages(false);
            }
            return () => {
                chatChannel.unsubscribe();
                pusher.disconnect();
            };
        }
    }, [canFetchMessages, canProcessPusherNotifications]);

    const messagesContainerRef = useRef();


    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            const container = messagesContainerRef.current;
            container.scrollTop = container.scrollHeight - container.clientHeight;
        }
    };

    const sendMessage = () => {
        axios.post('/chat/messages', { message: newMessage, chatRomId: selectedIncidentId, incident_id: incidentID })
            .then(response => {
                const newMessageData = response.data.message;
                const user = response.data.user;
                setMessages([...messages, { ...newMessageData, user }]);
                setNewMessage('');


                setTimeout(() => {
                    scrollToBottom();
                }, 0);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    }

    const getColorIncident = (chatRoom) => {
        const colorMap = {
            1: '#ff6f94',
            2: '#fabb1c',
            3: '#633cbd',
            4: '#fb9539',
            5: '#a2bd34',
            6: '#6bc2ed',
            7: '#7080fc',
        };
        return colorMap[chatRoom] || '#ff6f94';
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'} isCentered closeOnOverlayClick={false} closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent borderRadius={'20px'}>
                <ModalBody display={"flex"} flexDirection={"row"}    >
                    <Box borderRadius={"10px"}
                        width={"100%"}
                        Height={"850px"}
                        overflow='hidden'
                        px={{ base: "10px", md: "20px" }}
                        position='static'

                    >
                        <Box h={"max-content"}    >
                            <Flex className="flextickencamob">
                                <Flex display="flex" direction={'column'} justifyContent={'left'} >
                                    <Flex width={"100%"} justify={"space-between"}>
                                        <Button bg={'#b3b3b3'} color='white' size="md" _hover={{ background: "#b3b3b3" }}
                                            onClick={handleLeaveClick} mr={4} borderRadius={"5px"} title='Minimize chat'>
                                            <ArrowBackIcon boxSize={6} />
                                        </Button>
                                        <Flex>
                                            <Flex>
                                                <Text boxShadow={"1px 2.5px 10px 1px #cccccc"} width={"max-content"} color={'#1c2147'} fontSize={"18px"} fontWeight={"bold"} p={2} px={4} borderRadius={"10px"}>   {incident && incident.clients && incident.clients.name ? incident.clients.name : '---'}{' '} </Text>
                                                <Box borderRadius={"5px"} fontSize={"18px"} textAlign={"center"} p={2} px={4} width={"max-content"} ml={4} color={"white"} bg={getColorIncident(selectedIncidentId)}>
                                                    {(() => {
                                                        switch (selectedIncidentId) {
                                                            case 1:
                                                                return "IT";
                                                            case 2:
                                                                return "HR";
                                                            case 3:
                                                                return "Hiring ";
                                                            case 4:
                                                                return "OnCall ";
                                                            case 5:
                                                                return "Schedules ";
                                                            case 6:
                                                                return "Finances ";
                                                            case 7:
                                                                return "Calls ";
                                                            default:
                                                                return "IT ";
                                                        }
                                                    })()}
                                                </Box>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Text className="txtmsjname">
                                        {incident ? incident.first_message : '---'}
                                    </Text>
                                    <Flex height={"max-content"} flexDirection={"column"}>
                                        <Flex flexDirection={"column"}  >
                                            <Text fontSize={"16px"} fontWeight={"bold"} color={'#1c2147'} mr={2}  >Person Assisting:</Text>
                                            <Text fontSize={"16px"} fontWeight={"bold"} color={'#424ea8'}  >
                                                {incident && incident.supervisor && incident.supervisor.name ? incident.supervisor.name : '---'}{' '}
                                                {incident && incident.supervisor && incident.supervisor.last_name ? incident.supervisor.last_name : '---'}
                                            </Text>
                                        </Flex>
                                        <Text textAlign={"right"} color={'#424ea8'} fontWeight={"bold"} height={"max-content"}   >
                                            {incident ? incident.start : '---'}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Box
                                position='static'
                                style={{
                                    backgroundSize: 'auto',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                }}
                            >
                                <Flex
                                    ref={messagesContainerRef}
                                    className="chattickmob"
                                    style={{
                                        backgroundImage: `url(${bgMundochat})`,
                                        backgroundSize: 'auto',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                    }}
                                >
                                    {messages.map((message) => (
                                        <Flex
                                            direction='column'
                                            key={message.id}
                                            w='100%'
                                            maxW={{ base: "90%", lg: "calc(100% - 80px)" }}
                                            boxSizing='border-box'
                                        >
                                            <VStack borderRadius={"8px"} mt={2} mb={2} spacing={3} align={user.id != message.user_id ? 'start' : 'end'}>
                                                <Flex direction={'row'}>
                                                    <Flex height={"max-content"} borderRadius={user.id !== message.user_id ? '20px 20px 20px 0' : '20px 20px 0 20px'} minWidth={"150px"} maxWidth={"220px"} flexDirection={"column"} bg={user.id != message.user_id ? '#f2f2f2' : '#c3c8f4'}>
                                                        <Box position={"relative"} hidden>
                                                            <Text color={"white"} position={"absolute"} right={0} top={-2} borderRadius={"5px"} p={1} fontSize={"10px"} bg={"#424ea8"}></Text>
                                                        </Box>
                                                        <Flex justifyContent={'left'} mt={'10px'}    >
                                                            <Text className=" tickchatname ">
                                                                {message.users?.name} {message.users?.last_name}
                                                            </Text>
                                                        </Flex>
                                                        <Box
                                                            px={2}
                                                            borderRadius='lg'
                                                            maxWidth={'550px'}
                                                            minWidth={'120px'}
                                                            width={'auto'}
                                                        >
                                                            {message.message !== null && (
                                                                <Text
                                                                    mt={"5%"}
                                                                    textAlign={'justify'}
                                                                    mx={"10px"}
                                                                    color={message.message && (message.message.startsWith('http://') || message.message.startsWith('https://')) ? '#007BFF' : '#1c2147'}
                                                                    fontWeight='bold'
                                                                    fontSize={'15px'}
                                                                    textDecoration={message.message && (message.message.startsWith('http://') || message.message.startsWith('https://')) ? 'underline' : 'none'}
                                                                >
                                                                    {message.message && (message.message.startsWith('http://') || message.message.startsWith('https://')) ? (
                                                                        <a href={message.message} target="_blank" rel="noopener noreferrer">
                                                                            {message.message}
                                                                        </a>
                                                                    ) : (
                                                                        <span>
                                                                            {message.message}
                                                                        </span>
                                                                    )}
                                                                </Text>
                                                            )}
                                                            <Text ml={"10px"} textAlign={'left'} mb={2} fontWeight={"bold"} color={"#424ea8"} fontSize={'small'}>
                                                                {message.created_at}
                                                            </Text>
                                                        </Box>
                                                    </Flex>
                                                </Flex>
                                            </VStack>
                                        </Flex>
                                    ))}
                                </Flex>
                            </Box>
                            <Flex
                                bg={'transparent'}
                                backdropFilter='blur(20px)'
                                height={'75px'}
                            >
                                <Input
                                    mt={"15px"}
                                    variant='search'
                                    fontSize='12px'
                                    pl={{ base: "20px !important", lg: "65px !important" }}
                                    h={"45px"}
                                    maxHeight={"80px"}
                                    width={"290px"}
                                    maxWidth={"290px"}
                                    bg={'#f2f2f2'}
                                    color={'#1c2147'}
                                    fontWeight='500'
                                    _placeholder={{ color: "#cccccc", fontSize: "16px" }}
                                    borderRadius={"10px"}
                                    placeholder={"Type a message"}
                                    type="text"
                                    value={newMessage}
                                    onChange={e => setNewMessage(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                />

                                <Button title='Send message'
                                    borderRadius='50%'
                                    bg={'#1c2147'}
                                    w={{ base: "50px", lg: "50px" }}
                                    h={{ base: "50px", lg: "50px" }}
                                    mt={'10px'}
                                    ml={'10px'}
                                    variant='no-hover'
                                    onClick={sendMessage}
                                >
                                    <Icon
                                        as={IoPaperPlane}
                                        color='white'
                                        w={{ base: "18px", lg: "25px" }}
                                        h={{ base: "18px", lg: "25px" }}
                                    />
                                </Button>
                            </Flex>
                        </Box>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal >
    );
}
export default ModalChatMobile;