import { Text, useColorModeValue, Box } from '@chakra-ui/react';
import Card from 'components/card/Card';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import TimelineItem from 'components/dataDisplay/TimelineItem';

export default function TimelineMobile(props) {
  const { ...rest } = props;
  const textColor = useColorModeValue('#1c2147', 'white');
  const [schedules, setSchedules] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const meetingInfo = async () => {
    try {
      const response = await axios.get('/schedule/show')
      setSchedules(response.data.schedules);
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Error al obtener datos del usuario:', error);
    }
  }

  useEffect(() => {
    meetingInfo();
    const alertTimeout = setTimeout(() => {
      setShowAlert(true);
    }, 2000);
    return () => {
      clearTimeout(alertTimeout);
    };
  }, []);

  const dayMappings = {
    1: 'Mon',
    2: 'Tues',
    3: 'Wed',
    4: 'Thur',
    5: 'Fri',
    6: 'Sat',
    7: 'Sun'
  };

  const typeDayMappings = {
    1: 'Workday',
    2: 'Training',
    3: 'Additional',
    4: 'Coverage',
    5: 'Shadowing',
    6: 'No Shift'
  }

  return (
    <Card width='300px' mr='20px'>
      <Text fontSize="2xl" fontWeight="700" color={textColor}>
        Timeline
      </Text>
      <Text fontSize="md" fontWeight="500" color="#808080" mb="30px">
        Time of your Schedules
      </Text>
      {schedules.length > 0 ? (
        schedules.map(schedule => (
          <Box key={schedule.id} width='650px'>
            <TimelineItem
              mb="16px"
              title={typeDayMappings[schedule.type_daily]}
              day={schedule.day}
              weekday={dayMappings[schedule.id_day]}
              hours={schedule.type_daily === 6 ? '---' : schedule.timeS + " - " + schedule.timeE}
              current
            />
          </Box>
        ))
      ) : (
        <Box display={'flex'} justifyContent={'center'} bg={'#f2f2f2'} borderRadius={'5px'} height={'150px'} width='auto' alignItems={'center'}>
          <Text textAlign={'center'} fontSize={'25px'} color={'#808080'}>Without schedules, please contact your supervisor.</Text>
        </Box>
      )}
    </Card>

  );
}
