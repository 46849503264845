import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import 'jspdf-autotable';
import TicketsCompletedList from '../tableAgent';

function UseIncidentAgent() {
    const [incident, setIncident] = useState([]);
    const [selectIncident, setSelectIncident] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [openTranscript, setOpenTranscript] = useState(false);


    const fetchData = async () => {
        try {
            const response = await axios.get(
                `/incidentsU?page=${currentPage}`
            );
            const responseData = response.data;
            setIncident(responseData.data);

            setTotalPages(responseData.pagination.last_page);
            setTotal(responseData.pagination.total)
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, [currentPage]);


    const closeTranscriptModal = () => {
        setOpenTranscript(false);
    };

    const getColorSchemeForPriority = (priority) => {
        const colorMap = {
            1: '#ff6f94',
            2: '#fabb1c',
            3: '#633cbd',
            4: '#fb9539',
            5: '#a2bd34',
            6: '#6bc2ed',
            7: '#7080fc',
            8: '#fc7466',
        };
        return colorMap[priority] || '#6bc2ed';
    };

    const handleTranscript = async (incident) => {
        try {
            const response = await axios.get(`/incidents/show/${incident.id}`);
            const { incident: detail, supervisor, setting, clients, chat, operatorType, supervisorType, operator, closeUser, closeUserType } = response.data;
            setSelectIncident({ ...incident, setting, clients, chat, operatorType, supervisorType, operator, supervisor, closeUser, closeUserType });
            setOpenTranscript(true);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };
    return(
        <TicketsCompletedList
        incident={incident}
        getColorSchemeForPriority={getColorSchemeForPriority}
        loading={loading}
        handleTranscript={handleTranscript}
        total={total}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        openTranscript={openTranscript}
        closeTranscriptModal={closeTranscriptModal}
        selectIncident={selectIncident}
        currentPage={currentPage}
        />
    )
        
}
    export default UseIncidentAgent;