import { Flex, Button, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import CreateSchedule from "./components/items/Modals/create";
import WeeklySchedulesFunctions from "./components/items/schedulesFunctions";
export default function UsersSchedulesOverview() {
  const [showDailyList, setShowDailyList] = useState(false);
  const toggleView = () => {
    setShowDailyList(!showDailyList);
  };
  const handleBackToWeeklyList = () => {
    setShowDailyList(false);
  };

  const [userAuth, setUserAuth] = useState([]);
  const [userAuthID, setUserAuthID] = useState([]);
  const [userSearch, setUserSearch] = useState(null);
  const [clientSearch, setClientSearch] = useState(null);
  const [postSearch, setPostSearch] = useState('');
  const [acceptSearch, setAcceptSearch] = useState('');
  const [statusSearch, setStatusSearch] = useState('');
  const [statusDSearch, setStatusDSearch] = useState('');
  const [dailySearch, setDailySearch] = useState('');
  const [daySearch, setDaySearch] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [filterDateEnd, setFilterDateEnd] = useState('');


  const fetchDataAuth = async () => {
    try {
      const response = await axios.get("/user");
      const user = parseInt(response.data.user.type_user_id, 10);
      const id = parseInt(response.data.user.id, 10);
      setUserAuth(user);
      setUserAuthID(id);
    } catch (error) {
      console.error('Error al obtener datos del usuario:', error);
    }
  };

  useEffect(() => {
    fetchDataAuth();
  }, []);

  const exportSchedules = () => {
    axios.get(
      `/schedule/export?
      ${userSearch ? `&user=${userSearch}` : ''}
      ${clientSearch ? `&client=${clientSearch}` : ''}
      ${postSearch ? `&post=${postSearch}` : ''}
      ${acceptSearch ? `&accept=${acceptSearch}` : ''}
      ${statusSearch ? `&status=${statusSearch}` : ''}
      ${dailySearch ? `&type_daily=${dailySearch}` : ''}
      ${daySearch ? `&day=${daySearch}` : ''}
      ${filterDate ? `&date=${filterDate}` : ''}
      ${filterDateEnd ? `&dateEnd=${filterDateEnd}` : ''}
      ${statusDSearch ? `&statusD=${statusDSearch}` : ''}
      `, {
      responseType: 'blob'
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'schedules.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.error('Error al descargar el archivo Excel:', error);
      });
  };


  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }} >
      <Card px='100px'>
        <Flex align="center" justifyContent="flex-end" mt={4}>
          {userAuth === 1 || userAuth === 2 || userAuth === 3 || userAuth === 4 || userAuth === 23 || userAuth === 24 || userAuth === 25 || userAuth === 37 ? (
            <Button
              class={showDailyList ? "buttonCancel" : "buttonCreate"}
              onClick={toggleView}
            >
              {showDailyList ? "Back" : "New schedule"}
              {showDailyList ? <ArrowBackIcon ml={2} /> : <AddIcon ml={2} />}
            </Button>
          ) : null}
          {showDailyList ? (
            null
          ) : (
            <Button
              onClick={exportSchedules}
              display="flex"
              bg="#424ea8"
              _hover={{ background: "#1c2147", color: 'white' }}
              color="white"
              borderRadius={'5px'}
            >
              <Text mr={2}>
                Export
              </Text>
              <BsFileEarmarkExcelFill />
            </Button>
          )}
        </Flex>
        {showDailyList ? (
          <CreateSchedule onBackButtonClick={handleBackToWeeklyList} />
        ) : (
          <WeeklySchedulesFunctions
            userAuth={userAuth}
            userAuthID={userAuthID}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            clientSearch={clientSearch}
            setClientSearch={setClientSearch}
            statusSearch={statusSearch}
            setStatusSearch={setStatusSearch}
            statusDSearch={statusDSearch}
            setStatusDSearch={setStatusDSearch}
            dailySearch={dailySearch}
            setDailySearch={setDailySearch}
            postSearch={postSearch}
            setPostSearch={setPostSearch}
            acceptSearch={acceptSearch}
            setAcceptSearch={setAcceptSearch}
            daySearch={daySearch}
            setDaySearch={setDaySearch}
            filterDate={filterDate}
            setFilterDate={setFilterDate}
            filterDateEnd={filterDateEnd}
            setFilterDateEnd={setFilterDateEnd}
          />
        )}
      </Card>
    </Flex>
  );
} 