export const stateOptions = [
   "Aguascalientes",
   "Baja California",
   "Baja California Sur",
   "Chiapas",
   "Chihuahua",
   "CDMX",
   "Coahuila",
   "Colima",
   "Durango",
   "Edo. de México",
   "Guanajuato",
   "Guerrero",
   "Hidalgo",
   "Jalisco",
   "Michoacán",
   "Morelos",
   "Nayarit",
   "Nuevo León",
   "Oaxaca",
   "Puebla",
   "Querétaro",
   "Quintana Roo",
   "San Luis Potosí",
   "Sinaloa",
   "Sonora",
   "Tabasco",
   "Tamaulipas",
   "Tlaxcala",
   "Veracruz",
   "Tlaxcala",
   "Veracruz",
   "Zacatecas"
  ];