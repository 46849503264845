import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import UseSupervisor from "./components/itemsSupervisor/functions";

export default function ReportsSupOverview() {
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card>
        <UseSupervisor/>
      </Card>
    </Flex>
  );
} 