import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import UseClients from "./components/ItemsClients/Functions";


export default function ClientsOverview() {
       
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='100px'>
        <UseClients/>
      </Card>
    </Flex>
  );
}
