import axios from 'axios';
import React, { useEffect, useState } from "react";
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  Text, Button, Flex, Input,
  FormControl, FormErrorMessage
}
  from '@chakra-ui/react';
import ModalChat from './ModalChat';
import Ticket_Gray from "assets/img/dashboards/Ticket_Gray.svg"

function StartModal({ isOpen, onClose, modalContent, selectedIncidentId, incidentID, openModalChat }) {
  const [joiningRoomId, setJoiningRoomId] = useState(null);
  const [incident, setIncident] = useState(null);
  const [modalChat, setModalChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [userAuth, setUserAuth] = useState([]);

  const fetchDataAuth = async () => {
    try {
      const response = await axios.get("/user");
      const user = parseInt(response.data.user.type_user_id, 10);
      setUserAuth(user);
    } catch (error) {
      console.error('Error al obtener datos del usuario:', error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`/showTicket/${incidentID}`);
      setIncident(response.data);
    } catch (error) {
      console.error('Error fetching incident:', error);
    }
  };


  const handleJoinClick = async () => {
    if (selectedIncidentId !== null) {
      setJoiningRoomId(selectedIncidentId);
      try {
        await axios.post(`/chat/rooms/${selectedIncidentId}/joinUser`, { message: newMessage, chatRomId: selectedIncidentId, incident_id: incidentID })
          .then(response => {
            const newMessageData = response.data.message;
            const user = response.data.user;
            setMessages([...messages, { ...newMessageData, user }]);
            setNewMessage('');
            openModalChat();
            onClose();
            setFormErrors({});
          })
          .catch(error => {
            console.error(error);
            setFormErrors(error.response.data.errors);
          });
      } finally {
        setJoiningRoomId(null);
      }
    }
  };

  useEffect(() => {
    if (incidentID) {
      fetchData();
    }
    fetchDataAuth();
  }, [incidentID]);

  const getText = () => {
    switch (selectedIncidentId) {
      case 1:
        return ' Internet issues.';
      case 2:
        return ' Vacation request.';
      case 3:
        return ' Hiring status.';
      case 4:
        return ' Client issues.';
      case 5:
        return ' Adding extra hours.';
      case 6:
        return ' Payment issues.';
      case 7:
        return ' Client feedback.';
      case 8:
        return 'Supervisors task';
      default:
        return 'Please specify the issue.';
    }
  };

  const cancelTicket = () => {
    axios.delete(`/incidents/delete/${incident.id}`)
      .then(response => {
        onClose();
      })
      .catch(error => {
        console.error(error);
      });
  };

  const getColorIncident = (selectedIncidentId) => {
    const colorMap = {
      1: '#ff6f94',
      2: '#fabb1c',
      3: '#633cbd',
      4: '#fb9539',
      5: '#a2bd34',
      6: '#6bc2ed',
      7: '#7080fc',
      8: '#fc7466',
    };
    return colorMap[selectedIncidentId] || '#ff6f94';
  };


  return (
    <Flex>
      <Modal isOpen={isOpen} onClose={onClose} size={'xl'} isCentered closeOnOverlayClick={false}
        closeOnEsc={false}>
        <ModalOverlay />
        <ModalContent
          style={{
            backgroundImage: `url(${Ticket_Gray})`,
            backgroundSize: '180%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left',
            backgroundPositionX: '-350px',
            backgroundPositionY: '-120px'
          }}
          borderRadius={'20px'}
          width={"500px"}
          height={"600px"}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >

          <ModalHeader
            width={"max-content"}
            textAlign="center"
            color={'#1c2147'}
            fontSize={"40px"}
            mt={'50px'}

          >
            Incident Request
          </ModalHeader>

          <Text
            borderRadius={"10px"}
            width={"150px"}
            pt={1}
            fontSize={"22px"}
            color={"white"}
            textAlign={"center"}
            height={"42px"}
            bg={getColorIncident(selectedIncidentId)}
            mt={"2%"}
          >
            {(() => {
              switch (selectedIncidentId) {
                case 1:
                  return "IT";
                case 2:
                  return "HR";
                case 3:
                  return "Hiring";
                case 4:
                  return "OnCall";
                case 5:
                  return "Schedules";
                case 6:
                  return "Finances";
                case 7:
                  return "Calls";
                case 8:
                  return "Supervisors";
                default:
                  return "IT";
              }
            })()}
          </Text>
          <Text
            mt={"10%"}

            fontSize={'18px'}
            textAlign={'center'}
            color={'#1c2147'}
            fontWeight={"bold"}
            width={"max-content"}
          >
            <br />
            Write a brief description of the issue, <br /> like this: <Text as="span" color={'#7a86e6'}>{getText()}</Text>
          </Text>
          <Text ml={10} mt={4} mb={4} fontWeight={"bold"} width={"max-content"} color={"#1c2147"}>
            For support with your incident, <span style={{ color: '#b9ce67' }}> join the chat.</span>
          </Text>
          <FormControl width={"60%"} mb={6} isInvalid={!!formErrors.message}>
            <Input width={"100%"}
              textAlign={"center"}
              bg={"#f2f2f2"}
              borderColor={"#f2f2f2"}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder='Type a message'
            />
            {formErrors.message && (
              <FormErrorMessage display={'flex'} justifyContent={'center'}>{formErrors.message}</FormErrorMessage>
            )}
          </FormControl>
          <ModalFooter width={"max-content"} mb={'30px'}>
            <Button
              bg={'#b9ce67'}
              _hover={{ background: "#8aad01", color: 'white' }}
              color={'white'}
              mr={3}
              borderRadius='10px'
              fontWeight='bold'
              onClick={handleJoinClick}
            >
              <Text mr={1}>
                Join Chat
              </Text>
            </Button>
            <Button
              bg={'#424ea8'}
              _hover={{ background: "#fc7466", color: 'white' }}
              color={'white'}
              mr={3}
              borderRadius='10px'
              fontWeight='bold'
              onClick={cancelTicket}
            >
              Cancel Ticket
            </Button>
          </ModalFooter>
        </ModalContent>

      </Modal >
      {modalChat && (
        <ModalChat isOpen={modalChat} onClose={() => setModalChat(false)} selectedIncidentId={selectedIncidentId} />
      )}
    </Flex>
  );
}

export default StartModal;
