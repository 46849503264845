import React, { useState, useEffect } from 'react';
import {
    Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Menu, MenuButton, MenuItem, MenuList,
    Button, ButtonGroup,
    Flex, Box, Badge,
    Text, Input,
} from '@chakra-ui/react'
import { RepeatIcon } from '@chakra-ui/icons'
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import Select2 from 'react-select'
import axios from 'axios';
import { AiOutlineClear } from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import { TbReportSearch } from "react-icons/tb";
import { FaFilePdf } from "react-icons/fa";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import BillingFilters from './Filters';

function ReportsList({totalPages, currentPage, total, reportsE, loadingText, reportsD, setEndDateFilter, endDateFilter, setStartDateFilter, setTypeSearch, startDateFilter, resetKey, setCurrentPage, userTypeSearch, zIndexValue, clientSearch, usersClients, optionsUsers, filterOptions, optionClients, clearFilters, fetchData, fetchDataE, fetchDataClient,  handleClientChange, customStyles, handleSelectChangeF, handleDownloadExcel, downloadTableAsPDF}) {

    
    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'>
                <Flex zIndex={zIndexValue}
                    direction='row'
                    w='100%'
                    mt={2}
                    justifyContent="flex-end"
                    mb={2}
                >
                    <Flex align="center" justifyContent="flex-end">
                        <Button class="buttonCreate" onClick={handleDownloadExcel}>
                            <Text mr={2}>
                                Excel
                            </Text>
                            <RiFileExcel2Fill />
                        </Button>
                    </Flex>
                    <Flex align="center" justifyContent="flex-end">
                        <Button borderRadius={"10px"} color={"white"} bg={"#fc7466"} _hover={{ bg: "red" }} pr={3} pl={3} height={"43px"} onClick={downloadTableAsPDF}>
                            <Text mr={2}>
                                PDF
                            </Text>
                            <FaFilePdf />
                        </Button>
                    </Flex>
                </Flex>
                <BillingFilters
                setTypeSearch={setTypeSearch}
                clientSearch={clientSearch}
                usersClients={usersClients}
                optionsUsers={optionsUsers}
                userTypeSearch={userTypeSearch}
                handleSelectChangeF={handleSelectChangeF}
                setCurrentPage={setCurrentPage}
                customStyles={customStyles}
                resetKey={resetKey}
                filterOptions={filterOptions}
                optionClients={optionClients}
                handleClientChange={handleClientChange}
                startDateFilter={startDateFilter}
                setStartDateFilter={setStartDateFilter}
                endDateFilter={endDateFilter}
                setEndDateFilter={setEndDateFilter}
                clearFilters={clearFilters}
                />
                <br />
                <Table>
                    <Thead bg={"white"} >
                        <Tr>
                            <Th color='#808080' textAlign={'center'}>Agent</Th>
                            <Th color='#808080' textAlign={'center'}>Shift Duration</Th>
                            <Th color='#808080' textAlign={'center'}>Incident Duration</Th>
                            <Th color='#808080' textAlign={'center'}>Break Durations</Th>
                            <Th color='#808080' textAlign={'center'}>Additional <br /> Hours <br /> Duration</Th>
                            <Th color='#808080' textAlign={'center'}>Coverage <br /> Duration</Th>
                            <Th color='#808080' textAlign={'center'}>Efective Time</Th>
                            <Th color='#808080' textAlign={'center'}>Expected Time</Th>
                        </Tr>
                    </Thead>
                    <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                        {reportsD && reportsD.length > 0 ? (
                            reportsD.map((reportsD, index) => (
                                <Tr className={index % 2 === 0 ? 'white-row' : 'gray-row'} key={index}>

                                    <Td textAlign={'center'}>
                                        {reportsD.user.name} {reportsD.user.last_name}
                                        <br />
                                        {reportsD.user.user_clients && reportsD.user.user_clients.length > 0 ? (
                                            reportsD.user.user_clients.length === 1 ? (
                                                <Badge
                                                    backgroundColor={'#dd67ae'}
                                                    color="white"
                                                    mt={1}
                                                    width={'180px'}
                                                    fontSize={'10px'}
                                                    borderRadius={'5px'}
                                                    textAlign={'center'}
                                                >
                                                    {reportsD.user.user_clients[0].client.name}
                                                </Badge>
                                            ) : (

                                                <Menu>
                                                    <MenuButton
                                                        as={Button}
                                                        backgroundColor={'#dd67ae'}
                                                        color="white" size="sm"
                                                        width={'180px'}
                                                        fontSize={'11px'}
                                                        borderRadius={'5px'}
                                                        mt={1}
                                                        _hover={{ background: "#dd67ae", }}
                                                        _active={{ background: "#dd67ae", }}
                                                        height={'25px'}
                                                        title='Show Clients'
                                                    >
                                                        {`${reportsD.user.user_clients.length} Clients`}
                                                    </MenuButton>
                                                    <MenuList maxH="200px" overflowY="auto">
                                                        {reportsD.user.user_clients.map((client, index) => (
                                                            <MenuItem key={index} justifyContent={'center'} color={'#808080'} width={'80%'} ml={'20px'} mt={'10px'}>
                                                                <Text fontWeight={'bold'} >
                                                                    {client.client.name}
                                                                </Text>
                                                            </MenuItem>
                                                        ))}
                                                    </MenuList>
                                                </Menu>
                                            )
                                        ) : (
                                            <Badge
                                                backgroundColor={'#dd67ae'}
                                                color="white"
                                                mt={1}
                                                width={'180px'}
                                                fontSize={'10px'}
                                                borderRadius={'5px'}
                                                textAlign={'center'}
                                            >
                                                No Client
                                            </Badge>
                                        )}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.durationT}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.durationI}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.durationB}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.durationTE}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.durationCover}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.durationE === '00:00:00' ? '---' : reportsD.durationE}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.durationEX.startsWith('-') ? '---' : reportsD.durationEX}
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Th colSpan={'9'} >
                                    <Text
                                        color='#1c2147'
                                        fontSize={"25px"}
                                        textAlign={"center"}
                                        paddingTop={"15px"}
                                        paddingBottom={"15px"}
                                        bg={"#f2f2f2"}
                                        textTransform={'none'}
                                        fontFamily={'DM Sans'}
                                        letterSpacing="-1px"
                                    >
                                        {loadingText}
                                    </Text>
                                </Th>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
                <Table hidden id='ticketsTable' >
                    <Thead bg={"white"} >
                        <Tr>
                            <Th color='#808080' textAlign={'center'}>ID</Th>
                            <Th color='#808080' textAlign={'center'}>Agent</Th>
                            <Th color='#808080' textAlign={'center'}>Shift Duration</Th>
                            <Th color='#808080' textAlign={'center'}>Incident Duration</Th>
                            <Th color='#808080' textAlign={'center'}>Break Durations</Th>
                            <Th color='#808080' textAlign={'center'}>Additional <br /> Hours <br /> Duration</Th>
                            <Th color='#808080' textAlign={'center'}>Coverage <br /> Duration</Th>
                            <Th color='#808080' textAlign={'center'}>Efective Time</Th>
                            <Th color='#808080' textAlign={'center'}>Expected Time</Th>
                        </Tr>
                    </Thead>
                    <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                        {reportsE && reportsE.length > 0 ? (
                            reportsE.map((reportsE, index) => (
                                <Tr className={index % 2 === 0 ? 'white-row' : 'gray-row'} key={index}>
                                    <Td textAlign={'center'}>
                                        {reportsE.user.profile.mat_dp}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsE.user.name} {reportsE.user.last_name}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsE.durationT === '00:00:00' ? '---' : reportsE.durationT}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsE.durationI === '00:00:00' ? '---' : reportsE.durationI}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsE.durationB === '00:00:00' ? '---' : reportsE.durationB}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsE.durationTE === '00:00:00' ? '---' : reportsE.durationTE}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsE.durationCover === '00:00:00' ? '---' : reportsE.durationCover}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsE.durationE === '00:00:00' ? '---' : reportsE.durationE}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsE.durationEX.startsWith('-') ? '---' : reportsE.durationEX}
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Th colSpan={'9'} >
                                    <Text
                                        color='#1c2147'
                                        fontSize={"25px"}
                                        textAlign={"center"}
                                        paddingTop={"15px"}
                                        paddingBottom={"15px"}
                                        bg={"#f2f2f2"}
                                        textTransform={'none'}
                                        fontFamily={'DM Sans'}
                                        letterSpacing="-1px"
                                    >
                                        No data
                                    </Text>
                                </Th>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </Flex>
            <Box width={"100%"} display={"flex"} mt={"30px"}>
                <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                    {total === 0 ? (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Box mt={'5px'} mr={2} ml={4}>
                                    <TbReportSearch color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    No reports
                                </Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                                    {total}
                                </Text>
                                <Box mt={'5px'} mr={1}>
                                    <TbReportSearch color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    {total === 1 ? 'Report' : 'Reports'}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Flex>
                <ButtonGroup isAttached variant="outline" ml={"34%"} justifyContent={'center'} width={"max-content"}>
                    <Button
                        borderRadius={'0px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                    <Button
                        borderRadius={'0px'} ml={4}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowRightShort fontSize="20px" />
                    </Button>
                </ButtonGroup>
            </Box>
        </TableContainer>
    );

}

export default ReportsList;