const formDataRD = {

    first_day: '',
    date_of_birth: '',
    place_of_birth: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    id: '',
    social_security_card: '',
    birth_certificate: '',
    resume: '',
    school_diploma: '',
    letter_recommendation: '',
    bank_name: '',
    bank_account: '',
    account_name: '',
    swift_bank_code: '',
    paypal_account: '',
    paypal_account_email: '',
    emergency_contact_name: '',
    emergency_contact_phone: '',
    relationship: '',
    nickname: '',
    allergies: '',
    hobbie: '',
    favorite_food: '',
    marital_status: '',
    children: '',
    how_many: '',
    how_many_boys_and_girls: '',
    how_old: '',
    confirmation: '',

};

export default formDataRD;