import axios from 'axios';
import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'
import {
    Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Box,
    FormControl, Text, Flex, Heading, SimpleGrid, useToast, Input, Textarea
} from '@chakra-ui/react';
import { EditIcon, CloseIcon, AddIcon } from '@chakra-ui/icons'
import { FaUser, FaRegTrashAlt } from "react-icons/fa";

function ModalUpdate({ isOpen, onClose, user, setFlashing }) {

    const toast = useToast();
    const [updatedInfo, setUpdatedInfo] = useState({
        name: user?.name || '',
        last_name: user?.last_name || '',
        phone: user.profile?.phone || '',
        personal_email: user.profile?.personal_email || '',
        first_day: user.profile?.first_day || '',
        nationality: user.profile?.nationality || '',
        date_start_calls: user.profile?.date_start_calls || '',
        date_of_birth: user.profile?.date_of_birth || '',
        current_address: user.profile?.current_address || '',
        id_number: user.profile?.id_number || '',
        location: user.profile?.location || '',
        curp: user.profile?.curp || '',
        rfc_tax: user.profile?.rfc_tax || '',
        ssn: user.profile?.ssn || '',
        emergency_contact: user.emergency_info?.emergency_contact || '',
        emergency_contact_number: user.emergency_info?.emergency_contact_number || '',
        relationship: user.emergency_info?.relationship || '',
    });

    const [createdHRInfo, setCreatedHRInfo] = useState({
        user_id: user?.id || '',
        description: '',
    });

    const [notes, setNotes] = useState([]);


    const handleUpdateNew = async (id) => {

        try {
            const formData = new FormData();
            formData.append('id', id);

            // Agrega los campos de actualización al formulario
            for (const key in updatedInfo) {
                formData.append(key, updatedInfo[key]);
            }

            const response = await axios.post(`/users/updateUserInfo/${user.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Importante para enviar archivos
                },
            });
            setFlashing(formData);
            toast({
                title: "Employee Updated",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            onClose();
        } catch (error) {
            console.error(error);

            toast({
                title: "Error Updating Employee",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleCreatedNote = async (id) => {

        try {

            const response = await axios.post(`/notesHR/create`, createdHRInfo);
            toast({
                title: "Note added successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            fetchData();
            setCreatedHRInfo({ ...createdHRInfo, description: '' })
        } catch (error) {
            console.error(error);

            toast({
                title: "Error adding a note",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`/notesHR/${user.id}`);
            setNotes(response.data);
        } catch (error) {
            console.error('Error fetching Notes:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []
    );

    const deleteNote = async (note) => {
        const confirmDelete = await Swal.fire({
            title: 'Do you want to delete this note?',
            text: 'The note will be deleted.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: false
        }).then((result) => {
            return result.isConfirmed
        });

        if (confirmDelete) {
            try {
                const response = await axios.delete(`/notesHR/delete/${note.id}`);
                toast({
                    title: " Note deleted",
                    status: "success",
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                });
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error deleting Note",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'full'} isCentered closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent background={"#f2f2f2"} width={"2400px"} borderRadius={"20px"}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'1%'} fontWeight={'bold'}>
                    <Flex direction={'column'} textAlign={"center"}  >
                        <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} fontWeight={'bold'} fontSize={'30px'} bg={'white'} borderRadius={'20px'} width={'800px'} mb={'20px'} height={'80px'}>
                            <Box ml={'20px'}>
                                <FaUser size={'30px'} />
                            </Box>
                            <span style={{ marginLeft: '8px', marginRight: '20px' }}><span style={{ marginRight: '8px', color: '#1c2147' }}>{user.name} {user.last_name}</span></span>
                        </Flex>
                    </Flex>
                </ModalHeader>
                <ModalBody >
                    <Flex>
                        <Box mr={'5%'} ml={'5%'} bg={'white'} borderRadius={'5%'} w={'40%'}>
                            <Heading
                                as="h2"
                                fontSize="40px"
                                color={'#1c2147'}
                                pl={'12%'}
                                fontFamily="DM Sans"
                                mt={10}
                                mb={'5%'}
                            >
                                General Information
                            </Heading>
                            <SimpleGrid
                                columns='2'
                                gap='30px'
                                maxW='100%'
                                w={{ base: "100%", md: "unset" }}
                                pl={'10%'}
                                pb={'5%'}
                            >
                                <Text ml={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                    Name
                                </Text>
                                <Text color={'#1c2147'} fontWeight={'bold'}>
                                    Last name
                                </Text>
                                <FormControl>

                                    <Input
                                        background="#f2f2f2"
                                        borderRadius="10px"
                                        className='custom-placeholderInputs'
                                        w='220px'
                                        color={'#424ea8'}
                                        fontWeight={'bold'}
                                        ml={'5%'}
                                        placeholder="Name"
                                        value={updatedInfo.name}
                                        onChange={(e) =>
                                            setUpdatedInfo({ ...updatedInfo, name: e.target.value })
                                        }
                                    />
                                </FormControl>

                                <FormControl>
                                    <Input
                                        background="#f2f2f2"
                                        borderRadius="10px"
                                        color={'#424ea8'}
                                        fontWeight={'bold'}
                                        className='custom-placeholderInputs'
                                        w='230px'
                                        placeholder="Last name"
                                        value={updatedInfo.last_name}
                                        onChange={(e) =>
                                            setUpdatedInfo({ ...updatedInfo, last_name: e.target.value })
                                        }
                                    />
                                </FormControl>

                                <Text ml={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                    Phone Number
                                </Text>
                                <Text color={'#1c2147'} fontWeight={'bold'}>
                                    Personal Email
                                </Text>

                                <FormControl>
                                    <Input

                                        background="#f2f2f2"
                                        borderRadius="10px"
                                        color={'#424ea8'}
                                        fontWeight={'bold'}
                                        className='custom-placeholderInputs'
                                        w='220px'
                                        ml={'5%'}
                                        type="text"
                                        placeholder="Phone"
                                        value={updatedInfo.phone}
                                        onChange={(e) =>
                                            setUpdatedInfo({ ...updatedInfo, phone: e.target.value })
                                        }
                                    />
                                </FormControl>

                                <FormControl>
                                    <Input

                                        background="#f2f2f2"
                                        borderRadius="10px"
                                        fontWeight={'bold'}
                                        className='custom-placeholderInputs'
                                        color={'#424ea8'}
                                        w='230px'
                                        type="text"
                                        placeholder="Personal email"
                                        value={updatedInfo.personal_email}
                                        onChange={(e) =>
                                            setUpdatedInfo({ ...updatedInfo, personal_email: e.target.value })
                                        }
                                    />
                                </FormControl>

                                <Text ml={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                    Date of Birth
                                </Text>
                                <Text color={'#1c2147'} fontWeight={'bold'}>
                                    Nationality
                                </Text>

                                <FormControl>
                                    <Input

                                        background="#f2f2f2"
                                        borderRadius="10px"
                                        fontWeight={'bold'}
                                        className='custom-placeholderInputs'
                                        color={'#424ea8'}
                                        w='220px'
                                        ml={'5%'}
                                        type="date"
                                        value={updatedInfo.date_of_birth}
                                        onChange={(e) =>
                                            setUpdatedInfo({ ...updatedInfo, date_of_birth: e.target.value })
                                        }
                                    />
                                </FormControl>

                                <FormControl>
                                    <Input

                                        background="#f2f2f2"
                                        borderRadius="10px"
                                        fontWeight={'bold'}
                                        className='custom-placeholderInputs'
                                        color={'#424ea8'}
                                        w='230px'
                                        type="text"
                                        placeholder="Nationaility"
                                        value={updatedInfo.nationality}
                                        onChange={(e) =>
                                            setUpdatedInfo({ ...updatedInfo, nationality: e.target.value })
                                        }
                                    />
                                </FormControl>

                                <Text ml={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                    Current address
                                </Text>
                                <Text color={'#1c2147'} fontWeight={'bold'}>
                                    CURP
                                </Text>

                                <FormControl>
                                    <Input

                                        background="#f2f2f2"
                                        borderRadius="10px"
                                        className='custom-placeholderInputs'
                                        color={'#424ea8'}
                                        fontWeight={'bold'}
                                        w='220px'
                                        ml={'5%'}
                                        type="text"
                                        placeholder="Current address"
                                        value={updatedInfo.current_address}
                                        onChange={(e) =>
                                            setUpdatedInfo({ ...updatedInfo, current_address: e.target.value })
                                        }
                                    />
                                </FormControl>

                                <FormControl>
                                    <Input

                                        background="#f2f2f2"
                                        borderRadius="10px"
                                        className='custom-placeholderInputs'
                                        fontWeight={'bold'}
                                        color={'#424ea8'}
                                        w='230px'
                                        type="text"
                                        placeholder="CURP"
                                        value={updatedInfo.curp}
                                        onChange={(e) =>
                                            setUpdatedInfo({ ...updatedInfo, curp: e.target.value })
                                        }
                                    />
                                </FormControl>

                                <Text ml={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                    RFC
                                </Text>
                                <Text color={'#1c2147'} fontWeight={'bold'}>
                                    SSN (Social Security Number)
                                </Text>

                                <FormControl>
                                    <Input

                                        background="#f2f2f2"
                                        borderRadius="10px"
                                        className='custom-placeholderInputs'
                                        fontWeight={'bold'}
                                        color={'#424ea8'}
                                        w='220px'
                                        ml={'5%'}
                                        type="text"
                                        placeholder="RFC"
                                        value={updatedInfo.rfc_tax}
                                        onChange={(e) =>
                                            setUpdatedInfo({ ...updatedInfo, rfc_tax: e.target.value })
                                        }
                                    />
                                </FormControl>

                                <FormControl>
                                    <Input

                                        background="#f2f2f2"
                                        borderRadius="10px"
                                        className='custom-placeholderInputs'
                                        fontWeight={'bold'}
                                        color={'#424ea8'}
                                        w='230px'
                                        type="text"
                                        placeholder="Social Security Number"
                                        value={updatedInfo.ssn}
                                        onChange={(e) =>
                                            setUpdatedInfo({ ...updatedInfo, ssn: e.target.value })
                                        }
                                    />
                                </FormControl>
                                <Text color={'#1c2147'} fontWeight={'bold'} ml={'5%'}>
                                    First day
                                </Text>
                                <Text ml={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                    Date start calls
                                </Text>

                                <FormControl>
                                    <Input

                                        background="#f2f2f2"
                                        borderRadius="10px"
                                        className='custom-placeholderInputs'
                                        fontWeight={'bold'}
                                        color={'#424ea8'}
                                        w='220px'
                                        ml={'5%'}
                                        type="date"
                                        value={updatedInfo.first_day}
                                        onChange={(e) =>
                                            setUpdatedInfo({ ...updatedInfo, first_day: e.target.value })
                                        }
                                    />
                                </FormControl>
                                <FormControl>
                                    <Input

                                        background="#f2f2f2"
                                        borderRadius="10px"
                                        className='custom-placeholderInputs'
                                        fontWeight={'bold'}
                                        color={'#424ea8'}
                                        w='220px'
                                        ml={'5%'}
                                        type="date"
                                        value={updatedInfo.date_start_calls}
                                        onChange={(e) =>
                                            setUpdatedInfo({ ...updatedInfo, date_start_calls: e.target.value })
                                        }
                                    />
                                </FormControl>
                            </SimpleGrid>
                        </Box>
                        <Box bg={'white'} borderRadius={'5%'} width={'30%'} mr={'5%'} height={'max-content'}>
                            <Heading
                                as="h2"
                                fontSize="40px"
                                color={'#1c2147'}
                                textAlign={'center'}
                                fontFamily="DM Sans"
                                mt={10}
                                mb={'5%'}
                            >
                                Emergency Contact
                            </Heading>
                            <SimpleGrid
                                columns='1'
                                gap='30px'
                                maxW='100%'
                                w={{ base: "100%", md: "unset" }}
                                pl='25%'
                                pb={'8%'}
                            >
                                <Text ml={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                    Emergency contact name
                                </Text>
                                <FormControl>

                                    <Input

                                        background="#f2f2f2"
                                        borderRadius="10px"
                                        className='custom-placeholderInputs'
                                        color={'#424ea8'}
                                        w='220px'
                                        ml={'5%'}
                                        fontWeight={'bold'}
                                        placeholder="Emergency contact name"
                                        value={updatedInfo.emergency_contact}
                                        onChange={(e) =>
                                            setUpdatedInfo({ ...updatedInfo, emergency_contact: e.target.value })
                                        }
                                    />
                                </FormControl>

                                <Text color={'#1c2147'} fontWeight={'bold'}>
                                    Emergency contact phone number
                                </Text>
                                <FormControl>

                                    <Input

                                        background="#f2f2f2"
                                        borderRadius="10px"
                                        className='custom-placeholderInputs'
                                        color={'#424ea8'}
                                        fontWeight={'bold'}
                                        w='220px'
                                        ml={'5%'}
                                        placeholder="Emergency contact phone"
                                        value={updatedInfo.emergency_contact_number}
                                        onChange={(e) =>
                                            setUpdatedInfo({ ...updatedInfo, emergency_contact_number: e.target.value })
                                        }
                                    />
                                </FormControl>
                                <Text ml={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                    Relationship
                                </Text>
                                <FormControl>
                                    <Input

                                        background="#f2f2f2"
                                        borderRadius="10px"
                                        className='custom-placeholderInputs'
                                        color={'#424ea8'}
                                        fontWeight={'bold'}
                                        w='230px'
                                        ml={'5%'}
                                        placeholder="Relationship"
                                        value={updatedInfo.relationship}
                                        onChange={(e) =>
                                            setUpdatedInfo({ ...updatedInfo, relationship: e.target.value })
                                        }
                                    />
                                </FormControl>
                            </SimpleGrid>
                        </Box>
                        <Box bg={'white'} borderRadius={'5%'} width={'40%'} mr={'5%'}>
                            <Heading
                                as="h2"
                                ml={'5%'}
                                fontSize="50px"
                                color={'#1c2147'}
                                textAlign={'left'}
                                fontFamily="DM Sans"
                                mt={10}
                                mb={'5%'}
                            >
                                Notes
                            </Heading>
                            <Flex justifyContent={'center'} display={'flex'} alignItems={'center'}>
                                <Textarea
                                    mr={'2%'}
                                    background="#f2f2f2"
                                    borderRadius="10px"
                                    className='custom-placeholderInputs'
                                    color={'#424ea8'}
                                    fontWeight={'bold'}
                                    w='80%'
                                    placeholder="Message..."
                                    value={createdHRInfo.description}
                                    onChange={(e) =>
                                        setCreatedHRInfo({ ...createdHRInfo, description: e.target.value })
                                    }
                                />
                                <Button class="buttonCreate" onClick={handleCreatedNote}>
                                    <AddIcon />
                                </Button>
                            </Flex>
                            <Flex direction={'column'} height={"560px"} overflowY={"auto"}>
                                {notes && notes.length > 0 ? (
                                    notes.map((note) => (
                                        <Flex p={1}
                                            key={note.id}
                                            boxSizing='border-box'
                                            direction={'column'}
                                        >
                                            <Flex flexDirection={"column"} width={"100%"}  >
                                                <Flex mt={'2%'} justifyContent={'center'}>
                                                    <Box textAlign={'left'} fontWeight='bold' bg={"white"} borderRadius={"10px"} shadow={"0px 0px 3px 0px"} p={'2%'} w={'50%'}>
                                                        <Text color={'#1c2147'} fontSize={'16px'}>
                                                            {note.create_user.name} {note.create_user.last_name}
                                                        </Text>
                                                        <Text color={'#424ea8'} fontSize={'16px'}>
                                                            {note.create_user.user_type.name}
                                                        </Text>
                                                        <Text width={"100%"} fontSize={'13px'} color={"#999999"} mt={'5%'}>{note.description}</Text>
                                                    </Box>
                                                    <Button
                                                        mt={'8%'}
                                                        ml={2}
                                                        bg={"transparent"}
                                                        _hover={{ background: "transparent" }}
                                                        onClick={() => deleteNote(note)}
                                                    >
                                                        <FaRegTrashAlt size={"22px"} />
                                                    </Button>
                                                </Flex>
                                            </Flex>

                                        </Flex>
                                    ))
                                ) : (
                                    <Flex display={'flex'} justifyContent={'center'}>
                                        <Box width={'200px'} mt={'30px'} >
                                            <Text bg={"#f2f2f2"} fontSize={"24px"} fontWeight={"bold"} textAlign={"center"}>
                                                No notes
                                            </Text>
                                        </Box>
                                    </Flex>
                                )}
                            </Flex>
                        </Box>

                    </Flex>
                    <Flex justifyContent={'center'} mt={'3%'}>
                        <Button class="buttonCreate" onClick={() => handleUpdateNew(user.id)}>
                            Update <EditIcon ml={2} />
                        </Button>
                        <Button class="buttonCancel" onClick={onClose}>
                            Close <CloseIcon ml={2} />
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ModalUpdate;
