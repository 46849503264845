// Chakra imports
import { Flex, Text, useColorModeValue, Image } from '@chakra-ui/react';
// Custom components
import { HSeparator } from 'components/separator/Separator';
import Logo from "assets/img/home/Dedicated Word-01.jpg";
import avatar4 from 'assets/img/dashboards/logo2.png';
import React from "react";
import { Link } from 'react-router-dom';


export function SidebarBrand(props) {

  
  const { mini, hovered } = props;
  //   Chakra color mode
  let logoColor = useColorModeValue('navy.700', 'white');

  return (
    <Flex alignItems="center" flexDirection="column" pb={"10px"}  >
      <Link to="/admin">
        <Image src={mini ? avatar4 : Logo}
          width={mini ? "50%" : "85%"} ml={'20px'} mb={"2%"}
          mt={mini ? "50%" : "1%"}
        />
      </Link>
      <Text
        display={
          mini === false
            ? 'none'
            : mini === true && hovered === true
              ? 'none'
              : 'block'
        }
        fontSize={'20px'}
        fontWeight="800"
        color={logoColor}
      >
      </Text>
    </Flex>
  );
}

export default SidebarBrand;
