import React from "react";
import {
  Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, Text
} from '@chakra-ui/react';

function getColorForResolved(resolvedValue) {
  switch (resolvedValue) {
    case 'Definitive solution':
      return '#b9ce67';
    case 'Temporal solution':
      return '#fcd677';
    case 'Incomplete solution':
      return '#fc7466';
    default:
      return '#000000';
  }
}


function Show({ isOpen, onClose, incident }) {

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'4xl'} closeOnOverlayClick={false}
      closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent mt={"10%"} boxShadow={"8px 8px 5px 1px #b3b3b3"} borderRadius={"30px"}>
        <ModalHeader
          textColor='#1c2147'
          textAlign='center'
          mx="14"
          mt={6}
          fontSize={'30px'}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}
        >

        </ModalHeader>
        <ModalBody         >
          <Flex flexDirection={"row"}>
            <Flex display={'flex'} pl={16} justifyContent={'center'} width={"max-content"} flexDirection="column" alignItems="left"  >
              <Text
                textColor='#1c2147'
                width={"max-content"}
                mt={6}
                fontWeight={"bold"}
                fontSize={'60px'}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}
              >
                Ticket info
              </Text>
              <Flex display="flex" alignItems="center" justifyContent={'left'} mt={4}>
                <Text color={'#b3b3b3'} width={"145px"}>
                  Claimed by:
                </Text>
                <Text mr={4} fontWeight={'bold'} color={'#7a86e6'}>
                  {incident.supervisor ? incident.supervisor.name : '---'} {incident.supervisor ? incident.supervisor.last_name : '---'} - {incident.supervisorType ? incident.supervisorType.name : '---'}
                </Text>
              </Flex>
              <Flex display="flex" alignItems="center" justifyContent={'left'} mt={4}>
                <Text color={'#b3b3b3'} width={"145px"}>
                  Completed by:
                </Text>
                <Text mr={4} fontWeight={'bold'} color={'#7a86e6'}>{incident.closeUser.name} {incident.closeUser.last_name} - {incident.closeUserType.name}</Text>
              </Flex>
              <Flex display="flex" alignItems="center" justifyContent={'left'} mt={4}>
                <Text color={'#b3b3b3'} width={"145px"}>
                  Employee:
                </Text>
                <Text mr={4} maxWidth={"300px"} fontWeight={'bold'} color={'#1c2147'}>{incident.operator.name} {incident.operator.last_name}- {incident.operatorType.name}</Text>
              </Flex>
              <Flex display="flex" alignItems="center" justifyContent={'left'} mt={4}>
                <Text color={'#b3b3b3'} width={"145px"}>
                  Department:
                </Text>
                <Text mr={4} fontWeight={'bold'} color={'#b9ce67'}>{incident.chat.name}</Text>
              </Flex>
              <Flex display="flex" alignItems="center" justifyContent={'left'} mt={4}>
                <Text color={'#b3b3b3'} width={"145px"}>
                  Issue:
                </Text>
                <Text mr={4} fontWeight={'bold'} color={'#424ea8'}>{incident.option}</Text>
              </Flex>
              <Flex display="flex" alignItems="center" justifyContent={'left'} mt={4} >
                <Text color={'#b3b3b3'} width={"145px"} >
                  Problematic:
                </Text>
                <Text mr={4} fontWeight={'bold'} color={'#c3c8f4'} width={"max-content"} maxWidth={"330px"} textAlign={'justify'} >{incident.problematic}</Text>
              </Flex>
              <Flex display="flex" alignItems="center" justifyContent={'left'} mt={4} >
                <Text color={'#b3b3b3'} width={"145px"} >
                  Solution:
                </Text>
                <Text mr={4} fontWeight={'bold'} color={'#c3c8f4'} width={"max-content"} maxWidth={"330px"} textAlign={'justify'} >{incident.solution}</Text>
              </Flex>
              <Flex display="flex" alignItems="center" justifyContent={'left'} mt={4}>
                <Text color={'#b3b3b3'} width={"145px"}>
                  Resolved:
                </Text>
                <Text mr={4} fontWeight={'bold'} color={getColorForResolved(incident.resolved)}>
                  {incident.resolved}
                </Text>
              </Flex>
              <Button bg="#424ea8" mb={8} mt={8} p={4} color={'white'} width={"max-content"} _hover={{ background: "#808080", color: 'white' }} onClick={onClose} borderRadius={'5px'} size={'md'}>
                Close
              </Button>
            </Flex>
            <Flex flexDirection={"column"} width={"300px"} borderRadius={"30px"} px={8} py={8} height={"max-content"} bg={"#f2f2f2"}>
              <Flex display="flex" justifyContent={'left'} mt={10}  >
                <Text color={'#b3b3b3'} width={"100px"} fontSize={"lg"}  >
                  Started at:
                </Text>
                <Flex flexDirection={"column"}>
                  <Text mr={4} color={'#1c2147'} fontWeight={"bold"} fontSize={"lg"}  >
                    {incident.startHour}
                  </Text>
                  <Text as="span" color={'#b3b3b3'} fontSize={"sm"}>{incident.startDate} </Text>
                </Flex>
              </Flex>
              <Flex display="flex" justifyContent={'left'} mt={6}>
                <Text color={'#b3b3b3'} width={"100px"} fontSize={"lg"}>
                  Claimed at:
                </Text>
                <Flex flexDirection={"column"}>
                  <Text mr={4} color={'#1c2147'} fontWeight={"bold"} fontSize={"lg"}>
                    {incident.claimedHour}
                  </Text>
                  <Text as="span" color={'#b3b3b3'} fontSize={"sm"}>{incident.claimedDate}</Text>
                </Flex>
              </Flex>

              <Flex display="flex" justifyContent={'left'} mt={6}>
                <Text color={'#b3b3b3'} width={"100px"} fontSize={"lg"}>
                  End time:
                </Text>
                <Flex flexDirection={"column"}>
                  <Text mr={4} color={'#1c2147'} fontWeight={"bold"} fontSize={"lg"}>
                    {incident.endHour}
                  </Text>
                  <Text as="span" color={'#b3b3b3'} fontSize={"sm"}>{incident.endDate}</Text>
                </Flex>
              </Flex>
              <Flex display="flex" alignItems="center" justifyContent={'left'} mt={6}>
                <Text color={'#b3b3b3'} width={"145px"} fontSize={"lg"}>
                  Duration   claimed:
                </Text>
                <Text mr={4} color={'#1c2147'}> {incident.duration_claimed}</Text>
              </Flex>
              <Flex display="flex" alignItems="center" justifyContent={'left'} mt={"74%"} bg={"white"} borderRadius={"10px"} p={2}>
                <Text color={'#b3b3b3'} width={"145px"} fontSize={"lg"}>
                  Duration:
                </Text>
                <Text mr={4} fontWeight={'bold'} color={'#424ea8'}> {incident.duration}</Text>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>

      </ModalContent>
    </Modal>
  );
}
export default Show;
