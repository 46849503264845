// Chakra Imports
import {
  Button,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
// Assets
export default function ConfiguratorButton(props) {

  const { routes, mini, hovered, setMini } = props;

  const borderButton = useColorModeValue('secondaryGray.100', 'whiteAlpha.200');
  const activeShadow = useColorModeValue(
    '0px 0px 8px 3px #cccccc',
    'none',
  );
  const Bg = useColorModeValue('transparent', 'navy.700');
  const activeBg = useColorModeValue('#F7F9FF', 'whiteAlpha.100');

  return (
    <Flex display={'flex'} mt={'5%'}>
      <Button
        cursor={"pointer"} borderRadius={"6px"} 
        width={"max-content"}
        h="max-content"
        ml={mini === false
          ? '0'
          : mini === true && hovered === true
            ? '0'
            : '30px'}
        py="4px"
        display={'flex'}
        flexDirection="column"
        bg={props.active === true ? Bg : 'transparent'}
        _hover={{ background: 'transparent' }}
        borderColor={borderButton}
        onClick={props.onClick}
        as="label"
        px={{ base: '10px', md: 'none' }}
      >
        <Flex w="100%" justifyContent={'space-between'} mb="10px">
          {props.label}
        </Flex>
        {props.children}
      </Button>
    </Flex>
  );
}
