import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import VacationsFunctions from "./items/VacationsFunctions";


export default function OverviewVacations() {

  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='100px'>
        <VacationsFunctions />
      </Card>
    </Flex>
  );
}
