import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormErrorMessage

} from '@chakra-ui/react';

// Custom components
import DefaultAuth from 'layouts/auth/types/Default';

// Assets
import illustration from 'assets/img/auth/DP.png';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'


function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue('#1c2147', 'white');
  const textColorSecondary = '#808080';
  const brandStars = useColorModeValue('#fc7466', '#fc7466');
  axios.defaults.withCredentials = true;

  //Login
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const BASE_URL = '/login';
  const [loginError, setLoginError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      setEmailError('');
      setPasswordError('');

      const response = await axios.post(BASE_URL, {
        email: email,
        password: password,
      }, {
        withCredentials: true,
      });

      const { token, password_change_required, expires_at } = response.data;

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      localStorage.setItem('token', token);
      localStorage.setItem('expires_at', expires_at);

      if (password_change_required) {
        navigate('/change-password');
      } else {
        navigate('/admin');
      }
    } catch (error) {
      console.error(error);

      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;
        setEmailError(errors.email ? errors.email.join(', ') : '');
        setPasswordError(errors.password ? errors.password.join(', ') : '');
      } else {
        setLoginError(true);
      }
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box >
          <Heading color={textColor} fontSize="60px" align='center'>
            Welcome
          </Heading>
          <Text
            mb="35px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
            textAlign='center'
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '40px', md: '250px' }}
        >

          <Flex align="center" mb="25px">
          </Flex>
          <FormControl isInvalid={!!emailError}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              color={"#1c2147"}
              fontWeight='bold'
              mb="8px"
             
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              fontSize="sm"
              type="email"
              placeholder="mail@simmmple.com"
              color={'#808080'}
              size="lg"
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleLogin(e);
                }
              }}
            />
            <FormErrorMessage>{emailError}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!passwordError} mt={6}>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight='bold'
              color={"1c2147"}
              isRequired={true}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder="Min. 8 characters"
                color={'#808080'}
                size="lg"
                defaultValue={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleLogin(e);
                  }
                }}
              />
              <InputRightElement width="4.5rem" m={1} mr={6}>
                <Button
                  h="1.75rem"
                  size="sm"
                  bg={'transparent'}
                  _hover={{ background: "#1c2147", color: 'white' }}
                  color={'#808080'}
                  onClick={() => setShowPassword(!showPassword)}
                  borderRadius={'5px'}
                >
                  {showPassword ? (
                    <>
                      Hide <ViewOffIcon ml={2} />
                    </>
                  ) : (
                    <>
                      Show <ViewIcon ml={2} />
                    </>
                  )}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{passwordError}</FormErrorMessage>
            <Box display={'flex'} justifyContent={'center'}>
              <Button
                fontSize="sm"
                fontWeight="500"
                w="50%"
                h="50"
                mt="55px"
                bg={'#1c2147'}
                color={'white'}
                borderRadius={"5px"}
                _active={{ background: "#808080", color: 'white' }}
                _hover={{ background: "#808080", color: 'white' }}
                onClick={handleLogin}
              >
                Sign In
              </Button>
            </Box>

          </FormControl>


          {loginError && (
            <Alert
              status='error'
              variant='subtle'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              textAlign='center'
              height='200px'
            >
              <AlertIcon boxSize='40px' mr={0} />
              <AlertTitle mt={4} mb={1} fontSize='lg'>
                Attention!
              </AlertTitle>
              <AlertDescription maxWidth='sm'>
                And error occurred during login. Please verify your credentials and try again.
              </AlertDescription>
            </Alert>
          )}
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
