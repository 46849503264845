import React from 'react';
import {
    Button,
    Flex, Box,
    Select, Input
} from '@chakra-ui/react'
import { CalendarIcon } from '@chakra-ui/icons'
import { AiOutlineClear } from "react-icons/ai";
import Select2 from 'react-select'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'jspdf-autotable';

function CompletedTicketsFilters({ idFilter, setIdFilter, clientFilter, usersClients, optionAgents, agentFilter, handleSelectChange, setCurrentPage, customStyles, resetKey, optionSupervisors, supervisorFilter, optionClients, handleClientChange, optionTickets, ticketFilter, statusFilter, setStatusFilter, startDateFilter, setStartDateFilter, endDateFilter, setEndDateFilter, clearFilters }) {


    return (
        <Flex
            direction='column'
            w='max-content'
            height={"max-content"}
            bg="gray.200"
            p={2}
            borderRadius="16px"
            mt={'2%'}
        >
            <Flex align="center" w='max-content' display={"flex"} flexDirection={"row"} height={"max-content"}>
                <Input border={"1px"} borderColor={"#808080"} _hover={{ borderColor: "#808080" }}
                    borderRadius={"5px"}
                    value={idFilter}
                    width={"140px"}
                    minWidth={"130px"}
                    type="text"
                    placeholder={`Ticket #`}
                    onChange={(e) => setIdFilter(e.target.value)}
                    _placeholder={{
                        color: '#808080',
                    }}
                    _focusVisible={'none'}
                />
                <Select2
                    options={clientFilter > 0 ? usersClients : optionAgents}
                    value={clientFilter > 0 ? usersClients.find(option => option.value === agentFilter) : optionAgents.find(option => option.value === agentFilter)}
                    onChange={(selectedOption) => {
                        handleSelectChange('agent', selectedOption);
                        setCurrentPage(1);
                    }}
                    styles={customStyles}
                    placeholder="Agent"
                    key={`userSelect-${resetKey}`}
                />
                <Select2
                    options={optionSupervisors}
                    value={optionSupervisors.find(option => option.value === supervisorFilter)}
                    onChange={(selectedOption) => {
                        handleSelectChange('supervisor', selectedOption);
                        setCurrentPage(1);
                    }}
                    styles={customStyles}
                    placeholder="Supervisor"
                    key={`supervisorSelect-${resetKey}`}
                />
                <Select2
                    options={optionClients}
                    value={optionClients.find(option => option.value === clientFilter)}
                    onChange={(selectedOption) => {
                        handleClientChange(selectedOption);
                        setCurrentPage(1);
                    }}
                    styles={customStyles}
                    placeholder="Client"
                    key={`clientSelect-${resetKey}`}
                />
                <Select2
                    options={optionTickets}
                    value={optionTickets.find(option => option.value === ticketFilter)}
                    onChange={(selectedOption) => {
                        handleSelectChange('ticket', selectedOption);
                    }}
                    styles={customStyles}
                    placeholder="Department"
                    key={`ticketSelect-${resetKey}`}
                />
                <Select
                    borderRadius={"5px"}
                    borderColor={'#808080'}
                    color={'#808080'}
                    _focusVisible={'none'}
                    _hover={{
                        borderColor: "#808080",
                    }}
                    width={'160px'}
                    minWidth={"150px"}
                    ml={4}
                    fontSize={"md"}
                    mr={5}
                    value={statusFilter}
                    onChange={(e) => {
                        setStatusFilter(e.target.value);
                        setCurrentPage(1);
                    }}
                >
                    <option value="">Status</option>
                    <option value="1">Ticket closed</option>
                    <option value="2">On incident</option>
                </Select>
                <Flex
                    border={'1px solid #808080'}
                    display={'flex'}
                    borderRadius={'5px'}
                    mr={4}
                    height={'40px'}
                >
                    <Box mt={2}>
                        <DatePicker
                            selected={startDateFilter}
                            onChange={(date) => setStartDateFilter(date)}
                            placeholderText="Start date"
                            customInput={
                                <input
                                    className="custom-placeholder"
                                    style={{
                                        backgroundColor: 'transparent',
                                        textAlign: 'left',
                                        fontSize: 'medium',
                                        paddingLeft: '20px',
                                        outline: 'none',
                                        color: '#808080',
                                        width: '115px',
                                    }}
                                />
                            }
                        />
                    </Box>
                    <CalendarIcon mr={4} color={'#808080'} mt={'10px'} />
                </Flex>
                <Flex
                    border={'1px solid #808080'}
                    display={'flex'}
                    borderRadius={'5px'}
                    mr={4}
                    height={'40px'}
                >
                    <Box mt={2}>
                        <DatePicker
                            selected={endDateFilter}
                            onChange={(date) => setEndDateFilter(date)}
                            placeholderText="End date"
                            customInput={
                                <input
                                    className="custom-placeholder"
                                    style={{
                                        backgroundColor: 'transparent',
                                        textAlign: 'left',
                                        fontSize: 'medium',
                                        paddingLeft: '20px',
                                        outline: 'none',
                                        color: '#808080',
                                        width: '115px',
                                    }}
                                />
                            }
                        />
                    </Box>
                    <CalendarIcon mr={4} color={'#808080'} mt={'10px'} />
                </Flex>
                <Button onClick={clearFilters} bg={'transparent'} color={'#808080'} borderRadius={"50%"} padding={"0px"} data-tip="Clear filters" >
                    <AiOutlineClear size={'26px'} />
                </Button>

            </Flex>
        </Flex>

    );
}
export default CompletedTicketsFilters;
