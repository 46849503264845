import {
  Box, Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon, Text
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import VacationsRequest from "./forms/vacations";
import PermissionRequest from "./forms/permission";
import ChangeScheduleRequest from "./forms/changeSchedule";

export default function Requests(props) {
  const { user, ...rest } = props;

  return (
    <Card mb='20px' {...rest}>
      <Flex direction={'column'}>
        <Text
          color={'#1c2147'}
          fontWeight='bold'
          fontSize='2xl'
          mt='10px'
          mb='4px'
          ml={'2%'}
        >
          Requests
        </Text>
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: '#424ea8', color: 'white' }}>
                <Box as='span' flex='1' textAlign='left'>
                  Vacations
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <VacationsRequest />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: '#fb9539', color: 'white' }}>
                <Box as='span' flex='1' textAlign='left'>
                  Permission
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <PermissionRequest/>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: '#ff6f94', color: 'white' }}>
                <Box as='span' flex='1' textAlign='left'>
                  Change schedule
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <ChangeScheduleRequest/>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    </Card>
  );
}
