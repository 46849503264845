import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import SettingsList from "./components/table"; 
import UseSettings from "./components/settings";
export default function SettingsOverview() { 
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='100px'>
        <UseSettings/>
      </Card>
    </Flex>
  );
} 