import React, { useState, useEffect } from 'react';
import {
    Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Button, ButtonGroup,Badge, Text,
    Flex, Box,
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";

import CreateUserType from './create';

import UpdateUserType from './update';
import { FaUserGroup } from "react-icons/fa6";
import Buttons from './buttons'
import Filters from './filters'

function UserTypeList({ UserTypeList, setFlashing, selectUserType, closeModalUpdate, ModalUpdateIsOpen, fetchData, closeModalCreate, modalCreateIsOpen, totalPages, currentPage, setCurrentPage, total, loading, handleDelete, openModalCreate, searchType, setSearchType, searchTerm, setSearchTerm, handleSearch, clearFilters, usertype, getRowBackground, handleUpdate }) {

    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'
            >
                <Flex
                    direction='column'
                    w='100%'
                    p={3}
                    borderRadius="16px"
                >
                    <Flex align="center" justifyContent="flex-end" >
                        <Button class="buttonCreate" onClick={openModalCreate}>
                            Add user type <AddIcon ml={2} />
                        </Button>
                    </Flex>
                </Flex>
                <Filters searchType={searchType} setSearchType={setSearchType} searchTerm={searchTerm} setSearchTerm={setSearchTerm} handleSearch={handleSearch} clearFilters={clearFilters} />
                 <br />
                <Table size={'sm'}>
                    <Thead bg={"white"}>
                        <Tr>
                            <Th color='#808080' textAlign={'center'}>ID</Th>
                            <Th color='#808080' textAlign={'center'}>User Type</Th>
                            <Th color='#808080' textAlign={'center'}>Status</Th>
                            <Th textAlign={'center'}>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                        {usertype && usertype.length > 0 ? (
                            usertype.map(usertype => (
                                <Tr key={usertype.id} bg={getRowBackground(usertype)}>
                                    <Td textAlign={'center'}>{usertype.id}</Td>
                                    <Td textAlign={'center'}>{usertype.name}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        <Badge borderRadius={"5px"} color={"white"} bg={usertype.active === 1 ? '#8a6dce' : '#ff9fb8'} width={'75px'}>
                                            {usertype.active === 1 ? 'Active' : 'Inactive'}
                                        </Badge>
                                    </Td>
                                    <Td textAlign={'center'}>
                                        <Buttons usertype={usertype} handleDelete={handleDelete} handleUpdate={handleUpdate} loading={loading} />
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Th colSpan={'9'} >
                                    <Text
                                        color='#1c2147'
                                        fontSize={"25px"}
                                        textAlign={"center"}
                                        paddingTop={"15px"}
                                        paddingBottom={"15px"}
                                        bg={"#f2f2f2"}
                                        textTransform={'none'}
                                        fontFamily={'DM Sans'}
                                        letterSpacing="-1px"
                                    >
                                        No data
                                    </Text>
                                </Th>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </Flex>
            <Box width={"100%"} display={"flex"} mt={"30px"}>
                <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                    {total === 0 ? (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Box mt={'5px'} mr={2} ml={4}>
                                    <FaUserGroup color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    No user type
                                </Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                                    {total}
                                </Text>
                                <Box mt={'5px'} mr={1}>
                                    <FaUserGroup color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    {total === 1 ? 'User Type' : 'User Types'}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Flex>
                <ButtonGroup isAttached variant="outline" ml={"34%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
                    <Button
                        borderRadius={'0px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                    <Button
                        borderRadius={'0px'} ml={4}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowRightShort fontSize="20px" />
                    </Button>
                </ButtonGroup>
            </Box>
            <CreateUserType isOpen={modalCreateIsOpen} onClose={closeModalCreate} onUserTypeCreated={fetchData} />
            {ModalUpdateIsOpen && (
                <UpdateUserType isOpen={ModalUpdateIsOpen} onClose={closeModalUpdate} usertype={selectUserType} onUserTypeUpdated={fetchData} setFlashing={setFlashing} />)}
        </TableContainer>
    );
}
export default UserTypeList; 