// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { BsArrowRight } from 'react-icons/bs';
// Assets
import { IoMdTime } from 'react-icons/io';

export default function Default(props) {
  const { current, day, weekday, hours, title, note, boxColor, miniCardColor, textColor, textSecondaryColor, date, colorDate, ...rest } = props;

  return (
    <Flex
      align="center"
      p="10px"
      borderRadius="10px"
      bg={miniCardColor}
      width={'470px'}
      {...rest}
    >
      <Flex
        me="20px"
        direction="column"
        align="center"
        ml={"2%"}
        justify="center"
        w="80px"
        h="80px"
        borderRadius="15px"
        bg={boxColor}
      >
        <Text
          mb="2px"
          fontSize="sm"
          fontWeight="500"
          color={textSecondaryColor}
        >
          {weekday}
        </Text>
        <Text
          lineHeight="100%"
          fontSize="30px"
          fontWeight="700"
          color={textColor}
        >
          {day}
        </Text>
      </Flex>
      <Box>
        <Text
          fontSize="25px"
          fontWeight="700"
          color={'#1c2147'}
        >
          {title}
        </Text>
        <Flex align="center">
          <Text
            fontSize="md"
            fontWeight="500"
            color={'#808080'}
          >
            {note}
          </Text>
        </Flex>
        <Flex align="center">
          <Text
            fontSize="14px"
            fontWeight="bold"
            color={'#424ea8'}
          >
            {hours}
          </Text>

        </Flex>
        <Flex align="center">
          <Text
            fontSize="15px"
            fontWeight="bold"
            color={colorDate}
          >
            {date}
          </Text>

        </Flex>



      </Box>
    </Flex>
  );
}
