import React, { useState } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input,
    Box, Flex, Heading, SimpleGrid,
    FormControl, FormErrorMessage,
    Image,
    useToast,
} from '@chakra-ui/react';
import avatar4 from 'assets/img/dashboards/grey_world.png';
import { CloseIcon, AddIcon } from '@chakra-ui/icons'

const CreateClient = ({ isOpen, onClose, onClientCreated }) => {
    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        shortcut: '',
    });

    const clearForm = () => {
        setFormData({
            name: '',
            shortcut: '',
        });
        setFormErrors({});
    };

    const handleSubmit = async () => {

        const formSubmitData = {
            name: formData.name,
            shortcut: formData.shortcut,
        };

        try {
            const response = await axios.post('/clients/create', formSubmitData);
            onClientCreated();
            toast({
                title: 'Client Created',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Creating a Client",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error creating client:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };
    return (
        <Modal size="lg" isOpen={isOpen} onClose={() => {
            onClose();
            clearForm();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent mt={"5%"} background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalHeader

                    textColor='white'
                    textAlign='center'
                    borderRadius='20px'
                    mx="14"
                    mt={6}
                >
                    <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Image
                            src={avatar4}
                            alt="Avatar"
                            style={{ width: '80px', height: '80px', marginRight: '10px' }}
                        />
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>
                        <Heading as="h2" fontSize="18px" color={'#808080'} textAlign={'center'} fontFamily="DM Sans" position="relative" letterSpacing="1px" mb='6' mt={10}>
                            ADD CLIENT
                        </Heading>
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl isInvalid={!!formErrors.name}>
                                <Input
                                    type="text"
                                    placeholder="Enter client name"
                                    name='name'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.name}
                                    onChange={handleChange}
                                />
                                {formErrors.name && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.shortcut}>
                                <Input
                                    type="text"
                                    placeholder="Enter a shortcut"
                                    name='shortcut'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.shortcut}
                                    onChange={handleChange}
                                />
                                {formErrors.shortcut && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Add Client <AddIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                        clearForm();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default CreateClient;
