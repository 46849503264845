import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import UsePayroll from "./components/itemsPayroll/functions";

export default function ReportsPROverview() {
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card>
        <UsePayroll/>
      </Card>
    </Flex>
  );
} 