import React, { useState, useEffect } from 'react';
import {
    Menu, MenuButton, MenuItem, MenuList,
    Button, Text
} from '@chakra-ui/react'
import { DeleteIcon, SettingsIcon, RepeatIcon, EditIcon } from '@chakra-ui/icons'

function UserTypeButtons({ handleUpdate, handleDelete, usertype, loading }) {

    return (

        <Menu >
            <MenuButton borderRadius={"5px"} as={Button} _active={{ background: "#808080", color: 'white' }} bg="#1c2147" color={"white"} _hover={{ background: "#808080", color: 'white' }}>
                <SettingsIcon pb={"2px"} />
            </MenuButton>
            <MenuList  >
                <MenuItem isDisabled  >
                    <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions</div>
                </MenuItem>
                <MenuItem
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems="center">
                    <Button
                        borderRadius={"5px"}
                        bg={"#f2f2f2"}
                        color={"#1c2147"}
                        _hover={{ background: "#7080fc", color: 'white' }}
                        onClick={() => handleUpdate(usertype)}
                        width={"100%"}
                        justifyContent="space-between"
                        alignItems="center">
                        <Text flex={1} textAlign="center">
                            Edit user type
                        </Text>
                        <EditIcon ml={2} verticalAlign="middle" />
                    </Button>
                </MenuItem>
                <MenuItem
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems="center">
                    <Button
                        borderRadius={"5px"}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        color={"#1c2147"}
                        _hover={{ background: usertype.active === 1 ? '#de5b5b' : '#8aad01', color: 'white' }}
                        bg={"#f2f2f2"}
                        width="100%"
                        onClick={() => handleDelete(usertype)}
                        isLoading={loading}
                    >
                        {usertype.active === 1 ? (
                            <>
                                <Text flex={1} textAlign={'center'}>Deactivate</Text><DeleteIcon ml={2} />
                            </>
                        ) : (
                            <>
                                <Text flex={1} textAlign={'center'}>Activated</Text><RepeatIcon ml={2} />
                            </>
                        )}
                    </Button>
                </MenuItem>
            </MenuList>
        </Menu>

    );
}
export default UserTypeButtons; 