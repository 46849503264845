import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  useToast,
  Select,
  Flex,
  ButtonGroup,
  Badge,
  Box,
  Text,
  Input
} from '@chakra-ui/react'

import { InfoOutlineIcon, SettingsIcon, SearchIcon, CloseIcon, CheckIcon } from '@chakra-ui/icons'
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import EmployeeInfoModal from './items/modalshow';
import axios from 'axios';
import { AiOutlineClear } from "react-icons/ai";
import EmployeeInfoModalRemoteMx from './items/modalshowRemoteMX';
import EmployeeInfoModalRemoteRD from './items/modalshowRemoteRD';
import EmployeeInfoModalMXBZ from './items/modalshowMX';
import { FaUserCheck } from "react-icons/fa";


function EmployeeList() {

  const toast = useToast();
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('name');
  const [locationSearch, setLocationSearch] = useState('');
  const [location, setLocation] = useState([]);
  const [statusSearch, setStatusSearch] = useState('');


  const clearFilters = () => {
    setSearchType('');
    setSearchTerm('');
    setStatusSearch('');
    setLocationSearch('');
    setCurrentPage(1);
  }


  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/employees?page=${currentPage}&${searchType}=${searchTerm}
      ${locationSearch ? `&location=${locationSearch}` : ''}
      ${statusSearch ? `&status=${statusSearch}` : ''}
      `
      );
      const responseData = response.data;
      setCandidates(responseData.data.data);
      setTotalPages(responseData.data.last_page);
      setLocation(responseData.location);
      setTotal(responseData.data.total)
    } catch (error) {
      console.error('Error fetching candidates:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, searchTerm, searchType, locationSearch, statusSearch]);

  const handleSearch = () => {
    setCurrentPage(1);
  };


  const getColorSchemeForLocation = (locationId) => {
    const colorMap = {
      1: '#b9ce67',
      2: '#fca066',
      3: '#fca066',
      4: '#fc7466',
      5: '#fcd677'
    };

    return colorMap[locationId] || '#b9ce67';
  };

  const fetchAndUpdateCandidates = async () => {
    try {
      const response = await axios.get('/employees');
      setCandidates(response.data);
    } catch (error) {
      console.error('Error fetching vacancies:', error);
    }
  };


  const handleInfo = async (employee) => {
    try {
      const response = await axios.get(`/employees/${employee.id}`);
      const { employee: detailedEmployee, location, form, status, profile } = response.data;

      setSelectedEmployee({ ...employee, location, form, status, profile });
      setIsInfoModalOpen(true);
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  };


  const closeInfoModal = () => {
    setIsInfoModalOpen(false);
  };

  const handleAcepted = async (id) => {
    const confirmDelete = await Swal.fire({
      title: 'Do you want to accept this candidate?',
      text: 'The candidate has been accepted',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2C5282',
      cancelButtonColor: '#E53E3E',
      confirmButtonText: 'Accept it'
    }).then((result) => {
      return result.isConfirmed
    });

    if (confirmDelete) {
      setLoading(true);

      try {
        const response = await axios.post(`/employees/statusA/${id}`);
        // Mostrar una notificación de éxito
        toast({
          title: "Candidate Acepted",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: 'top',
        });

        fetchData();
      } catch (error) {
        console.error(error);
        // Mostrar una notificación de error
        toast({
          title: "Error Rejecting The Candidate",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = await Swal.fire({
      title: 'Do you want to rejected this candidate?',
      text: 'The candidate has been rejected',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2C5282',
      cancelButtonColor: '#E53E3E',
      confirmButtonText: 'Reject it'
    }).then((result) => {
      return result.isConfirmed
    });

    if (confirmDelete) {
      setLoading(true);

      try {
        const response = await axios.post(`/employees/statusR/${id}`);
        // Mostrar una notificación de éxito
        toast({
          title: "Candidate Rejected",
          status: "error",
          position: 'top',
          duration: 3000,
          isClosable: true,
        });

        fetchData();
      } catch (error) {
        console.error(error);
        // Mostrar una notificación de error
        toast({
          title: "Error Rejecting The Candidate",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const renderModal = () => {
    switch (selectedEmployee.type_form) {
      case 1:
        return <EmployeeInfoModalMXBZ isOpen={isInfoModalOpen} onClose={closeInfoModal} employee={selectedEmployee} dataEmployees={fetchAndUpdateCandidates} />;
      case 2:
        return <EmployeeInfoModalRemoteMx isOpen={isInfoModalOpen} onClose={closeInfoModal} employee={selectedEmployee} dataEmployees={fetchAndUpdateCandidates} />;
      case 3:
        return <EmployeeInfoModalRemoteRD isOpen={isInfoModalOpen} onClose={closeInfoModal} employee={selectedEmployee} dataEmployees={fetchAndUpdateCandidates} />;
      case 4:
        return <EmployeeInfoModal isOpen={isInfoModalOpen} onClose={closeInfoModal} employee={selectedEmployee} dataEmployees={fetchAndUpdateCandidates} />;
      default:
        return null; // En caso de que no haya un tipo de formulario válido
    }
  };

  return (

    <TableContainer>
      <Flex
        direction='column'
        width={"100%"}
        bg="#f2f2f2"
        p={2}
        borderRadius="16px"
        mt={4}>
        <Flex>
          <Select
            borderRadius={"5px"}
            value={searchType}
            mr={4}
            width={'120px'}
            borderColor={'#808080'}
            color={'#808080'}
            onChange={(e) => setSearchType(e.target.value)}
            _focusVisible={'none'}
            _hover={{
              borderColor: "#808080",
            }}
          >
            <option value="name">Name</option>
            <option value="id">ID</option>
          </Select>

          <Flex
            border={'1px solid #808080'}
            display={'flex'}
            borderRadius={'4px'}
          >
            <Input
              borderRadius={"5px"}
              width={'100%'}
              border={'0px'}
              type="text"
              placeholder={`Search for ${searchType === 'name' ? 'name' : 'ID'}`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              _placeholder={{
                color: '#808080',
              }}
              _focusVisible={'none'}
            />
            <Button bg={'none'} color={'#808080'} onClick={handleSearch}>
              <SearchIcon />
            </Button>
          </Flex>
          <Select
            borderRadius={"5px"}
            borderColor={'#808080'}
            color={'#808080'}
            _focusVisible={'none'}
            _hover={{
              borderColor: "#808080",
            }}
            width={'160px'}
            ml={4}
            value={statusSearch}
            onChange={(e) => {
              setStatusSearch(e.target.value);
              setCurrentPage(1);
            }}
          >
            <option value="">Status</option>
            <option value="1">In office</option>
            <option value="2">Remote</option>
          </Select>
          <Select
            borderRadius={"5px"}
            borderColor={'#808080'}
            color={'#808080'}
            _focusVisible={'none'}
            _hover={{
              borderColor: "#808080",
            }}
            width={'220px'}
            ml={4} mr={4}
            value={locationSearch}
            onChange={(e) => {
              setLocationSearch(e.target.value);
              setCurrentPage(1);
            }}
          >
            <option value="">Select location</option>
            {location.map((location) => (
              <option key={location.id} value={location.id}>
                {location.country}
              </option>
            ))}
          </Select>
          <Button onClick={clearFilters} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
            <AiOutlineClear size={'26px'} />
          </Button>
        </Flex>
      </Flex>
      <Flex alignItems={"auto"}
        direction='column'
        w='auto'
        mt={4}
      >
        <Table size={'sm'}  >
          <Thead bg={'white'}>
            <Tr>
              <Th color='#808080' textAlign={'center'}>ID</Th>
              <Th color='#808080' textAlign={'center'}>Full Name</Th>
              <Th color='#808080' textAlign={'center'}>Personal Email</Th>
              <Th color='#808080' textAlign={'center'}>Type Of Hiring</Th>
              <Th color='#808080' textAlign={'center'}>Location</Th>
              <Th color='#808080' textAlign={'center'}>Actions</Th>
            </Tr>
          </Thead>
          <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
            {candidates && candidates.length > 0 ? (
              candidates.map(candidate => (
                <Tr key={candidate.id} bg={candidate.id % 2 === 0 ? '#f2f2f2' : 'white'}>
                  <Td textAlign={'center'}>{candidate.id}</Td>
                  <Td textAlign={'center'}>{candidate.name}
                  </Td>
                  <Td textAlign={'center'}>{candidate.profile.personal_email}</Td>
                  <Td textAlign={'center'}>
                    <Badge borderRadius={"5px"} color={"white"} bg={candidate.status_employee_id === 1 ? '#8a6dce' : '#ff9fb8'} width={'75px'}>
                      {candidate.status.name}
                    </Badge>
                  </Td>
                  <Td textAlign={'center'}>
                    <Badge borderRadius={"5px"} color={"white"} bg={getColorSchemeForLocation(candidate.location_id)}>
                      {(() => {
                        switch (candidate.location_id) {
                          case 1:
                            return "MX";
                          case 2:
                            return "BZ";
                          case 3:
                            return "BZ";
                          case 4:
                            return "RD";
                          case 5:
                            return "COL";
                          default:
                            return "MX";
                        }
                      })()}
                    </Badge>
                  </Td>
                  <Td textAlign={'center'}>
                    <Menu>
                      <MenuButton borderRadius={"5px"} as={Button} _active={{ background: "#808080", color: 'white' }} bg="#1c2147" color={"white"} _hover={{ background: "#808080", color: 'white' }}>

                        <SettingsIcon pb={"2px"} />
                      </MenuButton>
                      <MenuList>
                        <MenuItem isDisabled>
                          <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions</div>
                        </MenuItem>
                        <MenuItem>
                          <Button
                            borderRadius={"5px"}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            bg={"#f2f2f2"} color={"1c2147"} width="100%" onClick={() => handleInfo(candidate)} _hover={{ background: "#7080fc", color: 'white' }}>
                            <span style={{ flex: 1, textAlign: 'center' }}>Info</span><InfoOutlineIcon ml={2} />
                          </Button>
                        </MenuItem>

                        <MenuItem>
                          <Button
                            borderRadius={"5px"}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            bg={"#f2f2f2"}
                            color={"#1c2147"}
                            _hover={{ background: "#b9ce67", color: 'white' }}
                            width="100%"
                            onClick={() => handleAcepted(candidate.id)}
                          > <span style={{ flex: 1, textAlign: 'center' }}>Accept candidate </span><CheckIcon ml={2} />

                          </Button>
                        </MenuItem>
                        <MenuItem>
                          <Button
                            borderRadius={"5px"}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            bg="#f2f2f2"
                            color={'#1c2147'}
                            _hover={{ background: "#de5b5b", color: 'white' }}
                            width="100%"
                            onClick={() => handleDelete(candidate.id)}
                            isLoading={loading}
                          > <span style={{ flex: 1, textAlign: 'center' }}> Reject candidate  </span><CloseIcon ml={2} />
                          </Button>
                        </MenuItem>
                      </MenuList>
                    </Menu>

                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Th colSpan={'9'} >
                  <Text
                    color='#1c2147'
                    fontSize={"25px"}
                    textAlign={"center"}
                    paddingTop={"15px"}
                    paddingBottom={"15px"}
                    bg={"#f2f2f2"}
                    textTransform={'none'}
                    fontFamily={'DM Sans'}
                    letterSpacing="-1px"
                  >
                    No data
                  </Text>
                </Th>
              </Tr>
            )}
          </Tbody>

        </Table>
      </Flex>
      <Box width={"100%"} display={"flex"} mt={"30px"}>
        <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
          {total === 0 ? (
            <>
              <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                <Box mt={'5px'} mr={2} ml={4}>
                  <FaUserCheck color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                  No candidates
                </Text>
              </Flex>
            </>
          ) : (
            <>
              <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                  {total}
                </Text>
                <Box mt={'5px'} mr={1}>
                  <FaUserCheck color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                  {total === 1 ? 'Candidate' : 'Candidates'}
                </Text>
              </Flex>
            </>
          )}
        </Flex>
        <ButtonGroup isAttached variant="outline" ml={"34%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
          <Button
            borderRadius={'0px'}
            onClick={() => setCurrentPage(currentPage - 1)}
            isDisabled={currentPage === 1}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowLeftShort fontSize="20px" />
          </Button>
          <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
          <Button
            borderRadius={'0px'} ml={4}
            onClick={() => setCurrentPage(currentPage + 1)}
            isDisabled={currentPage === totalPages}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowRightShort fontSize="20px" />
          </Button>
        </ButtonGroup>

      </Box>      {isInfoModalOpen && renderModal()}
    </TableContainer>


  );
}

export default EmployeeList;
