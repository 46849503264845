import {
  Flex,
  Menu, MenuButton, MenuItem, MenuList,
  Text, Button,
  useToast, Box, Badge
} from '@chakra-ui/react';
import { BiLogOut } from "react-icons/bi";
import { IoFastFood, IoTicketOutline, IoRibbonOutline } from "react-icons/io5";
import { FaUserCog } from "react-icons/fa";
import { EditIcon } from '@chakra-ui/icons'
import { GoClockFill } from "react-icons/go";
import axios from 'axios';
import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2'
import { useNavigate, Link } from 'react-router-dom';
import StartModal from './searchBar/ModalStart';
import CustomMenuItem from './searchBar/CustomMenuItem';
import ModalBreak from './searchBar/ModalBreak';
import Modalbreaktime from './searchBar/Modalbreaktime';
import ModalClock from './searchBar/ModalClock';
import ModalConfirmation from './searchBar/ModalRead';
import ModalChat from './searchBar/ModalChat';
import StartScheduleModal from './searchBar/ModalStartSchedules';
import { FaRegBell } from "react-icons/fa6";
import ModalChatOpen from './searchBar/ModalChatOpen';
import ModalWeeklyBonus from './searchBar/modalWeeklyBonus';
import UpdateModalInfo from './searchBar/ModalUpdate';
import FinancesMX from './Tickets/FinancesMX';
import FinancesBZ from './Tickets/FinancesBZ';
import FinancesInternational from './Tickets/FinancesInternational';


export default function HeaderLinks(props) {

  const { fetchData, setRead, dataShift, setIsOnBreak, shift, breakS, userAuth, numberNotify, notify, read, isOnBreak, notifyUser, bonoEnable } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [modalClock, setModalClock] = useState(false);
  const [modalBreakTime, setModalBreakTime] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalChat, setModalChat] = useState(false);
  const [modalOpenTicket, setModalOpenTicket] = useState(false);
  const BASE_URL = '/logout';
  const navigate = useNavigate();
  const [selectedIncidentId, setSelectedIncidentId] = useState(null);
  const [incidentId, setIncidentId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();
  const [modalSchedule, setModalSchedule] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [openTicket, setOpenTicket] = useState([]);
  const [modalWeeklyBonus, setModalWeeklyBonus] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalFinance, setModalFinance] = useState(false);


  const [dataDailyBreak, setDataDailyBreak] = useState([]);

  const handleLogout = async () => {
    try {
      const response = await axios.post(BASE_URL);
      if (response.data === 'The Token has been deleted') {
        localStorage.removeItem('token');
        navigate('/login');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };


  const startIncident = async (chat_room_id) => {
    try {
      const response = await axios.post('/start-incident', { chat_room_id });
      if (response.data.ticket) {
        const ticket = response.data.ticket;
        setOpenTicket(ticket);
        setModalOpenTicket(true);
      } else {
        const { message, incident } = response.data;

        setModalContent(`${message}`);
        setIncidentId(`${incident}`);

        // Verificar si chat_room_id es igual a 5
        if (chat_room_id === 5) {
          setModalSchedule(true);
        } else if (chat_room_id === 6) {
          setModalFinance(true);
        } else {
          setModalVisible(true);
        }

        setSelectedIncidentId(chat_room_id);
      }
    } catch (error) {
      setModalContent('Something went wrong while starting an incident.');
      setModalVisible(true);
    }
  };

  const openClockModal = () => {
    setModalClock(true);
  };

  const openModalUpdate = () => {
    setModalUpdate(true);
  };

  const openBreakTimeModal = () => {
    setModalBreakTime(true);
  };

  const openModalChat = () => {
    setModalChat(true);
  };

  const endBreak = async () => {
    const confirmDelete = await Swal.fire({
      title: 'Do you want to end your break?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#b9ce67',
      cancelButtonColor: '#fc7466',
      confirmButtonText: 'Yes, end it'
    }).then((result) => {
      return result.isConfirmed
    });

    if (confirmDelete) {
      setLoading(true);
      try {
        const response = await axios.get(`/endBreak`);
        fetchData();
        toast({
          title: "Your break has endend",
          description: "Now we're back in action.",
          status: "success",
          position: 'top',
          containerStyle: {
            width: '800px',
            maxWidth: '100%',
          },
          duration: 8000,
          isClosable: true,
        });
        setModalBreakTime(false);
        fetchDataBreaks();
      } catch (error) {
        console.error(error);
        const errorMessage = error.response?.data?.errors?.erro;
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  const ClockOut = async () => {
    const confirmDelete = await Swal.fire({
      title: 'Do you want to end your shift?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#b9ce67',
      cancelButtonColor: '#fc7466',
      confirmButtonText: 'Yes, end it'
    }).then((result) => {
      return result.isConfirmed
    });

    if (confirmDelete) {
      setLoading(true);
      try {
        const response = await axios.post(`/endShift/${dataShift.id_schedule}`);
        fetchData();
        toast({
          title: "Clocked out!",
          description: "Rest, take care.",
          status: "success",
          position: 'top',
          containerStyle: {
            width: '800px',
            maxWidth: '100%',
          },
          duration: 8000,
          isClosable: true,
        });
      } catch (error) {
        console.error(error);
        const errorMessage = error.response?.data?.errors?.erro;

        toast({
          title: "Error!",
          description: errorMessage,
          status: "error",
          position: 'top',
          containerStyle: {
            width: '800px',
            maxWidth: '100%',
          },
          duration: 8000,
          isClosable: true,
        });

        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleItemClick = (id) => {
    localStorage.setItem('selectedItemId', id);
  };

  const getColorIncident = (chatRoom) => {
    const colorMap = {
      1: '#ff6f94',
      2: '#fabb1c',
      3: '#633cbd',
      4: '#fb9539',
      5: '#a2bd34',
      6: '#6bc2ed',
      7: '#7080fc',
      8: '#fc7466',
    };
    return colorMap[chatRoom] || '#ff6f94';
  };

  const handleItemClickMenu = (event) => {
    event.stopPropagation();
  };

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const handleHoverB = () => {
    setIsOnBreak(!isOnBreak);
  };

  const openWeeklyBonus = () => {
    setModalWeeklyBonus(true);
  };

  const buttonText = isHovered ? 'Clock out' : 'Clocked in';

  const breakText = isOnBreak ? 'End break' : 'On break';

  const financeComponents = {
    1: FinancesMX,
    2: FinancesBZ,
    3: FinancesBZ,
    4: FinancesInternational,
    5: FinancesInternational,
    6: FinancesInternational,
  };

  const FinanceComponent = financeComponents[userAuth.location_id];

  const fetchDataBreaks = async () => {
    try {
      const response = axios.get("/getDailyBreak").then((response) => {
        const data = response.data;
        setDataDailyBreak(data);
      });
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const startBreak = async (dataDailyBreak) => {
    const confirmDelete = await Swal.fire({
      title: 'Do you want to start a lunch?',
      html: '<span style="color:#fcb06a; font-weight: bold;">30</span> ' +
        '<span style="font-weight: bold;">Minutes break lunch.</span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#b9ce67',
      cancelButtonColor: '#fc7466',
      confirmButtonText: 'Yes, Start it!'
    }).then((result) => {
      return result.isConfirmed;
    });

    if (confirmDelete) {
      try {
        const response = await axios.get(`/startBreak/${dataDailyBreak.id}`);
        if (response.status === 200) {
          fetchData();
          toast({
            title: "On break!",
            description: "Take a moment to relax; you've earned it.",
            status: "success",
            position: 'top',
            containerStyle: {
              width: '800px',
              maxWidth: '100%',
            },
            duration: 8000,
            isClosable: true,
          });
          openBreakTimeModal();
        }
      } catch (error) {
        console.error(error);
        const errorMessage = error.response?.data?.errors?.erro;
        setError(errorMessage);
        toast({
          title: "Attention!",
          description: "You already have an open break, please close the previous one and try again.",
          status: "error",
          position: 'top',
          containerStyle: {
            width: '800px',
            maxWidth: '100%',
          },
          duration: 8000,
          isClosable: true,
        });
      }
    }
  };



  const createBreak = async (dataShift) => {
    const confirmDelete = await Swal.fire({
      title: 'Do you want to start a break?',
      html: '<span style="color:#7080fc; font-weight: bold;">In addition, you are allowed 30 minutes for breaks throughout your shift.</span> ' +
        'Any extension beyond these allocated 30 minutes will impact your bonuses and overall compensation starting at 30:01. ' +
        '<span style="color:#fc7466; font-weight: bold;">Please consider call volume and align your break times with that of your assigned client.</span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#b9ce67',
      cancelButtonColor: '#fc7466',
      confirmButtonText: 'Yes, Start it!'
    }).then((result) => {
      return result.isConfirmed;
    });
    if (confirmDelete) {
      try {
        const response = await axios.post(`/createBreak/${dataShift.id_schedule}`);
        if (response.status === 201) {
          fetchData();
          toast({
            title: "On break!",
            description: "Take a moment to relax; you've earned it.",
            status: "success",
            position: 'top',
            containerStyle: {
              width: '800px',
              maxWidth: '100%',
            },
            duration: 8000,
            isClosable: true,
          });
          openBreakTimeModal();
        }
      } catch (error) {
        console.error(error);
        const errorMessage = error.response?.data?.errors?.erro;
        setError(errorMessage);
        toast({
          title: "Attention!",
          description: "You already have an open break, please close the previous one and try again.",
          status: "error",
          position: 'top',
          containerStyle: {
            width: '800px',
            maxWidth: '100%',
          },
          duration: 8000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    fetchDataBreaks();
  }, []);



  return (
    <Flex borderRadius={"25px"} height={"100px"}
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      p="15px"
    >
      {[22, 36, 43, 44].includes(userAuth.type_user_id)
        && [1, 2, 3].includes(userAuth.location_id)
        && [1, 2].includes(userAuth.status_employee_id)
        && bonoEnable
        ? <Button

          display="flex"
          justifyContent="space-between"
          alignItems="center"
          textColor="#1c2147"
          backgroundColor="#f2f2f2"
          borderRadius={'5px'}
          _hover={{
            background: "#424ea8",
            color: "white",
          }}
          mr={3}
          onClick={openWeeklyBonus}
        >
          <IoRibbonOutline size={'20px'} />
          <Text flex={1} textAlign='center' width={"100%"} mr={1} ml={1}>
            Weekly Bonus
          </Text>
        </Button> :
        null
      }
      {shift ? (
        <Button

          display="flex"
          justifyContent="space-between"
          alignItems="center"
          textColor="white"
          backgroundColor="#b9ce67"
          borderRadius={'5px'}
          _hover={{
            background: "#fc7466",
            color: "white",
            content: "'Clock Out'"
          }}
          mr={3}
          onClick={ClockOut}
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
        >
          <GoClockFill color='white' size={'20px'} />
          <Text flex={1} textAlign='center' width={"90px"} mr={1} ml={1}>
            {buttonText}
          </Text>
        </Button>

      ) : (
        <Button
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          textColor="#1c2147"
          backgroundColor="#f2f2f2"
          borderRadius={'5px'}
          mr={3}
          onClick={openClockModal}
        >
          <GoClockFill color='#fabb1c' size={'20px'} />
          <Text flex={1} textAlign='center' mr={1} ml={1}>
            Clock in
          </Text>

        </Button>
      )}

      {shift && !breakS ? (
        <>
          {dataDailyBreak.length > 0 ? (
            dataDailyBreak.map(dataDailyBreak => (
              <Flex key={dataDailyBreak.id} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Button
                  textColor="white"
                  bg={"#fcb06a"}
                  _hover={{
                    background: "#8aad01",
                    color: "white"
                  }}
                  borderRadius={'5px'}
                  mr={3}
                  onClick={() => startBreak(dataDailyBreak)}
                >
                  <Flex mb={1}>
                    <IoFastFood size={'20px'} />
                  </Flex>
                  <Text flex={1} textAlign='center' mr={1} ml={1}>
                    Lunch
                  </Text>

                </Button>
                <Button
                  textColor="white"
                  backgroundColor="#7a86e6"
                  borderRadius={'5px'}
                  _hover={{
                    background: "#8aad01",
                    color: "white"
                  }}
                  mr={3}
                  onClick={() => createBreak(dataShift)}
                >
                  <Flex mb={1}>
                    <IoFastFood size={'20px'} />
                  </Flex>
                  <Text flex={1} textAlign='center' mr={1} ml={1}>
                    Break
                  </Text>

                </Button>
              </Flex>
            ))
          ) : (
            <Flex display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Button
                textColor="white"
                backgroundColor="#7a86e6"
                borderRadius={'5px'}
                _hover={{
                  background: "#8aad01",
                  color: "white"
                }}
                mr={3}
                onClick={() => createBreak(dataShift)}
              >
                <Flex mb={1}>
                  <IoFastFood size={'20px'} />
                </Flex>
                <Text flex={1} textAlign='center' mr={1} ml={1}>
                  Break
                </Text>

              </Button>
            </Flex>
          )}
        </>
      ) : null}


      {shift && breakS ? (
        <>
          <Flex direction='column' >
            <Button boxShadow={"0px 0px 8px 4px"}
              textColor="white"
              backgroundColor="#b9ce67"
              borderRadius={'5px'}
              mr={3}
              onClick={endBreak}
              _hover={{
                background: "#fc7466",
                color: "white"
              }}
              onMouseEnter={handleHoverB}
              onMouseLeave={handleHoverB}
            >
              <Flex mb={1}>
                <IoFastFood color='white' size={'20px'} />
              </Flex>
              <Text flex={1} textAlign='center' width={"90px"} mr={1} ml={1}>
                {breakText}
              </Text>

            </Button>
          </Flex>

        </>
      ) : null}


      <Menu>
        <MenuButton

          justifyContent="space-between"
          alignItems="center"
          as={Button}
          bg={'#fabb1c'}
          _hover={{
            background: "#808080",
            color: "white"
          }}
          _active={{
            background: "#808080",
            color: "white"
          }}
          color='#1c2147'
          mr={3}
          borderRadius={'10px'}
        >
          <Flex flexDirection={"row"}>
            <IoTicketOutline size={"28px"} />
            <Text width={"max-content"} ml={2} textAlign={"center"} pt={"5px"}  >Make a ticket</Text>
          </Flex>
        </MenuButton>


        <MenuList ml={6} mt={4}>
          <CustomMenuItem
            textColor="#1c2147"
            buttonColor="#f2f2f2"
            spanColor="#ff6f94"
            onClick={() => startIncident(1)}
            label="IT"
          />
          <CustomMenuItem
            textColor="#1c2147"
            buttonColor="#f2f2f2"
            spanColor="#fabb1c"
            onClick={() => startIncident(2)}
            label="HR"
          />
          {userAuth.type_user_id !== 22 && userAuth.type_user_id !== 29 && userAuth.type_user_id !== 36 && userAuth.type_user_id !== 43 && userAuth.type_user_id !== 44 ? (
            <CustomMenuItem
              textColor="#1c2147"
              buttonColor="#f2f2f2"
              spanColor="#633cbd"
              onClick={() => startIncident(3)}
              label="Hiring"
            />
          ) : null}
          <CustomMenuItem
            textColor="#1c2147"
            buttonColor="#f2f2f2"
            spanColor="#fb9539"
            onClick={() => startIncident(4)}
            label="OnCall"
          />
          {userAuth.type_user_id !== 22 && userAuth.type_user_id !== 29 && userAuth.type_user_id !== 35 && userAuth.type_user_id !== 36 && userAuth.type_user_id !== 43 && userAuth.type_user_id !== 44 ? (
            <CustomMenuItem
              textColor="#1c2147"
              buttonColor="#f2f2f2"
              spanColor="#a2bd34"
              onClick={() => startIncident(5)}
              label="Schedules"
            />
          ) : null}
          <CustomMenuItem
            textColor="#1c2147"
            buttonColor="#f2f2f2"
            spanColor="#6bc2ed"
            onClick={() => startIncident(6)}
            label="Finances"
          />

          <CustomMenuItem
            textColor="#1c2147"
            buttonColor="#f2f2f2"
            spanColor="#7080fc"
            onClick={() => startIncident(7)}
            label="Calls"
          />
          {userAuth.type_user_id !== 22 && userAuth.type_user_id !== 29 && userAuth.type_user_id !== 35 && userAuth.type_user_id !== 36 && userAuth.type_user_id !== 43 && userAuth.type_user_id !== 44 ? (
            <CustomMenuItem
              textColor="#1c2147"
              buttonColor="#f2f2f2"
              spanColor="#fc7466"
              onClick={() => startIncident(8)}
              label="Supervisor"
            />
          ) : null}
        </MenuList>
      </Menu>
      <Menu>
        <MenuButton
          width={"50px"}
          height={"40px"}
          mr={4} borderRadius={"10%"}
          backgroundColor="#f2f2f2"
          as={Button}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          cursor={"pointer"}
          position={"relative"}
          _hover={{ background: "#cbd5e0" }}
        >

          {numberNotify !== 0 && (
            <Box position={"absolute"} bg={"#f4bb1c"} top={"-25%"} borderRadius={"50%"} right={"-25%"} width={"25px"} height={'25px'}>
              <Text textAlign={"center"} fontWeight={"bold"} color='#1c2147' mt={'2.5px'}>{numberNotify}</Text>
            </Box>
          )}
          <Box ml={-1} width={'25px'} display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
            <FaRegBell color='#1c2147' size={"26px"} />
          </Box>
        </MenuButton>
        <MenuList width={"670px"} height={"770px"} overflowY={"auto"} p={'5'} boxShadow={"1px 2.5px 10px 7px #cccccc"} borderRadius={"30px"} >
          <Text fontSize={'36px'} fontWeight={'bold'} color={'#1c2147'} ml={'20px'} mt={'20px'}  >
            Notifications
          </Text>
          {notify === null || notify.length === 0 ? (
            <Flex
              display={'flex'} bg={"#f2f2f2"}
              justifyContent={'center'}
              direction={'column'}
              width={'628px'}
              textAlign={'center'}
              alignItems={'center'}
              mt={'40px'}
              p={'5'}
            >
              <Flex display={'flex'} justifyContent={'center'} textAlign={'center'} mt={'10px'} mb={'20px'} >
                <FaRegBell color='#b3b3b3' size={"80px"} />
              </Flex>
              <Text
                color={'#808080'}
                fontWeight={'bold'}
                fontSize={'30px'}
              >
                No Notifications Yet
              </Text>
              <Text
                color={'#808080'}
                fontWeight={'bold'}
              >
                When you get notifications, they'll show up here
              </Text>
              <Button
                width={'200px'}
                bg={'#424ea8'}
                color={'white'}
                borderRadius={'5px'}
                mt={'10px'}
                onClick={fetchData}
                _hover={{
                  background: "#808080",
                  color: "white"
                }}
              >
                Refresh
              </Button>
            </Flex>
          ) : (
            notify.map((item, index) => (
              <Link to={`/admin/ticket/chat`} key={index} onClick={() => handleItemClick(item.incident_id)}>
                <MenuItem>
                  <Flex p={'5'} width={'600px'} direction={'row'} borderRadius={'10px'} bg={'#f2f2f2'} mt={'15px'} _hover={{ background: '#e6e6e6' }}
                  >
                    <Flex direction={'column'}>
                      <Text fontWeight={'bold'} textAlign={"right"} color={'#424ea8'} width={"550px"}  >
                        {item.formatted_date}
                      </Text>
                      <Flex display={'flex'} textAlign={'center'} height={'25px'} width={"max-content"}   >
                        <Badge bg={getColorIncident(item.chat_room_id)} fontWeight='500' textAlign={'center'} color={'white'} borderRadius={'5px'} >
                          {(() => {
                            switch (item.chat_room_id) {
                              case 1:
                                return "IT";
                              case 2:
                                return "HR";
                              case 3:
                                return "Hiring";
                              case 4:
                                return "OnCall";
                              case 5:
                                return "Schedules";
                              case 6:
                                return "Finances";
                              case 7:
                                return "Calls";
                              case 8:
                                return "Supervisor";
                              default:
                                return "IT";
                            }
                          })()}
                        </Badge>
                        <Badge color={'#1c2147'} fontWeight='500' textAlign={'center'} bg={'white'} borderRadius={'5px'} ml={2}  >
                          {item.users.user_client.client.name}
                        </Badge>
                      </Flex>
                      <Text mt={2} fontWeight={'bold'} color={'#1c2147'} fontSize={"18px"}  >
                        {item.users.name} {item.users.last_name}
                      </Text>
                      <Text color={'#808080'} fontSize={'18px'} textOverflow={'ellipsis'} width={'max-content'} maxWidth={'550px'} overflow={'hidden'} whiteSpace={"nowrap"}  >
                        {item.message}
                      </Text>

                    </Flex>

                  </Flex>
                </MenuItem>
              </Link>
            ))
          )}
          {notifyUser === null || notifyUser.length === 0 ? (
            null
          ) : (
            notifyUser.map((item, index) => (
              <Link to={`/admin/ticketU/chat`} key={index}>
                <MenuItem>
                  <Flex p={'5'} width={'600px'} direction={'column'} borderRadius={'10px'} bg={'#5868e0'} mt={'15px'} _hover={{ background: '#7a86e6' }}  >
                    <Text fontWeight={'bold'} color={'white'} textAlign={"right"}>
                      {item.formatted_date}
                    </Text>
                    <Flex direction={'column'} width={"540px"}>

                      <Flex display={'flex'} justifyContent={'left'} flexDirection={"row"} textAlign={'left'} height={'25px'}  >
                        <Badge bg={getColorIncident(item.chat_room_id)} fontWeight='500' textAlign={'center'} color={'white'} borderRadius={'5px'} >
                          {(() => {
                            switch (item.chat_room_id) {
                              case 1:
                                return "IT";
                              case 2:
                                return "HR";
                              case 3:
                                return "Hiring";
                              case 4:
                                return "OnCall";
                              case 5:
                                return "Schedules";
                              case 6:
                                return "Finances";
                              case 7:
                                return "Calls";
                              case 8:
                                return "Supervisor";
                              default:
                                return "IT";
                            }
                          })()}
                        </Badge>
                        <Badge color={'#1c2147'} fontWeight='500' textAlign={'center'} bg={'white'} borderRadius={'5px'} ml={2}  >
                          {item.users.user_client.client.name}
                        </Badge>
                      </Flex>
                      <Text mt={2} fontWeight={'bold'} fontSize={'18px'} color={'#1d2147'}    >
                        {item.users.name} {item.users.last_name}
                      </Text>
                      <Text color={'white'} fontSize={'18px'} textOverflow={'ellipsis'} width={'480px'} maxWidth={'550px'} whiteSpace={'nowrap'} overflow={'hidden'}>
                        {item.message}
                      </Text>


                    </Flex>

                  </Flex>
                </MenuItem>
              </Link>
            ))
          )}
        </MenuList>

      </Menu>

      {modalVisible && (
        <StartModal isOpen={modalVisible} onClose={() => setModalVisible(false)} modalContent={modalContent} selectedIncidentId={selectedIncidentId} incidentID={incidentId} openModalChat={openModalChat} />
      )}
      {modalBreakTime && (
        <Modalbreaktime isOpen={modalBreakTime} onClose={() => setModalBreakTime(false)} data={fetchData} endBreak={endBreak} />
      )}
      {modalClock && (
        <ModalClock isOpen={modalClock} onClose={() => setModalClock(false)} data={fetchData} userAuth={userAuth} dataBreaks={fetchDataBreaks} />
      )}
      {read && (
        <ModalConfirmation isOpen={read} onClose={() => setRead(false)} />
      )}
      {modalChat && (
        <ModalChat isOpen={modalChat} onClose={() => setModalChat(false)} selectedIncidentId={selectedIncidentId} incidentID={incidentId} />
      )}

      {modalSchedule && (
        <StartScheduleModal isOpen={modalSchedule} onClose={() => setModalSchedule(false)} modalContent={modalContent} selectedIncidentId={selectedIncidentId} incidentID={incidentId} openModalChat={openModalChat} />
      )}
      {modalOpenTicket && (
        <ModalChatOpen isOpen={(modalOpenTicket)} onClose={() => setModalOpenTicket(false)} ticket={openTicket} />
      )}
      {modalWeeklyBonus && (
        <ModalWeeklyBonus isOpen={openWeeklyBonus} onClose={() => setModalWeeklyBonus(false)} />
      )}
      {modalUpdate && (
        <UpdateModalInfo isOpen={openModalUpdate} onClose={() => setModalUpdate(false)} fetchData={fetchData} user_info={userAuth} />
      )}

      {modalFinance && FinanceComponent && (
        <FinanceComponent
          isOpen={modalFinance}
          onClose={() => setModalFinance(false)}
          modalContent={modalContent}
          selectedIncidentId={selectedIncidentId}
          incidentID={incidentId}
          openModalChat={openModalChat}
        />
      )}


      <Menu>
        <MenuButton
          mr={4} borderRadius={"10%"}
          backgroundColor="#f2f2f2"
          as={Button}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          cursor={"pointer"}
          position={"relative"}
          _hover={{ background: "#cbd5e0" }}
        >
          <Box ml={-1} width={'25px'} display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
            <FaUserCog color='#1c2147' size={"26px"} />
          </Box>
        </MenuButton>
        <MenuList>
          <MenuItem>
            <Button
              height={"40px"}
              width={'100%'}
              borderRadius={"5%"}
              px={"11px"}
              bg="#1c2147"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              cursor={"pointer"}
              position={"relative"}
              onClick={openModalUpdate}
              _hover={{ background: "#cbd5e0", color: "#1c2147" }}
              color="white"
            >
              <Text>
                Update Information
              </Text>
              <EditIcon ml='5px' size={'22px'} />
            </Button>
          </MenuItem>
          <MenuItem>
            <Button
              height={"40px"}
              width={'100%'}
              borderRadius={"5%"}
              px={"11px"}
              bg="#1c2147"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              cursor={"pointer"}
              position={"relative"}
              onClick={handleLogout}
              _hover={{ background: "#cbd5e0", color: "#1c2147" }}
              color="white"
            >

              <Text>
                Log out
              </Text>
              <BiLogOut size={'22px'} />
            </Button>
          </MenuItem>
        </MenuList>

      </Menu>


    </Flex>

  );
}
