// Chakra imports
import {
  Portal,
  Box,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import routes from 'routes';
import routesAccounts from 'routes/routesAccounts';
import routesHR from 'routes/routesHR';
import routesFinances from 'routes/routesFinances';
import routesHiring from 'routes/routesHiring';
import routesIT from 'routes/routesIT';
import routesOperations from 'routes/routesOperations';
import routesSchedules from 'routes/routesSchedules';
import routesSupervisor from 'routes/routesSupervisor';
import routesAgents from 'routes/routesAgents';
import routesSupervisorClient from 'routes/routesSupervisorClient';
import routesQA from 'routes/routesQA';
import { useUserData } from 'interceptors/userAuth';
import routesSchedulesTest from 'routes/routesSchedulesTest';
import routesITCoordinator from 'routes/routesITCoordinator';
import routesOCSupervisor from 'routes/routesOCSupervisor';
import routesDefault from 'routes/routesDefault';

// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [mini, setMini] = useState(false);
  const [hovered, setHovered] = useState(false);
  const { user, fetchData } = useUserData();

  const [sidebarVisible, setSidebarVisible] = useState(false);


  axios.defaults.withCredentials = true;

  useEffect(() => {
    fetchData();
  }, []);

  let userRoutes;

  // Lógica para determinar las rutas según el valor de user
  switch (user.type_user_id) {
    case 1:
      userRoutes = routes;
      break;
    case 2:
      userRoutes = routesFinances;
      break;
    case 3:
      userRoutes = routesOperations;
      break;
    case 4:
      userRoutes = routesAccounts;
      break;
    case 5:
      userRoutes = routesFinances;
      break;
    case 6:
      userRoutes = routesFinances;
      break;
    case 7:
      userRoutes = routes;
      break;
    case 8:
      userRoutes = routesHR;
      break;
    case 9:
      userRoutes = routesHR;
      break;
    case 10:
      userRoutes = routesHiring;
      break;
    case 11:
      userRoutes = routesHiring;
      break;
    case 12:
      userRoutes = routesHiring;
      break;
    case 13:
      userRoutes = routesHiring;
      break;
    case 14:
      userRoutes = routesOperations;
      break;
    case 15:
      userRoutes = routesOperations;
      break;
    case 16:
      userRoutes = routesOperations;
      break;
    case 17:
      userRoutes = routesOperations;
      break;
    case 18:
      userRoutes = routesOCSupervisor;
      break;
    case 19:
      userRoutes = routesSupervisor;
      break;
    case 20:
      userRoutes = routesSupervisor;
      break;
    case 21:
      userRoutes = routesSupervisor;
      break;
    case 22:
      userRoutes = routesAgents;
      break;
    case 23:
      userRoutes = routesSchedules;
      break;
    case 24:
      userRoutes = routesSchedules;
      break;
    case 25:
      userRoutes = routesSchedulesTest;
      break;
    case 26:
      userRoutes = routesSupervisor;
      break;
    case 27:
      userRoutes = routesSupervisor;
      break;
    case 28:
      userRoutes = routesSupervisor;
      break;
    case 29:
      userRoutes = routesQA;
      break;
    case 30:
      userRoutes = routesIT;
      break;
    case 31:
      userRoutes = routesIT;
      break;
    case 32:
      userRoutes = routesIT;
      break;
    case 33:
      userRoutes = routesIT;
      break;
    case 34:
      userRoutes = routesIT;
      break;
    case 36:
      userRoutes = routesAgents;
      break;
    case 37:
      userRoutes = routes;
      break;
    case 38:
      userRoutes = routesIT;
      break;
    case 39:
      userRoutes = routesHiring;
      break;
    case 40:
      userRoutes = routes;
      break;
    case 41:
      userRoutes = routesSupervisorClient;
      break;
    case 42:
      userRoutes = routesSupervisorClient;
      break;
    case 43:
      userRoutes = routesAgents;
      break;
    case 44:
      userRoutes = routesAgents;
      break;
    case 45:
      userRoutes = routesSupervisor;
      break;
    case 46:
      userRoutes = routesITCoordinator;
      break;
    default:
      userRoutes = routesDefault;
  }


  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };
  const getActiveRoute = (userRoutes) => {
    let activeRoute = 'Main Dashboard';
    for (let i = 0; i < userRoutes.length; i++) {
      if (userRoutes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(userRoutes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(userRoutes[i].layout + userRoutes[i].path) !== -1
        ) {
          return userRoutes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (userRoutes) => {
    let activeNavbar = false;
    for (let i = 0; i < userRoutes.length; i++) {
      if (userRoutes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(userRoutes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(userRoutes[i].layout + userRoutes[i].path) !== -1
        ) {
          return userRoutes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (userRoutes) => {
    return userRoutes.map((userRoutes, key) => {
      if (userRoutes.layout === '/admin') {
        return (
          <Route path={`${userRoutes.path}`} element={userRoutes.component} key={key} />
        );
      }
      if (userRoutes.collapse) {
        return getRoutes(userRoutes.items);
      } else {
        return null;
      }
    });
  };
  console.log(mini);
  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  const bg = useColorModeValue('background.100', 'background.900');
  return (
    <Box bg={bg} h="100vh" w="100vw">
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar
          hovered={hovered}
          sidebarVisible={sidebarVisible}
          setSidebarVisible={setSidebarVisible}
          setHovered={setHovered}
          mini={mini}
          setMini={setMini}
          routes={userRoutes}
          display="none"
          {...rest}
        />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"

          width={mini === true ? '92%' : '85%'}
        >
          <Portal>
            <Box>
              <Navbar
                hovered={hovered}
                setMini={setMini}
                mini={mini}
                onOpen={onOpen}
                brandText={getActiveRoute(userRoutes)}
                secondary={getActiveNavbar(userRoutes)}
                theme={props.theme}
                setTheme={props.setTheme}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: '20px', md: '30px' }}
              minH="95vh"
              pe="20px"
              pt="50px"
            >
              <Routes>
                {getRoutes(userRoutes)}
                <Route
                  path="/"
                  element={<Navigate to="/admin/home" replace />}
                />
              </Routes>
            </Box>
          ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
