import React, { useState, useEffect } from 'react';
import { Box, Image, Button } from '@chakra-ui/react';
import "assets/css/test.css";

export default function CarouselD({data, openImageViewer}) {
  
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(prevSlide => (prevSlide + 1) % data.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [data.length]);

  const handlePrevSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide - 1 + data.length) % data.length);
  };

  const handleNextSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide + 1) % data.length);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" height={"320px"} width={"100%"} justifyContent="center" position="relative">
      <Button
        position="absolute"
        left="3"
        top="50%"
        transform="translateY(-50%)"
        zIndex="10"
        onClick={handlePrevSlide}
        color={'white'}
        bg={'transparent'}
        _hover={{
          background: "rgba(0, 0, 0, 0.75)",
        }}
        height={'60px'}
        borderRadius={'5px'}
        ml={'1%'}
      >
        &#9664;
      </Button>

      {/* Carrusel de imágenes */}
      <Box
        display="flex"
        overflow="hidden"
        justifyContent="center"
        flexWrap="nowrap"
        width="800px"
        height="max-content"
      >
        {data.map((src, index) => (
          <Box
            key={index}
            flex="none"
            onClick={() => openImageViewer(index)}
            cursor="pointer"
            margin="0 5px"
            width="95%"
            height="100%"
            style={{
              display: index === currentSlide ? 'block' : 'none',
              transition: 'opacity 0.5s ease-in-out'
            }}
          >
            <Image
              src={src}
              alt={`Image ${index + 1}`}
              objectFit="contain"
              width="100%"
              height="100%"
            />
          </Box>
        ))}
      </Box>

      <Button
        position="absolute"
        right="3"
        top="50%"
        transform="translateY(-50%)"
        zIndex="10"
        onClick={handleNextSlide}
        color={'white'}
        bg={'transparent'}
        _hover={{
          background: "rgba(0, 0, 0, 0.75)",
        }}
        height={'60px'}
        borderRadius={'5px'}
        mr={'1%'}
      >
        &#9654;
      </Button>
      
    </Box>
  );
}
