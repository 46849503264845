import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
    Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Flex, Box,
    Text, Badge, Link,
    ButtonGroup, Button,
} from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons'
import { BsCalendarCheck, BsArrowLeftShort, BsArrowRightShort, BsWhatsapp } from "react-icons/bs";
import { AiOutlineClear } from "react-icons/ai";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Select2 from 'react-select'

const ModalOff = ({ isOpen, onClose }) => {

    const [dayOff, setDayOff] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');
    const [operatorSearch, setOperatorSearch] = useState('');
    const [resetKey, setResetKey] = useState(0);
    const [client, setClient] = useState([]);
    const [clientSearch, setClientSearch] = useState('');
    const [operator, setOperator] = useState([]);
    const [usersClients, setUsersClients] = useState([]);
    const [startDateFilter, setStartDateFilter] = useState(null);

    const clearFilters = () => {
        setClientSearch(null);
        setOperatorSearch(null);
        setStartDateFilter('');
        setCurrentPage(1);
        setResetKey(resetKey + 1);
    }

    const optionsUsers = operator.map(operator => ({
        value: operator.id,
        label: `${operator.name} ${operator.last_name}`,
    }));

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };
    const optionClients = client.map(client => ({
        value: client.id,
        label: client.name,
    }));

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            marginLeft: '20px',
            fontSize: '16px',
            height: '40px',
            width: '220px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const handleSelectChange = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                setOperatorSearch(selectedOption.value);
                break;
            case 'client':
                setClientSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const getWhatsAppLink = (phoneNumber, locationId) => {
        const countryCodeMap = {
            1: '52',
            2: '501',
            3: '501',
            4: '1',
            5: '57'
        };
        const countryCode = countryCodeMap[locationId] || '';
        const fullPhoneNumber = `+${countryCode}${phoneNumber}`;
        const sanitizedPhoneNumber = fullPhoneNumber.replace(/\s/g, '%20');
        return `https://wa.me/${sanitizedPhoneNumber}`;
    }

    const fetchDataOff = async () => {

        const dateStartParam = startDateFilter ? `&date=${startDateFilter.toISOString()}` : '';

        try {
            const response = await axios.get(
                `/schedule-dailyOff?page=${currentPage}
                ${operatorSearch ? `&operator=${operatorSearch}` : ''}
                ${clientSearch ? `&client=${clientSearch}` : ''}
                ${dateStartParam}`
            );

            const responseData = response.data;
            setDayOff(responseData.data.data);
            setTotalPages(responseData.data.last_page);
            setTotal(responseData.data.total);
            setOperator(responseData.operator);
            setClient(responseData.client);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchDataClient = async () => {
        try {
            const response = axios.get("/userClient").then((response) => {
            });
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleClientChange = (selectedOption) => {
        setClientSearch(selectedOption.value);

        if (selectedOption) {
            axios
                .get(`/userClient?id_client=${selectedOption.value}`)
                .then((response) => {
                    const userOptions = response.data.data.map((user) => ({
                        label: user.user ? `${user.user.name} ${user.user.last_name}` : '',
                        value: user.id_user,
                    }));
                    setUsersClients(userOptions);
                });
        } else {
            setUsersClients([]);
        }
    };


    useEffect(() => {
        fetchDataOff();
        fetchDataClient();
    }, [currentPage, clientSearch, operatorSearch, resetKey, startDateFilter]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'} isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent borderRadius={"30px"}  >
                <ModalHeader>
                    <Flex
                        direction='column'
                        w='100%'
                        p={3}
                        borderRadius="16px" align="center" justifyContent="center"
                    >
                        <Flex align="center" justifyContent="center" mt={10} >
                            <Text p={2} px={8} borderRadius={"10px"} style={{ display: 'flex', alignItems: 'center', color: 'white', background: '#424ea8', fontWeight: 'bold', fontSize: '30px' }}>
                                Days off
                            </Text>
                        </Flex>
                        <Flex display={'flex'} justifyContent={'left'} mt={5}>
                            {total === 0 ? (
                                <>
                                    <Flex bg={'#f2f2f2'} width={'130px'} display={'flex'} justifyContent={'center'}>
                                        <Box mt={'5px'} mr={2} ml={4}>

                                        </Box>
                                        <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                            No day off
                                        </Text>
                                    </Flex>
                                </>
                            ) : (
                                <>
                                    <Flex bg={'#f2f2f2'} width={'130px'} display={'flex'} justifyContent={'center'}>
                                        <Text fontSize={'14px'} mt={1} mr={1} color={"#1c2147"} fontWeight={'bold'}>
                                            {total}
                                        </Text>

                                        <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                            {total === 1 ? 'Day off' : 'Days off'}
                                        </Text>
                                    </Flex>
                                </>
                            )}
                        </Flex>
                    </Flex>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <TableContainer>
                        <Flex
                            direction='column'
                            w='100%'
                        >
                            <Flex
                                direction='row'
                                w='100%'
                                bg="#f2f2f2"
                                p={2}
                                borderRadius="16px"
                                mb={'20px'}
                                justifyContent={'center'}
                            >
                                <Select2
                                    options={clientSearch > 0 ? usersClients : optionsUsers}
                                    value={clientSearch > 0 ? usersClients.find(option => option.value === operatorSearch) : optionsUsers.find(option => option.value === operatorSearch)}
                                    onChange={(selectedOption) => handleSelectChange('user', selectedOption)}
                                    styles={customStyles}
                                    placeholder="Agent"
                                    key={`userSelect-${resetKey}`}
                                    isSearchable
                                    filterOption={filterOptions}
                                />
                                <Select2
                                    options={optionClients}
                                    value={optionClients.find(option => option.value === clientSearch)}
                                    onChange={(selectedOption) => handleClientChange(selectedOption)}
                                    styles={customStyles}
                                    placeholder="Client"
                                    key={`clientSelect-${resetKey}`}
                                />
                                <Flex width={"150px"}
                                    border={'1px solid #808080'}
                                    display={'flex'}
                                    borderRadius={'5px'}
                                    mr={4}
                                    ml={'20px'}
                                    height={'40px'}
                                >
                                    <Box mt={2} width={"150px"}>
                                        <DatePicker
                                            selected={startDateFilter}
                                            onChange={(date) => {
                                                setStartDateFilter(date);
                                                setCurrentPage(1);
                                            }}
                                            placeholderText="Date"
                                            customInput={
                                                <input
                                                    className="custom-placeholder"
                                                    style={{
                                                        backgroundColor: 'transparent',
                                                        textAlign: 'left',
                                                        paddingLeft: '20px',
                                                        outline: 'none',
                                                        color: '#808080'
                                                    }}
                                                />
                                            }
                                        />
                                        <CalendarIcon mr={4} ml={"-48%"} mt={"-3%"} color={'#808080'} />
                                    </Box>

                                </Flex>
                                <Button onClick={clearFilters} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                                    <AiOutlineClear size={'26px'} />
                                </Button>
                            </Flex>
                            <Table>
                                <Thead bg={"white"}>
                                    <Tr bg={'white'}>
                                        <Th textAlign={'center'}>Agent</Th>
                                        <Th textAlign={'center'}>Client</Th>
                                        <Th textAlign={'center'}>Phone</Th>
                                    </Tr>
                                </Thead>
                                <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                                    {dayOff && dayOff.length > 0 ? (
                                        dayOff.map((dayOff, index) => (
                                            <Tr key={dayOff.id}   >
                                                <Td width='400px' textAlign={'center'}  >
                                                    {dayOff.user.name.split(' ')[0]} {dayOff.user.last_name.split(' ')[0]}
                                                </Td>
                                                <Td textAlign={'center'}  >
                                                    <Badge color={"#424ea8"} bg={"transparent"} borderRadius={'5px'} width={'250px'} fontSize={'12px'} whiteSpace={'pre-wrap'}>
                                                        {dayOff.clients.name}
                                                    </Badge>
                                                </Td>
                                                <Td textAlign={'center'} display="flex" alignItems="center" justifyContent={'center'}    >
                                                    <Link
                                                        textAlign={'left'}
                                                        href={getWhatsAppLink(dayOff.user.profile.phone, dayOff.user.location_id)}
                                                        isExternal
                                                        mr={2}
                                                    >
                                                        <Box
                                                            width={8}
                                                            height={8}
                                                            borderRadius={"10px"}
                                                            bg={"#8aad01"}
                                                            _hover={{
                                                                background: "#b9ce67",
                                                            }}
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                        >
                                                            <BsWhatsapp color="white" size='18px' />
                                                        </Box>
                                                    </Link>
                                                    <Text width={"90px"}>
                                                        {dayOff.user.profile.phone}
                                                    </Text>
                                                </Td>
                                            </Tr>
                                        ))
                                    ) : (
                                        <Tr>
                                            <Th colSpan={'9'} >
                                                <Text
                                                    color='#1c2147'
                                                    fontSize={"25px"}
                                                    textAlign={"center"}
                                                    paddingTop={"15px"}
                                                    paddingBottom={"15px"}
                                                    bg={"#f2f2f2"}
                                                    textTransform={'none'}
                                                    fontFamily={'DM Sans'}
                                                    letterSpacing="-1px"
                                                >
                                                    No data
                                                </Text>
                                            </Th>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </Flex>
                        <ButtonGroup mt={4} variant="outline" borderRadius={'10px'} mb={'2'} justifyContent={'center'} display={'flex'}>
                            <Button
                                borderRadius={'10px'}
                                onClick={() => setCurrentPage(currentPage - 1)}
                                isDisabled={currentPage === 1}
                                bg="#cccccc"
                                color={"white"}
                                _hover={{
                                    background: "#808080",
                                }}
                                _active={{
                                    background: "#808080",
                                }}
                            >
                                <MdChevronLeft fontSize="20px" />
                            </Button>
                            <Text width={'50px'} fontSize={'14px'} ml={4} p={2}>{currentPage} of {totalPages}</Text>
                            <Button
                                ml={4}
                                borderRadius={'10px'}
                                onClick={() => setCurrentPage(currentPage + 1)}
                                isDisabled={currentPage === totalPages}
                                bg="#cccccc"
                                color={"white"}
                                _hover={{
                                    background: "#808080",
                                }}
                                _active={{
                                    background: "#808080",
                                }}
                            >
                                <MdChevronRight fontSize="20px" />
                            </Button>
                        </ButtonGroup>

                    </TableContainer>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
};

export default ModalOff;

