import axios from 'axios';
import React, { useEffect, useState } from "react";
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
  Text, Textarea, Button, Input,
  Box, Flex,
  FormControl, FormErrorMessage,
  useToast
}
  from '@chakra-ui/react';
import { BiChat } from "react-icons/bi";
import { CloseIcon } from '@chakra-ui/icons'
import { IoMdSend } from "react-icons/io";
import ModalChat from './ModalChat';
import moment from 'moment';
import Select2 from 'react-select'

function StartScheduleModal({ isOpen, onClose, selectedIncidentId, incidentID, openModalChat }) {

  const [joiningRoomId, setJoiningRoomId] = useState(null);
  const [incident, setIncident] = useState(null);
  const [modalChat, setModalChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [userAuth, setUserAuth] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState([]);
  const [selectedCoverage, setSelectedCoverage] = useState([]);
  const [users, setUsers] = useState([]);
  const [userCoverage, setUserCoverage] = useState([]);
  const [selectedWorkType, setSelectedWorkType] = useState([]);
  const [userCoverageOptions, setUserCoverageOptions] = useState(userCoverage);
  const [showSecondSelect, setShowSecondSelect] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const toast = useToast();
  const [showChat, setShowChat] = useState(false);
  const [showForm, setShowForm] = useState(false);


  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const calculateTimeEnd = () => {
    const { time_start, hours, minutes } = formData;

    if (time_start && hours && minutes) {
      const startTime = moment(`2000-01-01 ${time_start}`, 'YYYY-MM-DD HH:mm');

      const endTime = startTime.add(hours, 'hours').add(minutes, 'minutes');

      const formattedTimeEnd = endTime.format('HH:mm');
      setFormData((prevData) => ({
        ...prevData,
        time_end: formattedTimeEnd,
      }));
    }
  };

  const [formData, setFormData] = useState({
    date: new Date().toISOString().substr(0, 10),
    description: '',
    time_start: '08:00:00',
    hours: 8,
    minutes: 30,
    time_end: '16:30:00',
    status: 1
  });


  const fetchDataClient = async () => {
    try {
      const response = axios.get("/userClient").then((response) => {
        const clientOptions = response.data.client.map((client) => ({
          label: client.name,
          value: client.id,
        }));
        const coverageOptions = response.data.user.map((user) => ({
          label: `${user.name} ${user.last_name}`,
          value: user.id,
        }));
        setClients(clientOptions);
        setUserCoverage(coverageOptions);
      });
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataClient();
    calculateTimeEnd();
  }, [resetKey, formData.time_start, formData.hours, formData.minutes]);


  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption);

    if (selectedOption) {
      axios
        .get(`/userClient?id_client=${selectedOption.value}`)
        .then((response) => {
          const userOptions = response.data.data.map((user) => ({
            label: user.user ? `${user.user.name} ${user.user.last_name}` : '',
            value: user.id_user,
          }));
          setUsers(userOptions);
        });
    } else {
      setUsers([]);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '2px solid transparent',
      borderRadius: '8px',
      backgroundColor: '#f2f2f2',
      marginRight: '20px',
      textAlign: 'center',
      width: '350px',
      '&:hover': {
        border: '2px solid transparent',
      },
      '&:focus-visible': {
        outline: 'none',
        boxShadow: 'none'
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: '#f2f2f2',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
      backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
    }),
  };

  const handleWorkTypeChange = (selectedOption) => {
    setSelectedWorkType(selectedOption);

    if (selectedOption && selectedOption.value === '1' || selectedOption.value === '3' || selectedOption.value === '10') {
      setShowChat(true);
      setShowForm(false);
      setShowSecondSelect(false);
    } else if (selectedOption && (selectedOption.value === '8' || selectedOption.value === '9' || selectedOption.value === '4')) {
      setShowForm(true);
      setShowSecondSelect(true);
      setUserCoverageOptions(userCoverage);
      setShowChat(false);
    } else {
      setShowForm(false);
      setShowSecondSelect(false);
      setShowChat(false);
    }
  };

  const handleSubmit = async () => {

    const selectOperator = selectedOperator.value;
    const selectCoverage = selectedCoverage.value;
    const selectClient = selectedClient.value;
    const selectTypeDaily = selectedWorkType.value;

    const formSubmitData = {
      id_operator: selectOperator,
      id_operator_coverage: selectCoverage,
      id_client: selectClient,
      date: formData.date,
      time_start: formData.time_start,
      time_end: formData.time_end,
      durationH: formData.hours,
      durationM: formData.minutes,
      type_daily: selectTypeDaily,
      description: formData.description,
      status: formData.status
    };

    try {
      const response = await axios.post('/schedule/create', formSubmitData);
      if (response.data.msg) {
        setErrorMsg(response.data.msg);
      } else {
        toast({
          title: 'Ticket schedule send successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        cancelTicket();
      }
    } catch (error) {
      toast({
        title: "Error Creating a Schedule",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error('Error creating schedule:', error);
    }
  };

  const typeDaily = [
    { value: '1', label: 'Questions about your schedule' },
    { value: '4', label: 'Regular Coverage' },
    { value: '3', label: 'Additional hrs' },
    { value: '8', label: 'Additional Coverage' },
    { value: '9', label: 'Additional Shadowing' },
    { value: '10', label: 'Wrong schedule' },
  ];


  const fetchDataAuth = async () => {
    try {
      const response = await axios.get("/user");
      const user = parseInt(response.data.user.type_user_id, 10);
      setUserAuth(user);
    } catch (error) {
      console.error('Error al obtener datos del usuario:', error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`/showTicket/${incidentID}`);
      setIncident(response.data);
    } catch (error) {
      console.error('Error fetching incident:', error);
    }
  };

  const updateScheduleError = async () => {
    try {
      const response = await axios.post(`/updateScheduleError/${incidentID}`);
    } catch (error) {
      console.error('Error fetching incident:', error);
    }
  };

  const handleJoinClick = async () => {
    if (selectedIncidentId !== null) {
      setJoiningRoomId(selectedIncidentId);
      try {
        await axios.post(`/chat/rooms/${selectedIncidentId}/joinUser`, { message: newMessage, chatRomId: selectedIncidentId, incident_id: incidentID })
          .then(response => {
            const newMessageData = response.data.message;
            const user = response.data.user;
            setMessages([...messages, { ...newMessageData, user }]);
            setNewMessage('');
            openModalChat();
            if(selectedWorkType.value === '10'){
              updateScheduleError();
            }
            onClose();
            setFormErrors({});
          })
          .catch(error => {
            console.error(error);
            setFormErrors(error.response.data.errors);
          });
      } finally {
        setJoiningRoomId(null);
      }
    }
  };

  useEffect(() => {
    if (incidentID) {
      fetchData();
    }
    fetchDataAuth();
  }, [incidentID]);

  const filterOptions = (candidate, input) => {
    const words = input.toLowerCase().split(' ');
    return words.every(word =>
      candidate.label.toLowerCase().includes(word)
    );
  };

  const getPlaceholderText = () => {
    switch (selectedIncidentId) {
      case 1:
        return 'Please specify the issue. Example: Internet issues.';
      case 2:
        return 'Please specify the issue. Example: Vacation request.';
      case 3:
        return 'Please specify the issue. Example: Hiring status.';
      case 4:
        return 'Please specify the issue. Example: Client issues.';
      case 5:
        return 'Please specify the issue. Example: Schedule error.';
      case 6:
        return 'Please specify the issue. Example: Payment issues.';
      case 7:
        return 'Please specify the issue. Example: Client feedback.';
      default:
        return 'Please specify the issue.';
    }
  };

  const cancelTicket = () => {
    axios.delete(`/incidents/delete/${incident.id}`)
      .then(response => {
        onClose();
      })
      .catch(error => {
        console.error(error);
      });
  };


  return (
    <Flex>
      <Modal isOpen={isOpen} onClose={onClose} size={'6xl'} isCentered closeOnOverlayClick={false}
        closeOnEsc={false}>
        <ModalOverlay />
        <ModalContent borderRadius={'20px'}>
          <ModalHeader textAlign="center" color={'#808080'} mt={'50px'} fontSize={'40px'}>
            Ticket Schedule
          </ModalHeader>
          <ModalCloseButton onClick={cancelTicket} />
          <ModalBody>

            <Flex display={'flex'} justifyContent={'center'} mb={'40px'} mt={'20px'}>
              <Text mt={'5px'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'} >
                Schedule option:
              </Text>
              <Select2
                placeholder="Select issue"
                options={typeDaily}
                value={selectedWorkType}
                onChange={handleWorkTypeChange}
                styles={customStyles}
                key={`typeDailySelect-${resetKey}`}
              />

            </Flex>
            {showForm && (
              <Flex direction={'column'}>
                <Flex display='flex' justifyContent="center" mt={'50px'}>
                  <Text mt={'5px'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'}>
                    What client?
                  </Text>
                  <Select2
                    placeholder="Client"
                    options={clients}
                    value={selectedClient}
                    onChange={handleClientChange}
                    styles={customStyles}
                    key={`clientSelect-${resetKey}`}
                  />
                  <Text mt={'5px'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'}>
                    What agent?
                  </Text>
                  <Select2
                    placeholder="Agent"
                    options={users}
                    styles={customStyles}
                    value={selectedOperator}
                    onChange={(selectedOption) => setSelectedOperator(selectedOption)}
                    key={`userSelect-${resetKey}`}
                    isSearchable
                    filterOption={filterOptions}
                  />
                </Flex>

                <Flex display='flex' justifyContent="center" mt={'50px'}>

                  {showSecondSelect && (
                    <>
                      <Text mt={'5px'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'}>
                        Who's this agent covering for?
                      </Text>
                      <Select2
                        placeholder="Agent Coverage"
                        options={userCoverageOptions}
                        styles={customStyles}
                        value={selectedCoverage}
                        onChange={(selectedOption) => setSelectedCoverage(selectedOption)}
                        key={`CoverageSelect-${resetKey}`}
                      />
                    </>
                  )}

                  <Text mt={'5px'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'}>
                    What date?
                  </Text>
                  <Input
                    type='date'
                    bg={'#f2f2f2'}
                    width={'350px'}
                    color={'#808080'}
                    textAlign={'center'}
                    value={formData.date}
                    onChange={handleChange}
                    name='date' />

                </Flex>

                <Flex display={'flex'} justifyContent={'center'} mt={'60px'}>
                  <Text fontSize={'25px'} color={'#1c2147'} fontWeight={'bold'} display="flex"
                    justifyContent="space-between">
                    What time?
                  </Text>
                </Flex>

                <Flex display='flex' justifyContent="center" mt={'50px'}>
                  <FormControl isInvalid={errorMsg}>
                    <Input type='time' bg={'#f2f2f2'} color={'#808080'} textAlign={'center'} value={formData.time_start} onChange={handleChange} name='time_start' />
                    {errorMsg && <FormErrorMessage>{errorMsg}</FormErrorMessage>}
                  </FormControl>
                  <Input type='number' width={'120px'} ml={'20px'} mr={'10px'} bg={'#f2f2f2'} color={'#808080'} textAlign={'center'} value={formData.hours} onChange={handleChange} name='hours' />
                  <Text mt={'6px'} mr={'10px'} color={'#808080'}>
                    :
                  </Text>
                  <Input type='number' width={'120px'} mr={'20px'} bg={'#f2f2f2'} color={'#808080'} textAlign={'center'} value={formData.minutes} onChange={handleChange} name='minutes' />
                  <FormControl isInvalid={errorMsg}>
                    <Input type='time' bg={'#f2f2f2'} color={'#808080'} textAlign={'center'} value={formData.time_end} readOnly name='time_end' />
                    {errorMsg && <FormErrorMessage>{errorMsg}</FormErrorMessage>}
                  </FormControl>
                </Flex>

                <Flex display={'flex'} justifyContent={'center'} mt={'80px'}>
                  <Text fontSize={'20px'} color={'#1c2147'} fontWeight={'bold'}>
                    Add a note if is necessary.
                  </Text>
                </Flex>


                <Flex display='flex' justifyContent="center" mt={'50px'}>
                  <Textarea bg={'white'} mr={4} width={'50%'} placeholder='Description' value={formData.description} onChange={handleChange} name='description' />
                </Flex>

                <Flex display={'flex'} justifyContent={'center'} mt={'80px'} mb={'40px'}>
                  <Text fontSize={'20px'} color={'#7080fc'} fontWeight={'bold'}>
                    Note: Double-check the information to ensure your schedule is approved correctly.
                  </Text>
                </Flex>

                <Flex display={'flex'} justifyContent={'center'} mt={'80px'} mb={'40px'}>
                  <Button
                    borderRadius={"5px"}
                    bg={"#b9ce67"}
                    color={"white"}
                    _hover={{ background: "#8aad01", color: 'white' }}
                    onClick={handleSubmit}
                    mr={'30px'}
                  >
                    <Text mr={'5px'}>
                      Send
                    </Text>
                    <IoMdSend />
                  </Button>

                  <Button bg={'#424ea8'} _hover={{ background: "#fc7466", color: 'white' }} color={'white'} mr={3} borderRadius='10px' fontWeight='bold' onClick={cancelTicket}>
                    <CloseIcon mr={2} />  Cancel
                  </Button>
                </Flex>
              </Flex>
            )}

            {showChat && (
              <Box justifyContent={'center'} display={'flex'} mt={'20px'} textAlign={'center'} flexDirection={'column'} alignItems={'center'}>
                <FormControl isInvalid={!!formErrors.message}>
                  <Textarea
                    width={'80%'}
                    value={newMessage}
                    onChange={e => setNewMessage(e.target.value)}
                    placeholder={getPlaceholderText()}
                  />
                  {formErrors.message && (
                    <FormErrorMessage display={'flex'} justifyContent={'center'}>{formErrors.message}</FormErrorMessage>
                  )}
                </FormControl>
                <Text
                  fontSize={'16px'}
                  textAlign={'center'}
                  color={'#808080'}
                >
                  <br />
                  For support with your ticket,  <strong>join the chat.</strong>
                </Text>
                <Flex mt={'20px'} mb={'40px'}>
                  <Button bg={'#b9ce67'} _hover={{ background: "#8aad01", color: 'white' }} color={'white'} mr={3} borderRadius='10px' fontWeight='bold' onClick={handleJoinClick}>
                    <Box mr={2}>
                      <BiChat size={'20px'} />
                    </Box>
                    <Text mr={1}>
                      Join Chat
                    </Text>
                  </Button>
                  <Button bg={'#424ea8'} _hover={{ background: "#fc7466", color: 'white' }} color={'white'} mr={3} borderRadius='10px' fontWeight='bold' onClick={cancelTicket}>
                    <CloseIcon mr={2} />  Cancel
                  </Button>
                </Flex>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal >
      {modalChat && (
        <ModalChat isOpen={modalChat} onClose={() => setModalChat(false)} selectedIncidentId={selectedIncidentId} />
      )}
    </Flex>
  );
}

export default StartScheduleModal;
