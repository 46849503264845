import {
    Table, Thead, Tbody, Tr, Th, Td,
    Menu, MenuButton, MenuItem, MenuList,
    Button, Badge, Text,
} from '@chakra-ui/react'
import React from 'react';

const UserExport = ({ usersE, getColorSchemeForLocation, getRowBackground }) => {
    return (
        <>
            <Table size={'sm'} hidden id='usersTable'>
                <Thead bg={'white'}  >
                    <Tr>
                        <Th color='#808080' textAlign={'center'}>ID</Th>
                        <Th color='#808080' textAlign={'center'}>Full Name</Th>
                        <Th color='#808080' textAlign={'center'}>Dedicated People Email</Th>
                        <Th color='#808080' textAlign={'center'}>Type Of User</Th>
                        <Th color='#808080' textAlign={'center'}>Client</Th>
                        <Th color='#808080' textAlign={'center'}>Status</Th>
                        <Th color='#808080' textAlign={'center'}>Location</Th>
                        <Th color='#808080' textAlign={'center'}>First Day</Th>
                        <Th color='#808080' textAlign={'center'}>Date of Birth</Th>
                        <Th color='#808080' textAlign={'center'}>Place of Birth</Th>
                        <Th color='#808080' textAlign={'center'}>Personal email</Th>
                        <Th color='#808080' textAlign={'center'}>City</Th>
                        <Th color='#808080' textAlign={'center'}>State</Th>
                        <Th color='#808080' textAlign={'center'}>Zipcode</Th>
                        <Th color='#808080' textAlign={'center'}>Current address</Th>
                        <Th color='#808080' textAlign={'center'}>phone</Th>
                        <Th color='#808080' textAlign={'center'}>CURP</Th>
                        <Th color='#808080' textAlign={'center'}>RFC</Th>
                        <Th color='#808080' textAlign={'center'}>SSN</Th>
                        <Th color='#808080' textAlign={'center'}>INFONAVIT credits</Th>
                    </Tr>
                </Thead>
                <Tbody borderRadius="16px" fontSize={'18px'}  >
                    {usersE && usersE.length > 0 ? (
                        usersE.map((user, index) => (
                            <Tr key={user.id} bg={getRowBackground(user)} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>
                                <Td color='#808080' textAlign={'center'}>{user.profile.mat_dp}</Td>
                                <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                                    {user.name} {user.last_name}
                                </Td>
                                <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>{user.email}</Td>
                                <Td textAlign={'center'}>
                                    <Badge
                                        backgroundColor={'#7080fc'}
                                        color='white'
                                        width={'170px'}
                                        fontSize={'10px'}
                                        borderRadius={'5px'}
                                    >
                                        {user.user_type.name}
                                    </Badge>
                                </Td>
                                <Td textAlign={"center"}>
                                    {user.user_clients && user.user_clients.length > 0 ? (
                                        user.user_clients.length === 1 ? (
                                            <Badge
                                                backgroundColor={'#dd67ae'}
                                                color="white"
                                                mt={1}
                                                width={'158px'}
                                                fontSize={'10px'}
                                                borderRadius={'5px'}
                                                textAlign={'center'}
                                            >
                                                {user.user_clients[0].client.name}
                                            </Badge>
                                        ) : (

                                            <Menu>
                                                <MenuButton
                                                    as={Button}
                                                    backgroundColor={'#dd67ae'}
                                                    color="white" size="sm"
                                                    width={'158px'}
                                                    fontSize={'11px'}
                                                    borderRadius={'5px'}
                                                    mt={1}
                                                    _hover={{ background: "#dd67ae", }}
                                                    _active={{ background: "#dd67ae", }}
                                                    height={'25px'}
                                                    title='Show Clients'
                                                >
                                                    {`${user.user_clients.length} Clients`}
                                                </MenuButton>
                                                <MenuList maxH="200px" overflowY="auto">
                                                    {user.user_clients.map((client, index) => (
                                                        <MenuItem key={index} justifyContent={'center'} color={'#808080'} width={'80%'} ml={'20px'} mt={'10px'}>
                                                            <Text fontWeight={'bold'}>
                                                                {client.client.name}
                                                            </Text>
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>
                                            </Menu>
                                        )
                                    ) : (
                                        <Badge
                                            backgroundColor={'#dd67ae'}
                                            color="white"
                                            mt={1}
                                            width={'180px'}
                                            fontSize={'10px'}
                                            borderRadius={'5px'}
                                            textAlign={'center'}
                                        >
                                            No Client
                                        </Badge>
                                    )}
                                </Td>
                                <Td textAlign={'center'}>
                                    <Badge bg={user.status_employee_id === 1 ? '#ff9fb8' : '#8a6dce'} width={"80px"} color={"white"} borderRadius={'5px'} >
                                        {user.status ? user.status.name : 'No Status'}
                                    </Badge>
                                </Td>
                                <Td>
                                    <Badge bg={getColorSchemeForLocation(user.location_id)} width={"46px"} color={"white"} borderRadius={'5px'}>
                                        {(() => {
                                            switch (user.location_id) {
                                                case 1:
                                                    return "MX";
                                                case 2:
                                                    return "BZ";
                                                case 3:
                                                    return "BZ";
                                                case 4:
                                                    return "RD";
                                                case 5:
                                                    return "COL";
                                                default:
                                                    return "MX";
                                            }
                                        })()}
                                    </Badge>
                                </Td>
                                <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                                    {user.profile.general_information && user.profile.general_information.first_day ? user.profile.general_information.first_day : "No data"}
                                </Td>
                                <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                                    {user.profile && user.profile.date_of_birth ? user.profile.date_of_birth : "No data"}
                                </Td>
                                <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                                    {user.profile.general_information && user.profile.general_information.place_of_birth ? user.profile.general_information.place_of_birth : "No data"}
                                </Td>
                                <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                                    {user.profile && user.profile.personal_email ? user.profile.personal_email : "No data"}
                                </Td>
                                <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                                    {user.profile.general_information && user.profile.general_information.city ? user.profile.general_information.city : "No data"}
                                </Td>
                                <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                                    {user.profile.general_information && user.profile.general_information.state ? user.profile.general_information.state : "No data"}
                                </Td>
                                <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                                    {user.profile.general_information && user.profile.general_information.zipcode ? user.profile.general_information.zipcode : "No data"}
                                </Td>
                                <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                                    {user.profile.general_information && user.profile.general_information.address ? user.profile.general_information.address : "No data"}
                                </Td>
                                <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                                    {user.profile && user.profile.phone ? user.profile.phone : "No data"}
                                </Td>
                                <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                                    {user.profile.general_information && user.profile.general_information.curpT ? user.profile.general_information.curpT : "No data"}
                                </Td>
                                <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                                    {user.profile.general_information && user.profile.general_information.rfcT ? user.profile.general_information.rfcT : "No data"}
                                </Td>
                                <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                                    {user.profile.general_information && user.profile.general_information.ssnT ? user.profile.general_information.ssnT : "No data"}
                                </Td>
                                <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                                    {user.profile.general_information && user.profile.general_information.infonavit ? user.profile.general_information.infonavit : "No data"}
                                </Td>
                            </Tr>
                        ))
                    ) : (
                        <Tr>
                            <Th colSpan={'9'} >
                                <Text
                                    color='#1c2147'
                                    fontSize={"25px"}
                                    textAlign={"center"}
                                    paddingTop={"15px"}
                                    paddingBottom={"15px"}
                                    bg={"#f2f2f2"}
                                    textTransform={'none'}
                                    fontFamily={'DM Sans'}
                                    letterSpacing="-1px"
                                >
                                    No data
                                </Text>
                            </Th>
                        </Tr>
                    )}
                </Tbody>
            </Table>
        </>
    );
}

export default UserExport;
