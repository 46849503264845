import React from 'react';
import { Icon } from '@chakra-ui/react';
import { MdHome } from 'react-icons/md';
import { BsFillFilePersonFill } from "react-icons/bs";
import { FaRegCalendar } from "react-icons/fa6";
import { MdDashboardCustomize, MdDashboard } from "react-icons/md";
import DashboardsDefault from 'views/admin/dashboards/default';
import EmployeeOverview from 'views/admin/main/users/overview';
import VacancyOverview from 'views/admin/main/vacancies';
import IncidentsOverview from 'views/admin/main/incidents';
import DefaultUser from 'views/admin/main/others/messages/indexUser';
import Default from 'views/admin/main/others/messages';
import DailyOverview from 'views/admin/main/schedules/dailyschedule';
import UsersSchedulesOverview from 'views/admin/main/schedules/weeklyschedule';

import MyScheduleOverview from 'views/admin/main/schedules/myschedule';
import IncidentsAgentOverview from 'views/admin/main/incidents/indexAgent';

 




const routesHiring = [
  // --- Dashboards ---
  {
    name: 'Home',
    layout: '/admin',
    path: '/home',
    icon: <Icon as={MdHome} width="30px" height="30px" color="inherit" />,
    component: <DashboardsDefault />,
  },
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/dashboards',
    icon: <Icon as={MdDashboard} width="30px" height="30px" color="inherit" />,
    component: <DailyOverview />,
  },
  {
    name: 'Schedules',
    layout: '/admin',
    path: '/schedules',
    icon: <Icon as={FaRegCalendar} width="30px" height="30px" color="inherit" />,
    component: <UsersSchedulesOverview />,
  },
  {
    name: 'My schedule',
    path: '/my-schedule',
    icon: <Icon as={FaRegCalendar} width="30px" height="30px" color="inherit" />,
    layout: '/admin',
    component: <MyScheduleOverview />,
  },
  {
    name: 'Menu',
    path: '/main',
    icon: <Icon as={BsFillFilePersonFill} width="30px" height="30px" color="inherit" />,
    collapse: true,
    items: [
      // {
      //   name: 'Vacancies',
      //   path: '/vacancies',
      //   component: <VacancyOverview />,
      //   layout: '/admin',
      // },
      // {
      //   name: 'Candidates',
      //   path: '/candidates',
      //   layout: '/admin',
      //   component: <EmployeeOverview />,
      // },
      {
        name: 'Tickets',
        path: '/Ticket',
        collapse: true,
        items: [
          {
            name: 'Requests',
            layout: '/admin',
            path: '/ticket/chat',
            exact: false,
            component: <Default />,
          },
          {
            name: 'Ongoing',
            layout: '/admin',
            path: '/ticketU/chat',
            exact: false,
            component: <DefaultUser />,
          },
          {
            name: 'Completed',
            icon: <Icon as={MdHome} width="30px" height="30px" color="inherit" />,
            layout: '/admin',
            path: '/ticket/dashboard',
            exact: false,
            component: <IncidentsOverview />,
          },
          {
            name: 'My Completed',
            layout: '/admin',
            path: '/ticketU/finish',
            exact: false,
            component: <IncidentsAgentOverview />,
          },
        ],
      },
    ],
  },
];

export default routesHiring;
