import React from "react";
import {
    Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Flex, Text, Textarea, Box
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons'
function ShowWeeklModal({ isOpen, onClose, schedule }) {

    const getColorSchemeForTypeDaily = (typeDaily) => {
        const colorMap = {
            1: '#7080fc',
            2: '#fcd677',
            3: '#8a6dce',
            4: '#dd67ae',
            5: '#fca066',
            6: '#fc7466',
            7: '#fcd677',
            8: '#dd67ae',
            9: '#fca066'
        };
        return colorMap[typeDaily] || '#7080fc';
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'6xl'} isCentered closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent borderRadius={"30px"} height={"800px"} alignItems={"center"} alignContent={"center"}   >
                <Flex width={"1100px"} height={"750px"} justifyContent={"space-evenly"} >
                    <Flex width={"500px"} height={"650px"} flexDirection={"column"}>
                        <ModalHeader
                            textColor='#1c2147'
                            mt={'55px'}
                            display={'flex'}
                            fontSize={'30px'}
                            ml={'75px'} width={"max-content"}
                        >
                            Schedule information
                        </ModalHeader>
                        <ModalBody ml={'40px'}>
                            <Flex display={'flex'} justifyContent={'center'} flexDirection="column" alignItems="left" width={"max-content"}>
                                <Flex display="flex" alignItems="center" mt={4}>
                                    <Text color={'#b3b3b3'} fontWeight={'bold'} width={"100px"} fontSize={"18px"}>
                                        Employee:
                                    </Text>
                                    <Text mr={4} fontSize={"18px"} fontWeight={'bold'} color={'#1c2147'}>{schedule.user.name} {schedule.user.last_name}</Text>
                                </Flex>
                                {schedule.id_operator_coverage > 0 ? <Flex display="flex" alignItems="center" mt={4}>
                                    <Text color={'#b3b3b3'} fontWeight={'bold'} width={"100px"} fontSize={"18px"}>
                                        Employee coverage:
                                    </Text>
                                    <Text mr={4} fontWeight={'bold'} fontSize={"18px"} color={'#1c2147'}>{schedule.id_operator_coverage ? schedule.userC.name : 'No Coverage'} {schedule.id_operator_coverage ? schedule.userC.last_name : ''}</Text>
                                </Flex> :
                                    null
                                }
                                <Flex display="flex" alignItems="center" mt={4}>
                                    <Text color={'#b3b3b3'} fontWeight={'bold'} width={"100px"} fontSize={"18px"}>
                                        Date:
                                    </Text>
                                    <Text mr={4} fontWeight={'bold'} fontSize={"18px"} color={'#1c2147'}>{schedule.date}</Text>
                                </Flex>
                                <Flex display="flex" alignItems="center" mt={4}>
                                    <Text color={'#b3b3b3'} fontWeight={'bold'} width={"100px"} fontSize={"18px"}>
                                        Work type:
                                    </Text>
                                    <Text mr={4} fontSize={"15px"} color={'white'} bg={getColorSchemeForTypeDaily(schedule.type_daily)} width={"160px"} textAlign={"center"} borderRadius={"3px"} pl={4} pr={4}>
                                        {(() => {
                                            switch (schedule.type_daily) {
                                                case 1:
                                                    return "Regular shift";
                                                case 2:
                                                    return "Regular training";
                                                case 3:
                                                    return "Additional hours";
                                                case 4:
                                                    return "Regular coverage";
                                                case 5:
                                                    return "Regular shadowing"
                                                case 6:
                                                    return "No shift"
                                                case 7:
                                                    return "Additional training"
                                                case 8:
                                                    return "Additional coverage"
                                                case 9:
                                                    return "Additional shadowing"
                                                default:
                                                    return "Regular shift";
                                            }
                                        })()}
                                    </Text>
                                </Flex>
                                <Flex display="flex" alignItems="center" mt={4} fontWeight={"bold"} fontSize={"18px"}>
                                    <Text color={'#b3b3b3'} fontWeight={'bold'} width={"100px"} fontSize={"18px"}>
                                        Shift:
                                    </Text>
                                    <Text bg={"#f2f2f2"} width={"100px"} textAlign={"center"} borderRadius={"3px"} pl={2} pr={2} mr={4} fontWeight={'bold'} color={'#7080fc'}>{schedule.type_daily === 6 ? '---' : schedule.time_start_12hr}</Text>to
                                    <Text bg={"#f2f2f2"} width={"100px"} textAlign={"center"} borderRadius={"3px"} pl={2} pr={2} ml={4} fontWeight={'bold'} color={'#7080fc'}> {schedule.type_daily === 6 ? '---' : schedule.time_end_12hr} </Text>
                                </Flex>
                                <Flex display="flex" alignItems="center" mt={4} fontWeight={"bold"} fontSize={"18px"}>
                                    <Text color={'#b3b3b3'} fontWeight={'bold'} width={"100px"} fontSize={"18px"}>
                                        Daily log:
                                    </Text>
                                    <Text bg={"#f2f2f2"} width={"100px"} textAlign={"center"} borderRadius={"3px"} pl={2} pr={2} mr={4} fontWeight={'bold'} color={'#1c2147'}>{schedule.time_clock ? schedule.time_clock.date_start : '---'}</Text>to
                                    <Text bg={"#f2f2f2"} width={"100px"} textAlign={"center"} borderRadius={"3px"} pl={2} pr={2} mr={4} ml={4} fontWeight={'bold'} color={'#1c2147'}> {schedule.time_clock ? schedule.time_clock.date_end : '---'} </Text>
                                </Flex>
                                <Flex display="flex" alignItems="center" mt={4} fontSize={"18px"}>
                                    <Text color={'#b3b3b3'} fontWeight={'bold'} width={"230px"}>
                                        Accepted, Posted & Seen:
                                    </Text>
                                    <Text mr={4} fontWeight={'bold'} color={'#1c2147'} textAlign={'start'}>{schedule.accept == 1 ? 'Yes' : 'No'}</Text>,
                                    <Text mr={4} fontWeight={'bold'} color={'#1c2147'} textAlign={'start'}>{schedule.post == 1 ? 'Yes' : 'No'}</Text>,
                                    <Text mr={4} fontWeight={'bold'} color={'#1c2147'}>
                                        {schedule.read_confirmation == 1 ? 'Yes' : 'No'}
                                    </Text>
                                </Flex>
                                <Box height={'180px'} mt={'20px'} bg={'#f2f2f2'} display="flex" alignItems={'left'} fontSize={"18px"} border={'5px'} maxWidth={'350px'}>
                                    <Text color={'#b3b3b3'} fontWeight={'bold'} mt={'10px'} ml={'10px'} mr={'10px'}>
                                        Description:
                                    </Text>
                                    <Text mr={4} fontWeight={'bold'} color={'#1c2147'} mt={'10px'} fontSize={'15px'} textAlign={'justify'}>{schedule.description ? schedule.description : 'Without Description'}</Text>
                                </Box>
                            </Flex>
                        </ModalBody>
                    </Flex>
                    <Box width={"450px"} height={"525px"} mt={"100px"} borderRadius={"25px"} display="flex" bg={"#f2f2f2"} flexDirection="column" alignItems="center" pt={"50px"} >
                        <Text width={"300px"} fontSize={"28px"} fontWeight={"bold"} textColor='#1c2147' textAlign={"center"} bg={"white"} borderRadius={"10px"} height={"max-content"}>Lunch & break log</Text>
                        <Flex direction={'column'} overflowY={"auto"}>
                            <Flex height={"max-content"} flexDirection={"row"} justifyContent={"center"} fontSize={"16px"} color={""} mt={'20px'}>
                                <Text mr={14}>Duration</Text>
                                <Text mr={16}>Type</Text>
                                <Text mr={16} ml={2}>Start</Text>
                                <Text>End</Text>
                            </Flex>
                            {schedule.breaks && schedule.breaks.length > 0 ? (
                                schedule.breaks.map((breakItem, index) => {
                                    return (
                                        <Box key={breakItem.id} width={"500px"} >
                                            <Flex height={"max-content"} flexDirection={"row"} justifyContent={"center"} mt={"15px"} pl={8}>
                                                <Text textAlign={"center"} fontWeight={"bold"} width={"90px"} textColor={"#666666"}>

                                                    {breakItem.duration ? breakItem.duration : '---'}

                                                </Text>
                                                <Text borderRadius={"6px"} bg={breakItem.type === 1 ? '#fca066' : '#7080fc'} width={"90px"} textAlign={"center"} color={"white"} ml={4}>

                                                    {breakItem.type === 1 ? 'Lunch' : 'Break'}

                                                </Text>
                                                <Text textAlign={"center"} textColor='#1c2147' fontWeight={"bold"} width={"90px"} ml={4}>
                                                    {breakItem.date_start ? breakItem.date_start : ''}
                                                </Text>
                                                <Text textAlign={"center"} textColor='#1c2147' fontWeight={"bold"} width={"90px"} ml={2}>
                                                    {breakItem.date_end ? breakItem.date_end : ''}
                                                </Text>
                                            </Flex>
                                        </Box>
                                    );
                                })
                            ) : (
                                <Box width={"500px"} height={"350px"} overflowY={"auto"}>
                                    <Flex height={"max-content"} flexDirection={"row"} justifyContent={"center"} mt={"15px"} pl={8}>
                                        <Text textAlign={"center"} fontWeight={"bold"} width={"90px"} textColor={"#666666"}>No data</Text>
                                    </Flex>
                                </Box>
                            )}
                        </Flex>
                    </Box>
                </Flex>
                <ModalFooter justifyContent="center" mt={'40px'} mb={'20px'}  >
                    <Button bg="#1c2147" color={'white'} _hover={{ background: "#808080", color: 'white' }} mr={3} onClick={onClose} borderRadius={'5px'} size={'md'}>
                        Close <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
export default ShowWeeklModal; 