import {
    Button, Select, Flex
} from '@chakra-ui/react'

import React from 'react';
import Select2 from 'react-select'
import { AiOutlineClear } from "react-icons/ai";

const UserSearch = ({ customStyles, usersClients, clientSearch, optionsUsers, userTypeSearch, handleSelectChangeF, setCurrentPage, filterOptions, resetKey, optionsUserType, optionClients, handleClientChange, statusSearch, setStatusSearch, locationSearch, setLocationSearch, clearFilters, location }) => {

    return (
        <>
            <Flex
                direction='column'
                width={"100%"}
                bg="#f2f2f2"
                p={2}
                borderRadius="16px"
                mt={4}>
                <Flex>
                    <Select2
                        options={clientSearch > 0 ? usersClients : optionsUsers}
                        value={clientSearch > 0 ? usersClients.find(option => option.value === userTypeSearch) : optionsUsers.find(option => option.value === userTypeSearch)}
                        onChange={(selectedOption) => {
                            handleSelectChangeF('user', selectedOption);
                            setCurrentPage(1);
                        }}
                        styles={customStyles}
                        placeholder="Select user"
                        key={`agentSelect-${resetKey}`}
                        isSearchable
                        filterOption={filterOptions}
                    />
                    <Select2
                        options={optionsUserType}
                        value={optionsUserType.find(option => option.value === userTypeSearch)}
                        onChange={(selectedOption) => {
                            handleSelectChangeF('userT', selectedOption);
                            setCurrentPage(1);
                        }}
                        styles={customStyles}
                        placeholder="Select user type"
                        key={`userSelect-${resetKey}`}
                    />
                    <Select2
                        options={optionClients}
                        value={optionClients.find(option => option.value === clientSearch)}
                        onChange={(selectedOption) => {
                            handleClientChange(selectedOption);
                            setCurrentPage(1);
                        }}
                        styles={customStyles}
                        placeholder="Select client"
                        key={`clientSelect-${resetKey}`}
                    />
                    <Select
                        borderRadius={"5px"}
                        borderColor={'#808080'}
                        color={'#808080'}
                        _focusVisible={'none'}
                        _hover={{
                            borderColor: "#808080",
                        }}
                        width={'160px'}
                        ml={4}
                        value={statusSearch}
                        onChange={(e) => {
                            setStatusSearch(e.target.value);
                            setCurrentPage(1);
                        }}
                    >
                        <option value="">Status</option>
                        <option value="1">In office</option>
                        <option value="2">Remote</option>
                        <option value="3">Remote MX</option>
                    </Select>
                    <Select
                        mr={4}
                        borderRadius={"5px"}
                        borderColor={'#808080'}
                        color={'#808080'}
                        _focusVisible={'none'}
                        _hover={{
                            borderColor: "#808080",
                        }}
                        width={'220px'}
                        ml={4}
                        value={locationSearch}
                        onChange={(e) => {
                            setLocationSearch(e.target.value);
                            setCurrentPage(1);
                        }}
                    >
                        <option value="">Select location</option>
                        {location.map((location) => (
                            <option key={location.id} value={location.id}>
                                {location.country}
                            </option>
                        ))}
                    </Select>
                    <Button onClick={clearFilters} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                        <AiOutlineClear size={'26px'} />
                    </Button>
                </Flex>
            </Flex>
        </>
    );
}

export default UserSearch;
