import {
  Box, Flex, Grid,
  Text, Badge
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EndIncident from "../../incidents/componentes/endIncident";
import { IoTicketOutline } from "react-icons/io5";
import MessagesUser from "./components/MessagesUser";

export default function DefaultUser() {
  const [showMessages, setShowMessages] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [userJoin, setUserJoin] = useState('');
  const [incidentData, setIncidentData] = useState([]);
  const [selectIncident, setSelectIncident] = useState(null);
  const [setting, setSetting] = useState(null);
  const [openCloseIncidentModal, setOpenCloseIncidentModal] = useState(false);
  const [totalTickets, setTotalTickets] = useState('');

  const claimIncident = async () => {
    try {

      const response = await axios.get("/incidentsClaimUser");

      setIncidentData(response.data.data);
      setTotalTickets(response.data.totalRecords);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    claimIncident();
    const intervalId = setInterval(() => {
      claimIncident();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleJoinClick = async (incident) => {
    try {
      setShowMessages(false);
      const response = await axios.post(`/chat/rooms/${incident.chat_room_id}/join`);
      const data = response.data;
      setUserJoin(data.user);
      setShowMessages(true);
      setSelectedMessageId(incident);
      claimIncident();
    } catch (error) {
      console.error('Error al unirse a la sala de chat:', error);
    }
  };

  const closeMessages = () => {
    setShowMessages(false);
  };

  const handleUpdate = async (chatRoomId) => {
    try {
      const response = await axios.get(`/incidents/edit/${chatRoomId.id}`);
      const incident = response.data.incident;
      const setting = response.data.settings;
      setOpenCloseIncidentModal(true);
      setSelectIncident(incident);
      setSetting(setting);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const closeModalUpdate = () => {
    setOpenCloseIncidentModal(false);
  }

  const getColorIncident = (chatRoom) => {
    const colorMap = {
      1: '#ff6f94',
      2: '#fabb1c',
      3: '#633cbd',
      4: '#fb9539',
      5: '#a2bd34',
      6: '#6bc2ed',
      7: '#7080fc',
      8: '#fc7466',
    };
    return colorMap[chatRoom] || '#ff6f94';
  };

  return (
    <Grid
      display={{ base: "block", md: "grid" }}
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      gridTemplateColumns={{ base: "1.5fr 1.83fr", lg: "1fr 1.43fr" }}
      gap={{ base: "20px", xl: "10px" }}
      mt={'30px'}
    >
      <Card className="cardonetickuser">
        <Box>
          <Box>
            <Flex mb='15px' align='center' justify='space-between'>
              <Text color={'#1c2147'} fontSize='xl' fontWeight='700'>
                {totalTickets === 0 ? (
                  <>
                    <Flex width={'150px'} display={'flex'} justifyContent={'center'}>
                      <Box mt={'8px'} mr={2} ml={4}>
                        <IoTicketOutline color={"#1c2147"} fontWeight={'bold'} />
                      </Box>
                      <Text fontSize={'20px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                        No tickets
                      </Text>
                    </Flex>
                  </>
                ) : (
                  <>
                    <Flex width={'150px'} display={'flex'} justifyContent={'center'}>
                      <Box mt={'8px'} mr={1}>
                        <IoTicketOutline color={"#1c2147"} fontWeight={'bold'} />
                      </Box>
                      <Text fontSize={'20px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                        {totalTickets} {totalTickets === 1 ? 'Ticket' : 'Tickets'}
                      </Text>
                    </Flex>
                  </>
                )}
              </Text>
            </Flex>
          </Box>
          {incidentData.map((incident) => (
            <Box key={incident.id} onClick={() => handleJoinClick(incident)} cursor={'pointer'} bg={selectedMessageId && selectedMessageId.id === incident.id ? 'linear-gradient(315deg, rgba(122, 134, 230, 1.0) 0%, white 50%)' : (incident.id_supervisor > 0 ? '#f2f2f2' : 'white')}
              borderRadius={'10px'} mt={'10px'} boxShadow={"0px 0px 8px 3px #cccccc"} _hover={{ background: selectedMessageId && selectedMessageId.id === incident.id ? 'linear-gradient(315deg, rgba(122, 134, 230, 1.0) 0%, white 50%)' : '#e6e6e6' }}
            >
              <Flex flexDirection={"row"} >
                <Flex flexDirection={"column"}
                  pt='26px'
                  justifyContent='center'
                  w='max-content'
                >
                  <Box width={"130px"} p={4} height={"130px"}>
                    <IoTicketOutline size={"100px"} color={"#424ea8"} fontWeight={'bold'} />
                    <Text
                      bg={incident.id_supervisor > 0 ? '#424ea8' : 'transparent'}
                      px={2}
                      fontSize={"14"}
                      borderRadius={"5px"}
                      ml={"-8px"}
                      py={1}
                      textAlign={"center"}
                      mt={-16}
                      width={"130px"}
                      color={"white"}
                    >
                      {incident.id_supervisor > 0
                        ? `${incident.supervisor.name.split(' ')[0]} ${incident.supervisor.last_name.split(' ')[0]}`
                        : ``
                      }
                    </Text>
                  </Box>
                  <Text
                    color={selectedMessageId && selectedMessageId.id === incident.id ? '#2c3470' : '#b3b3b3'}
                    fontSize={'20px'}
                    fontWeight='bold' width={"100px"}
                    textAlign={'right'}
                    whiteSpace={'pre-wrap'}
                    mr={'20%'}
                    mb={'40%'}
                  >
                    #{incident.id}
                  </Text>
                </Flex>
                <Flex direction='column' align='start' me='auto' ml={'20px'} pt={8}>

                  <Text className="txtmsj">
                    {incident.first_message}
                  </Text>
                  <Text fontStyle={"italic"} color={selectedMessageId && selectedMessageId.id === incident.id ? '#424ea8' : '#b3b3b3'} fontWeight={"bold"} fontSize={'16px'}>
                    by:  {incident.users.name} {incident.users.last_name}
                  </Text>
                  <Flex flexDirection={"row"} mt={4}>
                    <Badge boxShadow={"0px 0px 8px 3px #cccccc"} bg={'#424ea8'} fontWeight='500' textAlign={'center'} color={'white'} borderRadius={'5px'} mr={2} mt={'10px'}>
                      {incident.clients.name}
                    </Badge>
                    <Badge boxShadow={"0px 0px 8px 3px #cccccc"} bg={getColorIncident(incident.chat_room_id)} fontWeight='500' textAlign={'center'} color={'white'} borderRadius={'5px'} mt={'10px'}>
                      {(() => {
                        switch (incident.chat_room_id) {
                          case 1:
                            return "IT request ";
                          case 2:
                            return "HR request ";
                          case 3:
                            return "Hiring request ";
                          case 4:
                            return "OnCall request ";
                          case 5:
                            return "Schedules request ";
                          case 6:
                            return "Finances request ";
                          case 7:
                            return "Calls request ";
                          case 8:
                            return "Supervisor task";
                          default:
                            return "IT request ";
                        }
                      })()}
                    </Badge>
                  </Flex>
                </Flex>
              </Flex>
              <Flex direction='column' alignItems={'flex-end'} mt={"-13%"} pb={8}>
                <Text
                  color={selectedMessageId && selectedMessageId.id === incident.id ? 'white' : '#b3b3b3'}
                  fontSize={"12px"}
                  fontWeight='bold'
                  textAlign={'right'}
                  whiteSpace={'pre-wrap'}
                  mr={'20px'}
                >
                  {incident.date}
                </Text>
                <Text
                  color={'#424ea8'}
                  fontSize={"20px"}
                  fontWeight='bold'
                  textAlign={'right'}
                  whiteSpace={'pre-wrap'}
                  mr={'20px'}
                >
                  {incident.hour}
                </Text>
                <Box textAlign={'right'} mr={'20px'} mb={6} width={'35px'} height={"35px"} borderRadius={"50%"} bg={'#424ea8'}
                  display={selectedMessageId && selectedMessageId.id === incident.id ? 'none' : (incident.lastMessageCount > 0 ? 'block' : 'none')} >
                  <Text mt={"4px"} textAlign={'center'} fontw fontSize={"18px"} color={'white'}>
                    {incident.lastMessageCount}
                  </Text>
                </Box>

              </Flex>
            </Box>
          ))}
        </Box>
      </Card>
      <Card
        className="cardtwotick">
        {showMessages && <MessagesUser status='active' chatRoomId={selectedMessageId} onClose={closeMessages} openModal={handleUpdate} />}
        {openCloseIncidentModal && <EndIncident
          isOpen={openCloseIncidentModal}
          onClose={closeModalUpdate}
          incident={selectIncident}
          setting={setting}
          onIncidentUpdated={claimIncident}
          closeMessages={closeMessages}
        />
        }
      </Card>
    </Grid>
  );
}