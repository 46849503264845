import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import MedicalNotesFunctions from "./items/MedicalNotesfunctions";


export default function MedicalNotesOverview() {

  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='100px'>
        <MedicalNotesFunctions />
      </Card>
    </Flex>
  );
}
