import {
    Box,
    Button,
    Flex,
    FormLabel,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    Heading,
    FormControl,
    Radio,
    RadioGroup,
    Link,
    Image,
    Alert,
    AlertIcon,
    CloseButton,
    Input,
    FormErrorMessage
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import React, { useState, useRef } from 'react';
import Grammar from "assets/img/candidates/grammartest.jpeg";
import Grammar2 from "assets/img/candidates/grammar2.png";
import BG from "assets/img/candidates/1.jpg";
import { ExternalLinkIcon, AttachmentIcon, ArrowRightIcon, ArrowLeftIcon, CheckIcon } from '@chakra-ui/icons'
import SignatureCanvas from 'react-signature-canvas';
//Form Data
import formDataMXBZ from './data/formDataMXBZ';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
export default function FormHiringOffice() {
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = useState({
        personalInfo: true,
        reference: false,
        medical: false,
        curriculum: false,
        currentW: false,
        requeriments: false,
        commitments: false,
        grammar: false,
        grammar2: false,
        grammar3: false,
        grammar4: false,
        grammar5: false,
        grammar6: false,
        typing: false,
        typing2: false,
        signature: false,
    });
    const personalInformationTab = React.useRef();
    const referenceTab = React.useRef();
    const medicalContidionTab = React.useRef();
    const currentWorkTab = React.useRef();
    const requerimentsTab = React.useRef();
    const commitmentsTab = React.useRef();
    const grammarTestTab = React.useRef();
    const grammarTestTab2 = React.useRef();
    const grammarTestTab3 = React.useRef();
    const grammarTestTab4 = React.useRef();
    const grammarTestTab5 = React.useRef();
    const grammarTestTab6 = React.useRef();
    const typingTestTab = React.useRef();
    const typingTestTab2 = React.useRef();
    const curriculumTestTab = React.useRef();
    const signatureTab = React.useRef();
    const signatureRef = useRef();
    const clearSignature = () => signatureRef.current.clear()
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [errorAlert, setErrorAlert] = useState('');
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ ...formDataMXBZ });
    const [formErrors, setFormErrors] = useState({});
    const [fileStates, setFileStates] = useState({
        curriculum: null,
        grammar_and_listening_testr: null,
        typing_test: null,
    });


    const handleSubmit = async (e) => {
        e.preventDefault();

        const formSubmitData = new FormData();
        const dataURL = signatureRef.current.toDataURL();

        for (const field in formData) {
            if (field === 'questions') {
                formSubmitData.append(`${field}[curriculum]`, formData[field].curriculum);
                formSubmitData.append(`${field}[grammar_and_listening_test]`, formData[field].grammar_and_listening_test);
                formSubmitData.append(`${field}[typing_test]`, formData[field].typing_test);
            } else {
                formSubmitData.append(field, formData[field]);
            }
        }

        try {
            const response = await axios.post('/formHiringMXBZ', formSubmitData, {
                signature: dataURL,
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setAlertMessage(response.data.message);
            setShowAlert(true);
            navigate('/login');
            setFormErrors({});
        } catch (error) {
            setErrorAlert('Postulation Failed');
            console.error('Error creating employee:', error);
            setFormErrors(error.response.data.errors);
        }
    }

    const handleInputChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileChange = (name, file) => {
        if (file) {
            setFileStates((prevStates) => ({
                ...prevStates,
                [name]: file,
            }));
            setFormData((prevData) => ({
                ...prevData,
                [name]: file,
            }));
        }
    };


    return (
        <Flex
            direction="column"
            minH="100vh"
            align="center"
            pt={{ sm: '125px', lg: '0px' }}
            position="relative"
        >
            <Box
                h="100%"
                position="absolute"
                backgroundImage={BG}
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                w="100%"
            >
                <Heading
                    color={'white'}
                    fontSize={{ base: '34px', lg: '36px' }}
                    mb="15px"
                    mt={'45px'}
                    lineHeight='2.0'
                    textAlign={'center'}
                >
                    Telephone Answering Service
                    Representative Application
                </Heading>
                <Text
                    color={'white'}
                    fontWeight="400"
                    fontSize="md"
                    textAlign={'center'}
                >
                    Hi there! We heard you're interested in working with us at Dedicated People.
                    <br />
                    Please fill out and submit this form to start your process.
                </Text>
                <br />
                <Text
                    color={'white'}
                    fontWeight="400"
                    fontSize="md"
                    textAlign={'center'}
                >
                    45 Questions
                </Text>
            </Box>
            <Tabs
                variant="unstyled"
                mt={{ base: '60px', md: '260px' }}
                zIndex="0"
                display="flex"
                flexDirection="column"
            >
                <TabList
                    display="flex"
                    alignItems="center"
                    alignSelf="center"
                    justifySelf="center"
                >
                    <Tab
                        ref={personalInformationTab}
                        _focus={{ border: '0px', boxShadow: 'unset' }}
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: false,
                                medical: false,
                                curriculum: false,
                                currentW: false,
                                requeriments: false,
                                commitments: false,
                                grammar: false,
                                grammar2: false,
                                grammar3: false,
                                grammar4: false,
                                grammar5: false,
                                grammar6: false,
                                typing: false,
                                typing2: false,
                                signature: false,
                            })
                        }

                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"

                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.personalInfo ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />

                        </Flex>
                    </Tab>
                    <Tab
                        ref={referenceTab}
                        _focus={{ border: '0px', boxShadow: 'unset' }}
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: true,
                                medical: false,
                                curriculum: false,
                                currentW: false,
                                requeriments: false,
                                commitments: false,
                                grammar: false,
                                grammar2: false,
                                grammar3: false,
                                grammar4: false,
                                grammar5: false,
                                grammar6: false,
                                typing: false,
                                typing2: false,
                                signature: false,
                            })
                        }

                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.reference ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />

                        </Flex>
                    </Tab>
                    <Tab

                        ref={medicalContidionTab}
                        _focus={{ border: '0px', boxShadow: 'unset' }}
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: true,
                                medical: true,
                                curriculum: false,
                                currentW: false,
                                requeriments: false,
                                commitments: false,
                                grammar: false,
                                grammar2: false,
                                grammar3: false,
                                grammar4: false,
                                grammar5: false,
                                grammar6: false,
                                typing: false,
                                typing2: false,
                                signature: false,
                            })
                        }
                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.medical ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />

                        </Flex>
                    </Tab>

                    <Tab
                        ref={curriculumTestTab}
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: true,
                                medical: true,
                                curriculum: true,
                                currentW: false,
                                requeriments: false,
                                commitments: false,
                                grammar: false,
                                grammar2: false,
                                grammar3: false,
                                grammar4: false,
                                grammar5: false,
                                grammar6: false,
                                typing: false,
                                typing2: false,
                                signature: false,
                            })
                        }

                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.curriculum ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />

                        </Flex>
                    </Tab>
                    <Tab
                        ref={currentWorkTab}
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: true,
                                medical: true,
                                curriculum: true,
                                currentW: true,
                                requeriments: false,
                                commitments: false,
                                grammar: false,
                                grammar2: false,
                                grammar3: false,
                                grammar4: false,
                                grammar5: false,
                                grammar6: false,
                                typing: false,
                                typing2: false,
                                signature: false,
                            })
                        }

                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.currentW ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />

                        </Flex>
                    </Tab>
                    <Tab
                        ref={requerimentsTab}
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: true,
                                medical: true,
                                curriculum: true,
                                currentW: true,
                                requeriments: true,
                                commitments: false,
                                grammar: false,
                                grammar2: false,
                                grammar3: false,
                                grammar4: false,
                                grammar5: false,
                                grammar6: false,
                                typing: false,
                                typing2: false,
                                signature: false,
                            })
                        }

                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.requeriments ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />

                        </Flex>
                    </Tab>
                    <Tab
                        ref={commitmentsTab}
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: true,
                                medical: true,
                                curriculum: true,
                                currentW: true,
                                requeriments: true,
                                commitments: true,
                                grammar: false,
                                grammar2: false,
                                grammar3: false,
                                grammar4: false,
                                grammar5: false,
                                grammar6: false,
                                typing: false,
                                typing2: false,
                                signature: false,
                            })
                        }

                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.commitments ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />

                        </Flex>
                    </Tab>
                    <Tab
                        ref={grammarTestTab}
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: true,
                                medical: true,
                                curriculum: true,
                                currentW: true,
                                requeriments: true,
                                commitments: true,
                                grammar: true,
                                grammar2: false,
                                grammar3: false,
                                grammar4: false,
                                grammar5: false,
                                grammar6: false,
                                typing: false,
                                typing2: false,
                                signature: false,
                            })
                        }

                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.grammar ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />

                        </Flex>
                    </Tab>
                    <Tab
                        ref={grammarTestTab2}
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: true,
                                medical: true,
                                curriculum: true,
                                currentW: true,
                                requeriments: true,
                                commitments: true,
                                grammar: true,
                                grammar2: true,
                                grammar3: false,
                                grammar4: false,
                                grammar5: false,
                                grammar6: false,
                                typing: false,
                                typing2: false,
                                signature: false,
                            })
                        }
                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.grammar2 ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />

                        </Flex>
                    </Tab>
                    <Tab

                        ref={grammarTestTab3}
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: true,
                                medical: true,
                                curriculum: true,
                                currentW: true,
                                requeriments: true,
                                commitments: true,
                                grammar: true,
                                grammar2: true,
                                grammar3: true,
                                grammar4: false,
                                grammar5: false,
                                grammar6: false,
                                typing: false,
                                typing2: false,
                                signature: false,
                            })
                        }
                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.grammar3 ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />

                        </Flex>
                    </Tab>
                    <Tab

                        ref={grammarTestTab4}
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: true,
                                medical: true,
                                curriculum: true,
                                currentW: true,
                                requeriments: true,
                                commitments: true,
                                grammar: true,
                                grammar2: true,
                                grammar3: true,
                                grammar4: true,
                                grammar5: false,
                                grammar6: false,
                                typing: false,
                                typing2: false,
                                signature: false,
                            })
                        }
                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.grammar4 ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />

                        </Flex>
                    </Tab>
                    <Tab
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: true,
                                medical: true,
                                curriculum: true,
                                currentW: true,
                                requeriments: true,
                                commitments: true,
                                grammar: true,
                                grammar2: true,
                                grammar3: true,
                                grammar4: true,
                                grammar5: true,
                                grammar6: false,
                                typing: false,
                                typing2: false,
                                signature: false,
                            })
                        }
                        ref={grammarTestTab5}
                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.grammar5 ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />

                        </Flex>
                    </Tab>
                    <Tab
                        ref={grammarTestTab6}
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: true,
                                medical: true,
                                curriculum: true,
                                currentW: true,
                                requeriments: true,
                                commitments: true,
                                grammar: true,
                                grammar2: true,
                                grammar3: true,
                                grammar4: true,
                                grammar5: true,
                                grammar6: true,
                                typing: false,
                                typing2: false,
                                signature: false,
                            })
                        }
                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.grammar6 ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />

                        </Flex>
                    </Tab>

                    <Tab
                        ref={typingTestTab}
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: true,
                                medical: true,
                                curriculum: true,
                                currentW: true,
                                requeriments: true,
                                commitments: true,
                                grammar: true,
                                grammar2: true,
                                grammar3: true,
                                grammar4: true,
                                grammar5: true,
                                grammar6: true,
                                typing: true,
                                typing2: false,
                                signature: false,
                            })
                        }

                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.typing ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />

                        </Flex>
                    </Tab>
                    <Tab
                        ref={typingTestTab2}
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: true,
                                medical: true,
                                curriculum: true,
                                currentW: true,
                                requeriments: true,
                                commitments: true,
                                grammar: true,
                                grammar2: true,
                                grammar3: true,
                                grammar4: true,
                                grammar5: true,
                                grammar6: true,
                                typing: true,
                                typing2: true,
                                signature: false,
                            })
                        }
                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.typing2 ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />

                        </Flex>
                    </Tab>
                    <Tab
                        ref={signatureTab}
                        onClick={() =>
                            setActiveBullets({
                                personalInfo: true,
                                reference: true,
                                medical: true,
                                curriculum: true,
                                currentW: true,
                                requeriments: true,
                                commitments: true,
                                grammar: true,
                                grammar2: true,
                                grammar3: true,
                                grammar4: true,
                                grammar5: true,
                                grammar6: true,
                                typing: true,
                                typing2: true,
                                signature: true,
                            })
                        }
                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                        >
                            <Box
                                zIndex="1"
                                border="2px solid"
                                borderColor={'white'}
                                bgGradient={activeBullets.signature ? "linear(to-b, white,white)" : ""}
                                w="16px"
                                h="16px"
                                mb="8px"
                                borderRadius="50%"
                            />
                        </Flex>
                    </Tab>
                </TabList>
                <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card
                            p="30px"
                            borderColor={'#fcd677'}
                            borderWidth={'8px'}
                        >
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Personal Information
                            </Text>
                            <Flex direction="column" w="100%">
                                <FormLabel ms="10px" color="#1B2559" fontWeight="bold">
                                    Please share your full name with us
                                </FormLabel>
                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.first_name}>
                                            <InputField
                                                mb="2px"
                                                name="first_name"
                                                defaultValue={formData.first_name}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                                placeholder="First Name"
                                                label="Don't Forget Your Middle Name!"
                                            />
                                            {formErrors.first_name && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}

                                        </FormControl>

                                    </Flex>
                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.last_name}>
                                            <InputField
                                                mb="0px"
                                                name="last_name"
                                                defaultValue={formData.last_name}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}

                                                placeholder="Last Name"
                                            />
                                            {formErrors.last_name && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>

                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            What is your date birth?
                                        </FormLabel>
                                    </Flex>
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            What is your age?
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.date_of_birth}>
                                            <InputField
                                                mb="0px"
                                                name="date_of_birth"
                                                defaultValue={formData.date_of_birth}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}

                                                type="date"
                                                label="Select Your Date Birth"
                                            />
                                            {formErrors.date_of_birth && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>

                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.age}>
                                            <InputField
                                                mb="0px"
                                                name="age"
                                                defaultValue={formData.age}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}

                                                label="Enter Your Age"
                                                placeholder="Age"
                                            />
                                            {formErrors.age && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Select your country
                                        </FormLabel>
                                    </Flex>
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Which office are you applying for?
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">

                                        <FormControl isInvalid={!!formErrors.location}>
                                            <RadioGroup name="location" value={formData.location} onChange={(value) => handleInputChange('location', value)}>
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='1' borderColor={"gray.300"}>México</Radio>
                                                    <Radio value='2' borderColor={"gray.300"}>Belize</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.location && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>

                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.office}>
                                            <FormLabel
                                                fontSize='sm'
                                                color={"gray.400"}
                                                fontWeight='bold'
                                            >
                                                <Text fontSize='sm' fontWeight='400' ms='2px'>
                                                    If you are not applying for any of these offices, this is not the correct application. Please contact us for assistance. </Text>
                                            </FormLabel>
                                            <RadioGroup
                                                name="office"
                                                value={formData.office} onChange={(value) => handleInputChange('office', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={12} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='Mérida' borderColor={"gray.300"}>Mérida</Radio>
                                                    <Radio value='Belize City' borderColor={"gray.300"}>Belize City</Radio>
                                                    <Radio value='San Ignacio' borderColor={"gray.300"}>San Ignacio</Radio>
                                                    <Radio value='Orange Walk Town' borderColor={"gray.300"}>Orange Walk Town</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.office && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>

                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            If you're from Mérida, Belize City or San Ignacio, are you willing to work from office?
                                        </FormLabel>
                                    </Flex>
                                </Flex>
                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormControl isInvalid={!!formErrors.work_in_office}>
                                            <FormLabel
                                                fontSize='sm'
                                                color={"gray.400"}
                                                fontWeight='bold'
                                            >
                                                <Text fontSize='sm' fontWeight='400' ms='2px'>
                                                    You can only work from office in these locations. This applies for surrounding villages (Santa Elena, Ladyville, etc.). If you're not willing to work from office, your application will be DECLINED.
                                                </Text>
                                            </FormLabel>
                                            <RadioGroup
                                                name="work_in_office"
                                                value={formData.work_in_office} onChange={(value) => handleInputChange('work_in_office', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={12} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='Yes' borderColor={"gray.300"}>Yes</Radio>
                                                    <Radio value='No' borderColor={"gray.300"}>No</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.work_in_office && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">

                                    <Flex direction="column" w="100%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            What is your HOME address?
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormControl isInvalid={!!formErrors.address}>
                                            <InputField
                                                mb="0px"
                                                name="address"
                                                defaultValue={formData.address}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                                placeholder="Home Address"
                                                label="Please share your FULL address with us. This is used to determine if you are eligible to apply with us."
                                            />
                                            {formErrors.address && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            What's your email address?
                                        </FormLabel>
                                    </Flex>
                                </Flex>
                                <FormControl isInvalid={!!formErrors.personal_email}>
                                    <InputField
                                        mb="2px"
                                        name="personal_email"
                                        defaultValue={formData.personal_email}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        placeholder="Personal Email"
                                        label="Please double check it! We will use this email to reach out and provide updates!"
                                    />
                                    {formErrors.personal_email && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}

                                </FormControl>
                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            What is your Phone Number?
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormControl isInvalid={!!formErrors.phone}>
                                            <InputField
                                                mb="2px"
                                                name="phone"
                                                defaultValue={formData.phone}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                                placeholder="Phone Number"
                                            />
                                            {formErrors.phone && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}

                                        </FormControl>
                                    </Flex>

                                </Flex>


                                <Flex justify="space-between" mt="24px">
                                    <Button
                                        fontSize="sm"
                                        borderRadius="16px"
                                        colorScheme="blue"
                                        bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                        w={{ base: '128px', md: '148px' }}
                                        h="46px"
                                        ms="auto"
                                        onClick={() => referenceTab.current.click()}
                                    >
                                        Next <ArrowRightIcon ml={2} />
                                    </Button>
                                </Flex>
                            </Flex>
                        </Card>

                    </TabPanel>
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card p="30px" borderColor={'#fcd677'}
                            borderWidth={'8px'}>
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Reference Information
                            </Text>
                            <Flex direction="column" w="100%">
                                <FormControl isInvalid={!!formErrors.about_us}>
                                    <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                        How did you hear about us?
                                    </FormLabel>
                                    <RadioGroup
                                        name="about_us"
                                        value={formData.about_us} onChange={(value) => handleInputChange('about_us', value)}
                                    >
                                        <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" bg={"#f2f2f2"} borderRadius={"10px"}>
                                            <Radio value='Company Website' borderColor={"gray.300"}>Company Website</Radio>
                                            <Radio value='CompuTrabajo' borderColor={"gray.300"}>CompuTrabajo</Radio>
                                            <Radio value='Facebook' borderColor={"gray.300"}>Facebook</Radio>
                                            <Radio value='Indeed' borderColor={"gray.300"}>Indeed</Radio>
                                            <Radio value='Instagram' borderColor={"gray.300"}>Instagram</Radio>
                                            <Radio value='Reference' borderColor={"gray.300"}>Reference</Radio>
                                            <Radio value='OCC Mundial' borderColor={"gray.300"}>OCC Mundial</Radio>
                                        </Stack>
                                    </RadioGroup>
                                    {formErrors.about_us && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            If you were referred by one of our employees, please write their name!
                                        </FormLabel>
                                    </Flex>
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            What's the name of your internet provider?
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.referred_employee}>
                                            <InputField
                                                mb="0px"
                                                type="text"
                                                name="referred_employee"
                                                defaultValue={formData.referred_employee}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                                label="Remember we have referral bonuses!"
                                            />
                                            {formErrors.referred_employee && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.internet_provider}>
                                            <InputField
                                                mb="0px"
                                                type="text"
                                                name="internet_provider"
                                                defaultValue={formData.internet_provider}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                            />
                                            {formErrors.internet_provider && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>

                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Is this your first time applying with us?
                                        </FormLabel>
                                    </Flex>
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Have you worked with us before?
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.first_time_apply}>
                                            <RadioGroup
                                                name="first_time_apply"
                                                value={formData.first_time_apply} onChange={(value) => handleInputChange('first_time_apply', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5} bg={"#f2f2f2"} borderRadius={"10px"} >
                                                    <Radio value='Yes' borderColor={"gray.300"} >Yes</Radio>
                                                    <Radio value='No' borderColor={"gray.300"}>No</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.first_time_apply && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>

                                    <Flex direction="column" w="48%">

                                        <FormControl isInvalid={!!formErrors.work_with_us_before}>
                                            <RadioGroup
                                                name="work_with_us_before"
                                                value={formData.work_with_us_before} onChange={(value) => handleInputChange('work_with_us_before', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='Yes' borderColor={"gray.300"}>Yes</Radio>
                                                    <Radio value='No' borderColor={"gray.300"}>No</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.work_with_us_before && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Do you have a high school (bachillerato) degree?
                                        </FormLabel>
                                    </Flex>
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Are you available from 7:45 AM to 4:30 PM CST?
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.studies}>
                                            <FormLabel
                                                fontSize='sm'
                                                color={"gray.400"}
                                                fontWeight='bold'
                                            >
                                                <Text fontSize='sm' fontWeight='400' ms='2px'>
                                                    A high school diploma will be required to work with us.</Text>
                                            </FormLabel>
                                            <RadioGroup
                                                name="studies"
                                                value={formData.studies} onChange={(value) => handleInputChange('studies', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={12} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='Yes' borderColor={"gray.300"}>Yes</Radio>
                                                    <Radio value='No' borderColor={"gray.300"}>No</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.studies && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>

                                    </Flex>

                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.disponibility}>
                                            <FormLabel
                                                fontSize='sm'
                                                color={"gray.400"}
                                                fontWeight='bold'
                                                _hover={{ cursor: "pointer" }}>
                                                <Text fontSize='sm' fontWeight='400' ms='2px'>
                                                    Our trainings are always in the mornings and training can last up to two months. If you cannot be available, we cannot hire you.
                                                </Text>
                                            </FormLabel>
                                            <RadioGroup
                                                name="disponibility"
                                                value={formData.disponibility} onChange={(value) => handleInputChange('disponibility', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='Yes' borderColor={"gray.300"}>Yes</Radio>
                                                    <Radio value='No' borderColor={"gray.300"}>No</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.disponibility && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Do you have plans to study within the next 6 months?
                                        </FormLabel>
                                    </Flex>
                                </Flex>


                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.plan_to_study}>
                                            <RadioGroup
                                                name="plan_to_study"
                                                value={formData.plan_to_study} onChange={(value) => handleInputChange('plan_to_study', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='Yes' borderColor={"gray.300"}>Yes</Radio>
                                                    <Radio value='No' borderColor={"gray.300"}>No</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.plan_to_study && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>

                                    </Flex>
                                </Flex>

                                <Flex justify="space-between" mt="24px">
                                    <Button
                                        bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                                        fontSize="sm"
                                        borderRadius="16px"
                                        w={{ base: '128px', md: '148px' }}
                                        h="46px"
                                        onClick={() => personalInformationTab.current.click()}
                                    >
                                        <ArrowLeftIcon mr={2} />  Prev
                                    </Button>
                                    <Button
                                        colorScheme="blue"
                                        bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                        fontSize="sm"
                                        borderRadius="16px"
                                        w={{ base: '128px', md: '148px' }}
                                        h="46px"
                                        onClick={() => medicalContidionTab.current.click()}
                                    >
                                        Next <ArrowRightIcon ml={2} />
                                    </Button>
                                </Flex>
                            </Flex>
                        </Card>

                    </TabPanel>
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card p="30px" borderColor={'#fcd677'}
                            borderWidth={'8px'}>
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Medical Information
                            </Text>
                            <Flex direction="column" w="100%">
                                <FormControl isInvalid={!!formErrors.medical_condition}>
                                    <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                        Do you have any medical condition we should be made aware of?
                                    </FormLabel>
                                    <RadioGroup
                                        name="medical_condition"
                                        value={formData.medical_condition} onChange={(value) => handleInputChange('medical_condition', value)}
                                    >
                                        <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" bg={"#f2f2f2"} borderRadius={"10px"}>
                                            <Radio value='Yes' borderColor={"gray.300"}>Yes</Radio>
                                            <Radio value='No' borderColor={"gray.300"}>No</Radio>
                                        </Stack>
                                    </RadioGroup>
                                    {formErrors.medical_condition && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            If you answered yes, please specify the nature of your medical condition:
                                        </FormLabel>
                                    </Flex>

                                </Flex>

                                <Flex direction="row" justify="space-between">

                                    <Flex direction="column" w="100%">
                                        <FormControl isInvalid={!!formErrors.specify_medical_contidion}>
                                            <InputField
                                                mb="0px"
                                                type="text"
                                                name="specify_medical_contidion"
                                                defaultValue={formData.specify_medical_contidion}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                            />
                                            {formErrors.specify_medical_contidion && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Do you have anxiety, depression or any other diagnosed mental disorder?
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormControl isInvalid={!!formErrors.diagnosed_mental_disorder}>
                                            <FormLabel
                                                fontSize='sm'
                                                color={"gray.400"}
                                                fontWeight='bold'
                                                _hover={{ cursor: "pointer" }}>
                                                <Text fontSize='sm' fontWeight='400' ms='2px'>
                                                    Please keep in mind that this is a job that requires a lot of stress handling.
                                                </Text>
                                            </FormLabel>
                                            <RadioGroup
                                                name="diagnosed_mental_disorder"
                                                value={formData.diagnosed_mental_disorder} onChange={(value) => handleInputChange('diagnosed_mental_disorder', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='Yes' borderColor={"gray.300"}>Yes</Radio>
                                                    <Radio value='No' borderColor={"gray.300"}>No</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.diagnosed_mental_disorder && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>

                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            If you answered yes, please specify the nature of your disorder:
                                        </FormLabel>
                                    </Flex>
                                </Flex>


                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormControl isInvalid={!!formErrors.specify_diagnosed_mental_disorder}>
                                            <InputField
                                                mb="0px"
                                                type="text"
                                                name="specify_diagnosed_mental_disorder"
                                                defaultValue={formData.specify_diagnosed_mental_disorder}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                            />
                                            {formErrors.specify_diagnosed_mental_disorder && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">

                                </Flex>
                                <Flex direction="row" justify="space-between">

                                    <Flex direction="column" w="100%">


                                    </Flex>
                                </Flex>
                                <Flex justify="space-between" mt="24px">
                                    <Button
                                        bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                                        fontSize="sm"
                                        borderRadius="16px"
                                        w={{ base: '128px', md: '148px' }}
                                        h="46px"
                                        onClick={() => referenceTab.current.click()}
                                    >
                                        <ArrowLeftIcon mr={2} />  Prev
                                    </Button>
                                    <Button
                                        colorScheme="blue"
                                        bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                        fontSize="sm"
                                        borderRadius="16px"
                                        w={{ base: '128px', md: '148px' }}
                                        h="46px"
                                        onClick={() => curriculumTestTab.current.click()}
                                    >
                                        Next <ArrowRightIcon ml={2} />
                                    </Button>
                                </Flex>
                            </Flex>
                        </Card>
                    </TabPanel>
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card p="30px" borderColor={'#fcd677'}
                            borderWidth={'8px'}>
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Curriculum
                            </Text>
                            <Flex direction="column" w="100%" mb={5}>
                                <FormControl isInvalid={!!formErrors.work_experience}>
                                    <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                        Do you have previous work experience?
                                    </FormLabel>
                                    <RadioGroup
                                        name="work_experience"
                                        value={formData.work_experience} onChange={(value) => handleInputChange('work_experience', value)}
                                    >
                                        <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" bg={"#f2f2f2"} borderRadius={"10px"}>
                                            <Radio value='Yes' borderColor={"gray.300"}>Yes</Radio>
                                            <Radio value='No' borderColor={"gray.300"}>No</Radio>
                                        </Stack>
                                    </RadioGroup>
                                    {formErrors.work_experience && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>

                            <Flex direction="row" justify="space-between">
                                <Flex direction="column" w="100%">
                                    <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                        If your answer was yes, please attach your RESUME! If your answer was no, please attach a cover letter.
                                    </FormLabel>
                                </Flex>
                            </Flex>
                            <Flex direction="column" w="100%">
                                <FormLabel ms="10px" color="gray.400" fontWeight="bold" mt={2}>
                                    This helps us find high-value candidates and could speed up your hiring process! WE WILL TAKE NOTE IF YOUR RESUME IS NOT UPDATED OR DOES NOT MATCH THE REST OF THE INFORMATION YOU PROVIDE.
                                </FormLabel>
                            </Flex>
                            <Box align='center'>
                                <FormControl isInvalid={!!formErrors.curriculum}>
                                    <Input
                                        type="file"
                                        name="curriculum"
                                        id="fileInput4"
                                        display="none"
                                        onChange={(e) => handleFileChange(e.target.name, e.target.files[0])}
                                    />
                                    <Button as="label" htmlFor="fileInput4" cursor="pointer" bg={"#7080fc"} color={"white"} _hover={{ background: "#808080", color: 'white' }}>
                                        Select A File <AttachmentIcon ml={2} />
                                    </Button>
                                    {fileStates.curriculum && (
                                        <div style={{ textAlign: "center" }}>
                                            <p>File Selected: {fileStates.curriculum.name}</p>
                                        </div>
                                    )}
                                    {formErrors.curriculum && (
                                        <FormErrorMessage>{formErrors.curriculum}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Box>
                            <Flex justify="space-between" mt="24px">
                                <Button
                                    bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => medicalContidionTab.current.click()}
                                >
                                    <ArrowLeftIcon mr={2} />  Prev
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => currentWorkTab.current.click()}
                                >
                                    Next <ArrowRightIcon ml={2} />
                                </Button>
                            </Flex>
                        </Card>
                    </TabPanel>
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card p="30px" borderColor={'#fcd677'}
                            borderWidth={'8px'}>
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Currently Work
                            </Text>
                            <Flex direction="column" w="100%">


                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Do you have call center experience?
                                        </FormLabel>
                                    </Flex>
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Are you currently working?
                                        </FormLabel>
                                    </Flex>

                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.experience_call_center}>
                                            <RadioGroup
                                                name="experience_call_center"
                                                value={formData.experience_call_center} onChange={(value) => handleInputChange('experience_call_center', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='Yes' borderColor={"gray.300"}>Yes</Radio>
                                                    <Radio value='No' borderColor={"gray.300"}>No</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.experience_call_center && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.currently_working}>
                                            <RadioGroup
                                                name="currently_working"
                                                value={formData.currently_working} onChange={(value) => handleInputChange('currently_working', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='Yes' borderColor={"gray.300"}>Yes</Radio>
                                                    <Radio value='No' borderColor={"gray.300"}>No</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.currently_working && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>


                                </Flex>



                                <Flex direction="row" justify="space-between">

                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            If your answer was yes, are you planning on keeping your current job?
                                        </FormLabel>
                                    </Flex>
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Talk to us about your current or most recent job: what's the name of the company and what was your position there?
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">


                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.planning_keep_currently_work}>
                                            <RadioGroup
                                                name="planning_keep_currently_work"
                                                value={formData.planning_keep_currently_work} onChange={(value) => handleInputChange('planning_keep_currently_work', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='Yes' borderColor={"gray.300"}>Yes</Radio>
                                                    <Radio value='No' borderColor={"gray.300"}>No</Radio>
                                                    <Radio value='I Am Currenlty Unemployed' borderColor={"gray.300"}>I Am Currenlty Unemployed</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.planning_keep_currently_work && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.currently_company_name}>
                                            <InputField
                                                mb="0px"
                                                type="text"
                                                name="currently_company_name"
                                                defaultValue={formData.currently_company_name}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                            />
                                            {formErrors.currently_company_name && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">

                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Please provide a contact number for your current or previous employer:
                                        </FormLabel>
                                    </Flex>
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            How long did you work there?
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">


                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.currently_company_phone}>
                                            <InputField
                                                mb="0px"
                                                type="text"
                                                name="currently_company_phone"
                                                defaultValue={formData.currently_company_phone}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                            />
                                            {formErrors.currently_company_phone && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.currently_company_time}>
                                            <InputField
                                                mb="0px"
                                                type="text"
                                                name="currently_company_time"
                                                defaultValue={formData.currently_company_time}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                            />
                                            {formErrors.currently_company_time && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">

                                    <Flex direction="column" w="100%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Why did you leave?
                                        </FormLabel>
                                    </Flex>
                                </Flex>


                                <Flex direction="row" justify="space-between">


                                    <Flex direction="column" w="100%">
                                        <FormControl isInvalid={!!formErrors.specify_leave_currently_work}>
                                            <InputField
                                                mb="0px"
                                                type="text"
                                                name="specify_leave_currently_work"
                                                defaultValue={formData.specify_leave_currently_work}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                                label="or are planning on leaving"
                                            />
                                            {formErrors.specify_leave_currently_work && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Please provide the name of your personal or professional reference
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormControl isInvalid={!!formErrors.professional_reference}>
                                            <InputField
                                                mb="0px"
                                                type="text"
                                                name="professional_reference"
                                                defaultValue={formData.professional_reference}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                                label="Tell us their name and the nature of your relationship. For example: Mario Rodríguez, former supervisor."
                                            />
                                            {formErrors.professional_reference && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>
                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            What is their contact number?
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormControl isInvalid={!!formErrors.number_professional_reference}>
                                            <InputField
                                                mb="0px"
                                                type="text"
                                                name="number_professional_reference"
                                                defaultValue={formData.number_professional_reference}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                            />
                                            {formErrors.number_professional_reference && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex justify="space-between" mt="24px">
                                    <Button
                                        bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                                        fontSize="sm"
                                        borderRadius="16px"
                                        w={{ base: '128px', md: '148px' }}
                                        h="46px"
                                        onClick={() => curriculumTestTab.current.click()}
                                    >
                                        <ArrowLeftIcon mr={2} />  Prev
                                    </Button>
                                    <Button
                                        colorScheme="blue"
                                        bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                        fontSize="sm"
                                        borderRadius="16px"
                                        w={{ base: '128px', md: '148px' }}
                                        h="46px"
                                        onClick={() => requerimentsTab.current.click()}
                                    >
                                        Next <ArrowRightIcon ml={2} />
                                    </Button>
                                </Flex>
                            </Flex>
                        </Card>
                    </TabPanel>
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card p="30px" borderColor={'#fcd677'}
                            borderWidth={'8px'}>
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Requirements
                            </Text>
                            <Flex direction="column" w="100%">


                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Do you speak fluent Spanish?
                                        </FormLabel>
                                    </Flex>
                                    <Flex direction="column" w="48%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Are you looking for a full-time shift?
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.speak_fluent_spanish}>
                                            <FormLabel
                                                fontSize='sm'
                                                color={"gray.400"}
                                                fontWeight='bold'
                                                _hover={{ cursor: "pointer" }}>
                                                <Text fontSize='sm' fontWeight='400' ms='2px'>
                                                    Please keep in mind we're looking for bilingual agents all the time! Only answer yes if you're also willing to take calls in Spanish.
                                                </Text>
                                            </FormLabel>
                                            <RadioGroup
                                                name="speak_fluent_spanish"
                                                value={formData.speak_fluent_spanish} onChange={(value) => handleInputChange('speak_fluent_spanish', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='Yes' borderColor={"gray.300"}>Yes</Radio>
                                                    <Radio value='No' borderColor={"gray.300"}>No</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.speak_fluent_spanish && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>

                                    <Flex direction="column" w="48%">
                                        <FormControl isInvalid={!!formErrors.time_work}>
                                            <FormLabel
                                                fontSize='sm'
                                                color={"gray.400"}
                                                fontWeight='bold'
                                                _hover={{ cursor: "pointer" }}>
                                                <Text fontSize='sm' fontWeight='400' ms='2px'>
                                                    Part time is not available for remote agents./
                                                </Text>
                                            </FormLabel>
                                            <RadioGroup
                                                name="time_work"
                                                value={formData.time_work} onChange={(value) => handleInputChange('time_work', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={14} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='Full-time' borderColor={"gray.300"}>Full-time</Radio>
                                                    <Radio value='12p.m to 12a.m' borderColor={"gray.300"}>Part-time (No Longer Available)</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.time_work && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>



                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            Please pick a 12 hour time frame that you are free and available to work so we can assign an 8 hour shift within those hours.
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormControl isInvalid={!!formErrors.time_frame}>
                                            <FormLabel
                                                fontSize='sm'
                                                color={"gray.400"}
                                                fontWeight='bold'
                                                _hover={{ cursor: "pointer" }}>
                                                <Text fontSize='sm' fontWeight='400' ms='2px'>
                                                    The listed times are the most needed. If you have a different availability, please pick the other option and type in your 12 hour time frame.
                                                </Text>
                                            </FormLabel>
                                            <RadioGroup
                                                name="time_frame"
                                                value={formData.time_frame} onChange={(value) => handleInputChange('time_frame', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='11 a.m to 11p.m' borderColor={"gray.300"}>11 a.m. to 11 p.m.</Radio>
                                                    <Radio value='12p.m to 12a.m' borderColor={"gray.300"}>12 p.m. to 12 a.m.</Radio>
                                                    <Radio value='1 p.m. to 1 a.m.' borderColor={"gray.300"}>1 p.m. to 1 a.m.</Radio>
                                                    <Radio value='2p.m to 2a.m' borderColor={"gray.300"}>2 p.m. to 2 a.m.</Radio>
                                                    <Radio value='other' borderColor={"gray.300"}>Other</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.time_frame && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            The time frame you provided is extremely important as it will determine if we can hire you or not. You must be 100% sure that you are commited to this availability.
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormControl isInvalid={!!formErrors.confirm_time_frame}>
                                            <FormLabel
                                                fontSize='sm'
                                                color={"gray.400"}
                                                fontWeight='bold'
                                                _hover={{ cursor: "pointer" }}>
                                                <Text fontSize='sm' fontWeight='400' ms='2px'>
                                                    Changing your availability might not be possible in the future or may lead to your application being declined.
                                                </Text>
                                            </FormLabel>
                                            <RadioGroup
                                                name="confirm_time_frame"
                                                value={formData.confirm_time_frame} onChange={(value) => handleInputChange('confirm_time_frame', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='I Understand' borderColor={"gray.300"}>I Understand</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.confirm_time_frame && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            As was mentioned earlier, we usually work weekends (and have 2 days off distributed throughout the week). Please let us know if you are available to work weekends.
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormControl isInvalid={!!formErrors.day_weekend_free}>
                                            <FormLabel
                                                fontSize='sm'
                                                color={"gray.400"}
                                                fontWeight='bold'
                                                _hover={{ cursor: "pointer" }}>
                                                <Text fontSize='sm' fontWeight='400' ms='2px'>
                                                    Changing your availability might not be possible in the future or may lead to your application being declined.
                                                </Text>
                                            </FormLabel>
                                            <RadioGroup
                                                name="day_weekend_free"
                                                value={formData.day_weekend_free} onChange={(value) => handleInputChange('day_weekend_free', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='I can work Saturdays and Sundays' borderColor={"gray.300"}>I can work Saturdays and Sundays</Radio>
                                                    <Radio value='I can only work Saturdays' borderColor={"gray.300"}>I can only work Saturdays</Radio>
                                                    <Radio value='I can only work Sundays' borderColor={"gray.300"}>I can only work Sundays</Radio>
                                                    <Radio value='I cannot work weekends and understand I WILL BE DECLINED' borderColor={"gray.300"}>I cannot work weekends and understand I WILL BE DECLINED</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.day_weekend_free && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            The weekend availability you have provided is extremely important as it will determine if we can hire you or not. You must be 100% sure that you are commited to this availability.
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormControl isInvalid={!!formErrors.confirm_day_weekend_free}>
                                            <FormLabel
                                                fontSize='sm'
                                                color={"gray.400"}
                                                fontWeight='bold'
                                                _hover={{ cursor: "pointer" }}>
                                                <Text fontSize='sm' fontWeight='400' ms='2px'>
                                                    Changing your availability might not be possible in the future or may lead to your application being declined.
                                                </Text>
                                            </FormLabel>
                                            <RadioGroup
                                                name="confirm_day_weekend_free"
                                                value={formData.confirm_day_weekend_free} onChange={(value) => handleInputChange('confirm_day_weekend_free', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='I Understand' borderColor={"gray.300"}>I Understand</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.confirm_day_weekend_free && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex justify="space-between" mt="24px">
                                    <Button
                                        bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                                        fontSize="sm"
                                        borderRadius="16px"
                                        w={{ base: '128px', md: '148px' }}
                                        h="46px"
                                        onClick={() => currentWorkTab.current.click()}
                                    >
                                        <ArrowLeftIcon mr={2} />  Prev
                                    </Button>
                                    <Button
                                        colorScheme="blue"
                                        bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                        fontSize="sm"
                                        borderRadius="16px"
                                        w={{ base: '128px', md: '148px' }}
                                        h="46px"
                                        onClick={() => commitmentsTab.current.click()}
                                    >
                                        Next <ArrowRightIcon ml={2} />
                                    </Button>
                                </Flex>
                            </Flex>
                        </Card>
                    </TabPanel>
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card p="30px" borderColor={'#fcd677'}
                            borderWidth={'8px'}>
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Commitments
                            </Text>
                            <Flex direction="column" w="100%">
                                <FormControl isInvalid={!!formErrors.commitments_soon}>
                                    <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                        Do you have any commitments within the next 4 months that might interfere with work?
                                    </FormLabel>
                                    <RadioGroup
                                        name="commitments_soon"
                                        value={formData.commitments_soon} onChange={(value) => handleInputChange('commitments_soon', value)}
                                    >
                                        <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" bg={"#f2f2f2"} borderRadius={"10px"}>
                                            <Radio value='Yes' borderColor={"gray.300"}>Yes</Radio>
                                            <Radio value='No' borderColor={"gray.300"}>No</Radio>
                                        </Stack>
                                    </RadioGroup>
                                    {formErrors.commitments_soon && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            If your answer was yes, please let us know what your commitment is as well as the times and dates
                                        </FormLabel>
                                    </Flex>

                                </Flex>

                                <Flex direction="row" justify="space-between">

                                    <Flex direction="column" w="100%">
                                        <FormControl isInvalid={!!formErrors.specify_commitments_soon}>
                                            <InputField
                                                mb="0px"
                                                type="text"
                                                name="specify_commitments_soon"
                                                defaultValue={formData.specify_commitments_soon}
                                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                                label="For example; I will be traveling to Mexico City from april 1st to the 8th."
                                            />
                                            {formErrors.specify_commitments_soon && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormLabel ms="10px" color="#1B2559" fontWeight="bold" mt={2}>
                                            If you were hired, when would you be able to start?
                                        </FormLabel>
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="space-between">
                                    <Flex direction="column" w="100%">
                                        <FormControl isInvalid={!!formErrors.able_to_start}>
                                            <FormLabel
                                                fontSize='sm'
                                                color={"gray.400"}
                                                fontWeight='bold'
                                                _hover={{ cursor: "pointer" }}>
                                                <Text fontSize='sm' fontWeight='400' ms='2px'>
                                                    We start training every Monday.
                                                </Text>
                                            </FormLabel>
                                            <RadioGroup
                                                name="able_to_start"
                                                value={formData.able_to_start} onChange={(value) => handleInputChange('able_to_start', value)}
                                            >
                                                <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5} bg={"#f2f2f2"} borderRadius={"10px"}>
                                                    <Radio value='ASAP' borderColor={"gray.300"}>ASAP</Radio>
                                                    <Radio value='I Need To Give My Two Weeks Notice' borderColor={"gray.300"}>I Need To Give My Two Weeks Notice</Radio>
                                                    <Radio value='Other' borderColor={"gray.300"}>Other</Radio>
                                                </Stack>
                                            </RadioGroup>
                                            {formErrors.able_to_start && (
                                                <FormErrorMessage>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>

                                    </Flex>
                                </Flex>


                                <Flex justify="space-between" mt="24px">
                                    <Button
                                        bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                                        fontSize="sm"
                                        borderRadius="16px"
                                        w={{ base: '128px', md: '148px' }}
                                        h="46px"
                                        onClick={() => requerimentsTab.current.click()}
                                    >
                                        <ArrowLeftIcon mr={2} />  Prev
                                    </Button>
                                    <Button
                                        colorScheme="blue"
                                        bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                        fontSize="sm"
                                        borderRadius="16px"
                                        w={{ base: '128px', md: '148px' }}
                                        h="46px"
                                        onClick={() => grammarTestTab.current.click()}
                                    >
                                        Next <ArrowRightIcon ml={2} />
                                    </Button>
                                </Flex>
                            </Flex>
                        </Card>
                    </TabPanel>
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card p="30px" borderColor={'#fcd677'}
                            borderWidth={'8px'}>
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Grammar & Listening Test
                            </Text>

                            <Text textAlign={'center'} fontSize={'40px'} color={'red'} fontWeight={'bold'}>
                                ADVANCED ENGLISH IS REQUIRED.
                            </Text>
                            <Text textAlign="center" fontSize="25px">
                                You must score advanced in{' '}
                                <Text as="strong" color="red">
                                    BOTH
                                </Text>{' '}
                                sections of the test. You can complete the test as many times as needed to achieve the required level.
                            </Text>


                            <Flex justify="space-between" mt="24px">
                                <Button
                                    bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => commitmentsTab.current.click()}
                                >
                                    <ArrowLeftIcon mr={2} />  Prev
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => grammarTestTab2.current.click()}
                                >
                                    Next <ArrowRightIcon ml={2} />
                                </Button>
                            </Flex>
                        </Card>
                    </TabPanel>
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card p="30px" borderColor={'#fcd677'}
                            borderWidth={'8px'}>
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Grammar & Listening Test
                            </Text>

                            <Text textAlign="center" fontSize="25px">
                                To complete your grammar and listening test, please copy and paste the following link in your browser:
                            </Text>

                            <Text textAlign={'center'} fontSize={'25px'} color={'blue'}>
                                <Link href="https://www.efset.org/quick-check/" isExternal>
                                    https://www.efset.org/quick-check/ <ExternalLinkIcon ml={2} />
                                </Link>
                            </Text>


                            <Flex justify="space-between" mt="24px">
                                <Button
                                    bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => grammarTestTab.current.click()}
                                >
                                    <ArrowLeftIcon mr={2} />  Prev
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => grammarTestTab3.current.click()}
                                >
                                    Next <ArrowRightIcon ml={2} />
                                </Button>
                            </Flex>
                        </Card>
                    </TabPanel>
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card p="30px" borderColor={'#fcd677'}
                            borderWidth={'8px'}>
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Grammar & Listening Test
                            </Text>

                            <Text textAlign={'center'} fontSize={'40px'} fontWeight={'bold'}>
                                Screenshot examples
                            </Text>
                            <Text textAlign="center" fontSize="25px">
                                Your screenshots must display the following information: {' '}
                                <Text as="strong" color="red">
                                    global score, reading score and listening score.
                                </Text>{' '}
                                Here are some examples:
                            </Text>


                            <Flex justify="space-between" mt="24px">
                                <Button
                                    bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => grammarTestTab2.current.click()}
                                >
                                    <ArrowLeftIcon mr={2} />  Prev
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => grammarTestTab4.current.click()}
                                >
                                    Next <ArrowRightIcon ml={2} />
                                </Button>
                            </Flex>
                        </Card>
                    </TabPanel>
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card p="30px" borderColor={'#fcd677'}
                            borderWidth={'8px'}>
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Grammar & Listening Test
                            </Text>

                            <Text textAlign={'center'} fontSize={'30px'} fontWeight={'bold'}>
                                Here's what your screenshot should look like if you take your tests on a computer:
                            </Text>
                            <Text textAlign="center" fontSize="15px">
                                It must include your separate reading and listening scores as well as your total result.
                            </Text>
                            <Image
                                src={Grammar2}
                                alt="Grammar & Listening"
                                mx="auto"
                                my={4}
                                maxW="100%"
                            />


                            <Flex justify="space-between" mt="24px">
                                <Button
                                    bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => grammarTestTab3.current.click()}
                                >
                                    <ArrowLeftIcon mr={2} />  Prev
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => grammarTestTab5.current.click()}
                                >
                                    Next <ArrowRightIcon ml={2} />
                                </Button>
                            </Flex>
                        </Card>
                    </TabPanel>
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card p="30px" borderColor={'#fcd677'}
                            borderWidth={'8px'}>
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Grammar & Listening Test
                            </Text>

                            <Text textAlign={'center'} fontSize={'30px'} fontWeight={'bold'}>
                                This is what your screenshot should look like if you do your tests on a cellphone.
                            </Text>
                            <Text textAlign="center" fontSize="15px">
                                It must include your separate listening and reading scores.
                            </Text>

                            <Image
                                src={Grammar}
                                alt="Grammar & Listening"
                                mx="auto"
                                my={4}
                                maxW="100%"
                            />

                            <Flex justify="space-between" mt="24px">
                                <Button
                                    bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => grammarTestTab4.current.click()}
                                >
                                    <ArrowLeftIcon mr={2} />  Prev
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => grammarTestTab6.current.click()}
                                >
                                    Next <ArrowRightIcon ml={2} />
                                </Button>
                            </Flex>
                        </Card>
                    </TabPanel>
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card p="30px" borderColor={'#fcd677'}
                            borderWidth={'8px'}>
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Grammar & Listening Test
                            </Text>
                            <Text textAlign={'center'} fontSize={'30px'} fontWeight={'bold'}>
                                Upload your SCREENSHOT below:
                            </Text>
                            <Text textAlign="center" fontSize="15px" color={'gray'} mb={2}>
                                DISCLAIMER. THE SCREENSHOT YOU SUBMIT MUST INCLUDE BOTH LISTENING AND READING SCORES. Applications submitted with incomplete English test results will be IMMEDIATELY DECLINED.
                            </Text>
                            <Box align='center'>
                                <FormControl isInvalid={!!formErrors.grammar_and_listening_test}>
                                    <Input
                                        type="file"
                                        name="grammar_and_listening_test"
                                        id="fileInput3"
                                        display="none"
                                        onChange={(e) => handleFileChange(e.target.name, e.target.files[0])}
                                    />
                                    <Button as="label" htmlFor="fileInput3" cursor="pointer" bg={"#7080fc"} color={"white"} _hover={{ background: "#808080", color: 'white' }}>
                                        Select A File <AttachmentIcon ml={2} />
                                    </Button>
                                    {fileStates.grammar_and_listening_test && (
                                        <div style={{ textAlign: "center" }}>
                                            <p>File Selected: {fileStates.grammar_and_listening_test.name}</p>
                                            <div style={{ display: "inline-block" }}>
                                                <img
                                                    src={URL.createObjectURL(fileStates.grammar_and_listening_test)}
                                                    alt={fileStates.grammar_and_listening_test.name}
                                                    style={{ maxWidth: "100px" }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {formErrors.grammar_and_listening_test && (
                                        <FormErrorMessage>{formErrors.grammar_and_listening_test}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Box>
                            <Flex justify="space-between" mt="24px">
                                <Button
                                    bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => grammarTestTab5.current.click()}
                                >
                                    <ArrowLeftIcon mr={2} />  Prev
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => typingTestTab.current.click()}
                                >
                                    Next <ArrowRightIcon ml={2} />
                                </Button>
                            </Flex>
                        </Card>
                    </TabPanel>
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card p="30px" borderColor={'#fcd677'}
                            borderWidth={'8px'}>
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Take your typing test!
                            </Text>

                            <Text textAlign="center" fontSize="25px">
                                Copy and paste the following link on your browser:
                            </Text>

                            <Text textAlign={'center'} fontSize={'25px'} color={'blue'}>
                                <Link href="https://10fastfingers.com/typing-test/english" isExternal>
                                    https://10fastfingers.com/typing-test/english <ExternalLinkIcon ml={2} />
                                </Link>
                            </Text>

                            <Flex justify="space-between" mt="24px">
                                <Button
                                    bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => grammarTestTab6.current.click()}
                                >
                                    <ArrowLeftIcon mr={2} />  Prev
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => typingTestTab2.current.click()}
                                >
                                    Next <ArrowRightIcon ml={2} />
                                </Button>
                            </Flex>
                        </Card>
                    </TabPanel>
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card p="30px" borderColor={'#fcd677'}
                            borderWidth={'8px'}>
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Typing Test
                            </Text>
                            <Text textAlign={'center'} fontSize={'25px'} fontWeight={'bold'}>
                                Once you have finished the test, take a SCREENSHOT of your results and upload the image below. Your score needs to be at least 30 WPM to be considered.
                            </Text>
                            <Text textAlign="center" fontSize="20px" color={'gray'} mb={2}>
                                PLEASE MAKE SURE YOUR FILE IS TITLED TYPING AND YOUR SCORE.
                            </Text>
                            <Text textAlign="center" fontSize="15px" color={'gray.500'} mb={2}>
                                FOR EXAMPLE TYPING30WPM
                            </Text>
                            <Box align='center'>
                                <FormControl isInvalid={!!formErrors.typing_test}>
                                    <Input
                                        type="file"
                                        name="typing_test"
                                        id="fileInput2"
                                        display="none"
                                        onChange={(e) => handleFileChange(e.target.name, e.target.files[0])}
                                    />
                                    <Button as="label" htmlFor="fileInput2" cursor="pointer" bg={"#7080fc"} color={"white"} _hover={{ background: "#808080", color: 'white' }}>
                                        Select A File <AttachmentIcon ml={2} />
                                    </Button>
                                    {fileStates.typing_test && (
                                        <div style={{ textAlign: "center" }}>
                                            <p>File Selected: {fileStates.typing_test.name}</p>
                                            <div style={{ display: "inline-block" }}>
                                                <img
                                                    src={URL.createObjectURL(fileStates.typing_test)}
                                                    alt={fileStates.typing_test.name}
                                                    style={{ maxWidth: "100px" }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {formErrors.typing_test && (
                                        <FormErrorMessage>{formErrors.typing_test}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Box>
                            <Flex justify="space-between" mt="24px">
                                <Button
                                    bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => typingTestTab.current.click()}
                                >
                                    <ArrowLeftIcon mr={2} />  Prev
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => signatureTab.current.click()}
                                >
                                    Next <ArrowRightIcon ml={2} />
                                </Button>
                            </Flex>
                        </Card>
                    </TabPanel>
                    <TabPanel
                        w={{ sm: '330px', md: '700px', lg: '850px' }}
                        p="0px"
                        mx="auto"
                    >
                        <Card p="30px" borderColor={'#fcd677'}
                            borderWidth={'8px'}>
                            <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                                Signature
                            </Text>

                            <Text textAlign={'center'} fontSize={'20px'} fontWeight={'bold'}>
                                I have reviewed my application and can guarantee the information I have provided is completely true and accurate. I also understand that my application will be considered against this information and that failure to follow the instructions listed in this form will lead to an immediate decline.
                            </Text>
                            <Text textAlign="center" fontSize="25px" color={'gray'} mb={2}>
                                Please sign below that you understand.
                            </Text>
                            <Box ml={"25%"} align='center' style={{ border: '1px solid #000' }} width={"max-content"}>
                                <SignatureCanvas ref={signatureRef} canvasProps={{ width: 400, height: 200 }} />
                            </Box>
                            <Flex direction="column" alignItems="center" justifyContent="center">

                                <Button
                                    onClick={clearSignature}
                                    width="20%"
                                    size="sm"
                                    bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                    mt="4"
                                >
                                    Clear
                                </Button>
                            </Flex>

                            <Flex justify="space-between" mt="24px">
                                <Button
                                    bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={() => typingTestTab2.current.click()}
                                >
                                    <ArrowLeftIcon mr={2} />  Prev
                                </Button>
                                <Button
                                    bg={"#1c2147"} color={"white"} _hover={{ background: "#8aad01", color: 'white' }}
                                    fontSize="sm"
                                    borderRadius="16px"
                                    w={{ base: '128px', md: '148px' }}
                                    h="46px"
                                    onClick={handleSubmit}
                                >
                                    Submit  <CheckIcon ml={2} />
                                </Button>
                            </Flex>

                            {showAlert && (
                                <Alert status="success" mt={4}>
                                    <AlertIcon />
                                    {alertMessage}
                                    <CloseButton
                                        onClick={() => setShowAlert(false)}
                                        position="absolute"
                                        right="8px"
                                        top="8px"
                                    />
                                </Alert>
                            )}
                            {errorAlert && (
                                <Alert status="error" mt={4}>
                                    <AlertIcon />
                                    {errorAlert}
                                    <CloseButton
                                        onClick={() => setErrorAlert('')}
                                        position="absolute"
                                        right="8px"
                                        top="8px"
                                    />
                                </Alert>
                            )}
                        </Card>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Flex>
    );
}
