const formDataMX = {

    first_day: '',
    date_of_birth: '',
    current_address: '',
    nationality:'',
    id_number:'', 
    rfc_tax:'',
    ine: '',
    curp: '',
    rfc_tax: '',
    ssn: '',
    location: '',
    emergency_contact: '',
    emergency_contact_number: '',
    relationship: '',
    allergies: '',
    favorite_food: '',
    marital_status: '',
    children: '',
    children_number: '',
    gender_children:'',
    age_children:'',
    hobbie: '',

    confirmation: '',

};

export default formDataMX;