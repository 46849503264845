import axios from 'axios';
import React, { useEffect, useState, useRef } from "react";
import Pusher from 'pusher-js';
import {
    Modal, ModalOverlay, ModalContent, ModalBody,
    Box, Flex, VStack,
    Input, InputGroup, Text, Button,
    Icon, Image, Menu, MenuItem, MenuButton, MenuList,
    FormControl, FormErrorMessage, Textarea
} from '@chakra-ui/react';
import { IoPaperPlane } from "react-icons/io5";
import bgMundochat from "assets/img/dashboards/Mundo_chat_2.png";
import logo2 from "assets/img/dashboards/logo2.png"
import { MdOutlineSupportAgent } from "react-icons/md";
import { FaRegImage } from "react-icons/fa6";
import { ImAttachment } from "react-icons/im";
import { IoDocument } from "react-icons/io5";
import { CloseIcon, DownloadIcon } from '@chakra-ui/icons'
import { IoMdDownload } from "react-icons/io";
import ImageViewer from "react-simple-image-viewer";
import logo from "assets/img/dashboards/Logo3.png";

function ModalChatOpen({ isOpen, onClose, ticket }) {
    const [joiningRoomId, setJoiningRoomId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [canFetchMessages, setCanFetchMessages] = useState(true);
    const [canProcessPusherNotifications, setCanProcessPusherNotifications] = useState(true);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [user, setUser] = useState("");
    const [incident, setIncident] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [documentFile, setDocumentFile] = useState(null);
    const fileInputRef = useRef(null);
    const [hoveredMessage, setHoveredMessage] = useState(null);
    const [formErrors, setFormErrors] = useState({});


    const handleLeaveClick = async () => {
        if (ticket.chat_room_id !== null) {
            setJoiningRoomId(ticket.chat_room_id);

            try {
                await axios.post(`/chat/rooms/${ticket.chat_room_id}/leave`);
                onClose();
            } catch (error) {
                console.error('Error al unirse a la sala de chat:', error);
            } finally {
                setJoiningRoomId(null);
            }
        }
    };

    const removeImage = () => {
        setImageFile(null)
        setFormErrors({});
    };
    const removeFile = () => {
        setDocumentFile(null)
        setFormErrors({});
    };

    const fetchDataIncident = async () => {
        try {
            const response = await axios.get(`/showTicket/${ticket.id}`);
            setIncident(response.data);
        } catch (error) {
            console.error('Error fetching incident:', error);
        }
    };

    const fetchData = async () => {
        try {
            const response = axios.get("/user").then((response) => {
                const user = response.data.user;
                setUser(user);
            });
        } catch (error) {
            console.error('Error al obtener datos del usuario:', error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchDataIncident();
        if (isOpen) {
            const timer = setInterval(() => {
                setElapsedTime(prevTime => prevTime + 1);
            }, 1000);
            return () => clearInterval(timer);
        }
        const intervalId = setInterval(() => {
            fetchDataIncident();
        }, 5000);
        return () => {
            clearInterval(intervalId);
        };
    }, [isOpen]);

    useEffect(() => {
        fetchDataIncident();
        const intervalId = setInterval(() => {
            fetchDataIncident();
        }, 5000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const pusher = new Pusher("20dade1a513097de43eb", {
                cluster: "mt1",
                encrypted: true,
                authEndpoint: "/pusher/auth",
                auth: {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            });
            const chatChannel = pusher.subscribe("pusher");
            chatChannel.bind("new-message", (data) => {
                if (canProcessPusherNotifications) {
                    setMessages((prevMessages) => [...prevMessages, data.message]);
                    setCanProcessPusherNotifications(false);
                    setTimeout(() => {
                        setCanProcessPusherNotifications(true);
                    }, 5000);
                }
            });
            const fetchMessages = () => {
                axios
                    .get(`/chat/messages/${ticket.id}`)
                    .then((response) => {
                        setMessages(response.data.messages);
                        setTimeout(() => {
                            setCanFetchMessages(true);
                        }, 5000);
                    })
                    .catch((error) => {
                        console.error(error);
                        setTimeout(() => {
                            setCanFetchMessages(true);
                        }, 10000);
                    });
            };
            if (canFetchMessages) {
                fetchMessages();
                setCanFetchMessages(false);
            }
            return () => {
                chatChannel.unsubscribe();
                pusher.disconnect();
            };
        }
    }, [canFetchMessages, canProcessPusherNotifications]);

    const messagesContainerRef = useRef();


    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            const container = messagesContainerRef.current;
            container.scrollTop = container.scrollHeight - container.clientHeight;
        }
    };

    const sendMessage = () => {
        const formData = new FormData();
        formData.append('message', newMessage);
        formData.append('chatRomId', ticket.chat_room_id);
        formData.append('incident_id', ticket.id);

        // Agrega el archivo de imagen si existe
        if (imageFile) {
            formData.append('image', imageFile?.file || ''); // Asegúrate de que imageFile esté definido
        }

        // Agrega el archivo de documento si existe
        if (documentFile) {
            formData.append('document', documentFile?.file || ''); // Asegúrate de que documentFile esté definido
        }

        axios.post('/chat/messages', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                const newMessageData = response.data.message;
                const user = response.data.user;
                setMessages([...messages, { ...newMessageData, user }]);
                setNewMessage('');
                // Elimina los archivos después de enviar el mensaje
                setImageFile(null);
                setDocumentFile(null);
                setFormErrors({});
                setTimeout(() => {
                    scrollToBottom();
                }, 0);
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.error === 'At least one of the fields (message, image, document) must be present.') {
                } else {
                    setFormErrors(error.response.data.errors);
                }
            });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (e.shiftKey) {
                e.preventDefault();
                setNewMessage(newMessage + '\n');
            } else {
                e.preventDefault();
                sendMessage();
            }
        }
    };

    const getColorIncident = (chatRoom) => {
        const colorMap = {
            1: '#ff6f94',
            2: '#fabb1c',
            3: '#633cbd',
            4: '#fb9539',
            5: '#a2bd34',
            6: '#6bc2ed',
            7: '#7080fc',
        };
        return colorMap[chatRoom] || '#ff6f94';
    };

    const handleImageChange = (e, imageFile) => {
        const file = e?.target?.files?.[0] || imageFile?.file;

        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageFile({
                file,
                previewUrl: imageUrl,
            });
        }
    };

    const handleDocumentChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setDocumentFile({
                file,
                name: file.name,
            });
        }
    };

    const downloadFile = async (message) => {
        try {
            const response = await axios.get(`/documentD/${encodeURIComponent(message.document_image)}`, { responseType: 'blob' });

            const contentDisposition = response.headers['content-disposition'];
            const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"$/);
            const suggestedFileName = fileNameMatch ? fileNameMatch[1] : message.document_image;

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', suggestedFileName);
            document.body.appendChild(link);
            link.click();

            // Liberar recursos
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    };

    const downloadImage = async (message) => {
        try {
            const response = await axios.get(`/imagesD/${encodeURIComponent(message.image_path)}`, { responseType: 'blob' });

            const contentDisposition = response.headers['content-disposition'];
            const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"$/);
            const suggestedFileName = fileNameMatch ? fileNameMatch[1] : message.image_path;

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', suggestedFileName);
            document.body.appendChild(link);
            link.click();

            // Liberar recursos
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    };

    const handlePaste = (e) => {
        const items = e.clipboardData.items;

        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (item.type.indexOf('image') !== -1) {
                const file = item.getAsFile();

                // Simular un evento de cambio en el input de tipo archivo
                const changeEvent = new Event('change', { bubbles: true });
                Object.defineProperty(changeEvent, 'target', { value: { files: [file] } });

                // Llamar a la función handleImageChange con la imagen pegada
                handleImageChange(changeEvent, { file, previewUrl: URL.createObjectURL(file) });
                break;  // Solo procesamos la primera imagen encontrada
            }
        }
    };

    const textareaRef = useRef(null);

    const autoResize = (e) => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedMimeType, setSelectedMimeType] = useState(null);

    const openImageViewer = (imageSrc, mimeType) => {
        setSelectedImage(imageSrc);
        setSelectedMimeType(mimeType);
        setIsViewerOpen(true);
    };

    const closeImageViewer = () => {
        setIsViewerOpen(false);
        setSelectedImage(null);
        setSelectedMimeType(null);
    };

    const renderUserImage = (message) => {
        const imageSrc = message.base64Image && message.imageMimeType
            ? `data:${message.imageMimeType};base64,${message.base64Image}`
            : logo;

        return (
            <Image
                src={imageSrc}
                h="160px"
                w="260px"
                mb={'2%'}
                onClick={() => openImageViewer(imageSrc, message.imageMimeType)}
                cursor={"pointer"}
            />
        );
    };

    const downloadImageViewer = () => {
        const extension = selectedMimeType.split('/')[1];
        const link = document.createElement('a');
        link.href = selectedImage;
        link.download = `image.${extension}`;
        link.click();
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'6xl'} isCentered closeOnOverlayClick={false} closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent borderRadius={'20px'}>
                <ModalBody display={"flex"} flexDirection={"row"}    >
                    <Box mr={"5px"} width={"50%"} p={10} borderRadius={"10px"} >
                        <Flex flexDirection={"column"} mt={"60%"}>
                            <Text mt={'40px'} fontSize={"30px"} color={'#1c2147'} textAlign={"center"} fontWeight={"bold"}>Support Chat</Text>
                            <Text mt={"15px"} fontSize={"22px"} fontWeight={"bold"} color={'#1c2147'}   >Person Assisting:</Text>
                            <Text fontSize={"24px"} fontWeight={"bold"} color={'#424ea8'} mb={"20px"}>
                                {incident && incident.supervisor && incident.supervisor.name ? incident.supervisor.name : '---'}{' '}
                                {incident && incident.supervisor && incident.supervisor.last_name ? incident.supervisor.last_name : '---'}
                            </Text>
                            <Flex  >
                                <Text boxShadow={"1px 2.5px 10px 7px #f2f2f2"} width={"max-content"} color={'#1c2147'} fontSize={"20px"} fontWeight={"bold"} p={2} px={4} borderRadius={"10px"}>   {incident && incident.clients && incident.clients.name ? incident.clients.name : '---'}{' '} </Text>
                                <Box boxShadow={"1px 2.5px 10px 7px #f2f2f2"} borderRadius={"5px"} fontSize={"20px"} textAlign={"center"} p={2} px={4} width={"max-content"} ml={4} color={"white"} bg={getColorIncident(ticket.chat_room_id)}>
                                    {(() => {
                                        switch (ticket.chat_room_id) {
                                            case 1:
                                                return "IT";
                                            case 2:
                                                return "HR";
                                            case 3:
                                                return "Hiring ";
                                            case 4:
                                                return "OnCall ";
                                            case 5:
                                                return "Schedules ";
                                            case 6:
                                                return "Finances ";
                                            case 7:
                                                return "Calls ";
                                            default:
                                                return "IT ";
                                        }
                                    })()}
                                </Box>
                            </Flex>
                        </Flex>
                        <Box mt={"40%"}  >
                            <Button bg={'#424ea8'} _hover={{ background: "#8aad01", color: 'white' }} color={'white'} mr={3} borderRadius='10px' onClick={handleLeaveClick}>
                                <Text mr={1} fontSize={"18px"}>
                                    Minimize Chat
                                </Text>
                            </Button>
                            <Text mt={"10px"} fontSize={"12px"} textAlign={"justify"} color={'#b3b3b3'} >
                                *To minimize the chat, click on "Minimize Chat". You can reopen it anytime by going to the "My Tickets" section from the Menu. </Text>
                        </Box>
                    </Box>
                    <Box borderRadius={"10px"} boxShadow={"1px 2.5px 10px 7px #f2f2f2"}
                        width={"100%"}
                        Height={"850px"}
                        overflow='hidden'
                        px={{ base: "10px", md: "20px" }}
                        position='static'
                        style={{
                            backgroundImage: `url(${bgMundochat})`,
                            backgroundSize: 'auto',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    >
                        <Box h={"max-content"}    >
                            <Flex className="flextickenca"
                            >
                                <Flex mt={6} display="flex" ml={"6%"} direction={'column'} justifyContent={'left'} >
                                    <Flex width={"100%"} justify={"flex-end"}>
                                        <Button bg={'#b3b3b3'} color='white' size="sm" _hover={{ background: "#b3b3b3" }}
                                            onClick={handleLeaveClick} mr={4} borderRadius={"5px"} title='Minimize chat'>
                                            —
                                        </Button>
                                    </Flex>
                                    <Text className=" txtmsjname ">
                                        {incident ? incident.first_message : '---'}
                                    </Text>
                                    <Flex>
                                        <Text mr={4} fontWeight={"bold"} color={'#7a86e6'}>
                                            {incident && incident.supervisor && incident.supervisor.name ? incident.supervisor.name : '---'}
                                            {incident && incident.supervisor && incident.supervisor.last_name ? incident.supervisor.last_name : '---'}
                                        </Text>
                                        <Text color={'#424ea8'} fontWeight={"bold"} >
                                            {incident ? incident.start : '---'}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Box
                                h='calc(100% - 120px)' height={"max-content"}
                                px={{ base: "10px", md: "55px" }}
                                position='static'
                                style={{
                                    backgroundSize: 'auto',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                }}
                            >
                                <Flex ref={messagesContainerRef} className="chattick" >
                                    {messages.map((message, index) => (
                                        <Flex
                                            direction='column'
                                            key={message.id}
                                            w='100%'
                                            maxW={{ base: "90%", lg: "calc(100% - 80px)" }}
                                            boxSizing='border-box'
                                        >

                                            <VStack borderRadius={"8px"} mt={2} mb={2} spacing={3} align={user.id != message.user_id ? 'start' : 'end'}  >
                                                <Flex direction={'row'}>
                                                    <Box borderRadius={"15px"} bg={"#f2f2f2"} p={"12px"} mr={4} mt={2} width={"60px"} height={"60px"} display={user.id != message.user_id ? 'block' : 'none'}>
                                                        <MdOutlineSupportAgent size={"38px"} />
                                                    </Box>
                                                    <Flex height={"max-content"} borderRadius={user.id !== message.user_id ? '20px 20px 20px 0' : '20px 20px 0 20px'} minWidth={"225px"} flexDirection={"column"} bg={user.id != message.user_id ? '#f2f2f2' : '#c3c8f4'}>
                                                        <Box position={"relative"} hidden>
                                                            <Text color={"white"} position={"absolute"} right={0} top={-2} borderRadius={"5px"} p={1} fontSize={"10px"} bg={"#424ea8"}></Text>
                                                        </Box>
                                                        <Flex justifyContent={'left'} mt={'10px'}    >
                                                            <Text className=" tickchatname ">
                                                                {message.users?.name} {message.users?.last_name}
                                                            </Text>
                                                        </Flex>
                                                        <Box
                                                            px={2}

                                                            borderRadius='lg'

                                                            maxWidth={'550px'}
                                                            minWidth={'120px'}
                                                            width={'auto'}
                                                        >
                                                            {message.message !== null && (
                                                                <Text
                                                                    textAlign={'justify'}
                                                                    mx={"10px"}
                                                                    color={message.message && (message.message.startsWith('http://') || message.message.startsWith('https://')) ? '#007BFF' : '#1c2147'}
                                                                    fontWeight='bold'
                                                                    fontSize={'15px'}
                                                                    whiteSpace={'pre-wrap'}
                                                                    textDecoration={message.message && (message.message.startsWith('http://') || message.message.startsWith('https://')) ? 'underline' : 'none'}
                                                                >
                                                                    {message.message && (message.message.startsWith('http://') || message.message.startsWith('https://')) ? (
                                                                        <a href={message.message} target="_blank" rel="noopener noreferrer">
                                                                            {message.message}
                                                                        </a>
                                                                    ) : (
                                                                        <span>
                                                                            {message.message}
                                                                        </span>
                                                                    )}
                                                                </Text>
                                                            )}
                                                            <Flex display={'flex'} justifyContent={'center'} >
                                                                {message.document_image && (
                                                                    <Button
                                                                        onClick={() => downloadFile(message)}
                                                                        borderRadius={'5px'}
                                                                        size={'lg'}
                                                                        width={'80px'}
                                                                        mt={'5%'} mb={'5%'}
                                                                        height={'80px'}
                                                                        mr={'20px'}
                                                                        onMouseEnter={() => setHoveredMessage(message.id)}
                                                                        onMouseLeave={() => setHoveredMessage(null)}
                                                                        _hover={{ background: "#cccccc" }}
                                                                        bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'}
                                                                    >
                                                                        {hoveredMessage === message.id ? (
                                                                            <DownloadIcon fontSize={'35px'} color="white" />
                                                                        ) : (
                                                                            <IoDocument ml={2} size={'50px'} color="white" />
                                                                        )}
                                                                    </Button>
                                                                )}
                                                                {message.image_path && (
                                                                    <>
                                                                        {renderUserImage(message, index)}
                                                                    </>
                                                                )}
                                                            </Flex>
                                                            <Text ml={"10px"} textAlign={'left'} mb={2} fontWeight={"bold"} color={"#424ea8"} fontSize={'small'}>
                                                                {message.created_at}
                                                            </Text>
                                                        </Box>
                                                    </Flex>
                                                    <Box borderRadius={"15px"} bg={"#c3c8f4"} p={"12px"} ml={4} mt={2} width={"60px"} height={"60px"} display={user.id != message.user_id ? 'none' : 'block'}>
                                                        <Image src={logo2} />
                                                    </Box>
                                                </Flex>
                                            </VStack>
                                        </Flex>
                                    ))}
                                </Flex>
                                {isViewerOpen && selectedImage && (
                                    <Flex style={{ height: '100%' }}>
                                        <ImageViewer
                                            src={[selectedImage]}
                                            currentIndex={0}
                                            onClose={closeImageViewer}
                                            disableScroll={false}
                                            backgroundStyle={{
                                                backgroundColor: "rgba(0,0,0,0.9)"
                                            }}
                                            closeOnClickOutside={true}
                                        />
                                        <Button
                                            onClick={downloadImageViewer}
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                right: '-30%',
                                                transform: 'translateY(-50%)',
                                                padding: '5px 10px',
                                                backgroundColor: 'transparent',
                                                color: 'white',
                                                border: 'none',
                                                borderRadius: '5px',
                                                cursor: 'pointer',
                                                zIndex: 1001
                                            }}
                                        >
                                            <IoMdDownload size={'40px'} />
                                        </Button>
                                    </Flex>
                                )}
                            </Box>
                            <Flex borderRadius={"0px 0px 10px 10px"}
                                bg={'transparent'}
                                backdropFilter='blur(20px)'
                                height={'75px'}
                            >
                                {ticket.chat_room_id === 6 ? (
                                    <Menu placement="top-start">
                                        <MenuButton borderRadius={"5px"} as={Button} _active={{ background: "#808080", color: 'white' }} bg="white" color={"#b3b3b3"} _hover={{ background: "#808080", color: 'white' }} mt={'3%'}>
                                            <ImAttachment pb={"2px"} size={'25px'} />
                                        </MenuButton>
                                        <MenuList placement="top">
                                            <MenuItem
                                                display={"flex"}
                                                justifyContent={"center"}
                                            >
                                                <Flex justifyContent={'space-evenly'} direction={'row'} display={'flex'} width={'100%'}>
                                                    <Input type="file" id="image" style={{ display: 'none' }} accept="image/*" onChange={(e) => handleImageChange(e, null)} ref={fileInputRef} />
                                                    <Button as="label" htmlFor="image" cursor="pointer" bg={'linear-gradient(180deg, rgba(251, 149, 57, 1.0) 50%, rgba(252, 160, 102, 1.0) 50%)'} color={"white"} _hover={{ background: "#808080", color: 'white' }} borderRadius={'50%'} height={'60px'} width={"60px"}>
                                                        <FaRegImage ml={2} size={'25px'} />
                                                    </Button>
                                                    <Input type="file" id="document" style={{ display: 'none' }} onChange={handleDocumentChange} accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, .xlsx, .csv" />
                                                    <Button as="label" htmlFor="document" cursor="pointer" bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'} color={"white"} _hover={{ background: "#808080", color: 'white' }} borderRadius={'50%'} height={'60px'} width={"60px"}>
                                                        <IoDocument ml={2} size={'35px'} />
                                                    </Button>
                                                </Flex>
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                ) : null}

                                <InputGroup w={{ base: "90%" }} ml={'15px'} mt={'15px'}>
                                    <Textarea
                                        ref={textareaRef}
                                        style={{
                                            width: '100%',
                                            fontSize: '16px',
                                            paddingLeft: '40px',
                                            height: '30px',
                                            backgroundColor: '#f2f2f2',
                                            color: '#1c2147',
                                            fontWeight: '500',
                                            borderRadius: '10px',
                                            border: '1px solid #ccc',
                                            resize: 'none',
                                            overflow: 'hidden',
                                            boxSizing: 'border-box',
                                            ...(window.innerWidth >= 992 && {
                                                paddingLeft: '65px',
                                                height: '45px'
                                            })
                                        }}
                                        placeholder="Type a message"
                                        value={newMessage}
                                        onChange={e => {
                                            setNewMessage(e.target.value);
                                            autoResize(e);
                                        }}
                                        onKeyPress={handleKeyPress}
                                        onPaste={handlePaste}
                                        rows={1}
                                    />
                                </InputGroup>
                                <Button title='Send message'
                                    borderRadius='50%'
                                    bg={'#1c2147'}
                                    w={{ base: "50px", lg: "50px" }}
                                    h={{ base: "50px", lg: "50px" }}
                                    mt={'10px'}
                                    ml={'15px'}
                                    mr={'20px'}
                                    variant='no-hover'
                                    onClick={sendMessage}
                                >
                                    <Icon
                                        as={IoPaperPlane}
                                        color='white'
                                        w={{ base: "18px", lg: "25px" }}
                                        h={{ base: "18px", lg: "25px" }}
                                    />
                                </Button>
                            </Flex>
                            <Flex mb={'2%'} justifyContent={'center'} display={'flex'} textAlign={'center'} px={'18%'}>
                                <FormControl isInvalid={!!formErrors.image}>
                                    {formErrors.image && (
                                        <FormErrorMessage>The image must be a file of type: jpeg, png, jpg.</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl isInvalid={!!formErrors.document}>
                                    {formErrors.document && (
                                        <FormErrorMessage>The document must to be a .pdf, .xlsx, .docx, csv.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>

                            <Flex display={'flex'} justifyContent={'center'}>
                                {imageFile && (
                                    <Flex direction={'column'} display={'flex'} justifyContent={'center'} alignItems={'center'} position={"relative"} width={'22%'}>
                                        <img src={imageFile.previewUrl} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                        <Text>
                                            {imageFile.file.name.length > 20
                                                ? `${imageFile.file.name.substring(0, 20)}…`
                                                : imageFile.file.name
                                            }
                                        </Text>
                                        <Button borderRadius={"5px"} cursor={"pointer"} px={2} onClick={removeImage} _hover={{ background: "transparent" }} position={"absolute"} top={-2} right={-4}><CloseIcon boxSize={"15px"} /></Button>
                                    </Flex>
                                )}
                                {documentFile && (
                                    <Flex display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'} ml={'10%'} position={"relative"}>
                                        <IoDocument ml={2} size={'45px'} color="#cccccc" />
                                        <Text>
                                            {documentFile.name.length > 20
                                                ? `${documentFile.name.substring(0, 20)}…`
                                                : documentFile.name
                                            }
                                        </Text>
                                        <Button borderRadius={"5px"} cursor={"pointer"} px={2} onClick={removeFile} _hover={{ background: "tr" }} position={"absolute"} top={-2} right={-2}><CloseIcon boxSize={"15px"} /></Button>
                                    </Flex>
                                )}
                            </Flex>
                        </Box>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal >
    );
}



export default ModalChatOpen;
