import {
    Flex, Button, Text, Input, Textarea, Box
} from "@chakra-ui/react";
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { EditIcon } from '@chakra-ui/icons'
export default function SchedulesIncidentsSection({ schedule, onClose, data, toast }) {

    const [fomrDataIncident, setFormDataIncident] = useState({
        duration: schedule.incident?.duration || '00:00:00',
        message: ''
    });

    const [formmDataScheduleIncident, setFormDataScheduleIncident] = useState([]);

    const handleChangeIncident = (e) => {
        const { name, value } = e.target;

        setFormDataIncident((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleGenerateIncident = async () => {

        const formSubmitData = {
            duration: fomrDataIncident.duration,
            message: fomrDataIncident.message,
        };

        try {
            const response = await axios.post(`/scheduleIncident/${schedule.id}`, formSubmitData);
            toast({
                title: "Incident time generated",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            onClose();
            data(schedule);
        } catch (error) {
            toast({
                title: "Error generating incident time",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Updating User Type:', error);
        }
    };


    const handleUpdateIncident = async (incidentTime, duration) => {
        try {
            const response = await axios.post(`/updateScheduleIncident/${incidentTime.id}`, {
                duration: duration,
            });

            if (response.status === 200) {
                toast({
                    title: "Schedule Incident Duration Updated",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                data(schedule);
                onClose();
            }
        } catch (error) {
            toast({
                title: "Error Updating Duration",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Updating Duration:', error);
        }
    };

    const handleIncidentChange = (index, value) => {
        const updatedFormDataIncidents = [...formmDataScheduleIncident];
        updatedFormDataIncidents[index] = { ...updatedFormDataIncidents[index], duration: value };
        setFormDataScheduleIncident(updatedFormDataIncidents);
    };

    if (!schedule.incidents || schedule.incidents.length === 0) {
        return (
            <>
                <Flex >
                    <Flex direction={'column'} alignItems={'center'} mt={'10%'} bg={'#f2f2f2'} mb={'20px'} height={'600px'} width={'350px'} borderRadius={'20px'}>
                        <Flex display={'flex'} mt={'50px'} justifyContent={'space-around'}>
                            <Text justifyContent={"center"} color={'#1c2147'} fontWeight={'bold'} fontSize={'20px'} >
                                Incidents details
                            </Text>
                        </Flex>
                        <Flex display={'flex'} justifyContent={'space-around'} mt={'40px'}>
                            <Flex display={'flex'} justifyContent={'space-around'}     >
                                <Flex direction={'column'} width={'100%'}>
                                    <Flex justifyContent={'center'}>
                                        <Text color={'#cccccc'} fontWeight={'bold'} fontSize={'20px'} >
                                            No data
                                        </Text>
                                    </Flex>
                                    <Flex mt={'10%'} width={'100%'}>
                                        <Input
                                            type='text'
                                            bg='white'
                                            color={'#808080'}
                                            value={fomrDataIncident.duration}
                                            name='duration'
                                            mr={'10px'}
                                            textAlign={'center'}
                                            onChange={handleChangeIncident}
                                        />
                                    </Flex>

                                    <Flex mt={'10%'} width={'100%'}>
                                        <Textarea borderColor={"#b3b3b3"}
                                            placeholder="Add a description here..."
                                            name="message"
                                            minHeight={"200px"}
                                            maxHeight={"510px"}
                                            className='custom-placeholderInputs'
                                            background="white"
                                            value={fomrDataIncident.message}
                                            onChange={handleChangeIncident}
                                            errorBorderColor='#fc7466'
                                        >
                                        </Textarea>
                                    </Flex>


                                    <Flex width={'100%'} justifyContent={'center'} mt={'20%'}>
                                        <Button
                                            bg={'#5868e0'}
                                            borderRadius={'5px'}
                                            color={'white'}
                                            _hover={{ background: "#808080" }}
                                            onClick={handleGenerateIncident}
                                        >
                                            Add Schedule Incident
                                        </Button>
                                    </Flex>

                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </>
        );
    }


    return (
        <Flex >
            <Flex direction={'column'} alignItems={'center'} mt={'10%'} bg={'#f2f2f2'} mb={'20px'} h={'630px'} overflowY={"auto"} width={'350px'} borderRadius={'20px'}>
                <Flex display={'flex'} mt={'50px'} justifyContent={'space-around'}>
                    <Text justifyContent={"center"} color={'#1c2147'} fontWeight={'bold'} fontSize={'20px'} >
                        Incidents details
                    </Text>
                </Flex>
                <Flex display={'flex'} justifyContent={'space-around'} direction={'column'}>
                    {schedule.incidents.map((incidentTime, index) => {
                        return (
                            <>
                                <Flex key={incidentTime.id} display={'flex'} justifyContent={'center'} mt={'30px'} direction={'column'} bg={'white'}>
                                    <Flex display={'flex'} justifyContent={'center'} mt={'10px'} mb={'10px'}>
                                        <Input
                                            type='text'
                                            bg='#f2f2f2'
                                            color={'#808080'}
                                            value={formmDataScheduleIncident[index]?.duration || incidentTime.duration}
                                            onChange={(e) => handleIncidentChange(index, e.target.value)}
                                            name='duration'
                                            width={'100%'}
                                            textAlign={'center'}
                                            mr={'5%'}
                                            ml={'10%'}
                                            step="1"
                                        />
                                        <Button
                                            bg={'#5868e0'}
                                            borderRadius={'5px'}
                                            color={'white'}
                                            mr={'10%'}
                                            _hover={{ background: "#808080" }}
                                            onClick={() => handleUpdateIncident(incidentTime, formmDataScheduleIncident[index]?.duration || incidentTime.duration)}
                                        >
                                            <EditIcon size={'25px'} />
                                        </Button>
                                    </Flex>
                                </Flex>

                            </>
                        );
                    })}
                    <Flex direction={'column'} width={'100%'}>
                        <Flex mt={'10%'} width={'100%'}>
                            <Input
                                type='text'
                                bg='white'
                                color={'#808080'}
                                value={fomrDataIncident.duration}
                                name='duration'
                                mr={'10px'}
                                textAlign={'center'}
                                onChange={handleChangeIncident}
                            />
                        </Flex>

                        <Flex mt={'10%'} width={'100%'}>
                            <Textarea borderColor={"#b3b3b3"}
                                placeholder="Add a description here..."
                                name="message"
                                minHeight={"200px"}
                                maxHeight={"510px"}
                                className='custom-placeholderInputs'
                                background="white"
                                value={fomrDataIncident.message}
                                onChange={handleChangeIncident}
                                errorBorderColor='#fc7466'
                            >
                            </Textarea>
                        </Flex>


                        <Flex width={'100%'} justifyContent={'center'} mt={'10%'}>
                            <Button
                                bg={'#5868e0'}
                                borderRadius={'5px'}
                                color={'white'}
                                _hover={{ background: "#808080" }}
                                onClick={handleGenerateIncident}
                            >
                                Add Schedule Incident
                            </Button>
                        </Flex>

                    </Flex>



                </Flex>
            </Flex>
        </Flex>
    );

} 