import axios from 'axios';

const axiosInterceptor = axios.create({
  baseURL: 'http://127.0.0.1:8000/api',
});

// Agregar el token a las solicitudes
axiosInterceptor.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export default axiosInterceptor;


