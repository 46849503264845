import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Textarea, Select,
    useToast,
    Flex, Box,
    FormControl, FormErrorMessage,
    Alert, AlertIcon, AlertDescription,
} from '@chakra-ui/react';
import { CloseIcon, EditIcon } from '@chakra-ui/icons'
import { FaUser } from "react-icons/fa";

const ClockOutModal = ({ isOpen, onClose, schedule, data }) => {

    const toast = useToast();

    const [formErrors, setFormErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState("");


    const [formData, setFormData] = useState({
        description: schedule?.description || '',
        option: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const forceClockOut = async (schedule) => {
        const confirmDelete = await Swal.fire({
            title: 'Do you want to end this shift?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: 'Yes, end it'
        }).then((result) => {
            return result.isConfirmed
        });

        if (confirmDelete) {
            const formSubmitData = {
                description: formData.description,
                option: formData.option,
            };

            try {
                const response = await axios.post(`/endShiftSupervisor/${schedule.id}`, formSubmitData);
                if (response.data.msg) {
                    setErrorMsg(response.data.msg);
                } else {
                    data();
                    onClose();
                    setFormErrors({});

                    toast({
                        title: "This shift has ended",
                        status: "success",
                        position: 'top',
                        duration: 8000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.error(error);
                setFormErrors(error.response.data.errors);
            }
        }
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}
            closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent background={"#f2f2f2"}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'70px'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'} display={'flex'} justifyContent={'center'} textAlign={'center'}>
                        <span style={{ marginRight: '8px' }}>Forced clock out</span>
                        <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#7080fc'} fontWeight={'bold'} fontSize={'22px'}>
                            <FaUser size={'18px'} />
                            <span style={{ marginLeft: '8px' }}><span style={{ marginRight: '8px', color: '#7080fc' }}>{schedule.operator} {schedule.operatorL}</span></span>
                        </Flex>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Flex direction={'column'} alignItems={'center'}>
                        <Box display="flex" justifyContent="center" alignItems="center" height="60px" width={'70%'} mt={'50px'} flexDirection={'column'}>
                            <FormControl isInvalid={!!formErrors.option} mt={'20px'}>
                                <Select
                                    fontSize={'md'}
                                    borderRadius={'5px'}
                                    name='option'
                                    value={formData.option}
                                    onChange={handleChange}
                                    color={'#cccccc'}
                                    background={"white"}
                                    textAlign={"center"}
                                    h='44px'
                                    errorBorderColor='#fc7466'
                                    placeholder='Select Option'>
                                    <option value='2'>Call out</option>
                                    <option value='1'>Late</option>
                                    <option value='11'>Late from lunch</option>
                                    <option value='12'>Late from break</option>
                                    <option value='3'>Leave early</option>
                                    <option value='4'>No call, no show</option>
                                    <option value='5'>Cell phone use</option>
                                    <option value='6'>Camera off</option>
                                    <option value='8'>Permission with client</option>
                                    <option value='9'>Disciplinary actions</option>
                                    <option value='10'>Vacations</option>
                                    <option value='7'>Other</option>
                                    <option value='13'>No longer with us</option>
                                </Select>
                                {formErrors.option && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.description} mt={'20px'}>
                                <Textarea
                                    fontSize={'14px'}
                                    placeholder='Please specify the reason why you forced the clock out.'
                                    value={formData.description}
                                    onChange={handleChange}
                                    bg={'white'}
                                    name='description'
                                />
                                {formErrors.description && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>

                        </Box>
                        <Flex mt={'40px'}>
                            {errorMsg && (
                                <Alert status="error" width={'500px'} mt={'10px'} display={'flex'} justifyContent={'center'} style={{ backgroundColor: 'rgba(252, 116, 102, 0.3)' }}>
                                    <AlertIcon color={'#1c2147'} />
                                    <AlertDescription fontSize={'20px'} color={'#1c2147'}>{errorMsg}</AlertDescription>
                                </Alert>
                            )}
                        </Flex>
                    </Flex>
                </ModalBody>
                <ModalFooter justifyContent="center" mt={'50px'} mb={'50px'}>
                    <Button class="buttonCreate" onClick={() => forceClockOut(schedule)}>
                        Clock out <EditIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={onClose}>
                        Close <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
};
export default ClockOutModal; 