import React from 'react';
import { Icon } from '@chakra-ui/react';
import { MdHome } from 'react-icons/md';
import { BsFillFilePersonFill } from "react-icons/bs";
import { FaRegCalendar } from "react-icons/fa6";
import { MdDashboardCustomize, MdDashboard } from "react-icons/md";
import DashboardsDefault from 'views/admin/dashboards/default';
import UsersSchedulesOverview from 'views/admin/main/schedules/weeklyschedule';
import UsersOverview from 'views/admin/main/users/newUser/items/userDashboard';
import DailyOverview from 'views/admin/main/schedules/dailyschedule';
import ReportsOverview from 'views/admin/main/reports';
import IncidentsOverview from 'views/admin/main/incidents';
import DefaultUser from 'views/admin/main/others/messages/indexUser';
import Default from 'views/admin/main/others/messages';
import ReportsTSOverview from 'views/admin/main/reports/indexTS';
import IncidentsAgentOverview from 'views/admin/main/incidents/indexAgent';

import MedicalNotesOverview from 'views/admin/main/Medical_Notes';

import MyScheduleOverview from 'views/admin/main/schedules/myschedule';
import ReportsPROverview from 'views/admin/main/reports/indexPR';
 



const routesAccounts = [
  // --- Dashboards ---
  {
    name: 'Home',
    layout: '/admin',
    path: '/home',
    icon: <Icon as={MdHome} width="30px" height="30px" color="inherit" />,
    component: <DashboardsDefault />,
  },
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/dashboards',
    icon: <Icon as={MdDashboard} width="30px" height="30px" color="inherit" />,
    component: <DailyOverview />,
  },
  {
    name: 'Schedules',
    layout: '/admin',
    path: '/schedules',
    icon: <Icon as={FaRegCalendar} width="30px" height="30px" color="inherit" />,
    component: <UsersSchedulesOverview />,
  },
  {
    name: 'My schedule',
    path: '/my-schedule', 
    icon: <Icon as={FaRegCalendar} width="30px" height="30px" color="inherit" />,
    layout: '/admin',
    component: <MyScheduleOverview/>,
  },
  {
    name: 'Menu',
    path: '/main',
    icon: <Icon as={BsFillFilePersonFill} width="30px" height="30px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Users',
        path: '/users',
        component: <UsersOverview />,
        layout: '/admin',
      },
      {
        name: 'Tickets',
        path: '/Ticket',
        collapse: true,
        items: [
          {
            name: 'Requests',
            layout: '/admin',
            path: '/ticket/chat',
            exact: false,
            component: <Default />,
          },
          {
            name: 'Ongoing',
            layout: '/admin',
            path: '/ticketU/chat',
            exact: false,
            component: <DefaultUser/>,
          },
          {
            name: 'My Completed',
            layout: '/admin',
            path: '/ticketU/finish',
            exact: false,
            component: <IncidentsAgentOverview />,
          },
          {
            name: 'Completed',
            icon: <Icon as={MdHome} width="30px" height="30px" color="inherit" />,
            layout: '/admin',
            path: '/ticket/dashboard',
            exact: false,
            component: <IncidentsOverview/>,
          },
        ],
      },
      {
        name: 'Reports',
        path: '/reports',
        collapse: true,
        items: [
          {
            name: 'Reports billing',
            layout: '/admin',
            path: '/reports/overview',
            exact: false,
            component: <ReportsOverview />,
          },
          {
            name: 'Reports timesheet',
            layout: '/admin',
            path: '/reports/timesheet',
            exact: false,
            component: <ReportsTSOverview/>,
          },
          {
            name: 'Reports payroll',
            layout: '/admin',
            path: '/reports/payroll',
            exact: false,
            component: <ReportsPROverview />,
          },
        ],
      },
      {
        name: 'Medical Notes',
        path: '/MedicalNotes',
        layout: '/admin',
        component: <MedicalNotesOverview/>,
      },
    ],
  },
];

export default routesAccounts;
