import {
    Flex, Button, Text, Box, VStack
} from "@chakra-ui/react";
import React from 'react';
import { FaRegTrashAlt } from "react-icons/fa";
export default function NotesSection({ handleNotes, schedule, notes, getColorSchemeOption, deleteNote }) {

    return (
        <Flex direction={'column'} alignItems={'center'}>
            <Flex display={'flex'} mt={'60px'} justifyContent={'center'} direction={'column'}>
                <Text color={'#1c2147'} fontWeight={'bold'} fontSize={'40px'}>
                    Notes
                </Text>
                <Button width={"max-content"} p={4}
                    bg={'#b9ce67'}
                    borderRadius={'5px'}
                    _hover={{ background: "#8aad01", }}
                    color={'white'}
                    onClick={() => handleNotes(schedule)}

                >
                    Add Note
                </Button>
            </Flex>
            <Flex direction={'column'} height={"500px"} overflowY={"auto"}>
                {notes && notes.length > 0 ? (
                    notes.map((note, index) => (
                        <Flex p={1}
                            key={note.id}
                            boxSizing='border-box'
                            direction={'column'}
                        >
                            <VStack spacing={3}>
                                <Flex flexDirection={"column"} width={"100%"}  >
                                    <Box width={"100%"} mt={2} mb={4} height={"4px"} bg={"#e6e6e6"}></Box>
                                    <Flex justifyContent={'left'} width={'80%'} ml={'8%'}>
                                        <Text color={'#1c2147'} fontSize={'15px'} textAlign={'center'} mr={"4px"} fontWeight={'bold'}>
                                            {(() => {
                                                const optionValue = note.option !== null ? note.option : 7;

                                                switch (optionValue) {
                                                    case 1:
                                                        return "Late";
                                                    case 2:
                                                        return "Call out";
                                                    case 3:
                                                        return "Leave early";
                                                    case 4:
                                                        return "No call, no show";
                                                    case 5:
                                                        return "Cell phone use";
                                                    case 6:
                                                        return "Camera off";
                                                    case 7:
                                                        return "Other";
                                                    case 8:
                                                        return "Permission with client";
                                                    case 9:
                                                        return "Disciplinary actions";
                                                    case 10:
                                                        return "Vacations";
                                                    case 11:
                                                        return "Late from lunch";
                                                    case 12:
                                                        return "Late from break";
                                                    default:
                                                        return "Other";
                                                }
                                            })()}
                                        </Text>
                                    </Flex>
                                    <Flex mt={'2%'}>
                                        <Box width={"22px"} background={getColorSchemeOption(note.option)} borderRadius='5px' height={"auto"} ></Box>

                                        <Box textAlign={'center'} color={'#b3b3b3'} textColor={"#b3b3b3"} fontWeight='medium' bg={"white"} borderRadius={"10px"} ml={4} shadow={"0px 0px 8px 0px"}>
                                            <Text width={"220px"} textAlign={"left"} fontSize={'14px'} p={2} textColor={"black"}>{note.description}</Text>
                                        </Box>
                                        <Button
                                            ml={2}
                                            onClick={() => deleteNote(note)}
                                            bg={"transparent"}
                                            _hover={{ background: "transparent" }}
                                        >
                                            <FaRegTrashAlt size={"22px"} />
                                        </Button>
                                    </Flex>
                                    <Flex ml={12} mt={2} justifyContent={'left'}>
                                        <Text color={'#808080'} fontSize={'11px'} textAlign={'center'} mr={"4px"}>
                                            {note.user_id === 1 ? 'Eri System' : `${note.user.name} ${note.user.last_name}`}
                                        </Text>
                                        <Text color={'#808080'} fontSize={'11px'} textAlign={'center'} mr={"4px"}>
                                            {note.created}
                                        </Text>
                                        <span style={{ color: '#808080', fontSize: '12px' }}></span>
                                    </Flex>
                                </Flex>


                            </VStack>
                            <span style={{ borderTop: '4px', borderColor: '#808080', width: '100%' }}></span>
                        </Flex>
                    ))
                ) : (
                    <Box width={'200px'} mt={'30px'}>
                        <Text bg={"#f2f2f2"} fontSize={"24px"} fontWeight={"bold"} textAlign={"center"}>
                            No notes
                        </Text>
                    </Box>
                )}
            </Flex>
        </Flex>
    );
} 