import {
  Avatar,
  Box,
  Flex,
  Stack,
  Text,
  useColorModeValue,
  Image
} from '@chakra-ui/react';
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import avatar4 from 'assets/img/dashboards/logo2.png';
import icon from 'assets/img/dashboards/Smiley.png';
import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { FaRegEyeSlash } from "react-icons/fa";

import ConfiguratorButton from 'components/navbar/ConfiguratorRadio';
import { IoEyeOutline } from "react-icons/io5";
import { IoMdMenu } from "react-icons/io";


function SidebarContent(props) {
  const { routes, mini, hovered, setMini } = props;
  const textColor = useColorModeValue('navy.700', 'white');
  const [userName, setUserName] = useState("");
  const [typeUserName, setTypeUserName] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [typeUser, setTypeUser] = useState('');
  const [hour, setHour] = useState('');
  const [date, setDate] = useState('');
  const [day, setDay] = useState('');
  const location = useLocation();




  useEffect(() => {
    const intervalID = setInterval(() => {
      const now = new Date();
      const day = now.toLocaleDateString('en-US', { weekday: 'long' });
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');

      setHour(`${hours}:${minutes}`);
      setDate(now.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }));
      setDay(day);
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  useEffect(() => {
    axios.get('/user')
      .then(response => {
        const name = response.data.user.name;
        const lName = response.data.user.last_name;
        const fullName = name + ' ' + lName;
        const nameParts = name.split(' ');
        const lastNameParts = lName.split(' ');
        const receivedTypeUserName = response.data.type_user_name;
        const type_user = response.data.user.type_user_id;

        if (nameParts.length >= 2) {
          const firstName = nameParts[0];
          const lastName = lastNameParts[0];
          setUserName(`${firstName} ${lastName}`);
          setTypeUserName(receivedTypeUserName);
          setTypeUser(type_user);
        } else {
          setUserName(fullName);
          setTypeUserName(receivedTypeUserName);
          setTypeUser(type_user);
        }

        setIsLoggedIn(true);
      })
      .catch(error => {
        console.error('Error al obtener datos del usuario:', error);
      });
  }, []);

  useEffect(() => {
    if (location.pathname === '/admin/dashboards') {
      setMini(true);
    } else {
      setMini(false);
    }
  }, [location.pathname]);


  return (
    <Flex direction="column" height="max-content" borderRadius="30px" justify={"space-evenly"} overflowY={'hidden'}  >
      <Brand mini={mini} hovered={hovered} />

      <ConfiguratorButton
        display="flex"
        onClick={() => {
          props.setMini(prevMini => !prevMini);

        }}
        active={props.mini}
      >
        <Flex width={"auto"} color={"#424ea8"}  flexDirection={"row"}>
          <Box>
            <IoMdMenu fontSize={'40px'}/>
          </Box>
        </Flex>
      </ConfiguratorButton>
      {typeUser === 1 ? (
        <>
          <Stack direction="column" mb="30px"     >
            <Box
              ps={
                mini === false
                  ? '20px'
                  : mini === true && hovered === true
                    ? '20px'
                    : '16px'
              }
              pe={{ md: '16px', '2xl': '1px' }}
              ms={mini && hovered === false ? '-5' : 'unset'}
            >
              <Links mini={mini} hovered={hovered} routes={routes} />
            </Box>
          </Stack>
        </>
      ) : (typeUser === 35) ? (
        <Text></Text>
      ) : (
        <Stack direction="column" mb="auto" mt="8px">
          <Box
            mt={mini === false
              ? '0px'
              : mini === true && hovered === true
                ? '0px'
                : '10%'}
            ps={
              mini === false
                ? '20px'
                : mini === true && hovered === true
                  ? '20px'
                  : '16px'
            }
            pe={{ md: '16px', '2xl': '1px' }}
            ms={mini && hovered === false ? '-12px' : 'unset'}
          >
            <Links mini={mini} hovered={hovered} routes={routes} setMini={setMini} />
          </Box>
        </Stack>
      )}



      <Box mb={2}
        ps="20px"
        pe={{ md: '16px', '2xl': '16px' }}
        borderRadius="30px"
        display={mini ? 'none' : 'block'}
      >
        <Box
          bg="white" boxShadow={"0px 0px 8px 5px #f2f2f2"}
          color="#1c2147"
          p={4}
          borderRadius="lg"
          textAlign="center"
        >
          <Flex className='DHD'>
            <Text color={'#FABB1C'} fontSize="28px" fontWeight={'bold'}>
              {day}
            </Text>
            <Text className='hour'>
              {hour}
            </Text>
            <Text color='#808080' fontWeight={'bold'} fontSize="lg" mt={-4}>
              {date}
            </Text>
          </Flex>
        </Box>
      </Box>
      <Flex
        mb={mini === false
          ? '20px'
          : mini === true && hovered === true
            ? '20px'
            : '120%'}
        pb={2} pt="15%" justifyContent="center" alignItems="center" >
        <Avatar
          h="48px"
          w="48px"
          src={avatar4}
          display={mini ? 'none' : 'block'}
          me={
            mini === false
              ? '20px'
              : mini === true && hovered === true
                ? '20px'
                : '0px'
          }
        />
        <Box
          display={
            mini === false
              ? 'block'
              : mini === true && hovered === true
                ? 'block'
                : 'none'
          }
        >
          <Text color={textColor} fontSize="md" fontWeight="700">
            {isLoggedIn ? `${userName}` : ''}
          </Text>
          <Text color="secondaryGray.600" fontSize="sm" fontWeight="400">
            {typeUserName}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SidebarContent;
