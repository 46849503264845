import axios from 'axios';
import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Text, Button,
    Box, Flex, Badge,
} from '@chakra-ui/react';
import { AiOutlineSchedule } from "react-icons/ai";

function ModalConfirmation({ isOpen, onClose }) {

    const [schedules, setSchedules] = useState([]);
    const fetchData = async () => {
        try {
            const response = await axios.get('/schedule/showR')
            setSchedules(response.data.schedules);
        } catch (error) {
            console.error('Error al obtener datos del usuario:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getColorSchemeForTypeDaily = (typeDaily) => {
        const colorMap = {
            1: '#7080fc',
            2: '#fcd677',
            3: '#8a6dce',
            4: '#dd67ae',
            5: '#fca066',
            7: '#fcd677',
            8: '#dd67ae',
            9: '#fca066'
        };
        return colorMap[typeDaily] || '#7080fc';
    };

    const confirmRead = async () => {
        const confirmDelete = await Swal.fire({
            title: 'Do you want to confirm the schedules?',
            html: "<p style='color: #f8ba85;'><strong> If you have any concerns, please reach out to your supervisor.</strong></p>",
            icon: 'warning',
            confirmButtonColor: '#b9ce67',
            confirmButtonText: 'Confirm',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            return result.isConfirmed
        });

        if (confirmDelete) {
            try {
                const response = await axios.post(`/confirm_read`);
                window.location.reload(true);

            } catch (error) {
                console.error(error);

            }
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'} closeOnOverlayClick={false} closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'70px'} fontWeight={'bold'} fontSize={'40px'}>
                    <span style={{ marginRight: '8px' }}>Schedule Confirmation</span>
                    <AiOutlineSchedule size={'45px'} />
                </ModalHeader>
                <ModalBody>
                    {schedules.length > 0 ? (
                        schedules.map(schedules => (
                            <Flex key={schedules.id} display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
                                <Box display="flex" justifyContent="space-between" alignItems="center" height="60px" bg={'#f2f2f2'} width={'80%'} mb={'10px'}>

                                    <Text fontWeight={'bold'} color={'#1c2147'} ml={'20px'} fontSize={'sm'}>
                                        {schedules.dateF}
                                    </Text>

                                    <Text fontWeight={'bold'} color={'#808080'} ml={'20px'} fontSize={'sm'}>
                                        {schedules.status === 2 ? '---' : `${schedules.timeS} - ${schedules.timeE}`}
                                    </Text>

                                    {schedules.description && (
                                        <>
                                            <Text fontWeight={'bold'} color={'#1c2147'} fontSize={'sm'}>
                                                {schedules.description}
                                            </Text>
                                        </>
                                    )}

                                    <Flex>
                                        {schedules.status === 2 ?
                                            <Badge color={"white"} bg={"#808080"} fontSize='12px' borderRadius={"5px"} mr={'20px'} textAlign={'center'} height={'25px'} width={'80px'}>
                                                Off
                                            </Badge>
                                            :
                                            <Badge color={"white"} bg={getColorSchemeForTypeDaily(schedules.type_daily)} fontSize='12px' borderRadius={"5px"} mr={'20px'} textAlign={'center'} height={'25px'}>
                                                {(() => {
                                                    switch (schedules.type_daily) {
                                                        case 1:
                                                            return "Regular shift";
                                                        case 2:
                                                            return "Regular training";
                                                        case 3:
                                                            return "Additional hours";
                                                        case 4:
                                                            return "Regular coverage";
                                                        case 5:
                                                            return "Regular shadowing"
                                                        case 6:
                                                            return "No shift"
                                                        case 7:
                                                            return "Additional training"
                                                        case 8:
                                                            return "Additional coverage"
                                                        case 9:
                                                            return "Additional shadowing"
                                                        default:
                                                            return "Regular shift";
                                                    }
                                                })()}
                                            </Badge>
                                        }
                                    </Flex>
                                </Box>

                            </Flex>
                        ))
                    ) : (
                        null
                    )}
                    <Flex display={'flex'} justifyContent={'center'}>
                        <Button
                            mt={'70px'}
                            mb={'50px'}
                            size={'md'}
                            color={"white"}
                            bg={"#b9ce67"}
                            borderRadius={'5px'}
                            _hover={{ background: "#8aad01", color: 'white' }}
                            onClick={confirmRead}
                        >
                            Confirm shifts
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ModalConfirmation;
