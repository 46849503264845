import axios from 'axios';
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Flex,
  Image,
  Box,
  SimpleGrid,
  FormLabel,
  Link
} from '@chakra-ui/react';
import { FaRegUser } from "react-icons/fa6";
import { BsWhatsapp } from "react-icons/bs";

function UserInfoModal({ isOpen, onClose, user }) {

  const anyDesk = user && user.anydesk;
  const [notes, setNotes] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/notesHR/${user.id}`);
      setNotes(response.data);
    } catch (error) {
      console.error('Error fetching Notes:', error);
    }
  };


  useEffect(() => {
    fetchData();
  }, []
  );

  if (!user) {
    return null;
  }

  const panelPersonalInfo = () => (
    <Box >
      <Text className='textGeneralInf'> General Information</Text>
      <SimpleGrid
        columns='2'
      >
        <FormLabel
          htmlFor='location_id'
        >
          <Text className='textPhone'>Phone</Text>

          <Text className='text2'> {user.profile && user.profile.phone ? `+52 ${user.profile.phone}` : "No data"}
            <Link
              mt={4}
              marginLeft={'2%'}
              textAlign={'center'}
              href={getWhatsAppLink(user.profile.phone, user.location_id)}
              isExternal
            >
              <Button colorScheme='whatsapp' size={'sm'} borderRadius={'10px'} >
                <BsWhatsapp color="white" size='15px' />
              </Button>
            </Link>
          </Text>
        </FormLabel>

        <FormLabel
          htmlFor='location_id'
        >
          <Text className='textPhone'> Personal Email</Text>

          <Text className='text2'>{user.profile && user.profile.personal_email ? user.profile.personal_email : "No data"}</Text>
        </FormLabel>

        <FormLabel
          htmlFor='location_id'
        >
          <Text className='textPhone'> Date of Birth</Text>
          <Text className='text2'>{user.profile && user.profile.date_of_birth ? user.profile.date_of_birth : "No data"}</Text>
        </FormLabel>

        <FormLabel
          htmlFor='location_id'
        >
          <Text className='textPhone'> Nationality</Text>
          <Text className='text2'>{user.profile && user.profile.nationality ? user.profile.nationality : "No data"}</Text>
        </FormLabel>

        <FormLabel
          htmlFor='location_id'
        >
          <Text className='textPhone'> Current address</Text>
          <Text className='text2'>{user.profile && user.profile.current_address ? user.profile.current_address : "No data"}</Text>
        </FormLabel>

        <FormLabel
          htmlFor='location_id'
        >
          <Text className='textPhone'> CURP</Text>
          <Text className='text2'>{user.profile && user.profile.curp ? user.profile.curp : "No data"}</Text>
        </FormLabel>

        <FormLabel
          htmlFor='location_id'
        >
          <Text className='textPhone'> RFC</Text>
          <Text className='text2'>{user.profile && user.profile.rfc_tax ? user.profile.rfc_tax : "No data"}</Text>
        </FormLabel>

        <FormLabel
          htmlFor='location_id'
        >
          <Text className='textPhone'> SSN (Social Security Number)</Text>
          <Text className='text2'>{user.profile && user.profile.ssn ? user.profile.ssn : "No data"}</Text>
        </FormLabel>

        <FormLabel
          htmlFor='location_id'
        >
          <Text className='textPhone'> First Day</Text>
          <Text className='text2'>{user.profile && user.profile.first_day ? user.profile.first_day : "No data"}</Text>
        </FormLabel>

        <FormLabel
          htmlFor='location_id'
        >
          <Text className='textPhone'> Start taking calls date</Text>
          <Text className='text2'>{user.profile && user.profile.date_start_calls ? user.profile.date_start_calls : "No data"}</Text>
        </FormLabel>

      </SimpleGrid>
    </Box>
  );


  const panelEmergency = () => (
    <Box >
      <Text className='textEmergencyContact'> Emergency Contact </Text>
      <SimpleGrid
        columns='1'

        w={{ base: "100%", md: "unset" }}>
        <FormLabel
          htmlFor='location_id' >
          <Text className='textPhone'>Contact Name</Text>
          <Text className='text2'>{user.emergency_info && user.emergency_info.emergency_contact ? user.emergency_info.emergency_contact : "No data"}</Text>
        </FormLabel>

        <FormLabel

          htmlFor='location_id'

        >
          <Text className='textPhone'>Emergency Contact Number</Text>
          <Text className='text2'>{user.emergency_info && user.emergency_info.emergency_contact_number ? user.emergency_info.emergency_contact_number : "No data"}</Text>
        </FormLabel>

        <FormLabel

          htmlFor='location_id'

        >
          <Text className='textPhone'>Relationship</Text>
          <Text className='text2'>{user.emergency_info && user.emergency_info.relationship ? user.emergency_info.relationship : "No data"}</Text>
        </FormLabel>

        <FormLabel

          htmlFor='location_id'

        >
          <Text className='textPhone'>Children</Text>
          <Text className='text2'>{user.emergency_info && user.emergency_info.children ? user.emergency_info.children : "No data"}</Text>
        </FormLabel>

        <FormLabel

          htmlFor='location_id'

        >
          <Text className='textPhone'>Quantity</Text>
          <Text className='text2'>{user.emergency_info && user.emergency_info.children_number ? user.emergency_info.children_number : "No data"}</Text>
        </FormLabel>

        <FormLabel

          htmlFor='location_id'

        >
          <Text className='textPhone'>Boys & Girls</Text>
          <Text className='text2'>{user.emergency_info && user.emergency_info.gender_children ? user.emergency_info.gender_children : "No data"}</Text>
        </FormLabel>

        <FormLabel

          htmlFor='location_id'

        >
          <Text className='textPhone'>Ages</Text>
          <Text className='text2'>{user.emergency_info && user.emergency_info.age_children ? user.emergency_info.age_children : "No data"}</Text>
        </FormLabel>

        <FormLabel

          htmlFor='location_id'

        >
          <Text className='textPhone'>Allergies</Text>
          <Text className='text2'>{user.emergency_info && user.emergency_info.allergies ? user.emergency_info.allergies : "No data"}</Text>
        </FormLabel>
        <FormLabel

          htmlFor='location_id'

        >
          <Text className='textPhone'>Favorite Food</Text>
          <Text className='text2'>{user.emergency_info && user.emergency_info.favorite_food ? user.emergency_info.favorite_food : "No data"}</Text>
        </FormLabel>
        <FormLabel

          htmlFor='location_id'

        >
          <Text className='textPhone'>Marital Status</Text>
          <Text className='text2'>{user.emergency_info && user.emergency_info.marital_status ? user.emergency_info.marital_status : "No data"}</Text>
        </FormLabel>
      </SimpleGrid>
    </Box>
  );

  const panelNotes = () => (
    <Box >
      <Text className='textGeneralInf' > Notes </Text>
      <Flex direction={'column'} height={"560px"} overflowY={"auto"}>
        {notes && notes.length > 0 ? (
          notes.map((note) => (
            <Flex p={1}
              key={note.id}
              boxSizing='border-box'
              direction={'column'}
            >
              <Flex flexDirection={"column"} width={"100%"}  >
                <Flex >
                  <Box className='boxNote'>
                    <Text className='textNote'>
                      {note.create_user.name} {note.create_user.last_name}
                    </Text>
                    <Text className='textNote2'>
                      {note.create_user.user_type.name}
                    </Text>
                    <Text className='textNoteDescription'>{note.description}</Text>
                  </Box>
                </Flex>
              </Flex>

            </Flex>
          ))
        ) : (
          <Flex display={'flex'} justifyContent={'center'}>
            <Box width={'200px'} mt={'30px'} >
              <Text className='textNoNotes'>
                No notes
              </Text>
            </Box>
          </Flex>
        )}
      </Flex>
    </Box>
  );



  const getWhatsAppLink = (phoneNumber, locationId) => {
    const countryCodeMap = {
      1: '52',
      2: '501',
      3: '501',
      4: '1',
      5: '57'
    };

    const countryCode = countryCodeMap[locationId] || '';

    // Agregar el código de país a la URL de WhatsApp
    const fullPhoneNumber = `+${countryCode}${phoneNumber}`;

    // Reemplazar espacios con %20 en el número de teléfono (si hay alguno)
    const sanitizedPhoneNumber = fullPhoneNumber.replace(/\s/g, '%20');

    // Construir la URL de WhatsApp
    return `https://wa.me/${sanitizedPhoneNumber}`;
  }


  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full" closeOnOverlayClick={false}
      closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent width={"80%"} borderRadius='20px' >
        <ModalHeader
         className='modalHeader'
        >
          <Flex width={"max-content"} fontSize={"40px"}>
            {user.name} {user.last_name} 
          
          <Flex display={"flex"}>
            
            <Flex width={"max-content"} marginLeft={'20px'} pl={6} color={"#1c2147"} pr={6} style={{ borderRight: '2px solid #1c2147', borderLeft: '2px solid #1c2147' }}>
              <Box  display="flex" alignItems="center">
                
                {user.status_employee_id === 1 ? (
                  <>
                    <Text ml={'5px'}>
                      {user.location.country}, {user.location.estate} {user.status.name}
                    </Text>
                  </>
                ) : (
                  <Text>
                    {user.location.country} {user.status.name}
                  </Text>
                )}
              </Box>
            </Flex>
            <Flex width={"max-content"} pl={6} color={"#808080"}>
              {anyDesk && (
                <Button alignSelf="center" borderRadius={"5px"} bg={"#fc7466"} color={"white"} _hover={{ background: "#de5b5b", color: 'white' }}> <a href={`anydesk:${user.anydesk}`}>AnyDesk</a> </Button>
              )}
            </Flex>
          </Flex>
          </Flex>

        </ModalHeader>
        <ModalCloseButton bg={"#808080"} textColor={'white'} fontWeight={'bold'} _hover={{ background: "red" }} mx="60px" mt={'2%'} />

        <ModalBody justifyContent={'center'}  mt={'2%'} >
          <Box display="flex"  justifyContent="center" >
            {Array.from({ length: 3 }).map((_, index) => {
              return (
                <Box
                  key={index}
                  width={{ base: "100%", md: "calc(33.33% - 40px)", "3xl": "calc(33.33% - 40px)" }}
                >

                  <Box width={'110%'} >
                    {index === 0 && panelPersonalInfo(index)}

                  </Box>
                  <Box width={'70%'} ml={'20%'} paddingLeft={'10%'} style={{borderLeft: '2px solid #cccccc', borderRight: '2px solid #cccccc' }}>
                    {index === 1 && panelEmergency(index)}

                  </Box>
                  <Box width={'100%'} >
                    {index === 2 && panelNotes(index)}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UserInfoModal;
