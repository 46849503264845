import axios from 'axios';
import React, { useEffect, useState } from "react";
import {
    Button, FormControl, FormLabel, Flex, useToast, Input, Text, Textarea, 
    Select, Box, FormErrorMessage
} from '@chakra-ui/react';
import { EditIcon, CloseIcon } from '@chakra-ui/icons'
import { ImAttachment } from "react-icons/im";
import Swal from 'sweetalert2'
import Select2 from 'react-select'

function ChangeScheduleRequest() {

    const toast = useToast();
    const [showForm, setShowForm] = useState(false);
    const [showAdvice, setShowAdvice] = useState(true);
    const [userList, setUserList] = useState([]);
    const [client, setClient] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const [userSearch, setUserSearch] = useState('');
    const [clientSearch, setClientSearch] = useState('');
    const [typeRequestSearch, setTypeRequestSearch] = useState('');
    const [formErrors, setFormErrors] = useState({});

    const [formData, setFormData] = useState({
        name: '', 
        email: '',
        reason: '',
    });

    const clearForm = () => {
        setFormData({
            name: '',
            email: '',
            reason: '',
        });
        setFormErrors({});
        setUserSearch(null);
        setClientSearch(null);
        setTypeRequestSearch(null);
        setResetKey(resetKey + 1);
    };


    const showFormButton = () => {
        setShowForm(true);
        setShowAdvice(false);
    };

    const handleOptions = async () => {
        try {
            const response = await axios.get(`/settings/options`);
            const responseData = response.data;
            setUserList(responseData.userList);
            setClient(responseData.client);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    useEffect(() => {
        handleOptions();
    }, []);

    const optionClients = client.map(client => ({
        value: client.name,
        label: client.name,
    }));

    const optionsUsers = userList.map(userList => ({
        value: `${userList.name} ${userList.last_name}`,
        label: `${userList.name} ${userList.last_name}`,
    }));

    const typeRequest = [
        { value: 'Relocation', label: 'Relocation' },
        { value: 'Availability - schedule mistake', label: 'Availability - schedule mistake' },
        { value: 'Home office request', label: 'Shift change request' },
        { value: 'Reduced hours', label: 'Reduced hours' },
    ];


    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            fontSize: '16px',
            height: '40px',
            width: '375px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const handleSelectChangeF = (componentName, selectedOption) => {
        switch (componentName) {
            case 'client':
                setClientSearch(selectedOption.value);
                break;
            case 'user':
                setUserSearch(selectedOption.value);
                break;
            case 'request':
                setTypeRequestSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async () => {

        const formSubmitData = {
            name: formData.name,
            typeRequest: typeRequestSearch,
            email: formData.email,
            reason: formData.reason,
            client: clientSearch,
            supervisor: userSearch,
        };

        try {
            const response = await axios.post('/changeScheduleRequests', formSubmitData);

            toast({
                title: 'Change schedule request',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Schedule request error",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Request Send:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };




    return (
        <Flex direction={'column'}>
            {showAdvice && (
                <Flex direction={'column'} alignItems={'center'} display={'flex'} width={'100%'}>
                    <Text fontWeight={'bold'} fontSize={'25px'} mb={'2%'}>
                        Welcome
                    </Text>
                    <Text>
                        Important: please be advised that the minimum time to get and update for your request is three (3)
                        weeks.
                    </Text>
                    <Text>
                        This allows us to effectively evaluate the request and the client's staffing needs before
                        approving it.
                    </Text>
                    <Flex width={'50%'} justifyContent={'center'} mt={'2%'}>
                        <Button class="buttonCreate" onClick={showFormButton} >
                            I've read and i agree
                        </Button>
                    </Flex>
                </Flex>
            )}
            {showForm && (
                <Flex mt={4} ml={10} mr={10} direction={'column'} width={'100%'}>
                    <Flex display={'flex'}>
                        <Flex direction={'column'} width={'80%'} mr={'1%'}>
                            <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'}>
                                Name
                            </Text>
                            <Text color={'gray.400'} mb={'1%'}>
                                Please add your name and last name.
                            </Text>
                            <FormControl isInvalid={!!formErrors.name} w='380px' >
                            <Input
                                placeholder="Full Name"
                                className='custom-placeholderInputs'
                                background="white"
                                value={formData.name}
                                onChange={handleChange}
                                name='name'
                            />
                            {formErrors.name && (
                                    <FormErrorMessage mb={"2%"}>{formErrors.name}</FormErrorMessage>
                                )}
                            </FormControl>
                        </Flex>

                        <Flex direction={'column'} width={'100%'}>
                            <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'}>
                                Team:
                            </Text>
                            <Text color={'gray.400'} mb={'1%'}>
                                If not found, please reach ot to HR.
                            </Text>
                            <FormControl isInvalid={!!formErrors.client} w='400px' >
                                <Select2
                                    options={optionClients}
                                    value={optionClients.find(option => option.value === clientSearch)}
                                    onChange={(selectedOption) => {
                                        handleSelectChangeF('client', selectedOption);
                                    }}
                                    styles={customStyles}
                                    placeholder="Select client"
                                    key={`clientSelect-${resetKey}`}
                                />
                                {formErrors.client && (
                                    <FormErrorMessage mb={"2%"}>{formErrors.client}</FormErrorMessage>
                                )}
                            </FormControl>
                        </Flex>
                    </Flex>
                    <Flex>
                        <Flex direction={'column'} width={'50%'} >
                            <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'}>
                                Supervisor:
                            </Text>
                            <Text color={'gray.400'} mb={'1%'}>
                                If not found, please reach ot to HR.
                            </Text>
                            <FormControl isInvalid={!!formErrors.supervisor} w='400px'>
                                <Select2
                                    options={optionsUsers}
                                    value={optionsUsers.find(option => option.value === userSearch)}
                                    onChange={(selectedOption) => {
                                        handleSelectChangeF('user', selectedOption);
                                    }}
                                    styles={customStyles}
                                    placeholder="Select supervisor"
                                    key={`agentSelect-${resetKey}`}
                                    isSearchable
                                    filterOption={filterOptions}
                                />
                                {formErrors.supervisor && (
                                    <FormErrorMessage mb={"2%"}>{formErrors.supervisor}</FormErrorMessage>
                                )}
                            </FormControl>
                        </Flex>

                        <Flex direction={'column'} width={'100%'}>
                            <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'} mt={'5.5%'}>
                                Type of request:
                            </Text>
                            <FormControl isInvalid={!!formErrors.typeRequest} w='400px'>
                                <Select2
                                    options={typeRequest}
                                    value={typeRequest.find(option => option.value === typeRequestSearch)}
                                    onChange={(selectedOption) => {
                                        handleSelectChangeF('request', selectedOption);
                                    }}
                                    styles={customStyles}
                                    placeholder="Select type request"
                                    key={`typeRequestSelect-${resetKey}`}
                                />
                                {formErrors.typeRequest && (
                                    <FormErrorMessage mb={"2%"}>{formErrors.typeRequest}</FormErrorMessage>
                                )}
                            </FormControl>
                        </Flex>
                    </Flex>
                    <Flex>
                        <Flex direction={'column'} width={'90%'} mr={'1%'} mt={'1%'}>
                            <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'}>
                                Email
                            </Text>
                            <Text color={'gray.400'} mb={'1%'}>
                                Please provide your DP email.
                                You will receive response to your request to this email, please make sure it is correct.
                            </Text>
                            <FormControl isInvalid={!!formErrors.email} w='400px'>
                            <Input
                                width={"200%"}
                                placeholder="Dedicated people email"
                                className='custom-placeholderInputs'
                                background="white"
                                value={formData.email}
                                onChange={handleChange}
                                name='email'
                            />
                            {formErrors.email && (
                                    <FormErrorMessage mb={"2%"}>{formErrors.email}</FormErrorMessage>
                                )}
                            </FormControl>
                        </Flex>
                    </Flex>
                    <Flex>
                        <Flex direction={'column'} width={'90%'}>
                            <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'} mt={'1%'}>
                                Reason of request
                            </Text>
                            <FormControl isInvalid={!!formErrors.reason} w='400px'>
                            <Textarea
                                width={"200%"}
                                value={formData.reason}
                                onChange={handleChange}
                                name='reason'
                            />
                            {formErrors.reason && (
                                    <FormErrorMessage mb={"2%"}>{formErrors.reason}</FormErrorMessage>
                                )}
                            </FormControl>
                        </Flex>
                    </Flex>
                    {/* <Flex>
                        <Flex direction={'column'} width={'100%'}>
                            <Text color={'gray.400'} mb={'1%'} fontWeight={'bold'} mt={'1%'}>
                                Speed test
                            </Text>
                            <Text color={'gray.400'} mb={'1%'}>
                                If you are making home office request is needed to attach one internet speed test
                            </Text>
                            <Input type="file" id="document" style={{ display: 'none' }}
                                accept="application/pdf" />
                            <Button as="label" htmlFor="document" cursor="pointer"
                                bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'}
                                color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                width={"95%"}
                            >
                                <Text>
                                    Attach the speed test
                                </Text>
                                <Box ml={2}>
                                    <ImAttachment size={'20px'} />
                                </Box>
                            </Button>
                        </Flex>
                    </Flex> */}
                    <Flex display={'flex'} justifyContent={'center'} mt={'2%'}>
                        <Button class="buttonCreate" >  {/* onClick={handleSubmit} */}
                            Submit <EditIcon ml={2} />
                        </Button>
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
}

export default ChangeScheduleRequest;
