import React from 'react';
import {
    Menu, MenuButton, MenuItem, MenuList,
    Button, Text,
} from '@chakra-ui/react'
import { InfoOutlineIcon, SettingsIcon } from '@chakra-ui/icons'
import { BsCalendarCheck } from "react-icons/bs";
import { FaUserSlash, FaNoteSticky } from "react-icons/fa6";
import { BiCalendarStar } from "react-icons/bi";
import { TbCalendarUp, TbCalendarPlus, TbCalendarX } from "react-icons/tb";
import { MdEditCalendar } from "react-icons/md";
import { AiOutlineAudit } from "react-icons/ai";


function WeeklyButtons({ userAuth, userAuthID, handleInfo, handleAudit, handleNotes, deleteSchedule, handleUpdate, handleAP, handleAccept, handlePublic, handleCallOut, generateShift, schedule }) {

    return (
        <>
            {[1, 2, 3, 4, 23, 24, 25, 37].includes(userAuth) ? (
                <Menu>
                    <MenuButton as={Button} _active={{ background: "#808080", color: 'white' }} bg="#1c2147" color={"white"} _hover={{ background: "#808080", color: 'white' }} borderRadius={'5px'} width={'45px'} height={'20px'} mr={2} >
                        <SettingsIcon textAlign={'center'} mt={"-3px"} boxSize={'3'} />
                    </MenuButton>
                    <MenuList>
                        <MenuItem isDisabled>
                            <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions on this schedule</div>
                        </MenuItem>
                        {schedule.deleted_at === null ? (
                            <>
                                {schedule.status === 2 ? (
                                    <>
                                        <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                            <Button
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                bg={"#f2f2f2"}
                                                color={"#1c2147"}
                                                _hover={{ background: "#7080fc", color: 'white' }}
                                                width="80%"
                                                onClick={() => handleInfo(schedule)}
                                            >
                                                <span style={{ flex: 1, textAlign: 'center' }}>Details </span>
                                                <InfoOutlineIcon ml={2} verticalAlign="middle" />
                                            </Button>
                                        </MenuItem>
                                        <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                            <Button
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                bg={"#f2f2f2"}
                                                color={"#1c2147"}
                                                _hover={{ background: "#fcd677", color: 'white' }}
                                                width="80%"
                                                onClick={() => handleAudit(schedule)}
                                            >
                                                <span style={{ flex: 1, textAlign: 'center' }}>Audit details </span>
                                                <AiOutlineAudit ml={2} verticalAlign="middle" />
                                            </Button>
                                        </MenuItem>
                                        <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                            <Button
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                bg="#f2f2f2"
                                                _hover={{ background: "#ff9fb8", color: 'white' }}
                                                color="#1c2147"
                                                width="80%"
                                                onClick={() => handleNotes(schedule)}
                                            >
                                                <Text
                                                    flex={1}
                                                    textAlign="center"
                                                >
                                                    Notes
                                                </Text>
                                                <FaNoteSticky ml={2} />
                                            </Button>
                                        </MenuItem>
                                        <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                            <Button
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                bg={"#f2f2f2"}
                                                color={"#1c2147"}
                                                _hover={{ background: "#ff9fb8", color: 'white' }}
                                                width="80%"
                                                onClick={() => handleUpdate(schedule)}
                                            >
                                                <span style={{ flex: 1, textAlign: 'center' }}>Update schedule</span>
                                                <MdEditCalendar size={'20px'} />
                                            </Button>
                                        </MenuItem>
                                        <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                            <Button
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                bg={"#f2f2f2"}
                                                color={"#1c2147"}
                                                _hover={{ background: "#fc7466", color: 'white' }}
                                                width="80%"
                                                onClick={() => deleteSchedule(schedule)}
                                            >
                                                <span style={{ flex: 1, textAlign: 'center' }}>Delete</span>
                                                <TbCalendarX size={'20px'} />
                                            </Button>
                                        </MenuItem>
                                    </>
                                ) : (
                                    <>
                                        <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                            <Button
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                bg={"#f2f2f2"}
                                                color={"#1c2147"}
                                                _hover={{ background: "#7080fc", color: 'white' }}
                                                width="80%"
                                                onClick={() => handleInfo(schedule)}
                                            >
                                                <span style={{ flex: 1, textAlign: 'center' }}>Details </span>
                                                <InfoOutlineIcon ml={2} verticalAlign="middle" />
                                            </Button>
                                        </MenuItem>

                                        <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                            <Button
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                bg={"#f2f2f2"}
                                                color={"#1c2147"}
                                                _hover={{ background: "#fcd677", color: 'white' }}
                                                width="80%"
                                                onClick={() => handleAudit(schedule)}
                                            >
                                                <span style={{ flex: 1, textAlign: 'center' }}>Audit details </span>
                                                <AiOutlineAudit ml={2} verticalAlign="middle" />
                                            </Button>
                                        </MenuItem>
                                        <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                            <Button
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                bg="#f2f2f2"
                                                _hover={{ background: "#ff9fb8", color: 'white' }}
                                                color="#1c2147"
                                                width="80%"
                                                onClick={() => handleNotes(schedule)}
                                            >
                                                <Text
                                                    flex={1}
                                                    textAlign="center"
                                                >
                                                    Notes
                                                </Text>
                                                <FaNoteSticky ml={2} />
                                            </Button>
                                        </MenuItem>

                                        <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                            <Button
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                bg={"#f2f2f2"}
                                                color={"#1c2147"}
                                                _hover={{ background: "#ff9fb8", color: 'white' }}
                                                width="80%"
                                                onClick={() => handleUpdate(schedule)}
                                            >
                                                <span style={{ flex: 1, textAlign: 'center' }}>Update schedule</span>
                                                <MdEditCalendar size={'20px'} />
                                            </Button>
                                        </MenuItem>
                                        {(schedule.option === 1) ? (
                                            <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                                <Button
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    bg={"#f2f2f2"}
                                                    color={"#1c2147"}
                                                    _hover={{ background: "#fca066", color: 'white' }}
                                                    width="80%"
                                                    onClick={() => handleAP(schedule)}
                                                >
                                                    <span style={{ flex: 1, textAlign: 'center' }}>A & P this week</span>
                                                    <BiCalendarStar size={'22px'} />
                                                </Button>
                                            </MenuItem>
                                        ) : null}
                                        {(schedule.option === 1) ? (
                                            <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                                <Button
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    bg={"#f2f2f2"}
                                                    color={"#1c2147"}
                                                    _hover={{ background: "#8aad01", color: 'white' }}
                                                    width="80%"
                                                    onClick={() => handleAccept(schedule)}
                                                >
                                                    <span style={{ flex: 1, textAlign: 'center' }}>{schedule.accept === 1 ? 'Unaccept' : 'Accept'}</span>
                                                    <BsCalendarCheck size={'18px'} />
                                                </Button>
                                            </MenuItem>
                                        ) : null}
                                        {(schedule.option === 1) ? (
                                            (schedule.accept === 1) ? (
                                                <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                                    <Button
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        bg={"#f2f2f2"}
                                                        color={"#1c2147"}
                                                        _hover={{ background: "#8a6dce", color: 'white' }}
                                                        width="80%"
                                                        onClick={() => handlePublic(schedule)}
                                                    >
                                                        <span style={{ flex: 1, textAlign: 'center' }}>{schedule.post === 1 ? 'Unpublish' : 'Publish'}</span>
                                                        <TbCalendarUp size={'20px'} />
                                                    </Button>
                                                </MenuItem>
                                            ) : null
                                        ) : null}
                                        {(schedule.option === 1) ? (
                                            <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                                <Button
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    bg={"#f2f2f2"}
                                                    color={"#1c2147"}
                                                    _hover={{ background: "#fc7466", color: 'white' }}
                                                    width="80%"
                                                    onClick={() => handleCallOut(schedule)}
                                                >
                                                    <span style={{ flex: 1, textAlign: 'center' }}>Call out</span>
                                                    <FaUserSlash size={'20px'} />
                                                </Button>
                                            </MenuItem>
                                        ) : null}

                                        {[1, 2, 3, 4, 23, 24, 25, 37].includes(userAuth) ? (
                                            <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                                <Button
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    bg={"#f2f2f2"}
                                                    color={"#1c2147"}
                                                    _hover={{ background: "#b9ce67", color: 'white' }}
                                                    width="80%"
                                                    onClick={() => generateShift(schedule)}
                                                >
                                                    <span style={{ flex: 1, textAlign: 'center' }}>
                                                        {schedule.time_clock === null ? 'Generate shift' : (schedule.time_clock.generate === 1 ? 'Clear shift' : 'Generate shift')}
                                                    </span>
                                                    <TbCalendarPlus size={'20px'} />
                                                </Button>
                                            </MenuItem>
                                        ) : null}
                                        <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                            <Button
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                bg={"#f2f2f2"}
                                                color={"#1c2147"}
                                                _hover={{ background: "#fc7466", color: 'white' }}
                                                width="80%"
                                                onClick={() => deleteSchedule(schedule)}
                                            >
                                                <span style={{ flex: 1, textAlign: 'center' }}>Delete</span>
                                                <TbCalendarX size={'20px'} />
                                            </Button>
                                        </MenuItem>

                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                    <Button
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        bg={"#f2f2f2"}
                                        color={"#1c2147"}
                                        _hover={{ background: "#7080fc", color: 'white' }}
                                        width="80%"
                                        onClick={() => handleInfo(schedule)}
                                    >
                                        <span style={{ flex: 1, textAlign: 'center' }}>Details </span>
                                        <InfoOutlineIcon ml={2} verticalAlign="middle" />
                                    </Button>
                                </MenuItem>
                                <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                    <Button
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        bg={"#f2f2f2"}
                                        color={"#1c2147"}
                                        _hover={{ background: "#fcd677", color: 'white' }}
                                        width="80%"
                                        onClick={() => handleAudit(schedule)}
                                    >
                                        <span style={{ flex: 1, textAlign: 'center' }}>Audit details </span>
                                        <AiOutlineAudit ml={2} verticalAlign="middle" />
                                    </Button>
                                </MenuItem>
                                <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                    <Button
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        bg="#f2f2f2"
                                        _hover={{ background: "#ff9fb8", color: 'white' }}
                                        color="#1c2147"
                                        width="80%"
                                        onClick={() => handleNotes(schedule)}
                                    >
                                        <Text
                                            flex={1}
                                            textAlign="center"
                                        >
                                            Notes
                                        </Text>
                                        <FaNoteSticky ml={2} />
                                    </Button>
                                </MenuItem>

                            </>
                        )}
                    </MenuList>
                </Menu>
            ) :
                <Menu>
                    <MenuButton as={Button} _active={{ background: "#808080", color: 'white' }} bg="#1c2147" color={"white"} _hover={{ background: "#808080", color: 'white' }} borderRadius={'5px'} width={'45px'} height={'20px'} mr={2}>
                        <SettingsIcon textAlign={'center'} boxSize={'3'} />
                    </MenuButton>
                    <MenuList>
                        <MenuItem isDisabled>
                            <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions on this schedule</div>
                        </MenuItem>
                        <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                            <Button
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                bg={"#f2f2f2"}
                                color={"#1c2147"}
                                _hover={{ background: "#7080fc", color: 'white' }}
                                width="80%"
                                onClick={() => handleInfo(schedule)}
                            >
                                <span style={{ flex: 1, textAlign: 'center' }}>Details </span>
                                <InfoOutlineIcon ml={2} verticalAlign="middle" />
                            </Button>
                        </MenuItem>
                        <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                            <Button
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                bg="#f2f2f2"
                                _hover={{ background: "#ff9fb8", color: 'white' }}
                                color="#1c2147"
                                width="80%"
                                onClick={() => handleNotes(schedule)}
                            >
                                <Text
                                    flex={1}
                                    textAlign="center"
                                >
                                    Notes
                                </Text>
                                <FaNoteSticky ml={2} />
                            </Button>
                        </MenuItem>
                        {(userAuth === 5 || userAuth === 6 || userAuth === 46) ? (
                            <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                <Button
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    bg={"#f2f2f2"}
                                    color={"#1c2147"}
                                    _hover={{ background: "#fcd677", color: 'white' }}
                                    width="80%"
                                    onClick={() => handleAudit(schedule)}
                                >
                                    <span style={{ flex: 1, textAlign: 'center' }}>Audit details </span>
                                    <AiOutlineAudit ml={2} verticalAlign="middle" />
                                </Button>
                            </MenuItem>
                        ) : null}
                        {((userAuth === 41 && schedule.id_client === 19 && userAuthID !== schedule.id_operator) || (userAuth === 42 && schedule.id_client === 3 && userAuthID !== schedule.id_operator)) ? (
                            <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                <Button
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    bg={"#f2f2f2"}
                                    color={"#1c2147"}
                                    _hover={{ background: "#ff9fb8", color: 'white' }}
                                    width="80%"
                                    onClick={() => handleUpdate(schedule)}
                                >
                                    <span style={{ flex: 1, textAlign: 'center' }}>Update schedule</span>
                                    <MdEditCalendar size={'20px'} />
                                </Button>
                            </MenuItem>
                        ) : null}
                    </MenuList>
                </Menu>
            }
        </>
    );
}
export default WeeklyButtons; 