import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import UseExports from "./components/itemsExports/functions";

export default function ReportsExpOverview() {
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card>
        <UseExports/>
      </Card>
    </Flex>
  );
} 