import React from 'react';
import {
    Button, Flex, Text, Input, Spinner
} from '@chakra-ui/react'
import { RepeatIcon } from '@chakra-ui/icons'
import { AiOutlineClear } from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";

function FiltersField({ handleDownloadExcel, startDateFilter, setStartDateFilter, endDateFilter, setEndDateFilter, clearFilters, title, filtersActive, setFiltersActive, isLoading }) {

    return (
        <>
            <Flex mt={'2%'} direction='column'>
                <Flex
                    w='100%'
                    justifyContent="flex-start"
                    mb={2}
                >
                    <Flex width={"max-content"} fontSize={"22px"} ml={8}>
                        <Text>{title}</Text>
                    </Flex>
                </Flex>
                <Flex py={"1%"} px={"4%"}
                    direction='row'
                    justifyContent={"space-between"}
                    w='95%'
                    bg="gray.200"
                    borderRadius="16px"
                    mb={'2%'}
                >
                    <Flex align="center" width={"max-content"}>
                        <Flex
                            border={'1px solid #808080'}
                            display={'flex'}
                            borderRadius={'4px'}
                            ml={4} mr={4}
                        >
                            <Input
                                width={'100%'}
                                border={'0px'}
                                type="date"
                                fontSize={"sm"}
                                value={startDateFilter}
                                onChange={(e) => {
                                    setStartDateFilter(e.target.value);
                                }}
                                _focusVisible={'none'}
                                color={'#808080'}
                            />
                            <Button bg={'none'} color={'#808080'} onClick={() => {
                                setStartDateFilter('');
                                setFiltersActive(false);
                            }}>
                                <RepeatIcon />
                            </Button>
                        </Flex>
                        <Flex
                            border={'1px solid #808080'}
                            display={'flex'}
                            borderRadius={'4px'}
                            mr={4}
                        >
                            <Input
                                width={'100%'}
                                border={'0px'}
                                type="date"
                                fontSize={"sm"}
                                value={endDateFilter}
                                onChange={(e) => {
                                    setEndDateFilter(e.target.value);
                                }}
                                _focusVisible={'none'}
                                color={'#808080'}
                            />
                            <Button bg={'none'} color={'#808080'} onClick={() => {
                                setEndDateFilter('');
                                setFiltersActive(false);
                            }}>
                                <RepeatIcon />
                            </Button>
                        </Flex>
                        <Button bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" onClick={clearFilters}>
                            <AiOutlineClear size={'26px'} />
                        </Button>
                    </Flex>
                    {filtersActive && startDateFilter !== null && endDateFilter !== null && (
                        <Flex width={"max-content"}>
                            <Button class="buttonCreate" onClick={handleDownloadExcel}>
                                <Text mr={2}>Excel</Text>
                                <RiFileExcel2Fill />
                            </Button>
                        </Flex>
                    )}
                </Flex>
                {!isLoading ? (
                    <Flex display={'flex'} justifyContent={'center'} alignItems={'center'} bg={"#f2f2f2"} width={'95%'}>
                        <Text
                            color='#1c2147'
                            fontSize={"25px"}
                            textAlign={"center"}
                            paddingTop={"15px"}
                            paddingBottom={"15px"}
                            textTransform={'none'}
                            fontFamily={'DM Sans'}
                            letterSpacing="-1px"
                            mr={'20px'}
                        >
                            Loading...
                        </Text>
                        <Spinner color='blue.500' />
                    </Flex>
                ) : (
                    null
                )}
            </Flex>
        </>
    );
}

export default FiltersField;
