// Chakra imports
import {
  Box, Flex
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from 'axios';

// Custom components
import Banner from "./items/Banner";
import GeneralInformation from "./items/General";

// Assets
import banner from "assets/img/auth/banner.png";
import logo from "assets/img/dashboards/logo.png";
import Requests from "./items/Request";
import UploadImage from "./items/Upload";

export default function OverviewProfile() {

  const [user, setUser] = useState("");
  const [typeUserName, setTypeUserName] = useState('');
  const [bonoEnable, setBonoEnable] = useState("");
  const [imageUrl, setImageUrl] = useState(null);


  const fetchData = async () => {
    try {
      const response = await axios.get('/user')
      const user = response.data.user;
      const receivedTypeUserName = response.data.type_user_name;
      const bono = response.data.bonoEnable;

      setUser(user);
      setTypeUserName(receivedTypeUserName);
      setBonoEnable(bono);

    } catch (error) {
      console.error('Error al obtener datos del usuario:', error);
    }
  };


  const fetchProfilePicture = async () => {
    try {
      const response = await axios.get('/users/profilePicture', {
        responseType: 'blob',
      });
      const imageBlob = URL.createObjectURL(response.data);
      setImageUrl(imageBlob);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchProfilePicture();
  }, []);

  const dateStart = user.profile && user.profile.first_day ? user.profile.first_day : '---';
  const dateStartCalls = user.profile && user.profile.date_start_calls ? user.profile.date_start_calls : '---';

  const profilePicture = imageUrl ? imageUrl : logo;

  const [modalUpdateImage, setModalUpdateImage] = useState(false);

  const openUpdateImage = () => {
    setModalUpdateImage(true);
  };


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} mt={'2%'}>
      <Flex >
        <Flex direction={'column'} mr={'1%'}>
          <Banner
            gridArea='1 / 1 / 2 / 2'
            banner={banner}
            avatar={profilePicture}
            name={user.name + " " + user.last_name}
            job={typeUserName}
            dateStart={dateStart}
            dateStartCalls={dateStartCalls}
            mt='3%'
            user={user}
            bonoEnable={bonoEnable}
            updateImage={openUpdateImage}
          />
        </Flex>

        <Flex direction={'column'}>
          <GeneralInformation
            gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
            minH='365px'
            pe='20px'
            user={user}
          />
          <Requests
            user={user}
          />
        </Flex>
      </Flex>
      {modalUpdateImage && (
        <UploadImage isOpen={openUpdateImage} onClose={() => setModalUpdateImage(false)} fetchProfilePicture={fetchProfilePicture} imageUrl={imageUrl} />
      )}
    </Box>
  );
}
