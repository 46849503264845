import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
    Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Flex, Text, Box, VStack
} from '@chakra-ui/react';
import { CloseIcon, DownloadIcon } from '@chakra-ui/icons'
import { FaRegImage } from "react-icons/fa6";
import { IoDocument } from "react-icons/io5";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FaFilePdf } from "react-icons/fa";

function TranscriptTicket({ isOpen, onClose, incident }) {

    const [messages, setMessages] = useState([]);
    const [hoveredMessage, setHoveredMessage] = useState(null);

    const fetchData = async () => {
        try {
            const response = await axios.get(`/chat/messages/${incident.id}`)
            setMessages(response.data.messages);
        } catch (error) {
            console.error('No Messages', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const modalBodyRef = useRef(null);
    const PAGE_WIDTH = 595; // Ancho de la página A4 en puntos (pt)
    const PAGE_HEIGHT = 900; // Alto de la página A4 en puntos (pt)

    const exportToPdf = async () => {
        const modalBody = modalBodyRef.current;
        if (!modalBody) return;

        const originalHeight = modalBody.style.height;
        modalBody.style.height = 'auto';

        const pages = Math.ceil(modalBody.scrollHeight / PAGE_HEIGHT);

        const pdf = new jsPDF('p', 'pt', 'a4');

        for (let i = 0; i < pages; i++) {
            const pageHeight = i === pages - 1 ? modalBody.scrollHeight % PAGE_HEIGHT : PAGE_HEIGHT;
            const yOffset = i * PAGE_HEIGHT;

            html2canvas(modalBody, {
                height: pageHeight,
                y: yOffset,
                scrollY: -yOffset
            }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                if (i > 0) pdf.addPage();
                const imgProps = pdf.getImageProperties(imgData);
                const imgWidth = imgProps.width;
                const imgHeight = imgProps.height;
                const scaleFactor = PAGE_WIDTH / imgWidth;
                const x = (PAGE_WIDTH - imgWidth * scaleFactor) / 2;
                const y = (PAGE_HEIGHT - imgHeight * scaleFactor) / 2;
                pdf.addImage(imgData, 'PNG', x, y, imgWidth * scaleFactor, imgHeight * scaleFactor);

                if (i === pages - 1) {
                    pdf.save(`Ticket#${incident.id}.pdf`);
                    modalBody.style.height = originalHeight; // Restaurar la altura original
                }
            });
        }
    };

    const downloadFile = async (message) => {
        try {
            const response = await axios.get(`/documentD/${encodeURIComponent(message.document_image)}`, { responseType: 'blob' });

            const contentDisposition = response.headers['content-disposition'];
            const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"$/);
            const suggestedFileName = fileNameMatch ? fileNameMatch[1] : message.document_image;

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', suggestedFileName);
            document.body.appendChild(link);
            link.click();

            // Liberar recursos
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    };

    const downloadImage = async (message) => {
        try {
            const response = await axios.get(`/imagesD/${encodeURIComponent(message.image_path)}`, { responseType: 'blob' });

            const contentDisposition = response.headers['content-disposition'];
            const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"$/);
            const suggestedFileName = fileNameMatch ? fileNameMatch[1] : message.image_path;

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', suggestedFileName);
            document.body.appendChild(link);
            link.click();

            // Liberar recursos
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'} isCentered closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    textColor='#1c2147'
                    textAlign='center'
                    mx="14"
                    mt={6}
                    fontSize={'30px'}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}
                >
                    Ticket Conversation
                    <hr style={{ position: 'absolute', width: '4%', bottom: 0, borderBottom: '3px solid #b3b3b3' }} />
                </ModalHeader>
                <ModalBody ml={'60px'}>
                    <Box
                        px={{ base: "10px", md: "20px" }}
                        overflowY={'auto'}
                        height={'850px'}
                        ref={modalBodyRef}
                        minHeight={'750px'}
                    >
                        <Flex direction='column'>
                            {messages.map((message) => (
                                <Flex
                                    direction='column'
                                    key={message.id}
                                    w='100%'
                                    maxW={{ base: "90%", lg: "calc(100% - 80px)" }}
                                    boxSizing='border-box'
                                >

                                    <VStack spacing={3}>
                                        <Flex justifyContent={'center'} mt={'10px'}>
                                            <Text color={'#b3b3b3'} fontSize={'sm'} textAlign={'center'}>
                                                {message.users.name} {message.users.last_name}
                                            </Text>
                                        </Flex>
                                        <Box
                                            p={2}
                                            borderRadius='lg'
                                            boxShadow="md"
                                            maxWidth={'550px'}
                                            width={'auto'}
                                        >
                                            {message.message !== null && (
                                                <Text
                                                    textAlign={'justify'}
                                                    mx={"10px"}
                                                    color={message.message && (message.message.startsWith('http://') || message.message.startsWith('https://')) ? '#007BFF' : '#1c2147'}
                                                    fontWeight='bold'
                                                    fontSize={'15px'}
                                                    textDecoration={message.message && (message.message.startsWith('http://') || message.message.startsWith('https://')) ? 'underline' : 'none'}
                                                >
                                                    {message.message && (message.message.startsWith('http://') || message.message.startsWith('https://')) ? (
                                                        <a href={message.message} target="_blank" rel="noopener noreferrer">
                                                            {message.message}
                                                        </a>
                                                    ) : (
                                                        <span>
                                                            {message.message}
                                                        </span>
                                                    )}
                                                </Text>
                                            )}
                                            <Flex display={'flex'} justifyContent={'center'}>
                                                {message.document_image && (
                                                    <Button
                                                        onClick={() => downloadFile(message)}
                                                        borderRadius={'5px'}
                                                        size={'lg'}
                                                        width={'80px'}
                                                        height={'80px'}
                                                        mt={'5%'} mb={'5%'}
                                                        mr={'20px'}
                                                        onMouseEnter={() => setHoveredMessage(message.id)}  // Al pasar el cursor, establece el mensaje actual
                                                        onMouseLeave={() => setHoveredMessage(null)}
                                                        _hover={{ background: "#cccccc" }}
                                                        bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'}
                                                    >
                                                        {hoveredMessage === message.id ? (
                                                            <DownloadIcon fontSize={'35px'} color="white" />
                                                        ) : (
                                                            <IoDocument ml={2} size={'50px'} color="white" />
                                                        )}
                                                    </Button>
                                                )}
                                                {message.image_path && (
                                                    <Button
                                                        onClick={() => downloadImage(message)}
                                                        borderRadius={'5px'}
                                                        size={'lg'}
                                                        width={'80px'}
                                                        height={'80px'}
                                                        mt={'5%'} mb={'5%'}
                                                        onMouseEnter={() => setHoveredMessage(message.id)}  // Al pasar el cursor, establece el mensaje actual
                                                        onMouseLeave={() => setHoveredMessage(null)}
                                                        bg={'linear-gradient(180deg, rgba(251, 149, 57, 1.0) 50%, rgba(252, 160, 102, 1.0) 50%)'}
                                                        _hover={{ background: "#cccccc" }}
                                                    >
                                                        {hoveredMessage === message.id ? (
                                                            <DownloadIcon fontSize={'35px'} color="white" />
                                                        ) : (
                                                            <FaRegImage size={'50px'} color="white" />
                                                        )}
                                                    </Button>
                                                )}
                                            </Flex>
                                            <Text textAlign={'right'} fontSize={'xs'}>
                                                {message.created_at}
                                            </Text>
                                        </Box>
                                    </VStack>
                                </Flex>
                            ))}

                        </Flex>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center">
                    <Button bg="#1c2147" color={'white'} _hover={{ background: "#808080", color: 'white' }} mr={3} onClick={onClose} borderRadius={'5px'} size={'md'}>
                        Close <CloseIcon ml={2} />
                    </Button>
                    <Button
                        bg="#424ea8"
                        color={'white'}
                        _hover={{ background: "#808080", color: 'white' }}
                        mr={3}
                        onClick={exportToPdf}
                        borderRadius={'5px'}
                        size={'md'}
                    >
                        Export
                        <Box ml={2}>
                            <FaFilePdf />
                        </Box>
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
export default TranscriptTicket;
