const formDataBelice = {

    first_day: '',
    date_of_birth: '',
    place_of_birth: '',
    address: '',
    city: '',
    state: '',
    social_security_card: '',
    SSC_name: '',
    SS_number: '',
    expiry_date: '',
    birth_certificate: '',
    vaccination_card: '',
    school_diploma: '',
    utility: '',
    police_record: '',
    bank_name: '',
    account_name: '',
    account_number: '',
    proof_banking: '',
    taxes_identification_number: '',
    emergency_contact_name: '',
    emergency_contact_phone: '',
    relationship: '',
    nickname: '',
    allergies: '',
    hobbie: '',
    favorite_food: '',
    marital_status: '',
    children: '',
    how_many: '',
    how_many_boys_and_girls: '',
    how_old: '',
    confirmation: '',

};

export default formDataBelice;