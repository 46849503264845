import React, { useState } from 'react';
import axios from 'axios';

import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input, Image,
    Box, Flex, Heading, SimpleGrid,
    FormControl, FormErrorMessage,
    useToast,
} from '@chakra-ui/react';

import avatar4 from 'assets/img/dashboards/grey_world.png';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons'

const ChangePassword = ({ isOpen, onClose, userData, user, setFlashing }) => {

    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        password: '',
    });

    const clearForm = () => {
        setFormData({
            password: ''
        });
        setFormErrors({});
    };

    const handleSubmit = async (id) => {
        try {
            const response = await axios.put(`/users/changePassword/${user.id}`, formData);

            if (response.status === 200) {
                userData();
                setFlashing(formData);
                toast({
                    title: 'Password Changed Success',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                clearForm();
                onClose();
                setFormErrors({});
            }
        } catch (error) {
            toast({
                title: "Error Changing Password",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Changing Password:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Modal size="lg" isOpen={isOpen} onClose={() => {
            onClose();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent marginTop={"15%"} background={"#f2f2f2"} height={"max-content"} borderRadius={"30px"}>
                <ModalHeader

                    textColor='white'
                    textAlign='center'
                    borderRadius='20px'
                    mx="14"
                    mt={6}
                >
                    <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Image
                            src={avatar4}
                            alt="Avatar"
                            style={{ width: '80px', height: '80px', marginRight: '10px' }}
                        />
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>
                        <Heading as="h2" fontSize="18px" color={'#808080'} textAlign={'center'} fontFamily="DM Sans" position="relative" letterSpacing="1px" mb='6' mt={10}>
                            CHANGE PASSWORD
                        </Heading>
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <FormControl ml={10} isInvalid={!!formErrors.password}>
                                    <Input
                                        type="text"
                                        placeholder="Enter the new password"
                                        name='password'
                                        className='custom-placeholderInputs'
                                        background="white"
                                        value={formData.password}
                                        onChange={handleChange}
                                        errorBorderColor='#fc7466'
                                    />
                                    {formErrors.password && (
                                        <FormErrorMessage>
                                            {"This field must be at least 9 characters"}
                                        </FormErrorMessage>
                                    )}
                                </FormControl>
                                <Button
                                    padding={"0px"}
                                    borderRadius={"50px"}
                                    bg={"transparent"}
                                    _hover={{ bg: "transparent" }}
                                    onClick={clearForm}
                                    title="Delete All"
                                    ml={1}
                                >
                                    <DeleteIcon color={"#808080"} />
                                </Button>
                            </div>
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={() => handleSubmit(user.id)}>
                        Change password
                    </Button>


                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ChangePassword;
