const formDataRemoteRD = {
    first_name: '',
    last_name: '',
    date_of_birth: '',
    age: '',
    location: '',
    municipality: '',
    address: '',
    personal_email: '',
    phone: '',
    whatsapp_number: '',
    about_us: '',
    referred_employee: '',
    student: '',
    plan_to_study: '',
    disponibility: '',
    studies: '',
    components_computer: '',
    microphone: '',
    headseat_model: '',
    webcam: '',
    webcam_integrated: '',
    webcam_model: '',
    webcam_on: '',
    no_break: '',
    no_break_model: '',
    connections_criteria: '',
    plan_to_upgrade: '',
    internet_provider: '',
    modem_in_home: '',
    internet_shared: '',
    medical_condition: '',
    specify_medical_contidion: '',
    diagnosed_mental_disorder: '',
    specify_diagnosed_mental_disorder: '',
    taking_medication: '',
    work_experience: '',
    curriculum: '',
    experience_call_center: '',
    experience_working_in_home: '',
    currently_working: '',
    planning_keep_currently_work: '',
    currently_company_name: '',
    currently_company_phone: '',
    currently_company_time: '',
    specify_leave_currently_work: '',
    professional_reference: '',
    number_professional_reference: '',
    speak_fluent_spanish: '',
    time_work: '',
    time_frame: '',
    confirm_time_frame: '',
    day_weekend_free: '',
    confirm_day_weekend_free: '',
    commitments_soon: '',
    specify_commitments_soon: '',
    able_to_start: '',
    grammar_and_listening_test: '',
    typing_test: '',
    signature: ''
}; 
export default formDataRemoteRD;