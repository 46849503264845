import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import {
    useToast
} from '@chakra-ui/react'
import axios from 'axios';
import SettingsList from './table';
function UseSettings() {
    const [setting, setSetting] = useState([]);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [ModalUpdateIsOpen, setModalUpdateIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchType, setSearchType] = useState('name');
    const [selectSetting, setSelectSetting] = useState(null);
    const [OptionSearch, setOptionSearch] = useState('');
    const [option, setOption] = useState([]);
    const [flashing, setFlashing] = useState(false);

    const clearFilters = () => {
        setSearchType('name');
        setSearchTerm('');
        setOptionSearch('');
        setCurrentPage(1);
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(`/settings?page=${currentPage}&${searchType}=${searchTerm}${OptionSearch ? `&option=${OptionSearch}` : ''}`);
            const responseData = response.data;
            setSetting(responseData.data.data);
            setOption(responseData.option);
            setTotalPages(responseData.data.last_page);
            setTotal(responseData.data.total)
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
        if (flashing) {
            const intervalId = setInterval(() => {
                setFlashing(false);
            }, 7000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [currentPage, searchTerm, searchType, OptionSearch, flashing]);
    const handleSearch = () => {
        setCurrentPage(1);
    };
    const openModalCreate = () => {
        setModalCreateIsOpen(true);
    }
    const closeModalCreate = () => {
        setModalCreateIsOpen(false);
    }
    const handleUpdate = async (setting) => {
        setSelectSetting(setting);
        setModalUpdateIsOpen(true);
        try {
            const response = await axios.get(`/setting/edit/${setting.id}`);
            const setting = response.data;

        } catch (error) {
            console.error('Error fetching setting details:', error);
        }
    };
    const closeModalUpdate = () => {
        setModalUpdateIsOpen(false);
    }
    const handleDelete = async (setting) => {
        const msg = setting.status == 0 ? 'activate' : 'deactivate';
        const confirmDelete = await Swal.fire({
            title: `Do you want to ${msg} This setting?`,
            text: `The setting will be ${msg}...`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2C5282',
            cancelButtonColor: '#E53E3E',
            confirmButtonText: `Yes, ${msg} it!`
        }).then((result) => {
            return result.isConfirmed
        });
        setFlashing(setting);
        if (confirmDelete) {
            setLoading(true);
            setSelectSetting(setting);
            try {
                const response = await axios.post(`/settings/status/${setting.id}`);
                toast({
                    title: "Status Update",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setFlashing(setting);
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error Updating The Status Item",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        }
    };
    const getRowBackground = (setting) => {
        if (selectSetting && selectSetting.id === setting.id) {
            if (flashing) {
                return '#fcd677';
            }
            return setting.id % 2 === 0 ? '#f2f2f2' : 'white';
        } else {
            return setting.id % 2 === 0 ? '#f2f2f2' : 'white';
        }
    };

    const getColorSchemeForTypeDaily = (idOption) => {
        const colorMap = {
            1: '#ff6f94',
            2: '#fabb1c',
            3: '#633cbd',
            4: '#fb9539',
            5: '#a2bd34',
            6: '#6bc2ed',
            7: '#7080fc',
        };
        return colorMap[idOption] || '#7080fc';
    };


    return (
        <SettingsList
            openModalCreate={openModalCreate}
            searchType={searchType}
            setSearchType={setSearchType}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleSearch={handleSearch}
            OptionSearch={OptionSearch}
            setOptionSearch={setOptionSearch}
            setCurrentPage={setCurrentPage}
            clearFilters={clearFilters}
            option={option}
            setting={setting}
            getRowBackground={getRowBackground}
            getColorSchemeForTypeDaily={getColorSchemeForTypeDaily}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            loading={loading}
            total={total}
            currentPage={currentPage}
            totalPages={totalPages}
            modalCreateIsOpen={modalCreateIsOpen}
            closeModalCreate={closeModalCreate}
            fetchData={fetchData}
            ModalUpdateIsOpen={ModalUpdateIsOpen}
            closeModalUpdate={closeModalUpdate}
            selectSetting={selectSetting}
            setFlashing={setFlashing}
        />
    );
}
export default UseSettings; 