import Select2 from 'react-select'
import {
    Button, Input,
    Flex, Box, Text
} from '@chakra-ui/react'
import { AiOutlineClear } from "react-icons/ai";
import { BsFileEarmarkExcelFill } from "react-icons/bs";


function VacationsFilters({ optionsUsers, userSearch, handleSelectChange, setCurrentPage, customStyles, filterOptions, resetKey, clearFilters, exportVacations }) {

    return (

        <Flex
            direction='column' justify={"space-evenly"}
            w='100%'
            p={2}
            borderRadius="16px"
        >
            <Flex
                direction='row' justify={"right"}
                w='100%'
                p={2}
                borderRadius="16px"
                mb={'1%'}
            >
                <Button borderRadius={"5px"} color={"white"} bg={"#424ea8"} _hover={{ bg: "#1c2147" }} height={"45px"} onClick={exportVacations}>
                    <Text mr={2}>
                        Export
                    </Text>
                    <BsFileEarmarkExcelFill />
                </Button>

            </Flex>
            <Flex flexDirection={"column"} bg="gray.200" p={2} borderRadius={"16px"} >
                <Flex align="center"    >
                    <Select2
                        options={optionsUsers}
                        value={optionsUsers.find(option => option.value === userSearch)}
                        onChange={(selectedOption) => {
                            handleSelectChange('user', selectedOption);
                            setCurrentPage(1);
                        }}
                        styles={customStyles}
                        isSearchable
                        filterOption={filterOptions}
                        placeholder="Select agent"
                        key={`userSelect-${resetKey}`}
                    />

                    <Box height={"max-content"} width={"max-content"} >
                        <Button onClick={clearFilters} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                            <AiOutlineClear size={'26px'} />
                        </Button>
                    </Box>
                </Flex>

            </Flex>

        </Flex>
    );
}
export default VacationsFilters; 