import './assets/css/App.css';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import AdminLayout from './layouts/admin';
import SignInDefault from 'views/auth/signIn/SignInDefault';
import axios from 'axios';


import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme';
import "assets/css/test.css"
import React, { useEffect, useState } from "react";
import FormHiringMexicoRemote from 'views/admin/main/users/candidates';
import FormHiringRDRemote from 'views/admin/main/users/candidates/indexRD';
import FormHiringVacancies from 'views/admin/main/users/candidates/indexVacancies';
import Alerts from 'views/admin/main/others/404';
import ForgotPassword from 'views/auth/forgotPassword/ForgotPasswordDefault';
import FormHiringOffice from 'views/admin/main/users/candidates/indexP';
import UserInfoMX from 'views/admin/main/users/UserInfo';
import UserInfoMXRemote from 'views/admin/main/users/UserInfo/indexRemote';
import UserInfoBZ from 'views/admin/main/users/UserInfo/indexBelice';
import UserInfoRD from 'views/admin/main/users/UserInfo/indexRD';
import UserInfoColombia from 'views/admin/main/users/UserInfo/indexColombia';
// Chakra imports

export default function Main() {

  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  const tokenKey = 'token';
  const expirationKey = 'expires_at';

  const token = localStorage.getItem(tokenKey);
  const expiration = localStorage.getItem(expirationKey);

  if (token && expiration) {
    const currentTimestamp = new Date().getTime() / 1000;
    const expirationTimestamp = new Date(expiration).getTime() / 1000;

    if (currentTimestamp < expirationTimestamp) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      localStorage.removeItem(tokenKey);
      localStorage.removeItem(expirationKey);
    }
  }

  const location = useLocation();

  const redirectToTargetUrl = () => {
    const targetUrl = localStorage.getItem('targetUrl');
    if (targetUrl && !targetUrl.startsWith("admin/*")) {
      localStorage.removeItem('targetUrl');
      return <Navigate to={targetUrl} />;
    } else {
      return <Navigate to="/" />;
    }
  };

  useEffect(() => {
    if (!token) {
      if (location.pathname.startsWith("admin/*")) {
        localStorage.setItem('targetUrl', location.pathname);
      }
    }
  }, [token, location]);


  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        {token ? (
          <>
            <Route path="/" element={<AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate to="/login" />} />
          </>
        )}
        <Route
          path="/login"
          element={
            <SignInDefault />
          }
        />
        <Route path='/5d3fdd6cf42ee5e2a02715888b593858' element={<FormHiringOffice />} />
        <Route path="/c0f2046d3586a2d0fc6da62c342754d0" element={<FormHiringMexicoRemote />} />
        <Route path="/29fa1a079b7d63df4494cde2a97714d1" element={<FormHiringRDRemote />} />
        <Route path="/b6abfa96bf5505de104c1da58cbf3ab5" element={<FormHiringVacancies />} />

        {token ? (
          <>
            <Route path="/665d63aad693f7feae395aa6f7aa3e40" element={<UserInfoMX />} />
          </>
        ) : (
          <>
            <Route path="/665d63aad693f7feae395aa6f7aa3e40" element={<Navigate to="/login" />} />
          </>
        )}

        {token ? (
          <>
            <Route path="/2c1848fa64a105251fdc39741e7e3d4e" element={<UserInfoMXRemote />} />
          </>
        ) : (
          <>
            <Route path="/2c1848fa64a105251fdc39741e7e3d4e" element={<Navigate to="/login" />} />
          </>
        )}

        {token ? (
          <>
            <Route path="/7568ae25ee1adb236ced1386a10a9e84" element={<UserInfoBZ />} />
          </>
        ) : (
          <>
            <Route path="/7568ae25ee1adb236ced1386a10a9e84" element={<Navigate to="/login" />} />
          </>
        )}

        {token ? (
          <>
            <Route path="/6563648f8280b50c6db45593faf7a7d2" element={<UserInfoRD />} />
          </>
        ) : (
          <>
            <Route path="/6563648f8280b50c6db45593faf7a7d2" element={<Navigate to="/login" />} />
          </>
        )}

        {token ? (
          <>
            <Route path="/f17d34c9bb31c05210e1957ed05e8a0b" element={<UserInfoColombia />} />
          </>
        ) : (
          <>
            <Route path="/f17d34c9bb31c05210e1957ed05e8a0b" element={<Navigate to="/login" />} />
          </>
        )}

        <Route path="/change-password" element={<ForgotPassword />} />


        {token ? (
          <>
            <Route path="admin/*" element={<AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />} />
          </>
        ) : (
          <>
            <Route path="admin/*" element={redirectToTargetUrl()} />
          </>
        )}
        <Route path="*" element={<Alerts />} />
      </Routes>
    </ChakraProvider>
  );
}

