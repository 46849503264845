import axios from 'axios';
import { useState } from 'react';

export const useUserData = () => {
  const [user, setUser] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get("/user");
      const userData = response.data.user;
      setUser(userData);
    } catch (error) {
      console.error('Error al obtener datos del usuario:', error);
    }
  };

  return { user, fetchData };
};
