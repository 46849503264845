import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Text, Flex, Box } from '@chakra-ui/react';
import React, { useEffect, useState } from "react";

function Modalbreaktime({ isOpen, onClose, data, endBreak }) {
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        let interval;

        const startInterval = () => {
            interval = setInterval(() => {
                setCounter((prevCounter) => prevCounter + 1);
            }, 1000);
        };

        if (isOpen) {
            startInterval();
        } else {
            setCounter(0);
        }

        return () => clearInterval(interval);
    }, [isOpen]);

    const formatTime = (seconds) => {
        const hh = Math.floor(seconds / 3600);
        const mm = Math.floor((seconds % 3600) / 60);
        const ss = seconds % 60;
        return `${String(hh).padStart(2, '0')}:${String(mm).padStart(2, '0')}:${String(ss).padStart(2, '0')}`;
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'5xl'} closeOnOverlayClick={false} closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent className='timermodalcont'>
                <ModalHeader className='timermodalheader'>
                    <Text className='textmodaltimerFS' > Break time </Text>
                </ModalHeader>
                <ModalBody className='timermodalbody'>
                    <Text className='texttimermodalbody'>   {formatTime(counter)} </Text>
                </ModalBody>
                <ModalFooter className='timermodalfooter'>
                    <Button onClick={endBreak} class='btnendbreak'>
                        <Text>End Break</Text>
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default Modalbreaktime;
