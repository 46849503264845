import React from 'react';
import {
    Table, Tbody, Tr, Th, Td, TableContainer,
    Button, ButtonGroup, Text, Flex,
} from '@chakra-ui/react'
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

function OnIncidentList({ incident, total, totalPages, currentPage, setCurrentPage, dataIncident }) {

    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'
            >
                <Flex
                    direction='column'
                    w='100%'
                    p={3}
                    borderRadius="16px"
                >

                    <Flex display={'flex'} justifyContent={'center'} mt={5}>
                        {total === 0 ? (
                            <>
                                <Flex bg={'#f2f2f2'} width={'130px'} display={'flex'} justifyContent={'center'}>
                                    <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                        No IT issues
                                    </Text>
                                </Flex>
                            </>
                        ) : (
                            <>
                                <Flex bg={'#f2f2f2'} width={'130px'} borderRadius={"5px"} display={'flex'} justifyContent={'center'}>
                                    <Text fontSize={'14px'} mt={1} mr={1} color={"#1c2147"} fontWeight={'bold'}>
                                        {total}
                                    </Text>
                                    <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                        {total === 1 ? 'IT issue' : 'IT issues'}
                                    </Text>
                                </Flex>
                            </>
                        )}
                    </Flex>
                </Flex>
                <Table size={'sm'}>
                    <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                        {incident && incident.length > 0 ? (
                            incident.map((incidentItem, index) => {
                                const dataIncidentItem = dataIncident[index] || { delay: '', incidentTimer: '' };

                                let color;
                                if (dataIncidentItem.incidentTimer < 5) {
                                    color = "#7080fc";
                                } else if (dataIncidentItem.incidentTimer <= 10) {
                                    color = "#fabb1c";
                                } else {
                                    color = "#fc7466";
                                }

                                return (
                                    <Tr key={incidentItem.id} borderBottom={"2px"} borderColor={"#cccccc"}>
                                        <Td textAlign={'center'} fontSize={'12px'}>
                                            <Flex direction={'column'}>
                                                <Text >
                                                    {incidentItem.users.name.split(' ')[0]} {incidentItem.users.last_name.split(' ')[0]}
                                                </Text>
                                                <Text>
                                                    {incidentItem.hour}
                                                </Text>
                                            </Flex>
                                        </Td>
                                        <Td textAlign={'center'} style={{ color: color, fontSize: '12px' }}>
                                            {dataIncidentItem.incidentTimer}min
                                        </Td>
                                        <Td textAlign={'center'} style={{ color: '#7080fc', fontSize: '12px' }}>
                                            {(() => {
                                                switch (incidentItem.id_setting) {
                                                    case 14:
                                                        return "VPN issues";
                                                    case 15:
                                                        return "Remote Issues";
                                                    case 16:
                                                        return "System Issues";
                                                    case 17:
                                                        return "Password Issues";
                                                    case 18:
                                                        return "Credentials Issues";
                                                    case 19:
                                                        return "Lost Connection";
                                                    case 20:
                                                        return "Audio Issues";
                                                    case 21:
                                                        return "Internet Outage";
                                                    case 22:
                                                        return "Lost Power";
                                                    case 23:
                                                        return "PC Restarted";
                                                    case 24:
                                                        return "PC Updated";
                                                    case 25:
                                                        return "PC didnt Turn On";
                                                    case 26:
                                                        return "Error Displays";
                                                    case 27:
                                                        return "Headsets Issues";
                                                    case 28:
                                                        return "Keyboard Issues";
                                                    case 29:
                                                        return "Hardware Issues";
                                                    case 30:
                                                        return "Other";
                                                    default:
                                                        return "----";
                                                }
                                            })()}
                                        </Td>
                                    </Tr>
                                );
                            })
                        ) : (
                            <Tr>
                                <Th colSpan={'9'}>
                                    <Text
                                        color='#1c2147'
                                        fontSize={"25px"}
                                        textAlign={"center"}
                                        paddingTop={"15px"}
                                        paddingBottom={"15px"}
                                        bg={"#f2f2f2"}
                                        textTransform={'none'}
                                        fontFamily={'DM Sans'}
                                        letterSpacing="-1px"
                                    >
                                        No data
                                    </Text>
                                </Th>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </Flex>
            <ButtonGroup mt={4} variant="outline" borderRadius={'10px'} mb={'2'} justifyContent={'center'} display={'flex'}>
                <Button
                    borderRadius={'10px'}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    isDisabled={currentPage === 1}
                    bg="#cccccc"
                    color={"white"}
                    _hover={{
                        background: "#808080",
                    }}
                    _active={{
                        background: "#808080",
                    }}
                >
                    <MdChevronLeft fontSize="25px" />
                </Button>
                <Text width={'50px'} fontSize={'14px'} ml={4} p={2}>{currentPage} of {totalPages}</Text>
                <Button
                    ml={4}
                    borderRadius={'10px'}
                    onClick={() => setCurrentPage(currentPage + 1)}
                    isDisabled={currentPage === totalPages}
                    bg="#cccccc"
                    color={"white"}
                    _hover={{
                        background: "#808080",
                    }}
                    _active={{
                        background: "#808080",
                    }}
                >
                    <MdChevronRight fontSize="25px" />
                </Button>
            </ButtonGroup>

        </TableContainer>
    );
}
export default OnIncidentList; 