import React, { useState, useEffect } from 'react';
import {
    Button,
    Flex, Input
} from '@chakra-ui/react'
import { RepeatIcon } from '@chakra-ui/icons'
import Select2 from 'react-select'
import { AiOutlineClear } from "react-icons/ai";
import 'jspdf-autotable';
function FiltersReportTS({ clientSearch, usersClients, optionsUsers, userTypeSearch, handleSelectChangeF, setCurrentPage, customStyles, resetKey, filterOptions, optionClients, handleClientChange, customStylesClient, startDateFilter, setStartDateFilter, setTypeSearch, endDateFilter, setEndDateFilter, clearFilters }) {

    return (

        <Flex
            direction='column'
            w='100%'
            bg="gray.200"
            p={2}
            borderRadius="16px"
        >
            <Flex align="center">
                <Select2
                    options={clientSearch > 0 ? usersClients : optionsUsers}
                    value={clientSearch > 0 ? usersClients.find(option => option.value === userTypeSearch) : optionsUsers.find(option => option.value === userTypeSearch)}
                    onChange={(selectedOption) => {
                        handleSelectChangeF('user', selectedOption);
                        setCurrentPage(1);
                    }}
                    styles={customStyles}
                    placeholder="Select operator"
                    key={`userSelect-${resetKey}`}
                    isSearchable
                    filterOption={filterOptions}
                />
                <Select2
                    options={optionClients}
                    value={optionClients.filter(option => clientSearch.includes(option.value))}
                    onChange={(selectedOptions) => {
                        handleClientChange(selectedOptions);
                        setCurrentPage(1);
                    }}
                    styles={customStylesClient}
                    isMulti  // Enable multi-select
                    placeholder="Select client"
                    key={`clientSelect-${resetKey}`}
                />
                <Flex
                    border={'1px solid #808080'}
                    display={'flex'}
                    borderRadius={'4px'}
                    ml={4} mr={4}
                >
                    <Input
                        width={'100%'}
                        border={'0px'}
                        type="date"
                        fontSize={"sm"}
                        value={startDateFilter}
                        onChange={(e) => {
                            setStartDateFilter(e.target.value);
                            setTypeSearch('between');
                            setCurrentPage(1);
                        }}
                        _focusVisible={'none'}
                        color={'#808080'}
                    />
                    <Button bg={'none'} color={'#808080'} onClick={() => {
                        setStartDateFilter('');
                        setTypeSearch('week');
                    }}>
                        <RepeatIcon />
                    </Button>
                </Flex>
                <Flex
                    border={'1px solid #808080'}
                    display={'flex'}
                    borderRadius={'4px'}
                    mr={4}
                >
                    <Input
                        width={'100%'}
                        border={'0px'}
                        type="date"
                        fontSize={"sm"}
                        value={endDateFilter}
                        onChange={(e) => {
                            setEndDateFilter(e.target.value);
                            setTypeSearch('between');
                            setCurrentPage(1);
                        }}
                        _focusVisible={'none'}
                        color={'#808080'}
                    />
                    <Button bg={'none'} color={'#808080'} onClick={() => {
                        setEndDateFilter('');
                        setTypeSearch('week');
                    }}>
                        <RepeatIcon />
                    </Button>
                </Flex>
                <Button bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" onClick={clearFilters}>
                    <AiOutlineClear size={'26px'} />
                </Button>
            </Flex>
        </Flex>

    );
}
export default FiltersReportTS; 