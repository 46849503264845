import axios from 'axios';
import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Text, Button,
    Flex, Input
} from '@chakra-ui/react';
import { AiOutlineClear } from "react-icons/ai";
import { RepeatIcon } from '@chakra-ui/icons'
import { BsSuitDiamondFill } from "react-icons/bs";

function ModalWeeklyBonus({ isOpen, onClose }) {

    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [weeklyData, setWeeklyData] = useState([]);

    const clearFilters = () => {
        setStartDateFilter('');
        setEndDateFilter('');
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `/userBono?`
                + `${startDateFilter ? `&startDate=${startDateFilter.trim()}` : ''}`
                + `${endDateFilter ? `&endDate=${endDateFilter.trim()}` : ''}`
            );
            const responseData = response.data;
            setWeeklyData(responseData);
        } catch (error) {
            console.error('Error fetching schedules:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [startDateFilter, endDateFilter]);


    const getColorborder = (selectedIncidentId) => {
        const colorMap = {
            'bronze': '#bf957e',
            'silver': '#b3b3b3',
            'gold': '#fcd36e',
            'diamond': '#7a86e6',
        };
        return colorMap[selectedIncidentId] || '#bf957e';
    };

    const getText = (selectedIncidentId) => {
        const colorMap = {
            'bronze': 'Bronze',
            'silver': 'Silver',
            'gold': 'Gold',
            'diamond': 'Diamond',
        };
        return colorMap[selectedIncidentId] || 'Bronze';
    };

    const getIconsByCategory = (category) => {
        let count;
        switch (category) {
            case 'bronze':
                count = 1;
                break;
            case 'silver':
                count = 2;
                break;
            case 'gold':
                count = 3;
                break;
            case 'diamond':
                count = 4;
                break;
            default:
                count = 0;
        }

        return Array(count).fill(<BsSuitDiamondFill />);
    }


    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'} closeOnOverlayClick={false} closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody>
                    <Flex direction={'column'} justifyContent={'center'} alignItems={'center'} mt={'10%'}>
                        <Flex align="center" width={"max-content"}>
                            <Flex
                                border={'1px solid #808080'}
                                display={'flex'}
                                borderRadius={'4px'}
                                ml={4} mr={4}
                            >
                                <Input
                                    width={'100%'}
                                    border={'0px'}
                                    type="date"
                                    fontSize={"sm"}
                                    value={startDateFilter}
                                    onChange={(e) => {
                                        setStartDateFilter(e.target.value);
                                    }}
                                    _focusVisible={'none'}
                                    color={'#808080'}
                                />
                                <Button bg={'none'} color={'#808080'} onClick={() => {
                                    setStartDateFilter('');
                                }}>
                                    <RepeatIcon />
                                </Button>
                            </Flex>
                            <Flex
                                border={'1px solid #808080'}
                                display={'flex'}
                                borderRadius={'4px'}
                                mr={4}
                            >
                                <Input
                                    width={'100%'}
                                    border={'0px'}
                                    type="date"
                                    fontSize={"sm"}
                                    value={endDateFilter}
                                    onChange={(e) => {
                                        setEndDateFilter(e.target.value);
                                    }}
                                    _focusVisible={'none'}
                                    color={'#808080'}
                                />
                                <Button bg={'none'} color={'#808080'} onClick={() => {
                                    setEndDateFilter('');
                                }}>
                                    <RepeatIcon />
                                </Button>
                            </Flex>
                            <Button bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" onClick={clearFilters}>
                                <AiOutlineClear size={'26px'} />
                            </Button>
                        </Flex>
                        <Flex mt={'5%'}>
                            <Text color={'#1c2147'} fontSize={'18px'} fontWeight={'bold'}>
                                If you have any doubts, please contact your
                                <Text as="span" color={'#9ba4ec'}> supervisor.</Text>
                            </Text>
                        </Flex>
                        {weeklyData.length > 0 ? (
                            <Flex
                                border={'2px solid'}
                                borderColor={getColorborder(weeklyData[0]?.category || 'bronze')}
                                display={'flex'}
                                width={'50%'}
                                pb={'5%'}
                                mt={'5%'}
                                borderRadius={'10px'}
                                direction={'column'}
                            >

                                <Flex key={weeklyData[0].id} display={'flex'} direction={'column'} alignItems={'center'}>
                                    <Flex bg={'#f2f2f2'} borderRadius={'5px'} width={'70%'} height={'40px'} justifyContent={'center'} mt={'9%'}>
                                        <Text fontSize={'20px'} color={'#1c2147'} justifyContent={'center'} mt={'2%'} fontWeight={'bold'}>
                                            {weeklyData[0].start_week} - {weeklyData[0].end_week}
                                        </Text>
                                    </Flex>
                                    <Flex className='bono'>
                                        {weeklyData[0].bono}
                                    </Flex>
                                    <Flex fontSize={'25px'} color={'#1c2147'} fontWeight={'bold'}>
                                        POINTS
                                    </Flex>
                                    {weeklyData[0].bono === null ? null : (
                                        <>
                                            <Flex fontSize={'25px'} color={getColorborder(weeklyData[0]?.category || 'bronze')} fontWeight={'bold'} mt={'1%'} direction={'row'}>
                                                {getIconsByCategory(weeklyData[0]?.category || 'bronze')}
                                            </Flex>
                                            <Flex fontSize={'25px'} color={getColorborder(weeklyData[0]?.category || 'bronze')} fontWeight={'bold'} mt={'1%'}>
                                                {getText(weeklyData[0]?.category || 'bronze')}
                                            </Flex>
                                        </>
                                    )}
                                    <Flex fontSize={'15px'} color={'#b3b3b3'} fontWeight={'bold'} mt={'1%'}>
                                        {weeklyData[0].name.split(' ')[0]} {weeklyData[0].last_name.split(' ')[0]}
                                    </Flex>
                                </Flex>
                            </Flex>
                        ) : null}



                        <Flex display={'flex'} justifyContent={'center'} mt={'5%'} mb={'5%'}>
                            <Button
                                size={'md'}
                                color={"white"}
                                bg={"#7080fc"}
                                borderRadius={'5px'}
                                _hover={{ background: "#fc7466", color: 'white' }}
                                onClick={onClose}
                            >
                                Close
                            </Button>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ModalWeeklyBonus;
