import React from 'react';
import {
    Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Button, ButtonGroup,
    Flex, Box, Badge,
    Text,
} from '@chakra-ui/react'
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { IoTicketOutline } from "react-icons/io5";
import 'react-datepicker/dist/react-datepicker.css';
import TranscriptTicket from './transcript';
import 'jspdf-autotable';
import MyTicketsCompletedButtons from './ItemsMyticketsCompleted/Buttons';

function TicketsCompletedList({ incident, getColorSchemeForPriority, loading, handleTranscript, total, setCurrentPage, currentPage, totalPages, openTranscript, closeTranscriptModal, selectIncident }) {
    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'
            >
                <Flex
                    direction='column'
                    w='100%'
                    p={3}
                    borderRadius="16px"
                >

                </Flex>

                <br />
                <Table size={'sm'}>
                    <Thead bg={"white"}>
                        <Tr>
                            <Th color='#808080' textAlign={'center'}>Ticket #</Th>
                            <Th color='#808080' textAlign={'center'}>Employee</Th>
                            <Th color='#808080' textAlign={'center'}>Start Time</Th>
                            <Th color='#808080' textAlign={'center'}>End Time</Th>
                            <Th color='#808080' textAlign={'center'}>Status</Th>
                            <Th color='#808080' textAlign={'center'}>Supervisor</Th>
                            <Th textAlign={'center'}>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                        {incident && incident.length > 0 ? (
                            incident.map((ic, index) => (
                                <Tr key={ic.id} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>
                                    <Td
                                        textAlign={'center'}
                                    >
                                        {ic.id}
                                    </Td>
                                    <Td textAlign={'center'} whiteSpace={'pre-wrap'} width={'260px'}>
                                        {ic.operator} {ic.operatorL} <br />
                                        <span style={{ color: '#424ea8' }}>
                                            {ic.client ? ic.client : 'No client'}
                                        </span> <br />
                                        <Badge
                                            backgroundColor={getColorSchemeForPriority(ic.chat_room_id)}
                                            color='white'
                                            mt={1}
                                            width={'100px'}
                                            fontSize={'10px'}
                                            mb={1}
                                            borderRadius={'5px'}
                                        >
                                            {(() => {
                                                switch (ic.chat_room_id) {
                                                    case 1:
                                                        return "IT";
                                                    case 2:
                                                        return "HR";
                                                    case 3:
                                                        return "Hiring";
                                                    case 4:
                                                        return "OnCall";
                                                    case 5:
                                                        return "Schedules";
                                                    case 6:
                                                        return "Finances";
                                                    case 7:
                                                        return "Calls";
                                                    case 8:
                                                        return "Supervisors";
                                                    default:
                                                        return "IT";
                                                }
                                            })()}
                                        </Badge>
                                    </Td>
                                    <Td
                                        textAlign={'center'}
                                    >
                                        {ic.startDate} <br />
                                        {ic.startHour}
                                    </Td>
                                    <Td
                                        textAlign={'center'}
                                    >
                                        {ic.endDate ? ic.endDate : '---'} <br />
                                        {ic.endHour ? ic.endHour : ''}
                                    </Td>

                                    <Td textAlign={'center'}>
                                        {ic.status === 1 ? (
                                            <Badge
                                                backgroundColor={'#7080fc'}
                                                color='white'
                                                mt={1}
                                                width={'100px'}
                                                fontSize={'10px'}
                                                borderRadius={'5px'}
                                            >
                                                Ticket finished
                                            </Badge>
                                        ) : (
                                            <Badge
                                                backgroundColor={'#fc7466'}
                                                color='white'
                                                mt={1}
                                                width={'100px'}
                                                fontSize={'10px'}
                                                borderRadius={'5px'}
                                            >
                                                On incident
                                            </Badge>
                                        )}
                                    </Td>
                                    <Td textAlign={'center'}> <Text>{ic.supervisor ? ic.supervisor : '---'} {ic.supervisorL ? ic.supervisorL : '---'}</Text></Td>
                                    <Td textAlign={'center'}>
                                        <MyTicketsCompletedButtons
                                            handleTranscript={handleTranscript}
                                            loading={loading}
                                            ic={ic}
                                        />
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Th colSpan={'9'} >
                                    <Text
                                        color='#1c2147'
                                        fontSize={"25px"}
                                        textAlign={"center"}
                                        paddingTop={"15px"}
                                        paddingBottom={"15px"}
                                        bg={"#f2f2f2"}
                                        textTransform={'none'}
                                        fontFamily={'DM Sans'}
                                        letterSpacing="-1px"
                                    >
                                        No data
                                    </Text>
                                </Th>
                            </Tr>
                        )}
                    </Tbody>
                </Table>

            </Flex>
            <Box width={"100%"} display={"flex"} mt={"30px"}>
                <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                    {total === 0 ? (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Box mt={'5px'} mr={2} ml={4}>
                                    <IoTicketOutline color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    No tickets
                                </Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                                    {total}
                                </Text>
                                <Box mt={'5px'} mr={1}>
                                    <IoTicketOutline color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    {total === 1 ? 'Ticket' : 'Tickets'}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Flex>
                <ButtonGroup isAttached variant="outline" ml={"34%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
                    <Button
                        borderRadius={'0px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                    <Button
                        borderRadius={'0px'} ml={4}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowRightShort fontSize="20px" />
                    </Button>
                </ButtonGroup>
            </Box>

            {openTranscript && (
                <TranscriptTicket
                    isOpen={openTranscript}
                    onClose={closeTranscriptModal}
                    incident={selectIncident}
                />
            )}
        </TableContainer>
    );
}

export default TicketsCompletedList;
