import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import UseIncidentList from "./componentes/CompletedTickets/FunctionsCompletedTickets";


export default function IncidentsOverview() {
       
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='100px'>
        <UseIncidentList/>
      </Card>
    </Flex>
  );
}
