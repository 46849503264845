import { Flex, Button } from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useState, useEffect } from 'react';
import SchedulesHrs from "./components/itemsMySchedule/tableHrs";
export default function SchedulesHrsOverview() {

  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='100px'>
        <SchedulesHrs/>
      </Card>
    </Flex>
  );
} 