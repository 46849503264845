import React from 'react';
import {
  Table, Thead, Tbody, Tr, Th, Td, TableContainer,
  Button, ButtonGroup,
  Text,
  Flex, Box, Badge,
} from '@chakra-ui/react'
import { FaUsers } from "react-icons/fa";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import RecordingsModal from './modalrecordings';
import Buttons from './buttonsRecordings'
import Filters from './filtersRecordings'

function RecordingList({ clientSearch, usersClients, optionsUsers, userSearch, total, currentPage, scheduleData, handleSelectChange, setCurrentPage, customStyles, totalPages, filterOptions, setFilterDateEnd, optionClients, setFilterDate, resetKey, handleClientChange, filterDate, filterDateEnd, clearFilters, openModalCreate, checkRecording, loading, totalSchedule, modalCreateIsOpen, closeModalCreate, fetchData, user, timeSearch, setTimeSearch }) {


  return (
    <TableContainer mt={"2%"}  >
      <Flex
        direction='column'
        w='100%'
      >
        <Filters
          clientSearch={clientSearch}
          usersClients={usersClients}
          optionsUsers={optionsUsers}
          userSearch={userSearch}
          handleSelectChange={handleSelectChange}
          setCurrentPage={setCurrentPage}
          customStyles={customStyles}
          filterOptions={filterOptions}
          setFilterDateEnd={setFilterDateEnd}
          optionClients={optionClients}
          setFilterDate={setFilterDate}
          resetKey={resetKey}
          handleClientChange={handleClientChange}
          filterDate={filterDate}
          filterDateEnd={filterDateEnd}
          clearFilters={clearFilters}
          openModalCreate={openModalCreate}
          timeSearch={timeSearch}
          setTimeSearch={setTimeSearch}
        />

        <Table size={'sm'} mt={"2%"}>
          <Thead bg={"white"}>
            <Tr>
              <Th textAlign={'center'}>Name</Th>
              <Th textAlign={'center'}>Client</Th>
              <Th textAlign={'center'}>Date</Th>
              <Th textAlign={'center'}>End time</Th>
              <Th textAlign={'center'}>Day</Th>
              <Th textAlign={'center'}>Options</Th>
            </Tr>
          </Thead>
          <Tbody>
            {scheduleData && scheduleData.length > 0 ? (
              scheduleData.map((item, index) => (
                <Tr key={item.id} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    {item.user.name.split(' ')[0]} {item.user.last_name.split(' ')[0]}
                  </Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    <Badge color={"white"} bg={'#424ea8'} borderRadius={'5px'} width={'100px'} fontSize={'10px'} whiteSpace={'pre-wrap'}>
                      {item.clients ? item.clients.name : '---'}
                    </Badge>
                  </Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    {item.date}
                  </Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    {item.time_end_12hr}
                  </Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    {(() => {
                      switch (item.id_day) {
                        case 1:
                          return "Monday";
                        case 2:
                          return "Tuesday";
                        case 3:
                          return "Wednesday";
                        case 4:
                          return "Thursday";
                        case 5:
                          return "Friday";
                        case 6:
                          return "Saturday";
                        case 7:
                          return "Sunday";
                        default:
                          return "Monday";
                      }
                    })()}
                  </Td>
                  <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                    <Buttons item={item} checkRecording={checkRecording} loading={loading} />
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Th colSpan={'9'} >
                  <Text
                    color='#1c2147'
                    fontSize={"25px"}
                    textAlign={"center"}
                    paddingTop={"15px"}
                    paddingBottom={"15px"}
                    bg={"#f2f2f2"}
                    textTransform={'none'}
                    fontFamily={'DM Sans'}
                    letterSpacing="-1px"
                  >
                    No data
                  </Text>
                </Th>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Flex >
      <Box width={"100%"} display={"flex"} mt={"30px"}>
        <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
          {total === 0 ? (
            <>
              <Flex bg={'#f2f2f2'} width={'250px'} display={'flex'} justifyContent={'center'}>
                <Box mt={'5px'} mr={2}>
                  <FaUsers color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                  No recordings
                </Text>


              </Flex>
            </>
          ) : (
            <>
              <Flex bg={'#f2f2f2'} width={'auto'} display={'flex'} justifyContent={'center'}>
                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'} ml={'20px'}>
                  {total}
                </Text>
                <Box mt={'5px'} mr={1}>
                  <FaUsers color={"#1c2147"} fontWeight={'bold'} />
                </Box>
                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'} mr={3}>
                  {total === 1 ? 'Recording' : 'Recordings'}
                </Text>
                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                  {totalSchedule}
                </Text>

              </Flex>
            </>
          )}
        </Flex>
        <ButtonGroup isAttached variant="outline" ml={"26%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
          <Button
            borderRadius={'0px'}
            onClick={() => setCurrentPage(currentPage - 1)}
            isDisabled={currentPage === 1}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowLeftShort fontSize="20px" />
          </Button>
          <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
          <Button
            borderRadius={'0px'} ml={4}
            onClick={() => setCurrentPage(currentPage + 1)}
            isDisabled={currentPage === totalPages}
            bg="#808080" color={"white"}
            _hover={{ background: "#808080", }}
            _active={{ background: "#808080", }}
          ><BsArrowRightShort fontSize="20px" />
          </Button>
        </ButtonGroup>
      </Box>
      <RecordingsModal isOpen={modalCreateIsOpen} onClose={closeModalCreate} fetchData={fetchData} user={user} />

    </TableContainer >
  );
}
export default RecordingList; 