import React, { useState } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input,
    Box, Flex, Heading, SimpleGrid,
    FormControl, FormErrorMessage,
    Image,
    useToast,
} from '@chakra-ui/react';
import avatar4 from 'assets/img/dashboards/grey_world.png';
import { CloseIcon, EditIcon } from '@chakra-ui/icons'

const ModalUpdateMedicalNotes = ({ isOpen, onClose, fetchData, selectedMN }) => {

    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        duration: selectedMN?.duration || '',
        date_start: selectedMN?.date_start || '',
        date_end: selectedMN?.date_end || '',
        description: selectedMN?.description || '',
        date_return: selectedMN?.date_return || '',
    });

    const handleSubmit = async () => {

        const formSubmitData = {
            duration: formData.duration,
            date_start: formData.date_start,
            date_end: formData.date_end,
            description: formData.description,
            date_return: formData.date_return
        };

        try {
            const response = await axios.post(`/medicalNotes/update/${selectedMN.id}`, formSubmitData);

            toast({
                title: 'Medical Note Updated',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchData();
            onClose();
        } catch (error) {
            toast({
                title: "Error Creating a Medical Note",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error creating medical note:', error);
            setFormErrors(error.response.data.errors);
        }
    };
    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };
    return (
        <Modal size="lg" isOpen={isOpen} onClose={() => {
            onClose();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent mt={"5%"} background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalHeader

                    textColor='white'
                    textAlign='center'
                    borderRadius='20px'
                    mx="14"
                    mt={6}
                >
                    <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Image
                            src={avatar4}
                            alt="Avatar"
                            style={{ width: '80px', height: '80px', marginRight: '10px' }}
                        />
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>
                        <Heading as="h2" fontSize="18px" color={'#808080'} textAlign={'center'} fontFamily="DM Sans" position="relative" letterSpacing="1px" mb='6' mt={10}>
                            Edit Medical Note
                        </Heading>
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl isInvalid={!!formErrors.duration}> Duration
                                <Input

                                    type="number"
                                    placeholder="Enter Duration"
                                    name='duration'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.duration}
                                    onChange={handleChange}
                                />
                                {formErrors.duration && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.date_start}>Start Date
                                <Input
                                    type="date"
                                    name='date_start'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.date_start}
                                    onChange={handleChange}
                                />
                                {formErrors.date_start && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.date_end}>End Date
                                <Input
                                    type="date"
                                    name='date_end'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.date_end}
                                    onChange={handleChange}
                                />
                                {formErrors.date_end && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.date_return}> Return Date
                                <Input
                                    type="date"
                                    name='date_return'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.date_return}
                                    onChange={handleChange}
                                />
                                {formErrors.date_return && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.description}> Description
                                <Input

                                    placeholder="Add a description..."
                                    name='description'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    minHeight={"100"}
                                    defaultValue={formData.description}
                                    onChange={handleChange}
                                />
                                {formErrors.description && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Update Note <EditIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default ModalUpdateMedicalNotes;
