import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import UseTSE from "./components/itemsTSC/FunctionsTSE";

export default function ReportsTSEOverview() {
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card>
        <UseTSE />
      </Card>
    </Flex>
  );
} 