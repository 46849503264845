import React, { useState } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input, Select,
    Box, Flex, Heading, SimpleGrid,
    FormControl, FormErrorMessage,
    Image,
    useToast,
} from '@chakra-ui/react';
import avatar4 from 'assets/img/dashboards/grey_world.png';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons'
const UpdateSetting = ({ isOpen, onClose, onSettingUpdated, setting, setFlashing }) => {
    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});

    const clearForm = () => {
        setFormData({
            name: '',
            id_option: ''
        });
        setFormErrors({});
    };

    const [formData, setFormData] = useState({
        name: setting?.name || '',
        id_option: setting?.id_option || '',
    });

    const handleSubmit = async (id) => {
        try {
            const response = await axios.put(`/settings/update/${setting.id}`, formData);
            onSettingUpdated();
            setFlashing(formData);
            toast({
                title: 'Setting Edit Success',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Editing Setting",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Editing Setting:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Modal size="lg" isOpen={isOpen} onClose={() => {
            onClose();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent mt={"8%"} background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalHeader

                    textColor='white'
                    textAlign='center'
                    borderRadius='20px'
                    mx="14"
                    mt={6}
                >
                    <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Image
                            src={avatar4}
                            alt="Avatar"
                            style={{ width: '80px', height: '80px', marginRight: '10px' }}
                        />
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>
                        <Heading as="h2" fontSize="18px" color={'#808080'} textAlign={'center'} fontFamily="DM Sans" position="relative" letterSpacing="1px" mb='6' mt={10}>
                            EDIT SETTING
                        </Heading>
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl isInvalid={!!formErrors.name}>
                                <Input
                                    type="text"
                                    placeholder="Enter setting for edit"
                                    name='name'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    value={formData.name}
                                    onChange={handleChange}
                                    errorBorderColor='#fc7466'
                                />
                                {formErrors.name && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.id_option}>
                                <Select
                                    w='460px'
                                    fontSize={'md'}
                                    borderRadius={'10px'}
                                    name="id_option"
                                    textAlign={"center"}
                                    color={"#cccccc"}
                                    background="white"
                                    value={formData.id_option}
                                    onChange={handleChange}
                                    h='44px'
                                    maxh='44px'
                                    errorBorderColor='#fc7466'
                                >
                                    <option value=''>Select type setting</option>
                                    <option value='1'>IT</option>
                                    <option value='2'>HR</option>
                                    <option value='3'>Hiring</option>
                                    <option value='4'>OnCall</option>
                                    <option value='5'>Schedules</option>
                                    <option value='6'>Finances</option>
                                    <option value='7'>Calls</option>
                                </Select>
                                {formErrors.id_option && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                                <Button padding={"0px"} borderRadius={"50px"} bg={"transparent"} _hover={{ bg: "transparent" }} ml={"94%"} onClick={clearForm} title="Delete All" > <DeleteIcon color={"#808080"} /> </Button>
                            </FormControl>
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Update setting
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default UpdateSetting; 