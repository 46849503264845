import { Box, Flex, Text, Image, Button } from '@chakra-ui/react';
import eriimg from "assets/img/dashboards/Eri.svg";
import React, { useState, useEffect } from "react";

const Carousel = ({ images, openImageViewer, user }) => {

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(prevSlide => (prevSlide + 1) % images.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handlePrevSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide - 1 + images.length) % images.length);
  };

  const handleNextSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide + 1) % images.length);
  };


  if (!user || user.type_user_id === undefined) {
    return (
      <>
        <Box display="flex" flexDirection="column" alignItems="center" height={"320px"} width={"100%"} mb={'5%'} justifyContent="center" position="relative">
          <Flex height={"350px"}
            mx={2}
            borderRadius={"15px"}
            justify={"space-evenly"}
            flexDirection={"column"}
            position={"relative"}
          >
            <Flex bg={"#f2f2f2"} flexDirection={"row"} borderRadius={"30px"} height={"300px"} overflow={"hidden"} alignItems={"center"}>
              <Text className='welcomedashtxt'>Welcome
                <Text className='totheportaltxt'>to the portal!</Text>
              </Text>
              <Image
                width={"450px"}
                height={"450px"}
                src={eriimg}
              />
            </Flex>
          </Flex>
        </Box>
      </>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" height={"320px"} width={"100%"} mb={'5%'} justifyContent="center" position="relative">
      <Button
        position="absolute"
        left="3"
        top="50%"
        transform="translateY(-50%)"
        zIndex="10"
        onClick={handlePrevSlide}
        color={'white'}
        bg={'transparent'}
        _hover={{
          background: "rgba(0, 0, 0, 0.75)",
        }}
        height={'60px'}
        borderRadius={'5px'}
        ml={'1%'}
      >
        &#9664;
      </Button>
      <Box
        display="flex"
        overflow="hidden"
        justifyContent="center"
        flexWrap="nowrap"
        width="800px"
        height="max-content"
      >
        {images.map((src, index) => (
          <Box
            key={index}
            flex="none"
            onClick={() => openImageViewer(index)}
            cursor="pointer"
            margin="0 5px"
            width="95%"
            height="100%"
            style={{
              display: index === currentSlide ? 'block' : 'none',
              transition: 'opacity 0.5s ease-in-out'
            }}
          >
            <Image
              src={src}
              alt={`Image ${index + 1}`}
              objectFit="contain"
              width="100%"
              height="100%"
            />
          </Box>
        ))}
      </Box>
      <Button
        position="absolute"
        right="3"
        top="50%"
        transform="translateY(-50%)"
        zIndex="10"
        onClick={handleNextSlide}
        color={'white'}
        bg={'transparent'}
        _hover={{
          background: "rgba(0, 0, 0, 0.75)",
        }}
        height={'60px'}
        borderRadius={'5px'}
        mr={'1%'}
      >
        &#9654;
      </Button>
    </Box>
  );
};

export default Carousel;
