import {
    Flex,
    Button,
} from '@chakra-ui/react';
import { BiLogOut } from "react-icons/bi";
import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import StartModal from './searchBar/startandchatmobile/ModalStartMobile';
import ModalChatMobile from './searchBar/startandchatmobile/ModalChatMobile';


export default function HeaderLinksMobile(props) {
    const { secondary } = props;
    const BASE_URL = '/logout';
    const navigate = useNavigate();
    const [modalContent, setModalContent] = useState('');
    const [incidentId, setIncidentId] = useState('');
    const [modalSchedule, setModalSchedule] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedIncidentId, setSelectedIncidentId] = useState(null);
    const [modalChat, setModalChat] = useState(false);
    const [modalChatMobile, setModalChatMobile] = useState(false);

    const handleLogout = async () => {
        try {
            const response = await axios.post(BASE_URL);
            if (response.data === 'The Token has been deleted') {
                localStorage.removeItem('token');
                navigate('/login');
            }
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const startIncident = async (chat_room_id) => {
        try {
            const response = await axios.post('/start-incident', { chat_room_id });
            const { message, incident } = response.data;

            setModalContent(`${message}`);
            setIncidentId(`${incident}`);

            // Verificar si chat_room_id es igual a 5
            if (chat_room_id === 5) {
                setModalSchedule(true);
            } else {
                setModalVisible(true);
            }

            setSelectedIncidentId(chat_room_id);

        } catch (error) {
            setModalContent('Something went wrong while starting an incident.');
            setModalVisible(true);
        }
    };


      const openModalChatMobile = () => {
        setModalChatMobile(true);
      };

    return (
        <Flex
            w={{ sm: '100%', md: 'auto' }}
            alignItems="center"
            flexDirection="row"
            p="10px"
        >
            <Button
                class="btnLog"
                onClick={handleLogout}
            >
                <span style={{ flex: 1, textAlign: 'center' }}>Logout account</span>
                <BiLogOut size={'23px'} />
            </Button>

            <Button
                class="btnmaketick"
                onClick={() => startIncident(1)}
            >
                <span style={{ flex: 1, textAlign: 'center' }}>Make a ticket</span>

            </Button>
            {modalVisible && (
                <StartModal isOpen={modalVisible} onClose={() => setModalVisible(false)} modalContent={modalContent} selectedIncidentId={selectedIncidentId} incidentID={incidentId} openModalChatMobile={openModalChatMobile} />
            )}
             {modalChatMobile && (
                <ModalChatMobile  isOpen={modalChatMobile} onClose={() => setModalChatMobile(false)} selectedIncidentId={selectedIncidentId} incidentID={incidentId} />
            )}
        </Flex>


    );
}
