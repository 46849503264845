import axios from 'axios';
import React, { useEffect, useState } from "react";
import {
    Button, FormControl, FormLabel, Flex, useToast, Input, Text, Textarea,
    FormErrorMessage
} from '@chakra-ui/react';
import { EditIcon, CloseIcon } from '@chakra-ui/icons'
import Swal from 'sweetalert2'

const VacationsRequest = () => {

    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        first_day: '',
        last_day: '',
        comments: '',
    });

    const clearForm = () => {
        setFormData({
            name: '',
            email: '',
            first_day: '',
            last_day: '',
            comments: '',
        });
        setFormErrors({});
    };

    const toast = useToast();

    const handleSubmit = async () => {

        const formSubmitData = {
            name: formData.name,
            email: formData.email,
            first_day: formData.first_day,
            last_day: formData.last_day,
            comments: formData.comments,
        };

        try {
            const response = await axios.post('/vacationrequests', formSubmitData);

            toast({
                title: 'Request Sent',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Request Sent",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Request Sent:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Flex mt={4} ml={10} mr={10} direction={'column'}>
            <Flex display={'flex'}>
                <Flex direction={'column'} width={'100%'} mr={'1%'}>
                    <Text color={'gray.400'} mb={'1%'}>
                        Full name:
                    </Text>
                    <FormControl isInvalid={!!formErrors.name} w='400px' >
                        <Input
                            placeholder="Full name"
                            className='custom-placeholderInputs'
                            background="white"
                            value={formData.name}
                            onChange={handleChange}
                            name='name'
                        />
                        {formErrors.name && (
                            <FormErrorMessage mb={"2%"}>{formErrors.name}</FormErrorMessage>
                        )}
                    </FormControl>
                </Flex>

                <Flex direction={'column'} width={'100%'}>
                    <Text color={'gray.400'} mb={'1%'}>
                        Email:
                    </Text>
                    <FormControl isInvalid={!!formErrors.email} w='400px' >
                        <Input
                            placeholder="Dedicated People email"
                            className='custom-placeholderInputs'
                            background="white"
                            value={formData.email}
                            onChange={handleChange}
                            name='email'
                        />
                        {formErrors.email && (
                            <FormErrorMessage mb={"2%"}>{formErrors.email}</FormErrorMessage>
                        )}
                    </FormControl>
                </Flex>
            </Flex>
            <Flex>
                <Flex direction={'column'} width={'100%'} mr={'1%'}>
                    <Text color={'gray.400'} mb={'1%'}>
                        First day of vacation:
                    </Text>
                    <FormControl isInvalid={!!formErrors.first_day} w='400px' >
                        <Input
                            className='custom-placeholderInputs'
                            background="white"
                            type='date'
                            value={formData.first_day}
                            onChange={handleChange}
                            name='first_day'
                        />
                        {formErrors.first_day && (
                            <FormErrorMessage mb={"2%"}>{formErrors.first_day}</FormErrorMessage>
                        )}
                    </FormControl>
                </Flex>

                <Flex direction={'column'} width={'100%'}>
                    <Text color={'gray.400'} mb={'1%'}>
                        Last day of vacation:
                    </Text>
                    <FormControl isInvalid={!!formErrors.last_day} w='400px' >
                        <Input
                            className='custom-placeholderInputs'
                            background="white"
                            type='date'
                            value={formData.last_day}
                            onChange={handleChange}
                            name='last_day'
                        />
                        {formErrors.last_day && (
                            <FormErrorMessage mb={"2%"}>{formErrors.last_day}</FormErrorMessage>
                        )}
                    </FormControl>
                </Flex>
            </Flex>
            <Flex>
                <Flex direction={'column'} width={'100%'}>
                    <Text color={'gray.400'} mb={'1%'}>
                        Comments:
                    </Text>
                    <Textarea
                        value={formData.comments}
                        onChange={handleChange}
                        name='comments' />
                </Flex>
            </Flex>
            <Flex display={'flex'} justifyContent={'center'} mt={'1%'}>
                <Button class="buttonCreate" >  {/* onClick={handleSubmit} */}
                    Submit <EditIcon ml={2} />
                </Button>
            </Flex>
        </Flex>
    );
}

export default VacationsRequest;
