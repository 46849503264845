import { Text, useColorModeValue, Box, Flex, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import TimelineItem from 'components/dataDisplay/TimelineItem';

export default function Default(props) {
  const { ...rest } = props;
  const textColor = useColorModeValue('#1c2147', 'white');
  const [schedules, setSchedules] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [date, setDate] = useState('');
  const [showAll, setShowAll] = useState(false);


  const currentShift = async () => {
    try {
      const response = await axios.get('/schedule/show')
      setSchedules(response.data.schedules);
      setDate(response.data.date);
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Error al obtener datos del usuario:', error);
    }
  }

  useEffect(() => {
    currentShift();
    const alertTimeout = setTimeout(() => {
      setShowAlert(true);
    }, 2000);
    return () => {
      clearTimeout(alertTimeout);
    };
  }, []);

  const dayMappings = {
    1: 'Mon',
    2: 'Tues',
    3: 'Wed',
    4: 'Thur',
    5: 'Fri',
    6: 'Sat',
    7: 'Sun'
  };

  const typeDayMappings = {
    1: 'Regular shift',
    2: 'Regular training',
    3: 'Additional hours',
    4: 'Regular coverage',
    5: 'Regular shadowing',
    6: 'No Shift',
    7: 'Additional training',
    8: 'Additional coverage',
    9: 'Additional shadowing'
  }


  return (
    <Flex className='timelinebox'>
      {schedules.length > 0 ? (
        <Flex display={'flex'} direction={'column'} mt={1}>
          <Text fontSize="30px" fontWeight="700" color={'#1c2147'}>
            Timeline
          </Text>
          <Text fontWeight={"bold"} borderRadius={"10px"} color={"#b3b3b3"}>
            View today’s shift here.
          </Text>
        </Flex>
      ) : (
        null
      )}
      {schedules.length > 0 ? (
        <>
          <Box key={schedules[0].id} width='max-content' mb={'2%'} mt={'5%'}>
            <TimelineItem
              className='timelineminibox'
              boxColor={schedules[0].date === date ? '#424ea8' : 'white'}
              miniCardColor={'#f2f2f2'}
              textColor={schedules[0].date === date ? 'white' : '#1c2147'}
              textSecondaryColor={schedules[0].date === date ? 'white' : '#808080'}
              title={
                schedules[0].status === 1
                  ? typeDayMappings[schedules[0].type_daily]
                  : schedules[0].status === 2
                    ? 'Off'
                    : schedules[0].status === 3
                      ? 'Holiday shift'
                      : schedules[0].status === 11
                        ? 'Surecall O/C'
                        : typeDayMappings[schedules[0].type_daily]
              }
              day={schedules[0].day}
              weekday={dayMappings[schedules[0].id_day]}
              hours={
                schedules[0].status === 2 || schedules[0].type_daily === 6
                  ? '---'
                  : schedules[0].timeS + ' - ' + schedules[0].timeE
              }
              note={schedules[0].description ? schedules[0].description : ''}
              date={schedules[0].dateF}
              colorDate={schedules[0].date === date ? '#b3b3b3' : '#1c2147'}
            />
          </Box>
          {showAll &&
            schedules.slice(1).map(schedule => (
              <Box key={schedule.id} width='max-content' mb={'20px'}>
                <TimelineItem
                  className='timelineminibox'
                  boxColor={schedule.date === date ? '#424ea8' : 'white'}
                  miniCardColor={'#f2f2f2'}
                  textColor={schedule.date === date ? 'white' : '#1c2147'}
                  textSecondaryColor={schedule.date === date ? 'white' : '#808080'}
                  title={
                    schedule.status === 1
                      ? typeDayMappings[schedule.type_daily]
                      : schedule.status === 2
                        ? 'Off'
                        : schedule.status === 3
                          ? 'Holiday shift'
                          : schedule.status === 11
                            ? 'Surecall O/C'
                            : typeDayMappings[schedule.type_daily]
                  }
                  day={schedule.day}
                  weekday={dayMappings[schedule.id_day]}
                  hours={
                    schedule.status === 2 || schedule.type_daily === 6
                      ? '---'
                      : schedule.timeS + ' - ' + schedule.timeE
                  }
                  note={schedule.description ? schedule.description : ''}
                  date={schedule.dateF}
                  colorDate={schedule.date === date ? '#b3b3b3' : '#1c2147'}
                />
              </Box>
            ))}
          <Box
            display={'flex'}
            justifyContent={'center'}
            mt={'5px'}
            width={'470px'}
          >
            <Button
              onClick={() => setShowAll(!showAll)}
              bg="#424ea8"
              _hover={{ background: "#1c2147", color: 'white' }}
              color="white"
              borderRadius={'10px'}
              width={'100%'}
            >
              {showAll ? 'Hide' : 'Show all shifts'}
            </Button>
          </Box>
        </>
      ) : (
        <Box
          display={'flex'}
          justifyContent={'center'}
          mt={'7.1%'}
          bg={'#f2f2f2'}
          borderRadius={'20px'}
          height={'max-content'}
          width='auto'
          alignItems={'center'}
        >
          <Text textAlign={'center'} fontSize={'25px'} p={10} color={'#808080'}>
            Your currently have no schedules, please contact your supervisor.
          </Text>
        </Box>
      )}
    </Flex>

  );
}
