import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import ReportsList from './table';
function UseBilling() {

    const [reportsD, setReportsD] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [clientS, setClientS] = useState([]);
    const [users, setUsers] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const [clientSearch, setClientSearch] = useState('');
    const [userTypeSearch, setUserTypeSearch] = useState('');
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [typeSearch, setTypeSearch] = useState('week');
    const [usersClients, setUsersClients] = useState([]);
    const [reportsE, setReportsE] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [loadingText, setLoadingText] = useState('Loading...');
    const [zIndexValue, setZIndexValue] = useState(-10);


    const optionsUsers = users.map(users => ({
        value: users.id,
        label: `${users.name} ${users.last_name}`,
    }));

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const optionClients = clientS.map(clientS => ({
        value: clientS.id,
        label: clientS.name,
    }));

    const clearFilters = () => {
        setUserTypeSearch(null);
        setClientSearch(null);
        setStartDateFilter('');
        setEndDateFilter('');
        setTypeSearch('week');
        setResetKey(resetKey + 1);
        setCurrentPage(1);
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(`/reports/durations?page=${currentPage}
            ${userTypeSearch ? `&operator=${userTypeSearch}` : ''}
            ${clientSearch ? `&client=${clientSearch}` : ''}
            ${startDateFilter ? `&date_start=${startDateFilter}` : ''}
            ${endDateFilter ? `&date_end=${endDateFilter}` : ''}
            ${typeSearch ? `&date=${typeSearch}` : ''}`
            );
            const responseData = response.data;
            setReportsD(responseData.data.data);
            setClientS(responseData.clients);
            setUsers(responseData.operators);
            setTotalPages(responseData.data.last_page);
            setTotal(responseData.total_data)
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };



    const fetchDataE = async () => {
        try {

            setLoadingText('Loading...');
            setZIndexValue(-10);

            const response = await axios.get(`/reports/durationsExport?` +
                `${userTypeSearch ? `&operator=${encodeURIComponent(userTypeSearch)}` : ''}` +
                `${clientSearch ? `&client=${encodeURIComponent(clientSearch)}` : ''}` +
                `${startDateFilter ? `&date_start=${encodeURIComponent(startDateFilter)}` : ''}` +
                `${endDateFilter ? `&date_end=${encodeURIComponent(endDateFilter)}` : ''}` +
                `${typeSearch ? `&date=${encodeURIComponent(typeSearch)}` : ''}`
            );

            const responseData = response.data;

            if (response.status === 200) {
                alert('You can download now!');
                setLoadingText('Download now!');

                setZIndexValue(100);
            }

            setReportsE(responseData.data);
            setClientS(responseData.clients);
            setUsers(responseData.operators);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };





    const fetchDataClient = async () => {
        try {
            const response = axios.get("/userClient").then((response) => {
            });
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleClientChange = (selectedOption) => {
        setClientSearch(selectedOption.value);

        if (selectedOption) {
            axios
                .get(`/userClient?id_client=${selectedOption.value}`)
                .then((response) => {
                    const userOptions = response.data.data.map((user) => ({
                        label: user.user ? `${user.user.name} ${user.user.last_name}` : '',
                        value: user.id_user,
                    }));
                    setUsersClients(userOptions);
                });
        } else {
            setUsersClients([]);
        }
    };



    useEffect(() => {
        fetchDataE();
        fetchDataClient();
    }, [currentPage, clientSearch, userTypeSearch, startDateFilter, endDateFilter, resetKey, typeSearch]);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            marginLeft: '20px',
            fontSize: '16px',
            height: '40px',
            width: '220px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const handleSelectChangeF = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                setUserTypeSearch(selectedOption.value);
                break;
            case 'client':
                setClientSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const handleDownloadExcel = () => {
        try {

            const selectedClient = optionClients.find(option => option.value === clientSearch);
            const clientName = selectedClient ? selectedClient.label : '';

            const startDate = startDateFilter || '';
            const endDate = endDateFilter || '';

            const fileName = `Reports_${clientName}_${startDate}_${endDate}.xlsx`;

            const ws = XLSX.utils.table_to_sheet(document.getElementById('ticketsTable'));
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

            const cols = [];
            cols.push({ wch: 47 });
            const numberOfColumns = XLSX.utils.decode_range(ws['!ref']).e.c;

            for (let i = 1; i <= numberOfColumns; i++) {
                if (i === 3) {
                    cols.push({ wch: 10 });
                } else if (i === 4) {
                    cols.push({ wch: 15 });
                } else {
                    cols.push({ wch: 27 });
                }
            }
            ws['!cols'] = cols;

            XLSX.writeFile(wb, fileName);
        } catch (error) {
            console.error('Error downloading Excel:', error);
        }
    };


    const downloadTableAsPDF = async () => {
        try {
            setLoading(true);

            const tableRef = document.getElementById('ticketsTable');
            const pdf = new jsPDF({ orientation: 'landscape' });
            const styles = {
                valign: 'middle',
                halign: 'center',
            };

            pdf.autoTable({ html: '#ticketsTable', styles });

            const selectedClient = optionClients.find(option => option.value === clientSearch);
            const clientName = selectedClient ? selectedClient.label : '';

            const startDate = startDateFilter || '';
            const endDate = endDateFilter || '';

            const fileName = `reports_${clientName}_${startDate}_${endDate}.pdf`;

            pdf.save(fileName);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ReportsList
        totalPages={totalPages}
        currentPage={currentPage}
        total={total}
        reportsE={reportsE}
        loadingText={loadingText}
        reportsD={reportsD}
        setEndDateFilter={setEndDateFilter}
        endDateFilter={endDateFilter}
        setStartDateFilter={setStartDateFilter}
        setTypeSearch={setTypeSearch}
        startDateFilter={startDateFilter}
        resetKey={resetKey}
        setCurrentPage={setCurrentPage}
        userTypeSearch={userTypeSearch}
        zIndexValue={zIndexValue}
        clientSearch={clientSearch}
        usersClients={usersClients}
        optionsUsers={optionsUsers}
        filterOptions={filterOptions}
        optionClients={optionClients}
        clearFilters={clearFilters}
        fetchData={fetchData}
        fetchDataE={fetchDataE}
        fetchDataClient={fetchDataClient}
        handleClientChange={handleClientChange}
        customStyles={customStyles}
        handleSelectChangeF={handleSelectChangeF}
        handleDownloadExcel={handleDownloadExcel}
        downloadTableAsPDF={downloadTableAsPDF}/>
    )
}
    export default UseBilling;