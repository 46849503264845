import {
    Button, Select, Flex, Box, Input, InputGroup, Text, InputRightElement
} from '@chakra-ui/react'

import React from 'react';
import { BiWorld } from "react-icons/bi";
import { AddIcon } from '@chakra-ui/icons'
import { RiFileExcel2Fill } from "react-icons/ri";

const UserHeaderButtons = ({ userAuth, openModalCreate, handleDownloadExcel, handleSelectChange, opciones, opcionSeleccionada, handleCopyClick }) => {

    return (
        <>
            <Box p={'1%'}>
                <Flex align="center" justifyContent="flex-end"  >
                    {(userAuth === 1 || userAuth === 7 || userAuth === 8 || userAuth === 24 || userAuth === 25 || userAuth === 37) ? (
                        <Button class="buttonCreate" onClick={openModalCreate} borderRadius={'5px'}>
                            Add user <AddIcon ml={2} />
                        </Button>

                    ) : null}
                    {(userAuth === 1 || userAuth === 7 || userAuth === 8 || userAuth === 37) ? (

                        <Button
                            color={"white"}
                            bg={"#424ea8"}
                            borderRadius={'10px'}
                            mr={3}
                            height={'45px'}
                            _hover={{ background: "#1c2147", color: 'white' }}
                            onClick={handleDownloadExcel}
                        >
                            <Text mr={2}>
                                Excel
                            </Text>
                            <RiFileExcel2Fill />
                        </Button>
                    ) : null}
                </Flex>
                {/* {(userAuth === 1 || userAuth === 7 || userAuth === 8 || userAuth === 9 || userAuth === 37) ? (
                    <Flex mt={4} style={{ display: 'flex', justifyContent: 'flex-end' }}   >
                        <Box
                            display="flex"
                            alignItems="center"
                            bg="#f2f2f2"
                            width="auto"
                            borderRadius={'5px'}
                            overflow="hidden"
                            mr={6}
                        >
                            <BiWorld color="gray.400" size={'25px'} style={{ marginLeft: '8px' }} />
                            <Select
                                id="seleccion"
                                onChange={handleSelectChange}
                                _focusVisible={'none'}
                                _hover={{
                                    borderColor: "none",
                                }}
                            >
                                {opciones.map((opcion) => (
                                    <option key={opcion.value} value={opcion.value}>
                                        {opcion.label}
                                    </option>
                                ))}
                            </Select>
                        </Box>
                        <InputGroup width={'20%'} bg={'#f2f2f2'} borderRadius={'5px'} >
                            <Input type="text" id="textoInput" borderRadius="16px" value={opcionSeleccionada} _focusVisible={'none'} border={'0px'} readOnly />
                            <InputRightElement width="4.5rem">
                                <Button bg="#1c2147" color={"white"} h="1.75rem" size="sm" onClick={handleCopyClick} _hover={{
                                    background: "#808080",
                                }} borderRadius={'5px'} mr={4}>
                                    Copy
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </Flex>
                ) : null} */}
            </Box>
        </>
    );
}

export default UserHeaderButtons;
