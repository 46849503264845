// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Text,
  FormErrorMessage
} from '@chakra-ui/react';

// Custom components
import DefaultAuth from 'layouts/auth/types/Default';

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

// Assets
import illustration from 'assets/img/auth/DP.png';

function ForgotPassword() {
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const token = localStorage.getItem('token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  const handleChangePassword = async () => {
    
    try {
      setPasswordError('');

      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }

      const response = await axios.post('/changePassword', {
        password: password,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          withCredentials: true,
        },
      });

      if (response.status === 200) {
        navigate('/admin');
      }
    } catch (error) {
      console.error(error);

      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;
        setPasswordError(errors.password ? errors.password.join(', ') : '');
      }
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        me="auto"
        h="100%"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box mt={'100px'}>
          <Heading
            color={textColor} fontSize="60px" align='center'
          >
            Change your password
          </Heading>
          <Text
            mt="25px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
            textAlign='center'
          >
            Please create a new password for your account.
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '40px', md: '250px' }}
        >
          <FormControl isInvalid={!!passwordError} m={'100px'}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              color={"#1c2147"}
              fontWeight='bold'
              mb="8px"
            >
              New Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup>
              <Input
                fontSize="sm"
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter your new password"
                size="lg"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                errorBorderColor='red.300'
              />
              <InputRightElement width="4.5rem" m={1} mr={6}>
                <Button
                  h="1.75rem"
                  size="sm"
                  borderRadius={'5px'}
                  bg={'transparent'}
                  _hover={{ background: "#1c2147", color: 'white' }}
                  color={'#808080'}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <>
                      Hide <ViewOffIcon ml={2} />
                    </>
                  ) : (
                    <>
                      Show <ViewIcon ml={2} />
                    </>
                  )}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{passwordError}</FormErrorMessage>
            <Button
              fontSize="sm"
              fontWeight="500"
              w="100%"
              h="50"
              mt="50px"
              bg={'#1c2147'}
              color={'white'}
              borderRadius={"5px"}
              _active={{ background: "#808080", color: 'white' }}
              _hover={{ background: "#808080", color: 'white' }}
              onClick={handleChangePassword}
            >
              Change Password
            </Button>
          </FormControl>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ForgotPassword;
