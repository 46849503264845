import {
  Box, Button, Flex, SimpleGrid,
  Stack, FormLabel, FormControl, Tab,
  TabList, TabPanel, TabPanels, Tabs,
  Text, useColorModeValue, Alert, AlertIcon,
  CloseButton, Radio, RadioGroup, FormErrorMessage
} from '@chakra-ui/react';
import { ArrowRightIcon, ArrowLeftIcon, CheckIcon } from '@chakra-ui/icons'

// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import BackGround from "assets/img/hr/Colombia.jpg"

import formDataColombia from '../trainers/dataHR/formDataColombia';


export default function UserInfoColombia() {
  const textColor = useColorModeValue('blue.700', 'white');

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [errorAlert, setErrorAlert] = useState('');

  const navigate = useNavigate();

  const [activeBullets, setActiveBullets] = useState({
    general_information: true,
    emergency: false,
    personal_info: false,
    children_info: false,
    confirmation: false,
  });

  const [formData, setFormData] = useState({ ...formDataColombia });
  const [formErrors, setFormErrors] = useState({});

  const generalInfoTab = React.useRef();
  const personalInfoTab = React.useRef();
  const emergencyTab = React.useRef();
  const childrenTab = React.useRef();
  const confirmationTab = React.useRef();


  const [showChildrenInputs, setShowChildrenInputs] = useState(false); 
  
  const handleInputChange = (name, value) => {
    let updatedFormData = {
      ...formData,
      [name]: value,
    };

    // Si se selecciona "No" en el RadioGroup, establece los valores en "N/A"
    if (name === 'children' && value === 'No') {
      updatedFormData = {
        ...updatedFormData,
        children_number: 'N/A',
        gender_children: 'N/A',
        age_children: 'N/A',
      };
    }

    setFormData(updatedFormData);
  };


  const handleSubmit = async (e) => {

    e.preventDefault();
    const formSubmitData = new FormData();

    for (const field in formData) {
      formSubmitData.append(field, formData[field]);
    }

    try {
      const response = await axios.post('/updateInfo', formSubmitData);
      //Show the success alert
      setAlertMessage(response.data.message);
      setShowAlert(true);
      //Redirect to DedicatedPeople Page
      navigate('/admin');
      setFormErrors({});
    } catch (error) {
      //Show the error alert
      setErrorAlert('Registration Failed');
      //Show Message in the console
      console.error('Error creating employee:', error);
      setFormErrors(error.response.data.errors);
    }
  };

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: '125px', lg: '0px' }}
      position="relative"
    >
      <Box
        h="100%"
        position="absolute"
        backgroundImage={BackGround}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        w="100%"
      >
      </Box>
      <Tabs
        variant="unstyled"
        zIndex="0"
        mt={{ base: '60px', md: '165px' }}
        display="flex"
        flexDirection="column"
      >
        <TabList
          display="flex"
          alignItems="center"
          alignSelf="center"
          justifySelf="center"
        >
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={generalInfoTab}
            onClick={() =>
              setActiveBullets({
                general_information: true,
                emergency: false,
                personal_info: false,
                children_info: false,
                confirmation: false,
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                position: 'absolute',
                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={'white'}
                bgGradient={activeBullets.general_information ? "linear(to-b, white,white)" : ""}
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />

            </Flex>
          </Tab>
        
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={emergencyTab}
            onClick={() =>
              setActiveBullets({
                general_information: true,
                emergency: true,
                personal_info: false,
                children_info: false,
                confirmation: false,
              })
            }

          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={'white'}
                bgGradient={activeBullets.emergency ? "linear(to-b, white,white)" : ""}
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
            </Flex>
          </Tab>
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={personalInfoTab}
            onClick={() =>
              setActiveBullets({
                general_information: true,
                emergency: true,
                personal_info: true,
                children_info: false,
                confirmation: false,
              })
            }

          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={'white'}
                bgGradient={activeBullets.personal_info ? "linear(to-b, white,white)" : ""}
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
            </Flex>
          </Tab>
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={childrenTab}
            onClick={() =>
              setActiveBullets({
                general_information: true,
                emergency: true,
                personal_info: true,
                children_info: true,
                confirmation: false,
              })
            }

          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={'white'}
                bgGradient={activeBullets.children_info ? "linear(to-b, white,white)" : ""}
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
            </Flex>
          </Tab>
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={confirmationTab}
            onClick={() =>
              setActiveBullets({
                general_information: true,
                emergency: true,
                personal_info: true,
                children_info: true,
                confirmation: true,
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={'white'}
                bgGradient={activeBullets.confirmation ? "linear(to-b, white,white)" : ""}
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
            </Flex>
          </Tab>
        </TabList>
        <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                General Information
              </Text>
              <Text color={"blue.700"} fontSize="15px" fontWeight="700" mb="20px">
                Welcome to the DP Family! We are thrilled to have you on board and excited to learn and grow with you. As we begin this journey together, we kindly ask that you provide us with your information for our records.
                Rest assured that all information shared will be kept confidential and will allow us to complete all subsequent processes in a timely and efficient manner.
              </Text>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                    <FormControl isInvalid={!!formErrors.first_day}>
                      <InputField
                        mb='0px'
                        label='First Day'
                        name="first_day"
                        type="date"
                        placeholder="Select a Date"
                        defaultValue={formData.first_day}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                      />
                      {formErrors.first_day && (
                        <FormErrorMessage>This field is required.</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl isInvalid={!!formErrors.date_of_birth}>
                      <InputField
                        mb='0px'
                        label='Date Of Birth'
                        name="date_of_birth"
                        type="date"
                        placeholder="Select a Date"
                        defaultValue={formData.date_of_birth}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                      />
                      {formErrors.date_of_birth && (
                        <FormErrorMessage>This field is required.</FormErrorMessage>
                      )}
                    </FormControl>
 

                    <FormControl isInvalid={!!formErrors.current_address}>
                      <InputField
                        mb="0px"
                        placeholder='Calle XX #XXX Col.Example'
                        label="Current Address"
                        name="current_address"
                        defaultValue={formData.current_address}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                      />
                      {formErrors.current_address && (
                        <FormErrorMessage>This field is required.</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={!!formErrors.nationality}>
                      <InputField
                        mb='0px'
                        label='Nationality'
                        name="nationality"
                        placeholder='Nationality'
                        defaultValue={formData.nationality}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                      />
                      {formErrors.nationality && (
                        <FormErrorMessage>This field is required.</FormErrorMessage>
                      )}
                    </FormControl>
                    
                    <FormControl isInvalid={!!formErrors.location}>
                      <InputField
                        mb='0px'
                        label='Location'
                        name="location"
                        placeholder='Location'
                        defaultValue={formData.location}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                      />
                      {formErrors.location && (
                        <FormErrorMessage>This field is required.</FormErrorMessage>
                      )}
                    </FormControl>
                    
                    <FormControl isInvalid={!!formErrors.rfc_tax}>
                      <InputField
                        mb='0px'
                        label='TAX'
                        name="rfc_tax"
                        placeholder='TAX'
                        defaultValue={formData.rfc_tax}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                      />
                      {formErrors.rfc_tax && (
                        <FormErrorMessage>This field is required.</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl isInvalid={!!formErrors.ssn}>
                      <InputField
                        mb='0px'
                        label='SSN (Social Security Number)'
                        name="ssn"
                        placeholder='Social Security Number'
                        defaultValue={formData.ssn}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                      />
                      {formErrors.ssn && (
                        <FormErrorMessage>This field is required.</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl isInvalid={!!formErrors.id_number}>
                      <InputField
                        mb='0px'
                        label='ID Number'
                        name="id_number"
                        placeholder='ID Number'
                        defaultValue={formData.id_number}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                      />
                      {formErrors.id_number && (
                        <FormErrorMessage>This field is required.</FormErrorMessage>
                      )}
                    </FormControl>

                  </SimpleGrid>
                </Stack>
                <Flex justify="space-between" mt="24px">
                  <Button
                    fontSize="sm"
                    bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    ms="auto"
                    onClick={() => emergencyTab.current.click()}
                  >
                    Next <ArrowRightIcon ml={2} />
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>

          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Emergency Contact Info
              </Text>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px" mb="20px">

                  <FormControl isInvalid={!!formErrors.emergency_contact}>
                    <InputField
                      mb="0px"
                      name="emergency_contact"
                      placeholder='Emergency Contact Name'
                      label="Emergency Contact Name"
                      defaultValue={formData.emergency_contact}
                      onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                    {formErrors.emergency_contact && (
                      <FormErrorMessage>This field is required.</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={!!formErrors.emergency_contact_number}>
                    <InputField
                      mb="0px"
                      name="emergency_contact_number"
                      placeholder='Please Enter A Valid Phone Number.'
                      label="Emergency Contact Number"
                      defaultValue={formData.emergency_contact_number}
                      onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                    {formErrors.emergency_contact_number && (
                      <FormErrorMessage>This field is required.</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={!!formErrors.relationship}>
                    <InputField
                      mb="0px"
                      name="relationship"
                      placeholder='Enter A Relationship With Their Emergency Contact'
                      label="Relationship"
                      defaultValue={formData.relationship}
                      onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                    {formErrors.relationship && (
                      <FormErrorMessage>This field is required.</FormErrorMessage>
                    )}
                  </FormControl>

                </Stack>
                <Flex justify="space-between">
                  <Button
                    bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => generalInfoTab.current.click()}
                  >
                    <ArrowLeftIcon mr={2} />  Prev
                  </Button>
                  <Button
                    fontSize="sm"
                    bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    ms="auto"
                    onClick={() => personalInfoTab.current.click()}
                  >
                    Next <ArrowRightIcon ml={2} />
                  </Button>
                </Flex>

              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Personal Information
              </Text>
              <Text color={"blue.700"} fontSize="15px" fontWeight="700" mb="20px">
                This section is to know a little more about you:
              </Text>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px" mb="20px">
                 
                  <FormControl isInvalid={!!formErrors.allergies}>
                    <InputField
                      mb="0px"
                      name="allergies"
                      placeholder='Enter Your Allergies Or If You Dont Have, Enter N/A'
                      label="Any Allergies"
                      defaultValue={formData.allergies}
                      onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                    {formErrors.allergies && (
                      <FormErrorMessage>This field is required.</FormErrorMessage>)}
                  </FormControl>
                  <FormControl isInvalid={!!formErrors.hobbie}>
                    <InputField
                      mb="0px"
                      name="hobbie"
                      placeholder='Enter Your Hobbie'
                      label="Hobbie"
                      defaultValue={formData.hobbie}
                      onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                    {formErrors.hobbie && (
                      <FormErrorMessage>This field is required.</FormErrorMessage>)}
                  </FormControl>

                  <FormControl isInvalid={!!formErrors.favorite_food}>
                    <InputField
                      mb="0px"
                      name="favorite_food"
                      placeholder='Enter Your Favorite Food'
                      label="Favorite Food"
                      defaultValue={formData.favorite_food}
                      onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                    {formErrors.favorite_food && (
                      <FormErrorMessage>This field is required.</FormErrorMessage>)}
                  </FormControl>

                  <FormControl isInvalid={!!formErrors.marital_status}>
                    <FormLabel ms="10px" color="gray.400" fontWeight="bold" mt={2}>
                      Marital Status:
                    </FormLabel>
                    <RadioGroup
                      name="marital_status"
                      value={formData.marital_status} onChange={(value) => handleInputChange('marital_status', value)}
                    >
                      <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5}>
                        <Radio value='Married'>Married</Radio>
                        <Radio value='Single'>Single</Radio>
                        <Radio value='Divorced'>Divorced</Radio>
                        <Radio value='Widow/Widower'>Widow/Widower</Radio>
                      </Stack>
                    </RadioGroup>
                    {formErrors.marital_status && (
                      <FormErrorMessage>This field is required.</FormErrorMessage>)}
                  </FormControl>
                </Stack>
                <Flex justify="space-between">
                  <Button
                    bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => emergencyTab.current.click()}
                  >
                    <ArrowLeftIcon mr={2} />  Prev
                  </Button>
                  <Button
                    fontSize="sm"
                    bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    ms="auto"
                    onClick={() => childrenTab.current.click()}
                  >
                    Next <ArrowRightIcon ml={2} />
                  </Button>
                </Flex>

              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Children Information
              </Text>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px" mb="20px">
                  <FormControl isInvalid={!!formErrors.children}>
                    <FormLabel ms="10px" color="gray.400" fontWeight="bold" mt={2}>
                      Do You Have Children?
                    </FormLabel>
                    <RadioGroup 
                      name="children"
                      value={formData.children}
                      onChange={(value) => {
                      handleInputChange('children', value);
                      setShowChildrenInputs(value === 'Yes');
                    }}
                    >
                      <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5}>
                        <Radio value='Yes'>Yes</Radio>
                        <Radio value='No'>No</Radio>
                      </Stack>
                    </RadioGroup>
                    {formErrors.children && (
                      <FormErrorMessage>This field is required.</FormErrorMessage>)}
                  </FormControl>
                  {showChildrenInputs && (
                  <Box  >
                  <FormControl isInvalid={!!formErrors.children_number}>
                    <InputField
                      mb="0px"
                      name="children_number"
                      label="How many children?"
                      defaultValue={formData.children_number}
                      onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                    {formErrors.children_number && (
                      <FormErrorMessage>This field is required.</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={!!formErrors.gender_children}>
                    <InputField
                      mb="0px"
                      name="gender_children"
                      label="How many boys and girls?"
                      defaultValue={formData.gender_children}
                      onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                    {formErrors.gender_children && (
                      <FormErrorMessage>This field is required.</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={!!formErrors.age_children}>
                    <InputField
                      mb="0px"
                      name="age_children"
                      label="How old are they?"
                      defaultValue={formData.age_children}
                      onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    />
                    {formErrors.age_children && (
                      <FormErrorMessage>This field is required.</FormErrorMessage>
                    )}
                  </FormControl>
                  </Box>
                  )}
                </Stack>
                <Flex justify="space-between">
                  <Button
                    bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => personalInfoTab.current.click()}
                  >
                    <ArrowLeftIcon mr={2} />  Prev
                  </Button>
                  <Button
                    fontSize="sm"
                    bg={"#1c2147"} color={"white"} _hover={{ background: "#808080", color: 'white' }}
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    ms="auto"
                    onClick={() => confirmationTab.current.click()}
                  >
                    Next <ArrowRightIcon ml={2} />
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px" mb="20px">
                  <FormControl isInvalid={!!formErrors.confirmation}>
                    <FormLabel ms="10px" color="blue.700" fontWeight="bold" mt={2}>
                      Lastly, Please Remember That You Need To Record Your Audios During Your Onboarding Process. Have You Submitted Them Yet?
                    </FormLabel>
                    <RadioGroup
                      name="confirmation"
                      value={formData.confirmation} onChange={(value) => handleInputChange('confirmation', value)}
                    >
                      <Stack direction="row" spacing={0} flexWrap="wrap" alignItems="center" justifyContent="space-around" mt={5}>
                        <Radio value='1'>Yes</Radio>
                        <Radio value='0'>No</Radio>
                      </Stack>
                    </RadioGroup>
                    {formErrors.confirmation && (
                      <FormErrorMessage>This field is required.</FormErrorMessage>)}
                  </FormControl>
                </Stack>
                <Flex justify="space-between">
                  <Button
                    bg={"#f2f2f2"} color={"#1c2147"} _hover={{ background: "#808080", color: 'white' }}
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => childrenTab.current.click()}
                  >
                    <ArrowLeftIcon mr={2} />  Prev
                  </Button>
                  <Button
                    bg={"#1c2147"} color={"white"} _hover={{ background: "#b9ce67", color: 'white' }}
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={handleSubmit}
                  >
                    Submit  <CheckIcon ml={2} />
                  </Button>
                </Flex>
              </Flex>
              {showAlert && (
                <Alert status="success" mt={4}>
                  <AlertIcon />
                  {alertMessage}
                  <CloseButton
                    onClick={() => setShowAlert(false)}
                    position="absolute"
                    right="8px"
                    top="8px"
                  />
                </Alert>
              )}
              {errorAlert && (
                <Alert status="error" mt={4}>
                  <AlertIcon />
                  {errorAlert}
                  <CloseButton
                    onClick={() => setErrorAlert('')}
                    position="absolute"
                    right="8px"
                    top="8px"
                  />
                </Alert>
              )}
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
