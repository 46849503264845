import axios from 'axios';
import React, { useState } from "react";
import {
    Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Box, FormControl, FormLabel, Select, Image, Flex, Heading, SimpleGrid, useToast, Textarea, FormErrorMessage
} from '@chakra-ui/react';
import avatar4 from 'assets/img/dashboards/grey_world.png';
import { EditIcon, CloseIcon } from '@chakra-ui/icons'
function ModalTransfer({ isOpen, onClose, incident, onIncidentUpdated, closeMessages }) {
    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        reason_transfer: '',
        chat_room_id: '',
    });

    const clearForm = () => {
        setFormData({
            reason_transfer: '',
            chat_room_id: '',
        });
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
    const handleSubmit = async (id) => {
        try {
            const response = await axios.post(`/transferTicket/${incident.id}`, formData);
            if (response.status === 200) {
                onIncidentUpdated();
                toast({
                    title: 'Ticket Transfered Successfully',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                clearForm();
                onClose();
                closeMessages();
                setFormErrors({});
            }
        } catch (error) {
            toast({
                title: "Error transfered a Ticket",
                status: "error",
                duration: 1000,
                isClosable: true,
            });
            console.error('Error transfered employee:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}
            closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent mt={"1%"} background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalHeader

                    textColor='white'
                    textAlign='center'
                    borderRadius='20px'
                    mx="14"
                    mt={6}
                >
                    <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Image
                            src={avatar4}
                            alt="Avatar"
                            style={{ width: '80px', height: '80px', marginRight: '10px' }}
                        />
                    </Flex>
                </ModalHeader>
                <ModalBody mt={2} ml={10} mr={10}>
                    <Box>
                        <Heading fontSize="18px" color={'#808080'} textAlign={'center'} fontFamily="DM Sans" position="relative" letterSpacing="1px" mb='10' mt={10}>
                            TRANSFER TICKET
                        </Heading>
                        <SimpleGrid
                            columns='1'
                            gap='20px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl isInvalid={!!formErrors.reason_transfer}>
                                <FormLabel
                                    ms='10px'
                                    fontSize='md'
                                    color="#808080"
                                    textAlign={"center"}
                                >
                                    Reason
                                </FormLabel>
                                <Textarea
                                    placeholder="Explain the problematic. min. 80 characters"
                                    name="reason_transfer"
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.reason_transfer}
                                    onChange={handleInputChange}
                                    errorBorderColor='#fc7466'
                                />
                                {formErrors.reason_transfer && (
                                    <FormErrorMessage>This field is required. Max 510 characters.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.chat_room_id}>
                                <FormLabel
                                    ms='10px'
                                    fontSize='md'
                                    color="#808080"
                                    textAlign={"center"}
                                >
                                    Department
                                </FormLabel>
                                <Select
                                    w='100%'
                                    fontSize={'md'}
                                    borderRadius={'5px'}
                                    name='chat_room_id'
                                    defaultValue={formData.chat_room_id}
                                    onChange={handleInputChange}
                                    color={'#cccccc'}
                                    background={"white"}
                                    textAlign={"center"}
                                    h='44px'
                                    errorBorderColor='#fc7466'
                                    maxh='44px'
                                    placeholder='Select department'>
                                    <option value='1'>IT</option>
                                    <option value='2'>HR</option>
                                    <option value='3'>Hiring</option>
                                    <option value='4'>OnCall</option>
                                    <option value='5'>Schedules</option>
                                    <option value='6'>Finances</option>
                                    <option value='7'>Calls</option>
                                </Select>
                                {formErrors.chat_room_id && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"6%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Transfer Ticket <EditIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={onClose}>
                        Close <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
}
export default ModalTransfer;