const formDataVacancies = {

    vacancies_id: '',
    first_name: '',
    last_name: '',
    date_of_birth: '',
    age: '',
    city_of_birth: '',
    location: '',
    office: '',
    address: '',
    personal_email: '',
    phone: '',
    whatsapp_number: '',
    about_us: '',
    student: '',
    plan_to_study: '',
    studies: '',
    medical_condition: '',
    specify_medical_contidion: '',
    diagnosed_mental_disorder: '',
    specify_diagnosed_mental_disorder: '',
    taking_medication: '',
    speak_fluent_spanish: '',
    currently_working: '',
    planning_keep_currently_work: '',
    currently_company_name: '',
    currently_company_phone: '',
    currently_company_time: '',
    specify_leave_currently_work: '',
    day_weekend_free: '',
    confirm_day_weekend_free: '',
    commitments_soon: '',
    specify_commitments_soon: '',
    can_you_start: '',
    grammar_and_listening_test: '',
    curriculum: '',
    signature: ''
    
}; 
export default formDataVacancies;