import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import UseBilling from "./components/ItemsBilling/Functions";

export default function ReportsOverview() { 
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='100px'>
        <UseBilling/>
      </Card>
    </Flex>
  );
} 