import React, { useState } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input, Select, Image,
    Box, Flex, Heading, SimpleGrid,
    FormControl, FormErrorMessage,
    useToast

} from '@chakra-ui/react';

import Select2 from 'react-select'
import avatar4 from 'assets/img/dashboards/grey_world.png';
import { CloseIcon, AddIcon } from '@chakra-ui/icons'

const CreateUser = ({ isOpen, onClose, onUserCreated, userType, clients }) => {

    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [selectedClient, setSelectedClient] = useState('');
    const [resetKey, setResetKey] = useState(0);

    const optionClients = clients.map(clients => ({
        value: clients.id,
        label: clients.name,
    }));

    const optionUserType = userType.map(userType => ({
        value: userType.id,
        label: userType.name,
    }));

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            borderRadius: '5px',
            borderColor: 'white',
            fontSize: '16px',
            height: '40px',
            width: 'auto',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),

        placeholder: (provided, state) => ({
            ...provided,
            color: '#cccccc',
        }),

        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? 'white' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };


    const clearForm = () => {
        setFormData({
            name: '',
            last_name: '',
            email: '',
            type_user_id: '',
            location_id: '',
            status_employee_id: '',
            phone: '',
            personal_email: '',
            anydesk: '',
        });
        setSelectedClient([]);
        setResetKey(resetKey + 1);
        setFormErrors({});
    };

    const [formData, setFormData] = useState({
        name: '',
        last_name: '',
        email: '',
        type_user_id: '',
        location_id: '',
        status_employee_id: '',
        phone: '',
        personal_email: '',
        anydesk: '',
        id_client: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            const response = await axios.post('/users/create', formData);
            onUserCreated();
            toast({
                title: 'User Created',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Creating User",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Creating User:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };


    return (
        <Modal isOpen={isOpen} size={'4xl'} onClose={() => {
            onClose();
            clearForm();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent mt={"8%"} background={"#f2f2f2"} borderRadius={"30px"}  >
                <ModalHeader

                    textColor='white'
                    textAlign='center'
                    borderRadius='20px'
                    mx="14"
                    mt={6}
                >
                    <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Image
                            src={avatar4}
                            alt="Avatar"
                            style={{ width: '80px', height: '80px', marginRight: '10px' }}
                        />
                    </Flex>
                </ModalHeader>

                <ModalBody>
                    <Box mx="auto" textAlign="center" my={"auto"} justifyContent={"center"}>
                        <Heading as="h2" fontSize="18px" color={'#808080'} textAlign={'center'} fontFamily="DM Sans" position="relative" letterSpacing="3px" mb='6' mt={5}>
                            ADD USER
                        </Heading>
                        <SimpleGrid
                            columns='2'
                            gap='40px'
                            width={"max-content"}
                        >
                            <FormControl isInvalid={!!formErrors.name} w='400px' >
                                <Input mr={"20px"}
                                    type="text"
                                    placeholder="Enter First Name"
                                    name='name'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.name}
                                    onChange={handleChange}
                                    errorBorderColor='red.300'
                                />
                                {formErrors.name && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl >
                            <FormControl isInvalid={!!formErrors.last_name} w='400px' >
                                <Input mr={"20px"}
                                    type="text"
                                    placeholder="Enter Last Name"
                                    name='last_name'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.last_name}
                                    onChange={handleChange}
                                    errorBorderColor='red.300'
                                />
                                {formErrors.last_name && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl >
                            <FormControl isInvalid={!!formErrors.email} w='400px' >
                                <Input
                                    type="text"
                                    placeholder="Enter Email"
                                    name='email'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.email}
                                    onChange={handleChange}
                                    errorBorderColor='red.300'
                                />
                                {formErrors.email && (
                                    <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                                )}
                            </FormControl >
                            <FormControl isInvalid={!!formErrors.personal_email} w='400px'>
                                <Input
                                    type="text"
                                    placeholder="Enter Personal Email"
                                    name='personal_email'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.personal_email}
                                    onChange={handleChange}
                                    errorBorderColor='red.300'
                                />
                                {formErrors.personal_email && (
                                    <FormErrorMessage>{formErrors.personal_email}</FormErrorMessage>
                                )}
                            </FormControl >
                            <FormControl isInvalid={!!formErrors.type_user_id} w='400px'>
                                <Select2
                                    options={optionUserType}
                                    styles={customStyles}
                                    placeholder="Select user type"
                                    key={`userTypeSelect-${resetKey}`}
                                    onChange={(selectedOption) => {
                                        setFormData({
                                            ...formData,
                                            type_user_id: selectedOption ? selectedOption.value : '',
                                        });
                                    }}
                                />
                                {formErrors.type_user_id && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl >
                            <FormControl isInvalid={!!formErrors.status_employee_id} w='400px'>
                                <Select
                                    fontSize='md'
                                    name='status_employee_id'
                                    defaultValue={formData.status_employee_id}
                                    onChange={handleChange}
                                    borderRadius={'10px'}
                                    background="white"
                                    textAlign={"center"}
                                    h='44px'
                                    maxh='44px'
                                    errorBorderColor='red.300'
                                    me='20px'
                                    placeholder='Select Status'
                                    color={'#cccccc'}
                                >
                                    <option value='1'>In office</option>
                                    <option value='2'>Remote</option>
                                    <option value='3'>Remote MX</option>
                                </Select>
                                {formErrors.status_employee_id && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.location_id} w='400px'>
                                <Select
                                    fontSize='md'
                                    name='location_id'
                                    defaultValue={formData.location_id}
                                    onChange={handleChange}
                                    borderRadius={'10px'}
                                    background="white"
                                    color={'#cccccc'}
                                    textAlign={"center"}
                                    h='44px'
                                    maxh='44px'
                                    errorBorderColor='red.300'
                                    me='20px'
                                    placeholder='Select Location'>
                                    <option value='1'>México</option>
                                    <option value='2'>Belice, City</option>
                                    <option value='3'>Belice, San Ignacio</option>
                                    <option value='4'>Dominican Republic</option>
                                    <option value='5'>Bogotá, Colombia</option>
                                    <option value='6'>Guatemala</option>
                                </Select>
                                {formErrors.location_id && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.phone} w='400px'>
                                <Input
                                    type="text"
                                    placeholder="Enter Phone"
                                    name='phone'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.phone}
                                    onChange={handleChange}
                                    errorBorderColor='red.300'
                                />
                                {formErrors.phone && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl >
                            <Select2
                                options={optionClients}
                                styles={customStyles}
                                placeholder="Select client"
                                key={`clientSelect-${resetKey}`}
                                onChange={(selectedOption) => {
                                    setFormData({
                                        ...formData,
                                        id_client: selectedOption ? selectedOption.value : '',
                                    });
                                }}
                            />
                            <FormControl isInvalid={!!formErrors.anydesk} w='400px'>
                                <Input
                                    type="text"
                                    placeholder="Enter Anydesk"
                                    name='anydesk'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.anydesk}
                                    onChange={handleChange}
                                    errorBorderColor='red.300'
                                />

                            </FormControl >
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Add user <AddIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                        clearForm();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CreateUser;
