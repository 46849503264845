import React from 'react';
import {
    Menu, MenuButton, MenuItem, MenuList,
    Button,
    Text
} from '@chakra-ui/react'
import { SettingsIcon, EditIcon } from '@chakra-ui/icons'
import 'react-datepicker/dist/react-datepicker.css';
import 'jspdf-autotable';

function CompletedTicketsButtons({ loading, handleInfo, handleTranscript, ic }) {


    return (

        <Menu >
            <MenuButton as={Button} _active={{ background: "#808080", color: 'white' }} bg="#1c2147" color={"white"} _hover={{ background: "#808080", color: 'white' }} borderRadius={'5px'}>
                <SettingsIcon pb={"2px"} />
            </MenuButton>
            <MenuList  >
                <MenuItem isDisabled  >
                    <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions</div>
                </MenuItem>
                <MenuItem
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems="center"
                >
                    <Button
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        color={"#1c2147"}
                        bg={"#f2f2f2"}
                        width="100%"
                        isLoading={loading}
                        _hover={{ background: "#7080fc", color: 'white' }}
                        onClick={() => handleInfo(ic)}
                    >
                        <Text flex={1} textAlign={'center'}>Info</Text>
                        <EditIcon ml={2} />
                    </Button>
                </MenuItem>
                <MenuItem
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems="center"
                >
                    <Button
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        color={"#1c2147"}
                        bg={"#f2f2f2"}
                        width="100%"
                        isLoading={loading}
                        _hover={{ background: "#fb9539", color: 'white' }}
                        onClick={() => handleTranscript(ic)}
                    >
                        <Text flex={1} textAlign={'center'}>Transcript messages</Text>
                        <EditIcon ml={2} />
                    </Button>
                </MenuItem>
            </MenuList>
        </Menu>

    );
}
export default CompletedTicketsButtons;
