import React, { useState, useEffect } from 'react';
import {
    Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Button, ButtonGroup,
    Flex, Box,
    Text, Spinner
} from '@chakra-ui/react'
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { RiFileExcel2Fill } from "react-icons/ri";
import { TbReportSearch } from "react-icons/tb";
import { FaFilePdf } from "react-icons/fa";
import 'jspdf-autotable';
import Filters from './FiltersTS'
function ReportTS({ handleDownloadExcel, downloadTableAsPDF, clientSearch, usersClients, optionsUsers, userTypeSearch, handleSelectChangeF, setCurrentPage, customStyles, resetKey, filterOptions, optionClients, handleClientChange, customStylesClient, startDateFilter, setStartDateFilter, setTypeSearch, endDateFilter, setEndDateFilter, clearFilters, reportsD, isLoading, total, currentPage, totalPages }) {

    return (
        <TableContainer>
            <Flex mt={4}
                direction='column'
                w='100%'>
                <Flex
                    direction='row'
                    w='100%'
                    justifyContent="flex-end"
                    mb={2}
                >
                    <Flex align="center" justifyContent="flex-end">
                        <Button class="buttonCreate" onClick={handleDownloadExcel}>
                            <Text mr={2}>
                                Excel
                            </Text>
                            <RiFileExcel2Fill />
                        </Button>
                    </Flex>
                    <Flex align="center" justifyContent="flex-end">
                        <Button borderRadius={"10px"} color={"white"} bg={"#fc7466"} _hover={{ bg: "red" }} pr={3} pl={3} height={"43px"} onClick={downloadTableAsPDF}>
                            <Text mr={2}>
                                PDF
                            </Text>
                            <FaFilePdf />
                        </Button>
                    </Flex>
                </Flex>
                <Filters clientSearch={clientSearch} usersClients={usersClients} optionsUsers={optionsUsers} userTypeSearch={userTypeSearch} handleSelectChangeF={handleSelectChangeF} setCurrentPage={setCurrentPage} customStyles={customStyles} resetKey={resetKey} filterOptions={filterOptions} optionClients={optionClients} handleClientChange={handleClientChange} customStylesClient={customStylesClient} startDateFilter={startDateFilter} setStartDateFilter={setStartDateFilter} setTypeSearch={setTypeSearch} endDateFilter={endDateFilter} setEndDateFilter={setEndDateFilter} clearFilters={clearFilters} />
                <br />
                <Table id='ticketsTable' size={'sm'}>
                    <Thead bg={"white"} >
                        <Tr>
                            <Th color='#808080' textAlign={'center'}>ID</Th>
                            <Th color='#808080' textAlign={'center'}>Full Name</Th>
                            <Th color='#808080' textAlign={'center'}>Schedule start time</Th>
                            <Th color='#808080' textAlign={'center'}>Schedule end time</Th>
                            <Th color='#808080' textAlign={'center'}>Date</Th>
                            <Th color='#808080' textAlign={'center'}>Start time</Th>
                            <Th color='#808080' textAlign={'center'}>End time</Th>
                            <Th color='#808080' textAlign={'center'}>Duration</Th>
                            <Th color='#808080' textAlign={'center'}>Schedule</Th>
                            <Th color='#808080' textAlign={'center'}>Job site</Th>
                            <Th color='#808080' textAlign={'center'}>Position</Th>
                            <Th color='#808080' textAlign={'center'}>Expect</Th>
                            <Th color='#808080' textAlign={'center'}>Lunch</Th>
                            <Th color='#808080' textAlign={'center'}>Breaks</Th>
                            <Th color={'#808080'} textAlign={'center'}>Notes</Th>
                        </Tr>
                    </Thead>
                    <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                        {reportsD && reportsD.length > 0 ? (
                            reportsD.map((reportsD, index) => (
                                <Tr className={index % 2 === 0 ? 'white-row' : 'gray-row'} key={index}>
                                    <Td textAlign={'center'}>
                                        {reportsD.shift.user.profile ? (
                                            reportsD.shift.user.profile.mat_dp
                                        ) : (
                                            "ID"
                                        )}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.shift.user.name} {reportsD.shift.user.last_name}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.shift.schedules.status >= 4 && reportsD.shift.schedules.status <= 10
                                            ? '---'
                                            : reportsD.shift.schedules.time_start_formatted
                                        }
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.shift.schedules.status >= 4 && reportsD.shift.schedules.status <= 10
                                            ? '---'
                                            : reportsD.shift.schedules.time_end_formatted
                                        }
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.shift.date_start_date}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.shift.schedules.status >= 4 && reportsD.shift.schedules.status <= 10
                                            ? '---'
                                            : reportsD.shift.date_start_time
                                        }
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.shift.schedules.status >= 4 && reportsD.shift.schedules.status <= 10
                                            ? '---'
                                            : reportsD.shift.date_end_time
                                        }
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {Number(reportsD.duration).toFixed(3)}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.shift.clients.name}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {(() => {
                                            switch (reportsD.shift.schedules.status) {
                                                case 4:
                                                    return 'Vacation';
                                                case 5:
                                                    return 'Maternity Leave';
                                                case 6:
                                                    return 'Permission Request';
                                                case 7:
                                                    return 'Wedding Leave';
                                                case 8:
                                                    return 'Medical Leave';
                                                case 9:
                                                    return 'Paternity Leave';
                                                case 10:
                                                    return 'Bereavement Leave';
                                                case 11:
                                                    return 'Surecall O/C';
                                                default:
                                                    switch (reportsD.shift.type) {
                                                        case 1:
                                                            return "Regular shift";
                                                        case 2:
                                                            return "Regular training";
                                                        case 3:
                                                            return "Additional hours";
                                                        case 4:
                                                            return "Regular covering";
                                                        case 5:
                                                            return "Reguar shadowing"
                                                        case 6:
                                                            return "Regular shift"
                                                        case 7:
                                                            return "Additional coverage"
                                                        case 8:
                                                            return "Additional training"
                                                        case 9:
                                                            return "Additional shadowing"
                                                        default:
                                                            return "Regular hours";
                                                    }
                                            }

                                        })()}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.shift.schedules.status === 12 ? `${reportsD.shift.user.user_type.name} Overnight` : reportsD.shift.user.user_type.name}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.shift.schedules.hours}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {Number(reportsD.total_break).toFixed(3)}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {Number(reportsD.breaks).toFixed(3)}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {reportsD.shift.schedules.notes && reportsD.shift.schedules.notes.length > 0 ? (
                                            reportsD.shift.schedules.notes.map((nota, notaIndex) => (
                                                <Flex key={notaIndex} display={'flex'} justifyContent={'center'} width={'250px'} direction={'column'}>
                                                    <Text whiteSpace={'pre-wrap'} color={'#808080'}>
                                                        {(() => {
                                                            const optionValue = nota.option !== null ? nota.option : 7;

                                                            switch (optionValue) {
                                                                case 1:
                                                                    return "Late";
                                                                case 2:
                                                                    return "Call out";
                                                                case 3:
                                                                    return "Leave early";
                                                                case 4:
                                                                    return "No call, no show";
                                                                case 5:
                                                                    return "Cell phone use";
                                                                case 6:
                                                                    return "Camera off";
                                                                case 7:
                                                                    return "Other";
                                                                case 8:
                                                                    return "Permission with client";
                                                                case 9:
                                                                    return "Disciplinary actions";
                                                                case 10:
                                                                    return "Vacations";
                                                                case 11:
                                                                    return "Late from lunch";
                                                                case 12:
                                                                    return "Late from break";
                                                                default:
                                                                    return "Other";
                                                            }
                                                        })()}
                                                    </Text>
                                                    <Text whiteSpace={'pre-wrap'}>
                                                        {nota.description}
                                                    </Text>
                                                </Flex>
                                            ))
                                        ) : (
                                            <p></p>
                                        )}
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                {!isLoading ? (
                                    <Th colSpan={'20'}>
                                        <Flex display={'flex'} justifyContent={'center'} alignItems={'center'} bg={"#f2f2f2"}>
                                            <Text
                                                color='#1c2147'
                                                fontSize={"25px"}
                                                textAlign={"center"}
                                                paddingTop={"15px"}
                                                paddingBottom={"15px"}
                                                textTransform={'none'}
                                                fontFamily={'DM Sans'}
                                                letterSpacing="-1px"
                                                mr={'20px'}
                                            >
                                                Loading...
                                            </Text>
                                            <Spinner color='blue.500' />
                                        </Flex>
                                    </Th>
                                ) : (
                                    <Th colSpan={'20'}>
                                        <Text
                                            color='#1c2147'
                                            fontSize={"25px"}
                                            textAlign={"center"}
                                            paddingTop={"15px"}
                                            paddingBottom={"15px"}
                                            bg={"#f2f2f2"}
                                            textTransform={'none'}
                                            fontFamily={'DM Sans'}
                                            letterSpacing="-1px"
                                        >
                                            No data
                                        </Text>
                                    </Th>
                                )}
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </Flex>
            <Box width={"100%"} display={"flex"} mt={"30px"}>
                <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                    {total === 0 ? (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Box mt={'5px'} mr={2} ml={4}>
                                    <TbReportSearch color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    No reports
                                </Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                                    {total}
                                </Text>
                                <Box mt={'5px'} mr={1}>
                                    <TbReportSearch color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    {total === 1 ? 'Report' : 'Reports'}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Flex>
                <ButtonGroup isAttached variant="outline" ml={"34%"} justifyContent={'center'} width={"max-content"} hidden>
                    <Button
                        borderRadius={'0px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                    <Button
                        borderRadius={'0px'} ml={4}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowRightShort fontSize="20px" />
                    </Button>
                </ButtonGroup>
            </Box>
        </TableContainer>
    );
}
export default ReportTS; 