import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import ChangeSchedulesFunctions from "./items/ChangeSchedulesFunctions";


export default function OverviewChangeSchedules() {

  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='100px'>
        <ChangeSchedulesFunctions />
      </Card>
    </Flex>
  );
}
