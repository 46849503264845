import {
    Flex, Button, Text, Input,
} from "@chakra-ui/react";
import React from 'react';
import { EditIcon } from '@chakra-ui/icons'
export default function ClockInSection({ schedule, formDataShift, handleChangeShift, handleUpdateShift }) {


    return (
        <Flex display={'flex'} justifyContent={'space-around'} mt={'13%'} ml={"10%"}>
            <Flex display={'flex'} justifyContent={'space-around'}     >
                <Flex display={'flex'} justifyContent={'center'} >
                    {schedule.time !== null ? (
                        <>
                            <Input
                                type='text'
                                bg='white'
                                color={'#808080'}
                                value={formDataShift.duration}
                                name='duration'
                                mr={'3%'}
                                width={'55%'}
                                textAlign={'center'}
                                onChange={handleChangeShift}
                            />
                            <Button
                                bg={'#424ea8'}
                                borderRadius={'5px'}
                                _hover={{ background: "#808080", }}
                                color={'white'}
                                onClick={handleUpdateShift}
                            >
                                <EditIcon size={'25px'} />
                            </Button>
                        </>
                    ) : (
                        <Text color={'#cccccc'} fontWeight={'bold'} ml={"-40px"} mt={"-30px"} fontSize={'20px'}>
                            No data
                        </Text>
                    )}
                </Flex>

            </Flex>
        </Flex>
    );
} 