import { Flex, Button } from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useState, useEffect } from 'react';
import MySchedules from "./components/itemsMySchedule/table";
export default function MyScheduleOverview() {

  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='100px'>
        <MySchedules />
      </Card>
    </Flex>
  );
} 