import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import {
    useToast
} from '@chakra-ui/react'
import RecordingList from './table';

function UseRecordingsFunctions() {
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');
    const [totalSchedule, setTotalSchedule] = useState('');
    const [userSearch, setUserSearch] = useState(null);
    const [clientSearch, setClientSearch] = useState(null);
    const [filterDate, setFilterDate] = useState('');
    const [filterDateEnd, setFilterDateEnd] = useState('');
    const [scheduleData, setScheduleData] = useState([]);
    const [user, setUser] = useState([]);
    const [client, setClient] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const [usersClients, setUsersClients] = useState([]);
    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [timeSearch, setTimeSearch] = useState('');


    const clearFilters = () => {
        setClientSearch(null);
        setUserSearch(null);
        setFilterDate('');
        setFilterDateEnd('');
        setCurrentPage(1);
        setTimeSearch('');
        setResetKey(resetKey + 1);
    }

    const openModalCreate = () => {
        setModalCreateIsOpen(true);
    }
    const closeModalCreate = () => {
        setModalCreateIsOpen(false);
    }

    const optionsUsers = user.map(user => ({
        value: user.id,
        label: `${user.name} ${user.last_name}`,
    }));

    const optionClients = client.map(client => ({
        value: client.id,
        label: client.name,
    }));

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            fontSize: '16px',
            marginRight: '3px',
            marginLeft: '10px',
            height: '40px',
            width: '250px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `/schedule-recordings?page=${currentPage}`
                +`${userSearch ? `&agent=${encodeURIComponent(userSearch)}` : ''}`
                +`${clientSearch ? `&client=${encodeURIComponent(clientSearch)}` : ''}`
                +`${filterDate ? `&startDate=${filterDate.trim()}` : ''}`
                +`${filterDateEnd ? `&endDate=${filterDateEnd.trim()}` : ''}`
                +`${timeSearch ? `&start_time=${timeSearch.trim()}` : ''}`
            );
            const responseData = response.data;
            setScheduleData(responseData.data.data);
            setTotal(responseData.data.total)
            setTotalPages(responseData.data.last_page);
            setTotalSchedule(responseData.total_schedules)
            setUser(responseData.agent);
            setClient(responseData.client);
        } catch (error) {
            console.error('Error fetching schedules:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchDataClient = async () => {
        try {
            const response = axios.get("/userClient").then((response) => {
            });
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleClientChange = (selectedOption) => {
        setClientSearch(selectedOption.value);

        if (selectedOption) {
            axios
                .get(`/userClient?id_client=${selectedOption.value}`)
                .then((response) => {
                    const userOptions = response.data.data.map((user) => ({
                        label: user.user ? `${user.user.name} ${user.user.last_name}` : '',
                        value: user.id_user,
                    }));
                    setUsersClients(userOptions);
                });
        } else {
            setUsersClients([]);
        }
    };

    useEffect(() => {
        fetchData();
        fetchDataClient();
    }, [currentPage, userSearch, clientSearch, filterDate, filterDateEnd, resetKey, timeSearch]);


    const handleSelectChange = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                setUserSearch(selectedOption.value);
                break;
            case 'client':
                setClientSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const checkRecording = async (item) => {
        const confirmDelete = await Swal.fire({
            title: 'Do you want to check this recording?',
            text: 'The recording will be checked.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: false
        }).then((result) => {
            return result.isConfirmed
        });

        if (confirmDelete) {
            setLoading(true);
            try {
                const response = await axios.post(`/recordings/${item.id}`);
                toast({
                    title: "Recording Checked Succesfully!!!",
                    status: "success",
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                });
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error Checking Recording!!!",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        }
    };


    return (
         
        <RecordingList
            clientSearch={clientSearch}
            usersClients={usersClients}
            optionsUsers={optionsUsers}
            userSearch={userSearch}
            total={total}
            currentPage={currentPage}
            scheduleData={scheduleData}
            handleSelectChange={handleSelectChange}
            setCurrentPage={setCurrentPage}
            customStyles={customStyles}
            totalPages={totalPages}
            filterOptions={filterOptions}
            setFilterDateEnd={setFilterDateEnd}
            optionClients={optionClients}
            setFilterDate={setFilterDate}
            resetKey={resetKey}
            handleClientChange={handleClientChange}
            filterDate={filterDate}
            filterDateEnd={filterDateEnd}
            clearFilters={clearFilters}
            openModalCreate={openModalCreate}
            checkRecording={checkRecording}
            loading={loading}
            totalSchedule={totalSchedule}
            modalCreateIsOpen={modalCreateIsOpen}
            closeModalCreate={closeModalCreate}
            fetchData={fetchData}
            user={user}
            timeSearch={timeSearch}
            setTimeSearch={setTimeSearch}
        />
    );
}
export default UseRecordingsFunctions; 