import {
  Table, Thead, Tbody, Tr, Th, Td,
  Menu, MenuButton, MenuItem, MenuList,
  Button, ButtonGroup,
  Link, Badge, Flex, Box, Text, Avatar
} from '@chakra-ui/react'
import React, { useEffect, useState, useCallback } from "react";
import { BsWhatsapp, BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import logo from "assets/img/dashboards/logo.png";
import UserButtons from './buttons';
import ImageViewer from "react-simple-image-viewer";

const UserList = ({ userAuth, setCurrentPage, users, getWhatsAppLink, getRowBackground, getColorSchemeForLocation, handleInfo, handleUpdate, handleUpdateNew, handleUpdateAD, handleUpdateClient, handleUpdateTU, handleCreateSchedule, handleReset, handleDelete, loading, total, currentPage, totalPages, handleChangePassword }) => {

  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openImageViewer = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
    setSelectedImage(null);
  };

  const renderUserImage = (user) => {
    const imageSrc = user.base64Image && user.imageMimeType
      ? `data:${user.imageMimeType};base64,${user.base64Image}`
      : logo;

    return (
      <Avatar
        src={imageSrc}
        h="60px"
        w="60px"
        onClick={() => openImageViewer(imageSrc)}
        cursor={"pointer"}
      />
    );
  };

  return (
    <>
      <Flex alignItems={"auto"}
        direction='column'
        width={'auto'}
        mt={5}
      >
        <Table size={'sm'}  >
          <Thead bg={'white'}  >
            <Tr>
              {[1, 37].includes(userAuth) ? (
                <Th color='#808080' textAlign={'center'}></Th>
              ) : null}
              <Th color='#808080' textAlign={'center'}>ID</Th>
              <Th color='#808080' textAlign={'center'}></Th>
              <Th color='#808080' textAlign={'center'}>Full Name</Th>
              <Th color='#808080' textAlign={'center'}>Dedicated People Email</Th>
              <Th color='#808080' textAlign={'center'}>Type Of User</Th>
              <Th color='#808080' textAlign={'center'}>Client</Th>
              <Th color='#808080' textAlign={'center'}>Status & Location</Th>
              <Th color='#808080' textAlign={'center'}>Actions</Th>
            </Tr>
          </Thead>
          <Tbody borderRadius="16px" fontSize={'18px'}  >
            {users && users.length > 0 ? (
              users.map((user, index) => (
                <Tr key={user.id} bg={getRowBackground(user)} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>
                  {[1, 37].includes(userAuth) ? (
                    <Td>
                      {renderUserImage(user, index)}
                    </Td>
                  ) : null}
                  <Td color='#808080' textAlign={'center'}>{user.profile.mat_dp}</Td>
                  <Td textAlign={'center'}> <Link
                    textAlign={'left'}
                    color="blue.500"
                    href={getWhatsAppLink(user.profile.phone, user.location_id)}
                    isExternal
                  >

                    <Box
                      width={8}
                      height={8}
                      borderRadius={"10px"}
                      bg={"#8aad01"}
                      _hover={{
                        background: "#b9ce67",
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <BsWhatsapp color="white" size='18px' />
                    </Box>

                  </Link></Td>
                  <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>
                    {user.name} {user.last_name}
                  </Td>
                  <Td color='#1c2147' fontWeight='bold' textAlign={'center'}>{user.email}</Td>
                  <Td textAlign={'center'}>
                    <Badge
                      backgroundColor={'#7080fc'}
                      color='white'
                      width={'180px'}
                      fontSize={'10px'}
                      borderRadius={'5px'}
                    >
                      {user.user_type.name}
                    </Badge>
                  </Td>
                  <Td textAlign={"center"}>
                    {user.user_clients && user.user_clients.length > 0 ? (
                      user.user_clients.length === 1 ? (
                        <Badge
                          backgroundColor={'#dd67ae'}
                          color="white"
                          mt={1}
                          width={'158px'}
                          fontSize={'10px'}
                          borderRadius={'5px'}
                          textAlign={'center'}
                        >
                          {user.user_clients[0].client.name}
                        </Badge>
                      ) : (

                        <Menu>
                          <MenuButton
                            as={Button}
                            backgroundColor={'#dd67ae'}
                            color="white" size="sm"
                            width={'158px'}
                            fontSize={'11px'}
                            borderRadius={'5px'}
                            mt={1}
                            _hover={{ background: "#dd67ae", }}
                            _active={{ background: "#dd67ae", }}
                            height={'25px'}
                            title='Show Clients'
                          >
                            {`${user.user_clients.length} Clients`}
                          </MenuButton>
                          <MenuList maxH="200px" overflowY="auto">
                            {user.user_clients.map((client, index) => (
                              <MenuItem key={index} justifyContent={'center'} color={'#808080'} width={'80%'} ml={'20px'} mt={'10px'}>
                                <Text fontWeight={'bold'}>
                                  {client.client.name}
                                </Text>
                              </MenuItem>
                            ))}
                          </MenuList>
                        </Menu>
                      )
                    ) : (
                      <Badge
                        backgroundColor={'#dd67ae'}
                        color="white"
                        mt={1}
                        width={'180px'}
                        fontSize={'10px'}
                        borderRadius={'5px'}
                        textAlign={'center'}
                      >
                        No Client
                      </Badge>
                    )}
                  </Td>
                  <Td textAlign={'center'}>
                    <Box display={"flex"} justifyContent={'space-between'}>
                      <Badge bg={user.status_employee_id === 1 ? '#ff9fb8' : '#8a6dce'} width={"88px"} color={"white"} borderRadius={'5px'} >
                        {user.status ? user.status.name : 'No Status'}
                      </Badge>
                      <Text
                        flex={'1'}
                        textAlign={'center'}
                      >
                        <Badge bg={getColorSchemeForLocation(user.location_id)} width={"50px"} color={"white"} borderRadius={'5px'}>
                          {(() => {
                            switch (user.location_id) {
                              case 1:
                                return "MX";
                              case 2:
                                return "BZ";
                              case 3:
                                return "BZ";
                              case 4:
                                return "RD";
                              case 5:
                                return "COL";
                              case 6:
                                return "GTM";
                              default:
                                return "MX";
                            }
                          })()}
                        </Badge>
                      </Text>

                    </Box>
                  </Td>
                  <Td textAlign={'center'}>
                    <UserButtons
                      user={user}
                      userAuth={userAuth}
                      handleInfo={handleInfo}
                      handleUpdate={handleUpdate}
                      handleUpdateNew={handleUpdateNew}
                      handleUpdateAD={handleUpdateAD}
                      handleUpdateClient={handleUpdateClient}
                      handleUpdateTU={handleUpdateTU}
                      handleCreateSchedule={handleCreateSchedule}
                      handleReset={handleReset}
                      handleDelete={handleDelete}
                      handleChangePassword={handleChangePassword}
                      loading={loading}
                    />
                  </Td>
                  {isViewerOpen && selectedImage && (
                    <ImageViewer
                      src={[selectedImage]}
                      currentIndex={0}
                      onClose={closeImageViewer}
                      disableScroll={false}
                      backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)"
                      }}
                      closeOnClickOutside={true}
                    />
                  )}
                </Tr>
              ))
            ) : (
              <Tr>
                <Th colSpan={'9'} >
                  <Text
                    color='#1c2147'
                    fontSize={"25px"}
                    textAlign={"center"}
                    paddingTop={"15px"}
                    paddingBottom={"15px"}
                    bg={"#f2f2f2"}
                    textTransform={'none'}
                    fontFamily={'DM Sans'}
                    letterSpacing="-1px"
                  >
                    No data
                  </Text>
                </Th>
              </Tr>
            )}
          </Tbody>
        </Table>
        <Box width={"100%"} display={"flex"} mt={"30px"}>
          <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
            {total === 0 ? (
              <>
                <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                  <Box mt={'5px'} mr={2} ml={4}>
                    <FaUsers color={"#1c2147"} fontWeight={'bold'} />
                  </Box>
                  <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                    No users
                  </Text>
                </Flex>
              </>
            ) : (
              <>
                <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                  <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                    {total}
                  </Text>
                  <Box mt={'5px'} mr={1}>
                    <FaUsers color={"#1c2147"} fontWeight={'bold'} />
                  </Box>
                  <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                    {total === 1 ? 'User' : 'Users'}
                  </Text>
                </Flex>
              </>
            )}
          </Flex>
          <ButtonGroup isAttached variant="outline" ml={"34%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
            <Button
              borderRadius={'0px'}
              onClick={() => setCurrentPage(currentPage - 1)}
              isDisabled={currentPage === 1}
              bg="#808080" color={"white"}
              _hover={{ background: "#808080", }}
              _active={{ background: "#808080", }}
            ><BsArrowLeftShort fontSize="20px" />
            </Button>
            <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
            <Button
              borderRadius={'0px'} ml={4}
              onClick={() => setCurrentPage(currentPage + 1)}
              isDisabled={currentPage === totalPages}
              bg="#808080" color={"white"}
              _hover={{ background: "#808080", }}
              _active={{ background: "#808080", }}
            ><BsArrowRightShort fontSize="20px" />
            </Button>
          </ButtonGroup>
        </Box>
      </Flex>

    </>
  );
}

export default UserList;
