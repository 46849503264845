import React from 'react';
import {
    Table, Tbody, Tr, Th, Td, TableContainer,
    Button, ButtonGroup, Text, Flex,
} from '@chakra-ui/react'
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

function BreaksList({ daysOff, total, totalPages, currentPage, setCurrentPage, dataBreak }) {

    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'
            >
                <Flex
                    direction='column'
                    w='100%'
                    p={3}
                    borderRadius="16px"
                >
                    
                    <Flex display={'flex'} justifyContent={'center'} mt={5}>
                        {total === 0 ? (
                            <>
                                <Flex bg={'#f2f2f2'} width={'130px'} display={'flex'} justifyContent={'center'}>
                                    <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                        No breaks
                                    </Text>
                                </Flex>
                            </>
                        ) : (
                            <>
                                <Flex bg={'#f2f2f2'} width={'130px'} borderRadius={"5px"} display={'flex'} justifyContent={'center'}>
                                    <Text fontSize={'14px'} mt={1} mr={1} color={"#1c2147"} fontWeight={'bold'}>
                                        {total}
                                    </Text>
                                    <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                        {total === 1 ? 'Break' : 'Breaks'}
                                    </Text>
                                </Flex>
                            </>
                        )}
                    </Flex>
                </Flex>
                <Table size={'sm'}>
                    <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                        {daysOff && daysOff.length > 0 ? (
                            daysOff.map((breakItem, index) => {
                                const dataLunchItem = dataBreak[index] || { delay: '', breaktimer: '' };

                                return (
                                    <Tr key={breakItem.id} borderBottom={"2px"} borderColor={"#cccccc"}>
                                        <Td textAlign={'center'} fontSize={'12px'}>
                                            {breakItem.user_info.name.split(' ')[0]} {breakItem.user_info.last_name.split(' ')[0]}
                                        </Td>
                                        <Td textAlign={'center'} color={"#7080fc"} fontSize={'12px'}>
                                            {dataLunchItem.breaktimer}
                                        </Td>
                                    </Tr>
                                );
                            })
                        ) : (
                            <Tr>
                                <Th colSpan={'9'} >
                                    <Text
                                        color='#1c2147'
                                        fontSize={"25px"}
                                        textAlign={"center"}
                                        paddingTop={"15px"}
                                        paddingBottom={"15px"}
                                        bg={"#f2f2f2"}
                                        textTransform={'none'}
                                        fontFamily={'DM Sans'}
                                        letterSpacing="-1px"
                                    >
                                        No data
                                    </Text>
                                </Th>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </Flex>
            <ButtonGroup mt={4} variant="outline" borderRadius={'10px'} mb={'2'} justifyContent={'center'} display={'flex'}>
                <Button
                    borderRadius={'10px'}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    isDisabled={currentPage === 1}
                    bg="#cccccc"
                    color={"white"}
                    _hover={{
                        background: "#808080",
                    }}
                    _active={{
                        background: "#808080",
                    }}
                >
                    <MdChevronLeft fontSize="25px" />
                </Button>
                <Text width={'50px'} fontSize={'14px'} ml={4} p={2}>{currentPage} of {totalPages}</Text>
                <Button
                    ml={4}
                    borderRadius={'10px'}
                    onClick={() => setCurrentPage(currentPage + 1)}
                    isDisabled={currentPage === totalPages}
                    bg="#cccccc"
                    color={"white"}
                    _hover={{
                        background: "#808080",
                    }}
                    _active={{
                        background: "#808080",
                    }}
                >
                    <MdChevronRight fontSize="25px" />
                </Button>
            </ButtonGroup>

        </TableContainer>
    );
}
export default BreaksList; 