import React from 'react';

//Icons
import { Icon } from '@chakra-ui/react';
import { MdHome } from 'react-icons/md';
import { IoTicketSharp } from "react-icons/io5";

//Templates
import DashboardsDefault from 'views/admin/dashboards/default';
import DefaultUser from 'views/admin/main/others/messages/indexUser';
import IncidentsAgentOverview from 'views/admin/main/incidents/indexAgent';


import MyScheduleOverview from 'views/admin/main/schedules/myschedule';
import { FaRegCalendar } from "react-icons/fa6";
import OverviewProfile from 'views/admin/main/profile';

const routesAgents = [
  {
    name: 'Home',
    layout: '/admin',
    path: '/home',
    icon: <Icon as={MdHome} width="30px" height="30px" color="inherit" />,
    component: <DashboardsDefault />,
  },
  {
    name: 'My schedule',
    path: '/my-schedule',
    icon: <Icon as={FaRegCalendar} width="30px" height="30px" color="inherit" />,
    layout: '/admin',
    component: <MyScheduleOverview />,
  },
  {
    name: 'Tickets',
    path: '/Ticket',
    icon: <Icon as={IoTicketSharp} width="30px" height="30px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Ongoing',
        layout: '/admin',
        path: '/ticketU/chat',
        exact: false,
        component: <DefaultUser />,
      },
      {
        name: 'My Completed',
        layout: '/admin',
        path: '/ticketU/finish',
        exact: false,
        component: <IncidentsAgentOverview />,
      },
      // {
      //   name: 'Profile',
      //   layout: '/admin',
      //   path: '/profile',
      //   exact: false,
      //   component: <OverviewProfile />,
      // },
    ],
  },
];

export default routesAgents;
