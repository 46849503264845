import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2'
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody,
  Button, Select,
  Flex, Box,
  useToast,
  Alert, AlertIcon, AlertDescription,
  Text, Input,
  FormErrorMessage, FormControl,
} from '@chakra-ui/react';
import { AiOutlineUser } from "react-icons/ai";

const ModalCreateSchedule = ({ isOpen, onClose, user }) => {

  const toast = useToast();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [schedules, setSchedules] = useState([]);
  const [timeStarts, setTimeStarts] = useState(['08:00:00', '08:00:00', '08:00:00', '08:00:00', '08:00:00', '08:00:00', '08:00:00']);
  const [timeEnds, setTimeEnds] = useState(['16:30:00', '16:30:00', '16:30:00', '16:30:00', '16:30:00', '16:30:00', '16:30:00']);
  const [status, setStatus] = useState(["1", "1", "1", "1", "1", "1", "1"]);
  const [hours, setHours] = useState(["8", "8", "8", "8", "8", "8", "8"]);
  const [minutes, setMinutes] = useState(['30', '30', '30', '30', '30', '30', '30']);
  const [description, setDescription] = useState(['', '', '', '', '', '', '']);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsg2, setErrorMsg2] = useState("");
  const [days, setDays] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const calculateTimeEnd = (index) => {
      if (timeStarts[index] && hours[index] !== '' && minutes[index] !== '') {
        const startTime = moment(timeStarts[index], 'HH:mm');
        const endTime = startTime.clone().add(hours[index], 'hours').add(minutes[index], 'minutes');
        const formattedEndTime = endTime.format('HH:mm');
        setTimeEnds((prevTimeEnds) => {
          const newTimeEnds = [...prevTimeEnds];
          newTimeEnds[index] = formattedEndTime;
          return newTimeEnds;
        });
      }
    };
    for (let i = 0; i < 7; i++) {
      calculateTimeEnd(i);
    }
  }, [timeStarts, hours, minutes]);


  const handleCreateSchedules = async () => {
    try {
      const newSchedules = [];

      for (let i = 0; i < 7; i++) {
        const scheduleData = {
          startDate,
          endDate,
          id_operator: user.id,
          status: status[i],
          hours: hours[i],
          minutes: minutes[i],
          time_start: timeStarts[i],
          time_end: timeEnds[i],
          description: description[i],
        };
        newSchedules.push(scheduleData);
      }

      const response = await axios.post('/schedule/createWeekly', {
        startDate,
        endDate,
        id_operator: user.id,
        time_start: timeStarts,
        time_end: timeEnds,
        status,
        hours,
        minutes,
        description
      });

      if (response.data.msg) {
        setErrorMsg(response.data.msg);
      } else {
        const result = await Swal.fire({
          title: 'Weekly Schedule Generated',
          html: `Do you want to continue creating schedules for <span style="color: #808080; font-weight: bold;">${user.name} ${user.last_name}</span> in the date range <span style="color: #1c2147; font-weight: bold;">${startDate}</span> to <span style="color: #1c2147; font-weight: bold;">${endDate}</span>?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        });

        if (result.isConfirmed) {

        } else {

          onClose();
        }
        toast({
          title: 'Weekly Schedule Generated',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setSchedules(response.data);
      }
    } catch (error) {
      toast({
        title: "Error in generating the weekly schedule.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setErrorMsg2('This field is required');
      console.error('Error creating schedule:', error);
    }
  };

  const fetchDays = async () => {
    try {
      if (startDate && endDate) {
        const response = await axios.get('/schedule/daysName', {
          params: { startDate, endDate },
        });

        const data = response.data;
        setDays(data);
      }
    } catch (error) {
      console.error('Error fetching days:', error);
    }
  };

  const fetchDataWeekly = async () => {
    try {
      if (startDate && endDate) {
        const response = await axios.get(`/schedule/beforeWeek/${user.id}`, {
          params: { startDate, endDate },
        });

        const data = response.data;
        setWeeklyData(data);
      }
    } catch (error) {
      console.error('Error fetching weekly data:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([fetchDays(), fetchDataWeekly()]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const calculateTotalTime = () => {
    let totalHours = 0;
    let totalMinutes = 0;

    // Conjunto de valores permitidos, tanto en string como en número
    const allowedStatuses = new Set([1, 3, 12, "1", "3", "12"]);

    hours.forEach((h, i) => {
      // Verificar si el status[i] está en el conjunto de valores permitidos
      if (allowedStatuses.has(status[i])) {
        let currentHours = parseInt(h) || 0;
        let currentMinutes = parseInt(minutes[i]) || 0;

        // Validar si las horas y minutos son mayores o iguales a 8:30
        if (currentHours > 8 || (currentHours === 8 && currentMinutes >= 30)) {
          // Restar 30 minutos
          currentMinutes -= 30;
          if (currentMinutes < 0) {
            currentMinutes += 60;
            currentHours -= 1;
          }
        }

        totalHours += currentHours;
        totalMinutes += currentMinutes;
      }
    });

    // Convertir los minutos en horas si hay más de 60
    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes = totalMinutes % 60;

    return `${totalHours} hrs ${totalMinutes} mins`;
  };




  useEffect(() => {
    if (weeklyData.length === 7) {
      setTimeStarts(weeklyData.map((data) => data.time_start || ''));
      setTimeEnds(weeklyData.map((data) => data.time_end || ''));
      setHours(weeklyData.map((data) => (data.hours !== null ? data.hours : 0)));
      setMinutes(weeklyData.map((data) => (data.minutes !== null ? data.minutes : 0)));
      setStatus(weeklyData.map((data) => (data.status !== null ? data.status : '')));
    }
  }, [weeklyData]);



  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'6xl'} isCentered closeOnOverlayClick={false}
      closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mt={'70px'} display={'flex'} justifyContent={'center'}>
          <Flex direction={'column'}>
            <Text fontSize={'40px'} color={'#1c2147'}>
              Weekly Schedules
            </Text>
            <Text color={'#808080'} display={'flex'} justifyContent={'center'} mb={'30px'}>
              <Box mr={2}>
                <AiOutlineUser size={'25px'} />
              </Box>
              {user.name} {user.last_name}
            </Text>
          </Flex>
        </ModalHeader>

        <ModalBody>
          <Flex direction={'column'} >
            <Flex justifyContent={'space-around'} display={'flex'} mt={'10px'}>
              <Text textAlign={'center'} color={'#808080'}>
                Start date
              </Text>
              <Text textAlign={'center'} color={'#808080'}>
                End date
              </Text>
            </Flex>
            <Flex mt={'40px'}>
              <FormControl isInvalid={!!errorMsg2} textAlign={'center'}>
                <Input type="date" onChange={(e) => setStartDate(e.target.value)} width={'350px'} textAlign={'center'} />
                {errorMsg2 && (
                  <FormErrorMessage display={'flex'} justifyContent={'center'}>This field is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errorMsg2} textAlign={'center'}>
                <Input type="date" onChange={(e) => setEndDate(e.target.value)} width={'350px'} textAlign={'center'} />
                {errorMsg2 && (
                  <FormErrorMessage display={'flex'} justifyContent={'center'}>This field is required.</FormErrorMessage>
                )}
              </FormControl>

            </Flex>

            <Flex justifyContent={'space-evenly'} display={'flex'} mt={'40px'} >
              <Text textAlign={'center'} color={'#808080'} width={'200px'}>
                Date
              </Text>
              <Text textAlign={'center'} color={'#808080'} width={'200px'}>
                Start time
              </Text>
              <Text textAlign={'center'} color={'#808080'} width={'200px'} >
                Duration
              </Text>
              <Text textAlign={'center'} color={'#808080'} width={'150px'} >
                End time
              </Text>
              <Text textAlign={'center'} color={'#808080'} width={'200px'} mr={'10%'}>
                Note
              </Text>
              <Text textAlign={'center'} color={'#808080'} width={'100px'} mr={'8%'}>
                Status day
              </Text>
            </Flex>

            {Array.from({ length: 7 }, (_, i) => {
              const day = days[i] || '';
              const data = weeklyData[i] || '';
              return (
                <Flex key={i} mt={'25px'} bg={'#f2f2f2'} justifyContent={'space-around'} height={'70px'} alignItems={'center'}>
                  <Text fontWeight={'bold'} color={'#1c2147'}>
                    {day.substring(0, 3)}
                  </Text>
                  <Text fontWeight={'bold'} color={'#808080'}>
                    {day.substring(4)}
                  </Text>
                  <Input
                    type="time"
                    value={timeStarts[i]}
                    bg={'white'}
                    width={'150px'}
                    textAlign={'center'}
                    mr={'5px'}
                    onChange={(e) => {
                      const newTimeStarts = [...timeStarts];
                      newTimeStarts[i] = e.target.value;
                      setTimeStarts(newTimeStarts);
                    }}
                  />
                  <Input
                    type="number"
                    placeholder="Hours"
                    bg={'white'}
                    width={'60px'}
                    textAlign={'center'}
                    value={hours[i]}
                    onChange={(e) => {
                      const newHours = [...hours];
                      newHours[i] = e.target.value;
                      setHours(newHours);
                    }}
                  />
                  <Input
                    type="number"
                    placeholder="Minutes"
                    bg={'white'}
                    width={'60px'}
                    textAlign={'center'}
                    value={minutes[i]}
                    onChange={(e) => {
                      const newMinutes = [...minutes];
                      newMinutes[i] = e.target.value;
                      setMinutes(newMinutes);
                    }}
                  />
                  <Input
                    type="time"
                    value={timeEnds[i]}
                    bg={'white'}
                    width={'150px'}
                    textAlign={'center'}
                    readOnly
                    onChange={(e) => {
                      const newTimeEnds = [...timeEnds];
                      newTimeEnds[i] = e.target.value;
                      setTimeEnds(newTimeEnds);
                    }}
                  />
                  <Input
                    type="text"
                    value={description[i]}
                    bg={'white'}
                    width={'200px'}
                    textAlign={'center'}
                    onChange={(e) => {
                      const newDescription = [...description];
                      newDescription[i] = e.target.value;
                      setDescription(newDescription);
                    }}
                  />
                  <Select
                    fontSize={'md'}
                    borderRadius={'5px'}
                    width={'auto'}
                    name='status'
                    value={status[i]}
                    onChange={(e) => {
                      const newStatus = [...status];
                      newStatus[i] = e.target.value;
                      setStatus(newStatus);
                    }}
                    color={'#808080'}
                    background={"white"}
                    textAlign={"center"}
                    errorBorderColor='#fc7466'
                    placeholder='Status'>
                    <option value='1'>Normal</option>
                    <option value='12'>Agent Overnight</option>
                    <option value='14'>Stand by</option>
                    <option value='2'>Off</option>
                    <option value='3'>Holiday</option>
                    <option value='4'>Vacation</option>
                    <option value='5'>Maternity Leave</option>
                    <option value='6'>Permission request</option>
                    <option value='7'>Wedding Leave</option>
                    <option value='8'>Medical Leave</option>
                    <option value='9'>Paternity Leave</option>
                    <option value='10'>Bereavement Leave</option>
                  </Select>
                </Flex>
              );
            })}

            <Flex p={'2%'}>
              <Text color={'#808080'} width={'100%'}>
                Total Time:
                <Text as="span" color={'#1c2147'} ml={'1%'} fontWeight={'bold'}>{calculateTotalTime()} </Text>
              </Text>
            </Flex>

            <Flex justifyContent='center' display='flex' alignItems={'center'} borderRadius={'5px'} mt={'50px'} mb={'50px'}>
              <Flex justifyContent={'center'} display={'flex'}>
                <Button
                  bg={'#b9ce67'}
                  width={'100px'}
                  color={'white'}
                  borderRadius={'5px'}
                  _hover={{
                    backgroundColor: "#8aad01",
                  }}
                  onClick={handleCreateSchedules}
                >
                  Create
                </Button>
              </Flex>
              <Flex justifyContent={'center'} display={'flex'} ml={'20px'}>
                <Button
                  onClick={onClose}
                  bg={'#7080fc'}
                  width={'100px'}
                  color={'white'}
                  borderRadius={'5px'}
                  _hover={{
                    backgroundColor: "#fc7466",
                  }}
                >
                  Close
                </Button>
              </Flex>
            </Flex>

            <Flex display={'flex'} alignItems={'center'} justifyContent={'center'} mb={'30px'}>
              <Flex>
                {errorMsg && (
                  <Alert status="error" width={'500px'} mt={'10px'} display={'flex'} justifyContent={'center'} style={{ backgroundColor: 'rgba(252, 116, 102, 0.3)' }}>
                    <AlertIcon color={'#1c2147'} />
                    <AlertDescription fontSize={'20px'} color={'#1c2147'}>{errorMsg}</AlertDescription>
                  </Alert>
                )}
              </Flex>
            </Flex>
          </Flex>

        </ModalBody>
      </ModalContent>
    </Modal >
  );
};

export default ModalCreateSchedule;