// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import BonoInformation from './Bono';
import { FaCamera } from "react-icons/fa";

export default function Banner(props) {
  const { banner, avatar, name, job, dateStart, dateStartCalls, user, bonoEnable, updateImage, ...rest } =
    props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const borderColor = useColorModeValue(
    'white !important',
    '#111C44 !important',
  );


  return (
    <Card mb={{ base: '0px', lg: '20px' }} alignItems="center" {...rest}>
      <Box
        bgGradient="linear(to-b, #1c2147, #141833)"
        bgSize="cover"
        borderRadius="16px"
        h="131px"
        w="100%"
      />
      <Box
        position="relative"
        mx="auto"
        mt="-43px"
        cursor="pointer"
        onClick={updateImage}
        _hover={{ ".hoverText": { display: "block" } }}
      >
        <Avatar
          src={avatar}
          h="87px"
          w="87px"
          border="4px solid"
          borderColor={borderColor}
        />
        <Text
          className="hoverText"
          display="none"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          bg="rgba(0, 0, 0, 0.7)"
          color="white"
          p="2px 8px"
          borderRadius="4px"
        >
          <FaCamera />
        </Text>
      </Box>
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">
        {job}
      </Text>
      <Flex w="max-content" mx="auto" mt="26px">
        <Flex mx="auto" me="60px" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {dateStart}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Date start
          </Text>
        </Flex>
        <Flex mx="auto" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {dateStartCalls}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Date start calls
          </Text>
        </Flex>
      </Flex>
      {[22, 36, 43, 44].includes(user.type_user_id)
        && [1, 2, 3].includes(user.location_id)
        && [1, 2].includes(user.status_employee_id)
        && bonoEnable
        ? <BonoInformation />
        :
        null
      }
    </Card>
  );
}
