import React, { useState } from 'react';
import axios from 'axios';
import Select2 from 'react-select'
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Heading,
    Button,
    Box, Flex, SimpleGrid,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons'
import { AiOutlineClear } from "react-icons/ai";

const RecordingsModal = ({ isOpen, onClose, fetchData, user }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [userSearch, setUserSearch] = useState([]);
    const [loading, setLoading] = useState(false);
    const [resetKey, setResetKey] = useState(0);

    const clearFilters = () => {
        setUserSearch(null);
        setCurrentPage(1);
        setResetKey(resetKey + 1);
    }

    const getData = async () => {
        try {
            const response = await axios.get(
                `/getRecording?page=${currentPage}&
                ${userSearch && userSearch.length > 0 ? `&agent=${userSearch.join(',')}` : ''}`
            );
            fetchData();
            onClose();
            clearFilters();
        } catch (error) {
            console.error('Error fetching schedules:', error);
        } finally {
            setLoading(false);
        }
    };

    const moreData = async () => {
        try {
            const response = await axios.get(
                `/moreRecording?page=${currentPage}&
                ${userSearch && userSearch.length > 0 ? `&agent=${userSearch.join(',')}` : ''}`
            );
            fetchData();
            onClose();
            clearFilters();
        } catch (error) {
            console.error('Error fetching schedules:', error);
        } finally {
            setLoading(false);
        }
    };

    const optionsUsers = user.map(user => ({
        value: user.id,
        label: `${user.name} ${user.last_name}`,
    }));

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };



    const customStylesRecordings = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            marginLeft: '20px',
            fontSize: '16px',
            width: 'auto',
            height: 'max-content',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? 'green' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };


    const handleSelectChange = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                const selectedValues = selectedOption ? selectedOption.map(option => option.value) : [];
                setUserSearch(selectedValues);
                break;

            default:
                break;
        }
    };



    return (
        <Modal size="4xl" isOpen={isOpen} onClose={() => {
            onClose();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent mt={"5%"} borderRadius={"30px"}  >
                <Heading as="h2" fontSize="40px" color={'#1c2147'} textAlign={'center'} fontFamily="DM Sans" position="relative" mb='6' mt={10}>
                    Recordings list
                </Heading>
                <ModalHeader
                >
                    <Flex
                        direction='row' justify={"center"}
                        w='100%'
                        bg="#E2E8F0"
                        p={2}
                        borderRadius="16px"
                    >
                        <Flex flexDirection={"column"}  >
                            <Flex align="center"    >
                                <Select2
                                    options={optionsUsers}
                                    value={optionsUsers.filter(option => userSearch && userSearch.includes(option.value))}
                                    onChange={(selectedOption) => {
                                        handleSelectChange('user', selectedOption);
                                        setCurrentPage(1);
                                    }}
                                    isMulti
                                    styles={customStylesRecordings}
                                    placeholder="Select agent"
                                    key={`userSelect-${resetKey}`}
                                    isSearchable
                                    filterOption={filterOptions}
                                />



                                <Box ml={4} width={"max-content"} height={"100%"}  >
                                    <Button onClick={clearFilters} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filter">
                                        <AiOutlineClear size={'26px'} />
                                    </Button>
                                </Box>
                            </Flex>
                        </Flex>

                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Flex direction={'column'}>
                        <Flex>
                            <Button bg={"#b9ce67"} p={"22px"} ml={4} _hover={{ background: "#8aad01" }} borderRadius={"8px"} color={"white"} onClick={getData}>Generate list</Button>
                            <Button bg={"#424ea8"} p={"22px"} ml={4} _hover={{ background: "#1c2147" }} borderRadius={"8px"} color={"white"} onClick={moreData}>Add more</Button>

                        </Flex>
                        <Flex justifyContent={'center'} mt={'10%'}>
                            <Button class="buttonCancel" onClick={() => {
                                onClose();
                            }}>
                                Cancel <CloseIcon ml={2} />
                            </Button>
                        </Flex>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default RecordingsModal; 