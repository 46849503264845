import React from 'react';


import { Icon } from '@chakra-ui/react';
import { MdHome } from 'react-icons/md';
import { BsFillFilePersonFill } from "react-icons/bs";
import { FaRegCalendar } from "react-icons/fa6";
import { MdDashboardCustomize, MdDashboard } from "react-icons/md";
import { IoTicketSharp } from "react-icons/io5";

import DashboardsDefault from 'views/admin/dashboards/default';
import UsersOverview from 'views/admin/main/users/newUser/items/userDashboard';
import Default from 'views/admin/main/others/messages';
import DefaultUser from 'views/admin/main/others/messages/indexUser';
import IncidentsOverview from 'views/admin/main/incidents';
import UsersSchedulesOverview from 'views/admin/main/schedules/weeklyschedule';
import DailyOverview from 'views/admin/main/schedules/dailyschedule';
import MyScheduleOverview from 'views/admin/main/schedules/myschedule';

import RecordingsOverview from 'views/admin/main/recordings';
import IncidentsAgentOverview from 'views/admin/main/incidents/indexAgent';
import MedicalNotesOverview from 'views/admin/main/Medical_Notes';




const routesIT = [
  // --- Dashboards ---
  {
    name: 'Home',
    layout: '/admin',
    path: '/home',
    icon: <Icon as={MdHome} width="30px" height="30px" color="inherit" />,
    component: <DashboardsDefault />,
  },
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/dashboards',
    icon: <Icon as={MdDashboard} width="30px" height="30px" color="inherit" />,
    component: <DailyOverview />,
  },
  {
    name: 'Schedules',
    layout: '/admin',
    path: '/schedules',
    icon: <Icon as={FaRegCalendar} width="30px" height="30px" color="inherit" />,
    component: <UsersSchedulesOverview />,
  },
  {
    name: 'Tickets',
    path: '/Ticket',
    icon: <Icon as={IoTicketSharp} width="30px" height="30px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Requests',
        layout: '/admin',
        path: '/ticket/chat',
        exact: false,
        component: <Default />,
      },
      {
        name: 'Ongoing',
        layout: '/admin',
        path: '/ticketU/chat',
        exact: false,
        component: <DefaultUser />,
      },
      {
        name: 'Completed',
        layout: '/admin',
        path: '/ticket/dashboard',
        exact: false,
        component: <IncidentsOverview />,
      },
      {
        name: 'My Completed',
        layout: '/admin',
        path: '/ticketU/finish',
        exact: false,
        component: <IncidentsAgentOverview />,
      },
    ],
  },
  {
    name: 'Menu',
    path: '/main',
    icon: <Icon as={BsFillFilePersonFill} width="30px" height="30px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Users',
        path: '/users',
        layout: '/admin',
        component: <UsersOverview />,
      },
      {
        name: 'Medical Notes',
        path: '/MedicalNotes',
        layout: '/admin',
        component: <MedicalNotesOverview />,
      },
      {
        name: 'My schedule',
        path: '/my-schedule',
        layout: '/admin',
        component: <MyScheduleOverview />,
      },
      {
        name: 'Recordings',
        path: '/recordings',
        layout: '/admin',
        component: <RecordingsOverview />,
      },
    ],
  },
];

export default routesIT;
