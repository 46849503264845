import React from 'react';
import {
    Button, Flex, Text
} from '@chakra-ui/react'
import { RiFileExcel2Fill } from "react-icons/ri";


function FiltersAD({ handleDownloadExcel, title }) {


    return (
        <>


            <Flex mt={'6.5%'} direction='column'>
                <Flex
                    direction='row'
                    justifyContent={"space-between"}
                    w='95%'
                    bg="gray.200"
                    py={"1%"} px={"4%"}
                    borderRadius="16px"
                    mb={'2%'}
                >

                    <Flex width={"55%"} fontSize={"22px"}  mt={1} justifyContent="flex-start">
                        <Text textAlign={'center'} width={'100%'}>{title}</Text>
                    </Flex>
                    <Button class="buttonCreate" onClick={handleDownloadExcel}>
                        <Text mr={2}>
                            Excel
                        </Text>
                        <RiFileExcel2Fill />
                    </Button>

                </Flex>
            </Flex>
        </>
    );
}
export default FiltersAD; 