import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import {
    Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Menu, MenuButton, MenuItem, MenuList,
    Button, ButtonGroup,
    useToast,
    Select, Badge, Text, Input,
    Flex, Box,
} from '@chakra-ui/react'
import { AddIcon, DeleteIcon, SettingsIcon, SearchIcon, RepeatIcon, EditIcon } from '@chakra-ui/icons'
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import axios from 'axios';
import CreateUserType from './create';
import { AiOutlineClear } from "react-icons/ai";
import UpdateUserType from './update';
import { FaUserGroup } from "react-icons/fa6";
import UserTypeList from './table';

function UseFunctions() {
    const [usertype, setUserType] = useState([]);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [ModalUpdateIsOpen, setModalUpdateIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchType, setSearchType] = useState('name');
    const [selectUserType, setSelectUserType] = useState(null);
    const [flashing, setFlashing] = useState(false);

    const clearFilters = () => {
        setSearchType('');
        setSearchTerm('');
        setCurrentPage(1);
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(`/usertype?page=${currentPage}&${searchType}=${searchTerm}`);
            const responseData = response.data;
            setUserType(responseData.data);
            setTotalPages(responseData.last_page);
            setTotal(responseData.total)
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
        if (flashing) {
            const intervalId = setInterval(() => {
                setFlashing(false);
            }, 7000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [currentPage, searchTerm, searchType, flashing]);
    const handleSearch = () => {
        setCurrentPage(1);
    };
    const openModalCreate = () => {
        setModalCreateIsOpen(true);
    }
    const closeModalCreate = () => {
        setModalCreateIsOpen(false);
    }
    const handleUpdate = async (usertype) => {
        setSelectUserType(usertype);
        setModalUpdateIsOpen(true);
        try {
            const response = await axios.get(`/usertype/edit/${usertype.id}`);
            const usertype = response.data;

        } catch (error) {
            console.error('Error fetching type of user details:', error);
        }
    };
    const closeModalUpdate = () => {
        setModalUpdateIsOpen(false);
    }
    const handleDelete = async (usertype) => {
        const msg = usertype.active == 0 ? 'activated' : 'deactivate';
        const confirmDelete = await Swal.fire({
            title: `Do you want to ${msg} this user?`,
            text: `The user type will be ${msg}...`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2C5282',
            cancelButtonColor: '#E53E3E',
            confirmButtonText: `Yes, ${msg} it!`
        }).then((result) => {
            return result.isConfirmed
        });
        setFlashing(usertype);
        if (confirmDelete) {
            setLoading(true);
            setSelectUserType(usertype);
            try {
                const response = await axios.post(`/usertype/status/${usertype.id}`);
                toast({
                    title: "Status Update",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setFlashing(usertype);
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error Updating The Status Item",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        }
    };
    const getRowBackground = (usertype) => {
        if (selectUserType && selectUserType.id === usertype.id) {
            if (flashing) {
                return '#fcd677';
            }
            return usertype.id % 2 === 0 ? '#f2f2f2' : 'white';
        } else {
            return usertype.id % 2 === 0 ? '#f2f2f2' : 'white';
        }
    };
    return (
        <UserTypeList
            openModalCreate={openModalCreate}
            searchType={searchType}
            setSearchType={setSearchType}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleSearch={handleSearch}
            clearFilters={clearFilters}
            usertype={usertype}
            getRowBackground={getRowBackground}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            loading={loading}
            total={total}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalPages={totalPages}
            modalCreateIsOpen={modalCreateIsOpen}
            closeModalCreate={closeModalCreate}
            fetchData={fetchData}
            ModalUpdateIsOpen={ModalUpdateIsOpen}
            closeModalUpdate={closeModalUpdate}
            selectUserType={selectUserType}
            setFlashing={setFlashing}
            UserTypeList={UserTypeList}
        />
    );
}
export default UseFunctions;