import { Image, Link, Box } from "@chakra-ui/react";
import error from "assets/img/others/404 2 UNIQUE.jpg";
import React from "react";

function Alerts() {
  return (
    <Box>
      <Link href="/">
        <Image
          src={error}
          alt="Descripción de la imagen"
          maxWidth="100%"
          height="100vh"
        />
      </Link>
    </Box>
  );
}
export default Alerts;