import Select2 from 'react-select'
import {
    Button, Input,
    Flex, Box
} from '@chakra-ui/react'
import { RepeatIcon } from '@chakra-ui/icons'
import { AiOutlineClear } from "react-icons/ai";


function MedicalNotesFilters({ clientSearch, usersClients, optionsUsers, userSearch, handleSelectChange, setCurrentPage, customStyles, filterOptions, setFilterDateEnd, optionClients, setFilterDate, resetKey, handleClientChange, filterDate, filterDateEnd, clearFilters, openModalCreate, user }) {

    return (

        <Flex
            direction='column' justify={"space-evenly"}
            w='100%'
            p={2}
            borderRadius="16px"
        >
            <Flex
                direction='row' justify={"right"}
                w='100%'
                p={2}
                borderRadius="16px"
            >

                {[1, 2, 3, 4, 5, 6, 7, 8, 9].includes(user.type_user_id) ? (
                    <Button width={"max-content"} bg={"#b9ce67;"} _hover={{ background: "#8aad01" }} borderRadius={"8px"} color={"white"} onClick={openModalCreate}>Add Note</Button>
                ) : null}


            </Flex>
            <Flex flexDirection={"column"} bg="gray.200" p={2} borderRadius={"16px"} >
                <Flex align="center"    >
                    <Select2
                        options={clientSearch > 0 ? usersClients : optionsUsers}
                        value={clientSearch > 0 ? usersClients.find(option => option.value === userSearch) : optionsUsers.find(option => option.value === userSearch)}
                        onChange={(selectedOption) => {
                            handleSelectChange('user', selectedOption);
                            setCurrentPage(1);
                        }}
                        styles={customStyles}
                        isSearchable
                        filterOption={filterOptions}
                        placeholder="Select agent"
                        key={`userSelect-${resetKey}`}
                    />
                    <Select2
                        options={optionClients}
                        value={optionClients.find(option => option.value === clientSearch)}
                        onChange={(selectedOption) => {
                            handleClientChange(selectedOption);
                            setCurrentPage(1);
                        }}
                        styles={customStyles}
                        placeholder="Select client"
                        key={`clientSelect-${resetKey}`}
                    />

                    <Flex
                        border={'1px solid #808080'}
                        display={'flex'}
                        borderRadius={'4px'}
                        ml={6} mr={4}
                    >
                        <Input
                            width={'200px'}
                            border={'0px'}
                            type="date"
                            fontSize={"sm"}
                            value={filterDate}
                            onChange={(e) => {
                                setFilterDate(e.target.value);
                                setCurrentPage(1);
                            }}
                            _focusVisible={'none'}
                            color={'#808080'}
                        />
                        <Button bg={'none'} color={'#808080'} onClick={() => setFilterDate('')}>
                            <RepeatIcon />
                        </Button>
                    </Flex>
                    <Flex
                        border={'1px solid #808080'}
                        display={'flex'}
                        borderRadius={'4px'}
                        ml={2} mr={4}
                    >
                        <Input
                            width={'200px'}
                            border={'0px'}
                            type="date"
                            fontSize={"sm"}
                            value={filterDateEnd}
                            onChange={(e) => {
                                setFilterDateEnd(e.target.value);
                                setCurrentPage(1);
                            }}
                            _focusVisible={'none'}
                            color={'#808080'}
                        />
                        <Button bg={'none'} color={'#808080'} onClick={() => setFilterDateEnd('')}>
                            <RepeatIcon />
                        </Button>
                    </Flex>

                    <Box height={"max-content"} width={"max-content"} >
                        <Button onClick={clearFilters} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                            <AiOutlineClear size={'26px'} />
                        </Button>
                    </Box>
                </Flex>

            </Flex>

        </Flex>
    );
}
export default MedicalNotesFilters; 