import { Flex, Button, Text, Box } from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ModalOff from "./components/itemsDaily/modalOff";
import LunchList from "./components/itemsDaily/tableLunch";
import BreaksList from "./components/itemsDaily/tableBreaks";
import FunctionsDashboard from "./components/itemsDaily/functionsDashboard";
import OnIncidentList from "./components/itemsDaily/tableIncident";

export default function DailyOverview() {

    const [breaks, setBreaks] = useState([]);
    const [dataLunch, setDataLunch] = useState([]);

    const [dayOff, setDayOff] = useState([]);
    const [dataBreak, setDataBreak] = useState([]);

    const [onIncident, setOnIncident] = useState([]);
    const [dataIncident, setDataIncident] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');

    const [currentPage2, setCurrentPage2] = useState(1);
    const [totalPages2, setTotalPages2] = useState(1);
    const [total2, setTotal2] = useState('');

    const [currentPage3, setCurrentPage3] = useState(1);
    const [totalPages3, setTotalPages3] = useState(1);
    const [total3, setTotal3] = useState('');


    const [operatorSearch, setOperatorSearch] = useState('');
    const [startDateFilter, setStartDateFilter] = useState(null);

    const [openDaysOff, setOpenDaysOff] = useState(false);
    const [showLunch, setShowLunch] = useState(true);
    const [showBreak, setShowBreak] = useState(true);
    const [showIncident, setShowIncident] = useState(true);

    const openInfoDayOff = () => {
        if (showLunch === true) {
            setShowLunch(false);
        } else {
            setShowLunch(true);
        }
    };

    const openInfoBreak = () => {
        if (showBreak === true) {
            setShowBreak(false);
        } else {
            setShowBreak(true);
        }
    };
    const openInfoIncident = () => {
        if (showIncident === true) {
            setShowIncident(false);
        } else {
            setShowIncident(true);
        }
    };

    const handleOpenModal = () => {
        setOpenDaysOff(true);
    };

    const handleCloseModal = () => {
        setOpenDaysOff(false);
    };

    const fetchData = async () => {

        const dateStartParam = startDateFilter ? `&date=${startDateFilter.toISOString()}` : '';

        try {
            const response = await axios.get(`/breaks?page=${currentPage}${dateStartParam}`);
            const responseData = response.data;

            setBreaks(responseData.breaks.data);
            setTotalPages(responseData.breaks.last_page);
            setTotal(responseData.breaks.total);
            setDataLunch(responseData.breakTimers);


        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };


    const fetchDataLunch = async () => {
        const dateStartParam = startDateFilter ? `&date=${startDateFilter.toISOString()}` : '';
        try {
            const response = await axios.get(
                `/breaksL?page=${currentPage2}${dateStartParam}`
            );
            const responseData = response.data;
            setDayOff(responseData.breaks.data);
            setTotalPages2(responseData.breaks.last_page);
            setTotal2(responseData.breaks.total)
            setDataBreak(responseData.breakTimers);

        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchDataOnIncident = async () => {
        const dateStartParam = startDateFilter ? `&start=${startDateFilter.toISOString()}` : '';
        try {
            const response = await axios.get(
                `/onIncident?page=${currentPage2}${dateStartParam}`
            );
            const responseData = response.data;
            setOnIncident(responseData.incidents.data);
            setTotalPages3(responseData.incidents.last_page);
            setTotal3(responseData.incidents.total)
            setDataIncident(responseData.incidentTimers);

        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };


    const handleRefresh = () => {
        fetchData();
        fetchDataLunch();
        fetchDataOnIncident();
    }

    useEffect(() => {
        fetchData();
        fetchDataLunch();
        fetchDataOnIncident();
    }, [currentPage, currentPage2, startDateFilter, currentPage3]);

    return (
        <Flex direction={{ base: 'column', lg: 'row' }} pt={{ sm: "125px", lg: "75px" }} mt={'80px'}  >
            <Box className='dashboardcard' >
                <FunctionsDashboard
                    sync={handleRefresh}
                    operatorSearch={operatorSearch}
                    setOperatorSearch={setOperatorSearch}
                    currentPage2={currentPage}
                    setCurrentPage2={setCurrentPage}
                    currentPage3={currentPage2}
                    setCurrentPage3={setCurrentPage2}
                    startDateFilter={startDateFilter}
                    setStartDateFilter={setStartDateFilter}
                />
            </Box>
            <Flex className='dailyscheduleflex'>
                <Flex align="center" justifyContent="center" >
                    <Button fontSize={'25px'} width={"200px"} borderRadius={'5px'} _hover={{ background: "#808080", color: 'white' }} color={'white'} bg={'#3e4a9e'} onClick={handleOpenModal}>
                        <Text ml={'2'}>
                            Days off
                        </Text>
                    </Button>
                </Flex>
                <Flex align="center" justifyContent="center" >
                    <Button width={"200px"} mt={4} bg={"#fb9539"} py={1} px={4} _hover={{ background: "#808080", color: 'white' }} borderRadius={"10px"} style={{ display: 'flex', alignItems: 'center', color: 'white', fontWeight: 'bold', fontSize: '25px' }} onClick={openInfoDayOff}>
                        Lunch
                    </Button>
                </Flex>
                {
                    showLunch && (
                        <Card mt={4}>
                            <LunchList breaksL={breaks} total={total} totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} dataLunch={dataLunch} />
                        </Card>
                    )
                }
                <Flex align="center" justifyContent="center" >
                    <Button width={"200px"} mt={4} bg={"#7a86e6"} py={1} px={6} _hover={{ background: "#808080", color: 'white' }} borderRadius={"10px"} style={{ display: 'flex', alignItems: 'center', color: 'white', fontWeight: 'bold', fontSize: '25px' }} onClick={openInfoBreak}>
                        Breaks
                    </Button>
                </Flex>
                {
                    showBreak && (
                        <Card mt={4}>
                            <BreaksList daysOff={dayOff} total={total2} totalPages={totalPages2} currentPage={currentPage2} setCurrentPage={setCurrentPage2} dataBreak={dataBreak} />
                        </Card>
                    )
                }
                <Flex align="center" justifyContent="center" >
                    <Button width={"200px"} mt={4} bg={"#ff6f94"} py={1} px={6} _hover={{ background: "#808080", color: 'white' }} borderRadius={"10px"} style={{ display: 'flex', alignItems: 'center', color: 'white', fontWeight: 'bold', fontSize: '25px' }} onClick={openInfoIncident}>
                        IT issues
                    </Button>
                </Flex>
                {
                    showIncident && (
                        <Card mt={4}>
                            <OnIncidentList incident={onIncident} total={total3} totalPages={totalPages3} currentPage={currentPage3} setCurrentPage={setCurrentPage3} dataIncident={dataIncident} />
                        </Card>
                    )
                }


            </Flex>
            {
                openDaysOff && (
                    <ModalOff
                        isOpen={handleOpenModal}
                        onClose={handleCloseModal}
                    />
                )
            }
        </Flex>
    );
} 