import React from 'react';
import {
    Menu, MenuButton, MenuItem, MenuList,
    Button,
    Text} from '@chakra-ui/react'
import { SettingsIcon } from '@chakra-ui/icons'
import { FaCalendarCheck } from "react-icons/fa6";

function RecordingsButtons({ item, checkRecording, loading }) {


    return (

        <Menu>
            <MenuButton borderRadius={"5px"} as={Button} _active={{ background: "#808080", color: 'white' }} bg="#1c2147" color={"white"} _hover={{ background: "#808080", color: 'white' }}>
                <SettingsIcon pb={"2px"} />
            </MenuButton>
            <MenuList>
                <MenuItem isDisabled>
                    <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions</div>
                </MenuItem>

                <MenuItem>
                    <Button
                        borderRadius={"5px"}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        bg={'#f2f2f2'}
                        color='#1c2147'
                        _hover={{ background: "#b9ce67", color: 'white' }}
                        width="100%"
                        onClick={() => checkRecording(item)}
                        isLoading={loading}
                    >
                        <Text
                            flex={1}
                            textAlign="center"
                        >
                            Check
                        </Text>
                        <FaCalendarCheck ml={2} verticalAlign="middle" />
                    </Button>
                </MenuItem>
            </MenuList>
        </Menu>

    );
}
export default RecordingsButtons; 