import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import ReportTS from './tableTS';


function FunctionsReports() {

    const [reportsD, setReportsD] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [clientS, setClientS] = useState([]);
    const [users, setUsers] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const [clientSearch, setClientSearch] = useState('');
    const [userTypeSearch, setUserTypeSearch] = useState('');
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [typeSearch, setTypeSearch] = useState('week');
    const [usersClients, setUsersClients] = useState([]);
    const [reportsE, setReportsE] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const CancelToken = axios.CancelToken;
    let cancelRequest;

    const optionsUsers = users.map(users => ({
        value: users.id,
        label: `${users.name} ${users.last_name}`,
    }));

    const optionClients = clientS.map(clientS => ({
        value: clientS.id,
        label: clientS.name,
    }));

    const clearFilters = () => {
        setUserTypeSearch(null);
        setClientSearch('');
        setStartDateFilter('');
        setEndDateFilter('');
        setTypeSearch('week');
        setResetKey(resetKey + 1);
        setCurrentPage(1);
    }


    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const handleClientChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setClientSearch(selectedValues);

        if (selectedValues.length > 0) {
            const selectedClientIds = selectedValues.join(',');

            axios
                .get(`/userClient?id_client=${selectedClientIds}`)
                .then((response) => {
                    const userOptions = response.data.data.map((user) => ({
                        label: user.user ? `${user.user.name} ${user.user.last_name}` : '',
                        value: user.id_user,
                    }));
                    setUsersClients(userOptions);
                });
        } else {
            setUsersClients([]);
        }
    };




    useEffect(() => {
        const fetchData = async () => {
            try {
                if (cancelRequest) {
                    cancelRequest('Previous request canceled');
                }
                setIsLoading(false);

                const yourConstructedURL = `/reports/timesheets?` +
                    `${userTypeSearch ? `&operator=${encodeURIComponent(userTypeSearch)}` : ''}` +
                    `${clientSearch.length > 0 ? `&client=${clientSearch.map(client => encodeURIComponent(client)).join(',')}` : ''}` +
                    `${startDateFilter ? `&date_start=${encodeURIComponent(startDateFilter)}` : ''}` +
                    `${endDateFilter ? `&date_end=${encodeURIComponent(endDateFilter)}` : ''}` +
                    `${typeSearch ? `&date=${encodeURIComponent(typeSearch)}` : ''}`;

                const response = await axios.get(yourConstructedURL, {
                    cancelToken: new CancelToken(function executor(c) {
                        cancelRequest = c;
                    })
                });

                const responseData = response.data;
                setReportsD(responseData.data);
                setClientS(responseData.clients);
                setUsers(responseData.operators);
                setTotalPages(responseData.data.last_page);
                setTotal(responseData.total_data);

                if (response.status === 200) {
                    if (clientSearch.length === 0) {
                        alert('Select a client');
                    } else {
                        alert('You can download now!');
                    }
                }

                setIsLoading(true);

            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchData();
        return () => {
            if (cancelRequest) {
                cancelRequest('Component unmounted or dependency changed');
            }
        };
    }, [currentPage, clientSearch, userTypeSearch, startDateFilter, endDateFilter, resetKey, typeSearch]);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            marginLeft: '20px',
            fontSize: '16px',
            height: '40px',
            width: '220px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const handleSelectChangeF = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                setUserTypeSearch(selectedOption.value);
                break;
            case 'client':
                setClientSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const handleDownloadExcel = () => {
        try {

            const selectedClients = optionClients.filter(option => clientSearch.includes(option.value));
            const clientNames = selectedClients.map(client => client.label).join('_');

            const startDate = startDateFilter || '';
            const endDate = endDateFilter || '';

            const fileName = `${clientNames}_Timesheet_${startDate}_${endDate}.xlsx`;

            const ws = XLSX.utils.table_to_sheet(document.getElementById('ticketsTable'));
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

            const cols = [];
            cols.push({ wch: 47 });
            const numberOfColumns = XLSX.utils.decode_range(ws['!ref']).e.c;

            for (let i = 1; i <= numberOfColumns; i++) {
                if (i === 3) {
                    cols.push({ wch: 10 });
                } else if (i === 4) {
                    cols.push({ wch: 15 });
                } else {
                    cols.push({ wch: 27 });
                }
            }
            ws['!cols'] = cols;

            XLSX.writeFile(wb, fileName);

        } catch (error) {
            console.error('Error downloading Excel:', error);
        }
    };


    const downloadTableAsPDF = async () => {
        try {
            setLoading(true);

            const tableRef = document.getElementById('ticketsTable');
            const pdf = new jsPDF({ orientation: 'landscape' });
            const styles = {
                valign: 'middle',
                halign: 'center',
            };

            pdf.autoTable({ html: '#ticketsTable', styles });

            const selectedClient = optionClients.find(option => option.value === clientSearch);
            const clientName = selectedClient ? selectedClient.label : '';

            const startDate = startDateFilter || '';
            const endDate = endDateFilter || '';

            const fileName = `${clientName}Timesheet_${startDate}_${endDate}.pdf`;

            pdf.save(fileName);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        } finally {
            setLoading(false);
        }
    };

    const customStylesClient = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            marginLeft: '20px',
            fontSize: '16px',
            width: 'auto',
            height: '40px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };





    return (
        <ReportTS
        handleDownloadExcel={handleDownloadExcel}
        downloadTableAsPDF={downloadTableAsPDF}
        clientSearch={clientSearch}
        usersClients={usersClients}
        optionsUsers={optionsUsers}
        userTypeSearch={userTypeSearch}
        handleSelectChangeF={handleSelectChangeF}
        setCurrentPage={setCurrentPage}
        customStyles={customStyles}
        resetKey={resetKey}
        filterOptions={filterOptions}
        optionClients={optionClients}
        handleClientChange={handleClientChange}
        customStylesClient={customStylesClient}
        startDateFilter={startDateFilter}
        setStartDateFilter={setStartDateFilter}
        setTypeSearch={setTypeSearch}
        endDateFilter={endDateFilter}
        setEndDateFilter={setEndDateFilter}
        clearFilters={clearFilters}
        reportsD={reportsD}
        isLoading={isLoading}
        total={total}
        currentPage={currentPage}
        totalPages={totalPages}
        />
    );
}
export default FunctionsReports; 