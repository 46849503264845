import React, { useState, useEffect } from 'react';
import {
    TableContainer, SimpleGrid, Flex
} from '@chakra-ui/react'
import axios from 'axios';
import FiltersAD from '../itemsExports/filters/filtersAD';
import FiltersField from './filters/filtersField';
import { useUserData } from 'interceptors/userAuth';
import FiltersFieldPR from './filters/filtersFieldPR';

function UseExports() {

    const { user, fetchData } = useUserData();
    const [clientS, setClientS] = useState([]);

    const [sections, setSections] = useState({
        callouts: { startDate: null, endDate: null, filtersActive: false, isLoading: true, client: null },
        nocallnoshow: { startDate: null, endDate: null, filtersActive: false, isLoading: true, client: null },
        late: { startDate: null, endDate: null, filtersActive: false, isLoading: true, client: null },
        scheduler: { startDate: null, endDate: null, filtersActive: false, isLoading: true, client: null },
        ticketsIT: { startDate: null, endDate: null, filtersActive: false, isLoading: true, client: null },
        ticketsSchedules: { startDate: null, endDate: null, filtersActive: false, isLoading: true, client: null },
        anydesk: { startDate: '', endDate: '', client: null },
        bonos: { startDate: null, endDate: null, filtersActive: false, isLoading: true, client: null },
        timesheetC: { startDate: null, endDate: null, filtersActive: false, isLoading: true, client: null, resetKey: 0 },
        payrollSP: { startDate: null, endDate: null, filtersActive: false, isLoading: true, client: null },
        schedulerCallouts: { startDate: null, endDate: null, filtersActive: false, isLoading: true, client: null },
    });

    const exportToExcel = (section, fileName) => {
        // Antes de comenzar la solicitud, establece isLoading en true
        setSections(prevSections => ({
            ...prevSections,
            [section]: {
                ...prevSections[section],
                isLoading: false
            }
        }));

        const { startDate, endDate, client } = sections[section];
        const params = { startDate, endDate };

        if (client && client.length > 0) {
            params.client = client.map(c => encodeURIComponent(c)).join(',');
        }

        axios.get(`/reports/${section}`, {
            params,
            responseType: 'blob'
        })
            .then(response => {
                // Cuando se complete la solicitud, establece isLoading en false
                setSections(prevSections => ({
                    ...prevSections,
                    [section]: {
                        ...prevSections[section],
                        isLoading: true
                    }
                }));

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${fileName}_${startDate}_${endDate}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                // Manejar errores y también establecer isLoading en false en caso de error
                console.error('Error al descargar el archivo Excel:', error);
                setSections(prevSections => ({
                    ...prevSections,
                    [section]: {
                        ...prevSections[section],
                        isLoading: false
                    }
                }));
            });
    };



    const clearFilters = section => {
        setSections(prevState => ({
            ...prevState,
            [section]: { startDate: '', endDate: '', filtersActive: false, isLoading: true, client: null, resetKey: + 1 }
        }));
    };


    const sectionValues = Object.values(sections);

    useEffect(() => {
    }, sectionValues);

    const handleStartDateChange = (section, value) => {
        setSections(prevState => ({
            ...prevState,
            [section]: { ...prevState[section], startDate: value }
        }));

        if (sections[section].endDate) {
            sections[section].filtersActive = true;
        }
    };

    const handleEndDateChange = (section, value) => {
        setSections(prevState => ({
            ...prevState,
            [section]: { ...prevState[section], endDate: value }
        }));

        if (sections[section].startDate) {
            sections[section].filtersActive = true;
        }
    };

    const handleActiveChange = (section, value) => {
        setSections(prevState => ({
            ...prevState,
            [section]: { ...prevState[section], filtersActive: value }
        }));
    };

    const handleSelectChangeF = (section, selectedOption) => {
        setSections(prevState => ({
            ...prevState,
            [section]: { ...prevState[section], client: selectedOption.map(option => option.value) }
        }));
    };


    const fetchDataClient = async () => {
        try {
            const response = await axios.get(`/clients`);
            const responseData = response.data;
            setClientS(responseData.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            marginLeft: '5px',
            fontSize: '16px',
            height: 'max-content',
            width: '100%',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',

        }),
    };

    const optionClients = clientS.map(clientS => ({
        value: clientS.id,
        label: clientS.name,
    }));


    useEffect(() => {
        fetchData();
        fetchDataClient();
    }, []);


    return (
        <Flex direction={'column'} width={'100%'}>
            <SimpleGrid columns={1} mt={'2%'} width={'100%'}>
                {[1, 2, 3, 4, 5, 6, 7, 24, 37].includes(user.type_user_id) ? (
                    <FiltersFieldPR
                        title="Timesheet"
                        handleDownloadExcel={() => exportToExcel('timesheetC', 'Timesheet')}
                        clearFilters={() => clearFilters('timesheetC')}
                        startDateFilter={sections['timesheetC'].startDate}
                        setStartDateFilter={(value) => handleStartDateChange('timesheetC', value)}
                        endDateFilter={sections['timesheetC'].endDate}
                        setEndDateFilter={(value) => handleEndDateChange('timesheetC', value)}
                        filtersActive={sections['timesheetC'].filtersActive}
                        setFiltersActive={(value) => handleActiveChange('timesheetC', value)}
                        isLoading={sections['timesheetC'].isLoading}
                        handleClientChange={(value) => handleSelectChangeF('timesheetC', value)}
                        resetKey={sections['timesheetC'].resetKey}
                        clientSearch={sections['timesheetC'].client}
                        optionClients={optionClients}
                        customStyles={customStyles}
                    />
                ) : null}
            </SimpleGrid>
            <SimpleGrid columns={2} width={'100%'}>
                {[1, 2, 3, 4, 5, 6, 7, 37, 46].includes(user.type_user_id) ? (
                    <FiltersField
                        title="Payroll"
                        handleDownloadExcel={() => exportToExcel('payrollSP', 'Payroll')}
                        clearFilters={() => clearFilters('payrollSP')}
                        startDateFilter={sections['payrollSP'].startDate}
                        setStartDateFilter={(value) => handleStartDateChange('payrollSP', value)}
                        endDateFilter={sections['payrollSP'].endDate}
                        setEndDateFilter={(value) => handleEndDateChange('payrollSP', value)}
                        filtersActive={sections['payrollSP'].filtersActive}
                        setFiltersActive={(value) => handleActiveChange('payrollSP', value)}
                        isLoading={sections['payrollSP'].isLoading}
                    />
                ) : null}
                {[1, 2, 3, 4, 5, 6, 7, 37].includes(user.type_user_id) ? (
                    <FiltersField
                        title="Scheduler"
                        handleDownloadExcel={() => exportToExcel('scheduler', 'Scheduler')}
                        clearFilters={() => clearFilters('scheduler')}
                        startDateFilter={sections['scheduler'].startDate}
                        setStartDateFilter={(value) => handleStartDateChange('scheduler', value)}
                        endDateFilter={sections['scheduler'].endDate}
                        setEndDateFilter={(value) => handleEndDateChange('scheduler', value)}
                        filtersActive={sections['scheduler'].filtersActive}
                        setFiltersActive={(value) => handleActiveChange('scheduler', value)}
                        isLoading={sections['scheduler'].isLoading}
                    />
                ) : null}
                {[1, 2, 3, 4, 5, 6, 7, 37].includes(user.type_user_id) ? (
                    <FiltersField
                        title="Scheduler Call outs"
                        handleDownloadExcel={() => exportToExcel('schedulerCallouts', 'SchedulerCallouts')}
                        clearFilters={() => clearFilters('schedulerCallouts')}
                        startDateFilter={sections['schedulerCallouts'].startDate}
                        setStartDateFilter={(value) => handleStartDateChange('schedulerCallouts', value)}
                        endDateFilter={sections['schedulerCallouts'].endDate}
                        setEndDateFilter={(value) => handleEndDateChange('schedulerCallouts', value)}
                        filtersActive={sections['schedulerCallouts'].filtersActive}
                        setFiltersActive={(value) => handleActiveChange('schedulerCallouts', value)}
                        isLoading={sections['schedulerCallouts'].isLoading}
                    />
                ) : null}
                {[1, 2, 3, 4, 5, 6, 7, 8, 37, 46].includes(user.type_user_id) ? (
                    <FiltersField
                        title="Late"
                        handleDownloadExcel={() => exportToExcel('late', 'Late')}
                        clearFilters={() => clearFilters('late')}
                        startDateFilter={sections['late'].startDate}
                        setStartDateFilter={(value) => handleStartDateChange('late', value)}
                        endDateFilter={sections['late'].endDate}
                        setEndDateFilter={(value) => handleEndDateChange('late', value)}
                        filtersActive={sections['late'].filtersActive}
                        setFiltersActive={(value) => handleActiveChange('late', value)}
                        isLoading={sections['late'].isLoading}
                    />
                ) : null}

                {[1, 2, 3, 4, 5, 6, 7, 8, 37].includes(user.type_user_id) ? (
                    <FiltersField
                        title="Call Outs"
                        handleDownloadExcel={() => exportToExcel('callouts', 'CallOuts')}
                        clearFilters={() => clearFilters('callouts')}
                        startDateFilter={sections['callouts'].startDate}
                        setStartDateFilter={(value) => handleStartDateChange('callouts', value)}
                        endDateFilter={sections['callouts'].endDate}
                        setEndDateFilter={(value) => handleEndDateChange('callouts', value)}
                        filtersActive={sections['callouts'].filtersActive}
                        setFiltersActive={(value) => handleActiveChange('callouts', value)}
                        isLoading={sections['callouts'].isLoading}
                    />
                ) : null}
                {[1, 2, 3, 4, 5, 6, 7, 37].includes(user.type_user_id) ? (
                    <FiltersField
                        title="No call, no show"
                        handleDownloadExcel={() => exportToExcel('nocallnoshow', 'NoCallNoShow')}
                        clearFilters={() => clearFilters('nocallnoshow')}
                        startDateFilter={sections['nocallnoshow'].startDate}
                        setStartDateFilter={(value) => handleStartDateChange('nocallnoshow', value)}
                        endDateFilter={sections['nocallnoshow'].endDate}
                        setEndDateFilter={(value) => handleEndDateChange('nocallnoshow', value)}
                        filtersActive={sections['nocallnoshow'].filtersActive}
                        setFiltersActive={(value) => handleActiveChange('nocallnoshow', value)}
                        isLoading={sections['nocallnoshow'].isLoading}
                    />
                ) : null}
                {[1, 2, 3, 4, 5, 6, 7, 30, 37, 46].includes(user.type_user_id) ? (
                    <FiltersField
                        title="Tickets IT"
                        handleDownloadExcel={() => exportToExcel('ticketsIT', 'TicketsIT')}
                        clearFilters={() => clearFilters('ticketsIT')}
                        startDateFilter={sections['ticketsIT'].startDate}
                        setStartDateFilter={(value) => handleStartDateChange('ticketsIT', value)}
                        endDateFilter={sections['ticketsIT'].endDate}
                        setEndDateFilter={(value) => handleEndDateChange('ticketsIT', value)}
                        filtersActive={sections['ticketsIT'].filtersActive}
                        setFiltersActive={(value) => handleActiveChange('ticketsIT', value)}
                        isLoading={sections['ticketsIT'].isLoading}
                    />
                ) : null}
                {[1, 2, 3, 4, 5, 6, 7, 24, 37].includes(user.type_user_id) ? (
                    <FiltersField
                        title="Tickets Schedules"
                        handleDownloadExcel={() => exportToExcel('ticketsSchedules', 'TicketsSchedules')}
                        clearFilters={() => clearFilters('ticketsSchedules')}
                        startDateFilter={sections['ticketsSchedules'].startDate}
                        setStartDateFilter={(value) => handleStartDateChange('ticketsSchedules', value)}
                        endDateFilter={sections['ticketsSchedules'].endDate}
                        setEndDateFilter={(value) => handleEndDateChange('ticketsSchedules', value)}
                        filtersActive={sections['ticketsSchedules'].filtersActive}
                        setFiltersActive={(value) => handleActiveChange('ticketsSchedules', value)}
                        isLoading={sections['ticketsSchedules'].isLoading}
                    />
                ) : null}
                {[1, 2, 3, 4, 5, 6, 7, 8, 37].includes(user.type_user_id) ? (
                    <FiltersField
                        title="Agents Bono"
                        handleDownloadExcel={() => exportToExcel('bonos', 'AgentsBono')}
                        clearFilters={() => clearFilters('bonos')}
                        startDateFilter={sections['bonos'].startDate}
                        setStartDateFilter={(value) => handleStartDateChange('bonos', value)}
                        endDateFilter={sections['bonos'].endDate}
                        setEndDateFilter={(value) => handleEndDateChange('bonos', value)}
                        filtersActive={sections['bonos'].filtersActive}
                        setFiltersActive={(value) => handleActiveChange('bonos', value)}
                        isLoading={sections['bonos'].isLoading}
                    />
                ) : null}
                {[1, 2, 3, 4, 5, 6, 7, 30, 37].includes(user.type_user_id) ? (
                    <FiltersAD
                        title="Agents without anydesk"
                        handleDownloadExcel={() => exportToExcel('anydesk', 'AgentsWAnydesk')}
                    />
                ) : null}

            </SimpleGrid>
        </Flex>
    );
}
export default UseExports; 